import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllProcedures, getAllCategories } from '../../app/config/functions';
const initialState = {
  procedureData: [],
  isLoading: false,
  initLoading: false,
  error: null,
  procedureInitData: [], 
  page: 1, 
  pageSize: 25, 
  total: 25, 
};



/*
 * Fetch procedure initial data
 */
export const fetchProcedureInitData = createAsyncThunk(
  'procedure/initData',
  async (organisationId, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllProcedures(false, organisationId, '', 1, 25);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
       
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch all procedure data
 */
export const fetchAllprocedureData = createAsyncThunk(
  'procedure/allData',
  async (organisationId, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllProcedures(false, organisationId, '', 1, 99999);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch filter procedure data
 */
export const fetchProcedureWithFilter = createAsyncThunk(
  'procedure/filter',
  async (
    {
      isGlobalTemplate,
      organisationId,
      searchName,
      page,
      pageSize,
      category,
      createdby = [],
      createdat = [],
      sort = [],
      assets=[],
    },
    { dispatch, rejectWithValue }
  ) => {

    try {
      const data = await getAllProcedures(
        isGlobalTemplate,
        organisationId,
        searchName,
        page,
        pageSize,
        category,
        createdby,
        createdat,
        sort,
        assets,
      );

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };
        return dataObj;
      
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const procedureSlice = createSlice({
  name: 'procedure',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.procedureData = action.payload;
    },
    setInitData: (state, action) => {
      state.procedureInitData = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    
      .addCase(fetchProcedureInitData.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchProcedureInitData.fulfilled, (state, action) => {
        state.initLoading = false;
        state.procedureInitData = action.payload.response;
        state.error = null; 
      })
      .addCase(fetchProcedureInitData.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchProcedureWithFilter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProcedureWithFilter.fulfilled, (state, action) => {

        state.isLoading = false;
        state.procedureData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null; 
        // if (state.procedureInitData && state.procedureInitData.length <= 0) {
        //   state.procedureInitData = action.payload.response;
        // }
      })
      .addCase(fetchProcedureWithFilter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllprocedureData.pending, (state) => {
       
      })
      .addCase(fetchAllprocedureData.fulfilled, (state, action) => {})
      .addCase(fetchAllprocedureData.rejected, (state, action) => {
   
      });
  },
});

export const {
  setData,
  setLoading,
  setError,
  setInitData,
} = procedureSlice.actions;

export default procedureSlice.reducer;
