import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";
import Animation from "../../components/common/CustomLoader";
import { Grid, Modal } from "semantic-ui-react";
import Toggle from "react-toggle";
import {
  CloseIcon,
  DashboardWOStatusIcon,
  HashBlueIcon,
  HashSvg,
  NoDashboardIcon,
  PercentageDashBoardBlueIcon,
  PercentageLightBlueIcon,
} from "../../../Assets/Icons/svg";
import { toast } from "react-toastify";
import Tooltip from "react-power-tooltip";
import AssetCategory from "./AssetCharts/AssetCategory";
import AssetAge from "./AssetCharts/AssetAge";
import AssetTCO from "./AssetCharts/AssetTCO";
import AssetValue from "./AssetCharts/AssetValue";
import DisposalSchedule from "./AssetCharts/DisposalSchedule";
import {
  getAssetDashBoardData,
  getDashboardUsersByID,
  GMAPAPIKEY,
} from "../../config/functions";
import AssetLocation from "./AssetCharts/AssetLocation";
import AssetFilter from "./Filters/assetFilter";
import { isEqual } from "lodash";
import { GoogleApiWrapper } from "google-maps-react";

const AssetsManagement = ({setWarrentySelectedDates,otherFilter,setOtherFilter,filtersTrigger,setFiltersTrigger,filterOptions,setFilterOptions,setCreateSelectedDates,customSelectedDates,updatedDates,warrentySelectedDates,google,hasNoFilterValues,createSelectedDates,setCurrentTime,currentTime,setCustomSelectedDates,}) => {
  const t = useSelector(selectTranslations);
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const [loading, setLoading] = useState(false);
  const [showUnAssign, setShowUnAssign] = useState(false);
  const [percentageTooltip, setpercentageTooltip] = useState({
    box1P: false,
    box1C: false,
    box2P: false,
    box2C: false,
    box3P: false,
    box3C: false,
  });

  const formatNumberWithCommas = (number) => {
    if (typeof number !== "number") return number;
    return number.toLocaleString(); // Automatically applies thousand separators
  };

  // const [currentTime, setCurrentTime] = useState("");
  // const [otherFilter, setOtherFilter] = useState({
  //   period: true,
  //   location: true,
  //   category: true,
  //   asset: true,
  //   status: true,
  //   people: false,
  //   teams: false,
  //   vendor: false,
  //   customer: false,
  //   manufacturer: false,
  //   warranty_date: false,
  //   date: false,
  //   refresh: "",
  // });

  const [assetDashBoardData, setAssetDashBoardData] = useState({
    totalAsset: {},
    activeAsset: {},
    assetByCategory: [],
    assetsByLocation: [],
    assetAge: {},
    disposalSchedule: [],
    TCO: [],
    assetValue: [],
  });

  // const [filtersTrigger, setFiltersTrigger] = useState({
  //   assigneeType: "people",
  //   people: {
  //     initOptions: [],
  //     options: [],
  //   },
  //   area: {
  //     initOptions: [],
  //     options: [],
  //   },
  //   orderType: {
  //     options: [
  //       {
  //         key: 1,
  //         text: t.common.all,
  //         value: "",
  //         color: "",
  //       },
  //       {
  //         key: 2,
  //         text: t.workOrders.form.order_type_1,
  //         value: "reactive",
  //       },
  //       {
  //         key: 2,
  //         text: t.workOrders.form.order_type_2,
  //         value: "scheduled",
  //       },
  //     ],
  //   },
  //   location: {
  //     initOptions: [],
  //     options: [],
  //   },
  //   status: {
  //     initOptions: [],
  //     options: [
  //       {
  //         key: "status0",
  //         value: "",
  //         text: t.common.all,
  //       },
  //       {
  //         key: "status1",
  //         value: "active",
  //         text: t.assets.active,
  //       },
  //       {
  //         key: "status2",
  //         value: "inactive",
  //         text: t.assets.inActive,
  //       },
  //       {
  //         key: "status3",
  //         value: "dispose",
  //         text: t.assets.disposed_asset,
  //       },
  //     ],
  //   },

  //   priority: {
  //     options: [
  //       {
  //         key: 0,
  //         text: t.common.all,
  //         value: "",
  //         color: null,
  //       },
  //       {
  //         key: 1,
  //         text: t.workOrders.priority_status.low,
  //         value: "low",
  //         color: "blue",
  //       },
  //       {
  //         key: 2,
  //         text: t.workOrders.priority_status.medium,
  //         value: "medium",
  //         color: "orange",
  //       },
  //       {
  //         key: 3,
  //         text: t.workOrders.priority_status.high,
  //         value: "high",
  //         color: "red",
  //       },
  //     ],
  //   },

  //   dueDate: {
  //     options: [
  //       {
  //         key: "1",
  //         value: "lastWeek",
  //         text: t.dashboard.filter.week,
  //       },
  //       {
  //         key: "2",
  //         value: "lastMonth",
  //         text: t.dashboard.filter.month,
  //       },
  //       {
  //         key: "3",
  //         value: "lastSixMonth",
  //         text: t.dashboard.filter.month_6,
  //       },
  //       {
  //         key: "4",
  //         value: "lastYear",
  //         text: t.dashboard.filter.year,
  //       },

  //       // {
  //       //   key: '5',
  //       //   value: 'date',
  //       //   text: t.dashboard.filter.date,
  //       // },
  //     ],

  //     initOptions: [
  //       {
  //         key: "1",
  //         value: "lastWeek",
  //         text: t.dashboard.filter.week,
  //       },
  //       {
  //         key: "2",
  //         value: "lastMonth",
  //         text: t.dashboard.filter.month,
  //       },
  //       {
  //         key: "3",
  //         value: "lastSixMonth",
  //         text: t.dashboard.filter.month_6,
  //       },
  //       {
  //         key: "4",
  //         value: "lastYear",
  //         text: t.dashboard.filter.year,
  //       },
  //     ],
  //   },

  //   categories: {
  //     options: [],
  //     initOptions: [],
  //   },

  //   asset: {
  //     initOptions: [],
  //     options: [],
  //   },
  //   customer: {
  //     initOptions: [],
  //     options: [],
  //   },
  //   vendor: {
  //     initOptions: [],
  //     options: [],
  //   }, manufacturer: {
  //     initOptions: [],
  //     options: [],
  //   },

  //   teams: {
  //     initOptions: [],
  //     options: [],
  //   },
  // });

  // const [filterOptions, setFilterOptions] = useState({
  //   dueDate: [],
  //   status: [],
  //   people: [],
  //   location: [],
  //   orderType: [""],
  //   categories: [],
  //   asset: [],
  //   priority: [],
  //   customer: [],
  //   vendor: [],
  //   manufacturer: [],
  //   teams: [],
  //   area: [],
  // });

  const [showPercentage, setShowPercentage] = useState({
    box1: true,
    box2: true,
    box3: true,
  });
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState();
  // const [createSelectedDates, setCreateSelectedDates] = useState([]);
  // const [customSelectedDates, setCustomSelectedDates] = useState([]);
  // const [warrentySelectedDates, setWarrentySelectedDates] = useState([]);
  const [status, setStatus] = useState(true);

  // const hasNoFilterValues = () => {
  //   return Object.values(filterOptions).every(
  //     (value) =>
  //       (Array.isArray(value) &&
  //         value.every((item) => item === "" || item.length === 0)) || // Check if all arrays are empty or only contain empty strings
  //       value === ""
  //   );
  // };

  useEffect(() => {
    (async function() {
      const filterData = await getDashboardUsersByID(user?.id);

      if (
        filterData?.assetDashboardSavedFilter &&
        !isEqual(filterData.assetDashboardSavedFilter, otherFilter)
      ) {
        // setCurrentTime(filterData.woDashboardSavedFilter?.refresh)
        setOtherFilter(filterData.assetDashboardSavedFilter);
      }
    })();
  }, []);
  useEffect(
    () => {
      setLoading(true);
      const fetchAssetDashboardData = async () => {
        let dashboardApiData = await getAssetDashBoardData(
          organisationId,
          filterOptions?.dueDate,
          filterOptions?.location,
          filterOptions?.people,
          filterOptions?.categories,
          filterOptions?.asset,
          filterOptions?.teams,
          filterOptions?.status,
          filterOptions?.customer,
          filterOptions?.vendor,
          filterOptions?.manufacturer,
          createSelectedDates,
          customSelectedDates,
          warrentySelectedDates,
          updatedDates
        );

        // const fetchData = dispatch(
        //   fetchAssetsInitData({ organisationId, role, allIds })
        // );
        if (dashboardApiData) {
          setAssetDashBoardData({
            ...assetDashBoardData,
            totalAsset: dashboardApiData?.totalAssets,
            activeAsset: dashboardApiData?.activeInactiveAssets,
            assetByCategory: dashboardApiData?.assetsByCategory,
            assetsByLocation: dashboardApiData?.assetsByLocation,
            assetAge: dashboardApiData?.assetsAge,
            disposalSchedule: dashboardApiData?.disposalSchedule,
            TCO: dashboardApiData?.TCO,
            assetValue: dashboardApiData?.assetValue,
          });

          // const updatedSeries = [
          //   dashboardApiData.WorkOrderStatus.open.count,
          //   dashboardApiData.WorkOrderStatus.inReview.count,
          //   dashboardApiData.WorkOrderStatus.onHold.count,
          //   dashboardApiData.WorkOrderStatus.completed.count,
          //   dashboardApiData.WorkOrderStatus.inProgress.count,
          // ];

          // const seriesPercentages = [
          //   open.percentage,
          //   inReview.percentage,
          //   onHold.percentage,
          //   completed.percentage,
          //   inProgress.percentage,
          // ];

          // const updatedLabels = [
          //   "Open",
          //   "In-Review",
          //   "On-Hold",
          //   "Completed",
          //   "In-Progress",
          // ];

          // setWoStatusChart(prevState => ({
          //   ...prevState,
          //   series: updatedSeries,
          //   options: {
          //     ...prevState.options,
          //     labels: updatedLabels,
          //   },
          // }));

          // const updatedStatusOptions = statusOptions.map((option) => {
          //   const count =
          //     dashboardApiData?.WorkOrderStatus[option.value]?.count || 0;
          //   return { ...option, count };
          // });

          // setStatusOptions(updatedStatusOptions);
        } else {
          toast.error(t.common.something_wrong);
        }
        setLoading(false);
      };

      fetchAssetDashboardData();
    },
    [
      filterOptions,
       createSelectedDates, customSelectedDates,warrentySelectedDates,updatedDates
    ]
  );

  const [chartConfigCategory, setChartConfigCategory] = useState({
    series: [
      {
        name: t.dashboard.asset_card.asset_no,
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false, // Disable the toolbar
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50px",  // Adjust this percentage to control overall column width
          endingShape: "rounded",
          barHeight: "75%",
        },
      },
      colors: ["#0C66E4"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false, // Hide the legend
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
        title: {
          text: t.dashboard.asset_card.category,
        },
      },
      yaxis: {
        title: {
          text: t.dashboard.asset_card.asset_no,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: "Roboto",
        },
        custom: function({ seriesIndex, dataPointIndex, w }) {
          // const value = w.config.series[seriesIndex].data[dataPointIndex]; // Get the value
          // const label = w.config.xaxis.categories[dataPointIndex]; // Get the label from categories
          // const color = w.config.colors[seriesIndex]; // Get the color from the series color
          const value = w.config.series[seriesIndex].data[dataPointIndex].y;
          const label = w.config.series[seriesIndex].data[dataPointIndex].x;
          const color = w.config.series[seriesIndex].data[dataPointIndex].fillColor;
          // Use selectedLanguage conditionally for margin adjustments
          return `
            <div style="background: white; box-shadow: 5px 4px 6px #00000026; border: 1px solid white; color: black; padding: 5px; border-radius: 8px;">
              <div style="display: flex; align-items: center;">
                <div style="width: 8px; height: 8px; background-color: ${color}; border-radius: 50%; ${
            selectedLanguage == "en" ? "margin-right: 4px" : "margin-left: 4px"
          }"></div>
                <span>${label} : </span>&nbsp; ${value}
              </div>
            </div>
          `;
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false, // Disable horizontal lines on the y-axis
          },
        },
      },
    },
  });

  const [chartConfigAge, setChartConfigAge] = useState({
    series: [
      {
        name: t.dashboard.asset_card.number_assets,
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false, // Disable the toolbar
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "75%", // Adjust this percentage to control overall column width
          barHeight: "75%",
        },
      },
      colors: ["rgba(12, 102, 228, 1)", "rgba(6, 50, 112, 1)"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false, // Hide the legend
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "0-5",
          "6-10",
          "11-15",
          "16-20",
          "21-25",
          "26-30",
          "31-35",
          "36-40",
          "41-45",
          "46-50",
          "50+",
        ],
        title: {
          text: t.dashboard.asset_card.age_yr,
        },
      },
      yaxis: {
        title: {
          text: t.dashboard.asset_card.number_assets,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: "Roboto",
        },
        custom: function({ seriesIndex, dataPointIndex, w }) {
          const value = w.config.series[seriesIndex].data[dataPointIndex]; // Get the value
          const label = w.config.xaxis.categories[dataPointIndex]; // Get the label from categories
          const color = w.config.colors[seriesIndex]; // Get the color from the series color

          // Use selectedLanguage conditionally for margin adjustments
          return `
            <div style="background: white; box-shadow: 5px 4px 6px #00000026; border: 1px solid white; color: black; padding: 5px; border-radius: 8px;">
              <div style="display: flex; align-items: center;">
                <div style="width: 8px; height: 8px; background-color: ${color}; border-radius: 50%; ${
            selectedLanguage == "en" ? "margin-right: 4px" : "margin-left: 4px"
          }"></div>
                <span>${label} : </span>&nbsp; ${value}
              </div>
            </div>
          `;
        },
      },
    },
  });

    const [chartConfigTCO, setChartConfigTCO] = useState({
      series: [
        {
          name: t.dashboard.asset_card.p_price,
          data: [],
        },
        {
          name: t.dashboard.asset_card.m_price,
          data: [],
        },
        {
          name: t.dashboard.asset_card.disposal_price,
          data: [],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false, // Disable the toolbar
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            // endingShape: "rounded",
            borderRadiusApplication: "end", // 'around', 'end'
            borderRadiusWhenStacked: "last", // 'all', 'last'

            columnWidth: "50px", // Set column width to a fixed 12px// Adjust this percentage to control overall column width
            // horizontal: false,
            // borderRadius: 10,
            // borderRadiusApplication: 'end', // 'around', 'end'
            // borderRadiusWhenStacked: 'last', // 'all', 'last'
            // barHeight: "75%",
          },
        },
        colors: ["rgba(12, 102, 228, 1)", "#9CC2F7", "#5494EE"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false, // Hide the legend
        },
        // grid: {
        //   padding: {
        //     left: 10,
        //     right: 10,
        //   },
        // },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
        
          ],
          title: {
            text: t.dashboard.asset_card.asset_category_label,
            style:{
              margin:100,
            }
          },
        },
        yaxis: {
          title: {
            text: t.dashboard.cards.cost_currency + `, ${organisationCurrency}`,
          },
          labels: {
            formatter: (value) => formatNumberWithCommas(value),
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontFamily: "Roboto",
          },
          custom: function({ seriesIndex, dataPointIndex, w }) {
            const value = w.config.series[seriesIndex].data[dataPointIndex]; // Accessing the correct data point
            const label = w.config.series[seriesIndex].name; // Accessing the series name
            const color = w.config.colors[seriesIndex]; // Accessing the corresponding color

            return `
              <div style="background: white; box-shadow: 5px 4px 6px #00000026; border: 1px solid white; color: black; padding: 5px; border-radius: 8px;">
                <div style="display: flex; align-items: center;">
                  <div style="width: 8px; height: 8px; background-color: ${color}; border-radius: 50%; margin-right: 4px;"></div>
                  <span>${label}: </span>&nbsp; ${formatNumberWithCommas(value)}
                </div>
              </div>
            `;
          },
        },
      },
    });

  const [chartConfigValue, setChartConfigValue] = useState({
    series: [
      {
        name: t.dashboard.asset_card.price,
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false, // Disable the toolbar
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50px", // Adjust this percentage to control overall column width
          endingShape: "rounded",
          barHeight: "75%",
        },
      },
      colors: ["#0A55BD"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false, // Hide the legend
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
        title: {
          text: t.dashboard.asset_card.asset_category_label,
          style:{
            margin:100,
          }
        },
      },
      yaxis: {
        title: {
          text: t.dashboard.asset_card.price + `, ${organisationCurrency}`,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: "Roboto",
        },
        custom: function({ seriesIndex, dataPointIndex, w }) {
          const value = w.config.series[seriesIndex].data[dataPointIndex].y;
          const label = w.config.series[seriesIndex].data[dataPointIndex].x;
          const color = w.config.series[seriesIndex].data[dataPointIndex].fillColor;

          // Use selectedLanguage conditionally for margin adjustments
          return `
            <div style="background: white; box-shadow: 5px 4px 6px #00000026; border: 1px solid white; color: black; padding: 5px; border-radius: 8px;">
              <div style="display: flex; align-items: center;">
                <div style="width: 8px; height: 8px; background-color: ${color}; border-radius: 50%; ${
            selectedLanguage == "en" ? "margin-right: 4px" : "margin-left: 4px"
          }"></div>
                <span>${label} : </span>&nbsp; ${value}
              </div>
            </div>
          `;
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false, // Disable horizontal lines on the y-axis
          },
        },
      },
    },
  });
  return (
    <>
      <div className="dashboard-container">
        {/* <AssetFilter
          hasNoFilterValues={hasNoFilterValues}
          createSelectedDates={createSelectedDates}
          setCurrentTime={setCurrentTime}
          currentTime={currentTime}
          setCustomSelectedDates={setCustomSelectedDates}
          setWarrentySelectedDates={setWarrentySelectedDates}
          warrentySelectedDates={warrentySelectedDates}
          customSelectedDates={customSelectedDates}
          setCreateSelectedDates={setCreateSelectedDates}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          setFiltersTrigger={setFiltersTrigger}
          filtersTrigger={filtersTrigger}
          setOtherFilter={setOtherFilter}
          otherFilter={otherFilter}
        /> */}
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "1000px " }}
          >
            <Animation />
            {/* <Placeholder fluid>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder> */}
          </div>
        ) : (
          <div className="all-cards">
            <Grid columns={"equal"} className="graph-container m-0">
              <Grid.Column className="completion-column pl-0">
                <div className="completion-column-data">
                  <div className="completion-cotainer d-flex justify-content-between mt-2">
                    <div className="completion-title d-flex">
                      <span className="title">
                        {t.dashboard.asset_box.total_asset}
                      </span>
                      <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() => setShowUnAssign(true)}
                          onMouseLeave={() => setShowUnAssign(false)}
                          className={
                            selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                          }
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "right" : "left"
                            }
                            show={showUnAssign}
                            textBoxWidth="150px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="0px 0px 0px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="end"
                            moveRight="8px"
                            // moveDown="10px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.asset_tooltip.total_asset}
                            </span>
                          </Tooltip>
                          <DashboardWOStatusIcon />
                        </p>
                    </div>
                  </div>
                  {assetDashBoardData?.totalAsset?.count ? 
                  <>
                  <div
                    style={{
                      padding: "20px 6px",

                      borderRadius: "4px",
                      marginInline: "13px",
                    }}
                    className="d-flex"
                  >
                    <div style={{ fontSize: "40px" }}>
                      {assetDashBoardData?.totalAsset?.count
                        ? formatNumberWithCommas(assetDashBoardData?.totalAsset?.count)
                        : 0}
                    </div>
                    {hasNoFilterValues() &&
                        createSelectedDates?.length == 0 &&
                        warrentySelectedDates?.length == 0 &&  updatedDates?.length == 0 &&
                        customSelectedDates?.length == 0 && (
                    <div
                      style={{
                        fontSize: "14px",
                        color: "rgba(120, 140, 165, 1)",
                      }}
                    >
                      <span
                        className={
                          selectedLanguage == "en" ? "ml-2" : "mr-2"
                        }
                        style={
                          assetDashBoardData?.totalAsset?.change == "decrease"
                            ?  { color: "rgba(209, 64, 64, 1)" }
                            :{ color: "rgba(79, 171, 104, 1)" }
                        }
                      >
                        {" "}
                        {assetDashBoardData?.totalAsset?.change == "decrease"
                          ? assetDashBoardData?.totalAsset?.decrease
                          :  assetDashBoardData?.totalAsset?.increase}
                      </span>{" "}
                      {assetDashBoardData?.totalAsset?.change == "decrease"
                        ? t.dashboard.box.decrease
                        :t.dashboard.box.increase }
                    </div>  )}
                  </div>

                  <div
                    style={{ gap: "10px" }}
                    className="mt-2 mb-16 d-flex justify-content-center"
                  ></div> </> :  <div className="d-flex mb-16 mt-5 flex-column align-items-center justify-content-center">
                  <NoDashboardIcon />
                  <p
                    style={{
                      color: "#44546F",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                    className="mt-3"
                  >
                    {t.common.no_data}
                  </p>
                </div>}
                </div>
              </Grid.Column>

              <Grid.Column className="completion-column pl-0">
                <div className="completion-column-data">
                  <div className="completion-cotainer d-flex justify-content-between mt-1">
                    <div
                      style={{ gap: "10px" }}
                      className="completion-title d-flex"
                    >
                      <div style={!status ? { color: "#788CA5", fontWeight: "400" } : {}} className="title">
                        {t.dashboard.asset_box.active}
                      </div>
                      <div>
                        <Toggle
                          className="asset-toggle"
                          checked={status}
                          onChange={(e) => setStatus(e.target.checked)}
                        />{" "}
                      </div>
                      <div
                        style={status ? { color: "#788CA5", fontWeight: "400" } : {}}
                        className="title"
                      >
                        {t.dashboard.asset_box.inActive_asset}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "20px 6px",

                      borderRadius: "4px",
                      marginInline: "13px",
                    }}
                    className="d-flex"
                  >
                    <div style={{ fontSize: "40px" }}>
                      {status
                        ? showPercentage?.box1
                          ? `${assetDashBoardData?.activeAsset?.activeAssets?.percentage}%`
                          :formatNumberWithCommas (assetDashBoardData?.activeAsset?.activeAssets?.count)
                        : showPercentage?.box1
                        ? `${assetDashBoardData?.activeAsset?.inactiveAssets?.percentage}%`
                        : formatNumberWithCommas(assetDashBoardData?.activeAsset?.inactiveAssets)
                            ?.count}
                    </div>
                    {hasNoFilterValues() &&
                        createSelectedDates?.length == 0 &&
                        warrentySelectedDates?.length == 0 &&updatedDates?.length == 0 &&
                        customSelectedDates?.length == 0 && (
                    <div
                      style={{
                        fontSize: "14px",
                        color: "rgba(120, 140, 165, 1)",
                      }}
                    >
                      <span
              className={
                selectedLanguage == "en" ? "ml-2" : "mr-2"
              }
                        style={
                          status
                            ? assetDashBoardData?.activeAsset?.activeAssets
                                ?.change == "decrease"
                              ? { color: "rgba(209, 64, 64, 1)" } // Color for activeAssets increase
                              : { color: "rgba(79, 171, 104, 1)" } // Color for activeAssets decrease
                            : assetDashBoardData?.activeAsset?.inactiveAssets
                                ?.change == "decrease"
                            ? { color: "rgba(209, 64, 64, 1)" } // Color for inactiveAssets increase
                            : { color: "rgba(79, 171, 104, 1)" } // Color for inactiveAssets decrease
                        }
                        // style={
                        //   dashBoardData?.unassignedWorkOrders
                        //     ?.findOpenWorkOrderPer?.increase == "decreased"
                        //     ? { color: "rgba(209, 64, 64, 1)" }
                        //     : { color: "rgba(79, 171, 104, 1)" }
                        // }
                      >
                        {status
                          ? showPercentage?.box1
                            ? assetDashBoardData?.activeAsset?.activeAssets
                                ?.increasePer
                              ? `${assetDashBoardData?.activeAsset?.activeAssets?.increasePer}%`
                              : assetDashBoardData?.activeAsset?.activeAssets
                                  ?.decreasePer
                              ? `${assetDashBoardData?.activeAsset?.activeAssets?.decreasePer}%`
                              : `0%`
                            : formatNumberWithCommas(assetDashBoardData?.activeAsset?.activeAssets
                                ?.increaseCount) ??
                                formatNumberWithCommas (assetDashBoardData?.activeAsset?.activeAssets
                                ?.decreaseCount) ??
                              0
                          : showPercentage?.box1
                          ? assetDashBoardData?.activeAsset?.inactiveAssets
                              ?.increasePer
                            ? `${assetDashBoardData?.activeAsset?.inactiveAssets?.increasePer}%`
                            : assetDashBoardData?.activeAsset?.inactiveAssets
                                ?.decreasePer
                            ? `${assetDashBoardData?.activeAsset?.inactiveAssets?.decreasePer}%`
                            : `0%`
                          : formatNumberWithCommas(assetDashBoardData?.activeAsset?.inactiveAssets
                              ?.increaseCount) ??
                              formatNumberWithCommas  (assetDashBoardData?.activeAsset?.inactiveAssets
                              ?.decreaseCount) ??
                            0}
                      </span>{" "}
                      {status
                        ? assetDashBoardData?.activeAsset?.activeAssets
                            ?.change == "decrease"
                          ? t.dashboard.box.decrease // Color for activeAssets increase
                          : t.dashboard.box.increase // Color for activeAssets decrease
                        : assetDashBoardData?.activeAsset?.inactiveAssets
                            ?.change == "decrease"
                        ? t.dashboard.box.decrease // Color for inactiveAssets increase
                        : t.dashboard.box.increase}
                    </div> )}
                  </div>

                  <div
                    style={{ gap: "10px" }}
                    className="mt-2 d-flex justify-content-center"
                  ></div>

                  <div
                    style={selectedLanguage == "en" ? { gap: "10px" } : {}}
                    className="mt-2 mb-4 d-flex justify-content-center"
                  >
                    <span
                      className="c-pointer"
                      onClick={() =>
                        setShowPercentage({
                          ...showPercentage,
                          box1: true,
                        })
                      }
                    >
                      <p
                        style={{
                          position: "relative",
                          cursor: "pointer",
                          marginTop: "-10px",
                        }}
                        onMouseOver={() =>
                          setpercentageTooltip({
                            ...percentageTooltip,
                            box1P: true,
                          })
                        }
                        onMouseLeave={() =>
                          setpercentageTooltip({
                            ...percentageTooltip,
                            box1P: false,
                          })
                        }
                        className="ml-3 mt-1"
                      >
                        <Tooltip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position={selectedLanguage == "en" ? "left" : "right"}
                          show={percentageTooltip?.box1P}
                          textBoxWidth="68px"
                          fontSize="10px"
                          fontWeight="400"
                          fontFamily="Roboto"
                          padding="8px 8px 8px 8px"
                          borderRadius="5px"
                          static
                          arrowAlign="start"
                          moveLeft="5px"
                          moveDown="3px"
                        >
                          <span
                            style={{ padding: "0px 10px" }}
                            className="work-order-tooltip"
                          >
                            {t.dashboard.percantage}
                          </span>
                        </Tooltip>
                        {showPercentage?.box1 ? (
                          <PercentageDashBoardBlueIcon />
                        ) : (
                          <PercentageLightBlueIcon />
                        )}
                      </p>
                    </span>

                    <span
                      className="c-pointer"
                      onClick={() =>
                        setShowPercentage({
                          ...showPercentage,
                          box1: false,
                        })
                      }
                    >
                      <p
                        style={{
                          position: "relative",
                          cursor: "pointer",
                          marginTop: "-10px",
                        }}
                        onMouseOver={() =>
                          setpercentageTooltip({
                            ...percentageTooltip,
                            box1C: true,
                          })
                        }
                        onMouseLeave={() =>
                          setpercentageTooltip({
                            ...percentageTooltip,
                            box1C: false,
                          })
                        }
                        className=" mt-1"
                      >
                        <Tooltip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position={
                            selectedLanguage == "ar" ? "left" : "right"
                          }
                          show={percentageTooltip?.box1C}
                          textBoxWidth="50px"
                          fontSize="10px"
                          fontWeight="400"
                          fontFamily="Roboto"
                          padding="8px 8px 8px 8px"
                          borderRadius="5px"
                          static
                          arrowAlign="start"
                          moveRight="5px"
                          moveDown="3px"
                        >
                          <span
                            style={{ padding: "0px 10px" }}
                            className="work-order-tooltip"
                          >
                            {t.dashboard.number}
                          </span>
                        </Tooltip>
                        {showPercentage?.box1 ? <HashSvg /> : <HashBlueIcon />}
                      </p>{" "}
                    </span>
                  </div>
                </div>
              </Grid.Column>
            </Grid>

            <Grid columns={"equal"} className="graph-container m-0">
              <AssetCategory
                setOpenModal={setOpenModal}
                setModalData={setModalData}
                openModal={openModal}
                chartConfigCategory={chartConfigCategory}
                assetDashBoardData={assetDashBoardData}
                setAssetDashBoardData={setAssetDashBoardData}
                setChartConfigCategory={setChartConfigCategory}
              />
            </Grid>

            <Grid columns={"equal"} className="graph-container m-0">
              <div className="pl-0 w-100">
                <AssetLocation
                  setOpenModal={setOpenModal}
                  google={google}
                  modalData={modalData}
                  setModalData={setModalData}
                  openModal={openModal}
                  // chartConfigCategory={chartConfigCategory}
                  assetDashBoardData={assetDashBoardData}
                  setAssetDashBoardData={setAssetDashBoardData}
                  // setChartConfigCategory={setChartConfigCategory}
                />
              </div>
            </Grid>

            <Grid columns={"equal"} className="graph-container m-0 mt-5">
              <AssetAge
                setOpenModal={setOpenModal}
                setModalData={setModalData}
                openModal={openModal}
                setChartConfigAge={setChartConfigAge}
                setAssetDashBoardData={setAssetDashBoardData}
                assetDashBoardData={assetDashBoardData}
                chartConfigAge={chartConfigAge}
              />
            </Grid>

            <Grid columns={"equal"} className="graph-container m-0">
              <DisposalSchedule
                setOpenModal={setOpenModal}
                setModalData={setModalData}
                openModal={openModal}
                setChartConfigAge={setChartConfigAge}
                assetDashBoardData={assetDashBoardData}
                setAssetDashBoardData={setAssetDashBoardData}
                chartConfigAge={chartConfigAge}
              />
            </Grid>

            <Grid columns={"equal"} className="graph-container m-0">
              <AssetTCO
                setOpenModal={setOpenModal}
                setModalData={setModalData}
                openModal={openModal}
                setChartConfigTCO={setChartConfigTCO}
                assetDashBoardData={assetDashBoardData}
                setAssetDashBoardData={setAssetDashBoardData}
                chartConfigTCO={chartConfigTCO}
              />

              <AssetValue
                setOpenModal={setOpenModal}
                assetDashBoardData={assetDashBoardData}
                setAssetDashBoardData={setAssetDashBoardData}
                setModalData={setModalData}
                openModal={openModal}
                chartConfigValue={chartConfigValue}
                setChartConfigValue={setChartConfigValue}
              />
            </Grid>
          </div>
        )}
      </div>
      <Modal
        size="large"
        open={openModal}
        className="assets-modal new-dashboard-modal"
        style={{ height: "auto" }}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className="header-container">
          <span className="assets-header">
            {modalData == "Category"
              ? t.dashboard.asset_card.asset_category
              : modalData == "Age"
              ? t.dashboard.asset_card.age
              : modalData == "Location"
              ? t.dashboard.asset_card.asset_location
              : modalData == "TCO"
              ? t.dashboard.asset_card.tco
              : modalData == "DisposalSchedule"
              ? t.dashboard.asset_card.disposal_schedule
              : modalData == "Value"
              ? t.dashboard.asset_card.value
              : ""}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content className="">
          <div className="all-cards">
            {modalData == "Category" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <AssetCategory
                  setOpenModal={setOpenModal}
                  setModalData={setModalData}
                  openModal={openModal}
                  chartConfigCategory={chartConfigCategory}
                  assetDashBoardData={assetDashBoardData}
                  setAssetDashBoardData={setAssetDashBoardData}
                  setChartConfigCategory={setChartConfigCategory}
                />
              </Grid>
            ) : modalData == "Age" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <AssetAge
                  setOpenModal={setOpenModal}
                  setModalData={setModalData}
                  openModal={openModal}
                  setChartConfigAge={setChartConfigAge}
                  setAssetDashBoardData={setAssetDashBoardData}
                  assetDashBoardData={assetDashBoardData}
                  chartConfigAge={chartConfigAge}
                />
              </Grid>
            ) : modalData == "Location" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                  {/* <div className="w-100">
                  <AssetLocation
                  google={google}
                    setOpenModal={setOpenModal}
                    setModalData={setModalData}
                    modalData={modalData}
                    openModal={openModal}
                    // chartConfigCategory={chartConfigCategory}
                    assetDashBoardData={assetDashBoardData}
                    setAssetDashBoardData={setAssetDashBoardData}
                    // setChartConfigCategory={setChartConfigCategory}
                  /></div> */}
              </Grid>
            ) : modalData == "TCO" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <AssetTCO
                  setOpenModal={setOpenModal}
                  setModalData={setModalData}
                  openModal={openModal}
                  setChartConfigTCO={setChartConfigTCO}
                  assetDashBoardData={assetDashBoardData}
                  setAssetDashBoardData={setAssetDashBoardData}
                  chartConfigTCO={chartConfigTCO}
                />
              </Grid>
            ) : modalData == "DisposalSchedule" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <DisposalSchedule
                  setOpenModal={setOpenModal}
                  setModalData={setModalData}
                  openModal={openModal}
                  setChartConfigAge={setChartConfigAge}
                  assetDashBoardData={assetDashBoardData}
                  setAssetDashBoardData={setAssetDashBoardData}
                  chartConfigAge={chartConfigAge}
                />
              </Grid>
            ) : modalData == "Value" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <AssetValue
                  setOpenModal={setOpenModal}
                  assetDashBoardData={assetDashBoardData}
                  setAssetDashBoardData={setAssetDashBoardData}
                  setModalData={setModalData}
                  openModal={openModal}
                  chartConfigValue={chartConfigValue}
                  setChartConfigValue={setChartConfigValue}
                />
              </Grid>
            ) : null}
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

// export default AssetsManagement;
export default AssetsManagement;
