export default {
  title: 'Categories',
  add: 'Category',
  title_small:"categories",
  create_category: 'Add Category',
  filter: {
    searchByName: 'Search by name',
    searchBySerialNumber: 'Search by serial number',
    area: 'Area',
    model: 'Model',
    wed: 'WED',
    barcode: 'Barcode',
    vendors: 'Vendors',
    createdByUser: 'Created By',
    createdAt: 'Created At',
    qrcode: 'QR Code',
  },
  table: {
    name: 'Name',
    ID: 'ID',
    createdByUser: 'Created By',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    action: 'Actions',
  },
  cannot_delete_category_statement:
    'This category cannot be deleted as it is already assigned to assets and parts.',
  edit_category: 'Edit Category',
  there_are_no:"There are no",
  added:"added",
  save: 'Save',
  category_name: 'Category Name',
  add_category_name: 'Add category name',
  add_a_category_name: 'Add Category',
  category: 'Category',
  jobTitle: 'Job Title',
  delete_category_statement: 'Are you sure you want to delete this category?',
  no_category_available: "Looks like you don't have categories yet.",
  category_updated_success: 'Category updated successfully',
  category_already_exist: 'Category already exist with given name.',
  job_title_already_exist: 'Job Title already exist with given name.',
  category_delete_success: 'Category deleted successfully',
  category_create_success: 'Category added successfully',
  category_upload_success: 'Category uploaded successfully',
  category_not_created: 'Category cannot be created',
  import: 'Upload',
  import_categories: 'Upload Categories',
  upload_categories:"Import Categories",
  upload_excel_category_file:
    'Please upload correct excel file to add categories',
     upload_excel_jobtitle_file:
    'Please upload correct excel file to add job title',
   
  download_sample: 'Click to download sample file format',
  status: 'Status',
  error: 'Error',
  failed: 'Failed',
  warn: 'Alert',
  success: 'Success',
  unique:"Category name must be unique",
  how_to_add_category:"How to add categories",
  no_category_added:"Start adding categories",
  not_updated:"Category cannot be updated"
};
	
