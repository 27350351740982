import React, { useState, useEffect, createRef } from "react";
import useNewCancelModal from "../../../hooks/Messages/useNewCncelModal";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import useDisplayFormattedText from "../../../hooks/useDisplayFormattedText";
import {
  Popup,
  Header,
  Modal,
  List,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  Checkbox,
  TextArea,
  Label,
  Radio,
  Loader,
  Dimmer,
  GridColumn,
} from "semantic-ui-react";
import parent_icon from "../../../Assets/Icons/parentAsset.png";
import child_icon from "../../../Assets/Icons/childAsset.png";
import gchild_icon from "../../../Assets/Icons/grandChild.png";
import calendar_icon from "../../../Assets/Icons/calender-icon.svg";
import clock_icon from "../../../Assets/Icons/clock.svg";
import DatePicker from "react-multi-date-picker";
import { useCancelModal } from "../../../hooks/Messages/useCancelModal";
import location_icon from "../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import moment from "moment";
import { format } from "date-fns";
import {
  getActiveUsersByRole,
  IMAGES_URL,
  getAllAssetsByCustomer,
  getAllTeams,
  uploadFile,
  urlToFile,
  getAllLocations,
  updateWorkOrder,
  updateWorkRequestStatus,
  getAllCustomers,
  getAllProcedures,
  getAllPartsByAssets,
  handleKeyDown,
  getAllAssets,
  getAllAreaByLocationId,
  getAllParts,
  getAllSets,
  getAllParentLocations,
  getAllAssetsOption,
  currencyDecimalPlaces,
  getAllWOPartsData,
  fetchInvLocationsOptions,
} from "../../config/functions";
import _ from "lodash";
import AddCustomerModal from "./popups/add-customer";
import AddLocationModal from "./popups/add-location";
import AddPeopleModal from "./popups/add-people";
import AddProcedureModal from "./popups/add-procedure";
import AddAssetModal from "./popups/add-asset";
import AddTeamModal from "./popups/add-team";
import { useSuccessModal } from "../../../hooks/Messages/useSuccessModal";
import DataTable from "react-data-table-component";
import Pagination from "../../components/common/CommonPagination";

import md5 from "md5";

import { useSelector } from "react-redux";

import { selectTranslations } from "../../config/i18n/slice";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import ar from "date-fns/locale/ar-SA";
import enGB from "date-fns/locale/en-GB/index.js";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Asterisk from "../../components/common/Asterisk";
import {
  AddIconWithCircle,
  AddImage,
  DeleteIcon,
  TextSnippet,
  CategoryInspection,
  CategoryMechanical,
  CloseIcon,
  CopyIcon,
  Damage,
  Electrical,
  HeadingIcon,
  OperationProcedure,
  Preventive,
  Project,
  Refrigeration,
  Safety,
  ClockIcon,
  PriceIcon,
  CalenderGreyIcon,
  CatgoryPreventive,
  CorrectiveIcon,
  PlannedIcon,
  UnPlannedIcon,
  CustomerPlusIcon,
  ArrowLeft,
  ArrowRight,
  SearchIcon,
  AddedFileIcon,
  PngPhotos,
  TxtFile,
  CsvImg,
  DocImg,
  Mp4File,
  DocxImg,
  XlsxFile,
  ImgPhotos,
  FilesIcon,
  PdfBlueIcon,
} from "../../../Assets/Icons/svg";
import { useDropzone } from "react-dropzone";
import { scrollToError } from "../../../utilFunctions/scrollToError";
import ToolTip from "react-power-tooltip";
import PartsDropdown from "../../components/common/PartsDropdown";
import { Resizable } from "re-resizable";

/*
 * The FileUpload component in JavaScript allows users to upload files with customizable drag and drop functionality.
 */

const FileUpload = ({
  onFileChange,
  name = "",
  onCertificateFileChange,
  attachments = {},
  setAttachments = () => {},
}) => {
  const fileTypes = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "image/webp",
      "image/heic",
    ],
    documents: [
      "text/plain",
      "application/pdf",
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    video: ["video/mp4", "video/quicktime", "video/webm", "video/hevc"],
  };

  const fileTypesCompare = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "image/webp",
      "image/heic",
    ],
    txt: ["text/plain"],
    csv: ["text/csv"],
    docx: [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    xlsx: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ],
    documents: ["application/msword"],
    pdf: ["application/pdf"],
    video: [
      "video/mp4",
      "video/quicktime",
      "application/octet-stream",
      "video/webm",
      "video/hevc",
    ],
  };

  const { getRootProps, getInputProps } = useDropzone({
    // accept:
    //   name === "image"
    //     ? {
    //         "image/jpeg": [],
    //         "image/jpg": [],
    //         "image/png": [],
    //         "audio/mpeg": [],
    //         "video/mp4": [],
    //       }
    //     : undefined, // No restrictions for other types
    onDrop: (acceptedFiles) => {
      const allowedFileTypes =
        name === "image"
          ? [...fileTypes.images, ...fileTypes.video]
          : [...fileTypes.images, ...fileTypes.documents, ...fileTypes.video];

      // Validate file types
      const invalidFiles = acceptedFiles.filter(
        (file) => !allowedFileTypes.includes(file.type)
      );

      // Show toast if there are invalid files
      if (invalidFiles?.length > 0) {
        toast.error(t.common.invalid_msg);
        return;
      }

      let totalFile = attachments?.file?.length + acceptedFiles?.length;
      let totalAttachment = organisationImage?.numberOfAttachments
        ? organisationImage?.numberOfAttachments
        : 10;
      let totalSize = organisationImage?.attachmentsSize
        ? organisationImage?.attachmentsSize
        : 200;
      if (name !== "image" && totalFile > totalAttachment) {
        toast.error(
          `${t.common.number_error_1} ${totalAttachment} ${t.common.number_error_2}`
        );
        return;
      }
      const validFiles = acceptedFiles.filter((file) => {
        const isImage = fileTypes.images.includes(file.type);
        const isDocument = fileTypes.documents.includes(file.type);
        const isVideo = fileTypes.video.includes(file.type);

        const validSize =
          (isVideo || isImage || isDocument) &&
          file.size < totalSize * 1024 * 1024;

        if (!validSize) {
          toast.error(
            `${t.common.file_upload_valid} ${totalSize} ${t.common.file_upload_second}`
          );
          return false;
        }
        return true;
      });

      name === "image"
        ? onFileChange(validFiles, name)
        : onCertificateFileChange(validFiles, name);
    },
  });

  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const organisationImage = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation;
    } else {
      return null;
    }
  });

  const renderFile = (file, index) => (
    <div
      className={`image-dropzone blue-border p-1 file d-flex justify-content-between text-start ${
        index == 0 ? "" : "mt-5"
      }`}
    >
      <div>
        <label
          style={{ fontSize: "14px" }}
          className="d-flex align-items-center m-1"
        >
          <div
            className={`${
              selectedLanguage == "en" ? "ml-4 mr-4" : "ml-4 mr-4"
            }`}
          >
            {fileTypesCompare?.images?.includes(file?.type) ? (
              <PngPhotos />
            ) : fileTypesCompare?.txt?.includes(file?.type) ? (
              <TxtFile />
            ) : fileTypesCompare?.csv?.includes(file?.type) ? (
              <CsvImg />
            ) : fileTypesCompare?.documents?.includes(file?.type) ? (
              <DocImg />
            ) : fileTypesCompare?.video?.includes(file?.type) ? (
              <Mp4File />
            ) : fileTypesCompare?.pdf?.includes(file?.type) ? (
              <PdfBlueIcon />
            ) : fileTypesCompare?.docx?.includes(file?.type) ? (
              <DocxImg />
            ) : fileTypesCompare?.xlsx?.includes(file?.type) ? (
              <XlsxFile />
            ) : null}
          </div>

          {file?.name}
        </label>
      </div>

      <div
        className="mr-5 ml-5 mt-1 c-pointer"
        onClick={(e) => {
          e.stopPropagation();
          const newCovidCopy = attachments.file.filter(
            (covidFile, i) => i !== index
          );
          setAttachments({
            ...attachments,
            file: newCovidCopy,
          });
        }}
      >
        <CloseIcon width={"18px"} height={"18px"} />
      </div>
    </div>
    // <div>
    //   <label>
    //     {file?.name}
    //     <span
    //       className="ml-3 mr-3 c-pointer"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         const newCovidCopy = attachments.warrantyFile.filter(
    //           (covidFile, i) => i !== index
    //         );
    //         setAttachments({
    //           ...attachments,
    //           warrantyFile: newCovidCopy,
    //         });
    //       }}
    //     >
    //       <DeleteIcon />
    //     </span>
    //   </label>
    // </div>
  );

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === "image" ? (
        attachments?.image?.name ? (
          <div
            className={`image-dropzone blue-border p-1 file d-flex justify-content-between text-start
            `}
          >
            <div>
              <label
                style={{ fontSize: "14px" }}
                className="d-flex align-items-center m-1"
              >
                <div
                  className={`${
                    selectedLanguage == "en" ? "ml-4 mr-4" : "ml-4 mr-4"
                  }`}
                >
                  {fileTypes?.images?.includes(attachments?.image.type) ? (
                    <PngPhotos />
                  ) : (
                    <Mp4File />
                  )}
                </div>

                {attachments?.image?.name}
              </label>
            </div>

            <div
              className="mr-5 ml-5 mt-1 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setAttachments({
                  ...attachments,
                  image: null,
                });
              }}
            >
              <CloseIcon width={"18px"} height={"18px"} />
            </div>
          </div>
        ) : (
          //           <div className="image-dropzone blue-border p-1 text-center mb-5">
          //             <label  style={{ fontSize: "14px" }}
          //           className="d-flex align-items-center m-1">

          // <div
          //             className={`${
          //               selectedLanguage == "en" ? "ml-4 mr-4" : "ml-4 mr-4"
          //             }`}
          //           >
          //             <DocxImg />
          //           </div>
          //               {attachments?.image?.name}

          //             </label>
          //           </div>

          <div className="image-dropzone  text-center mb-5">
            <div>
              <p className="p1">
                <div className="d-flex align-items-start justify-content-center ">
                  <div>
                    {" "}
                    <ImgPhotos />
                  </div>
                  <div
                    className={`mr-2 ml-2 ${
                      selectedLanguage == "en" ? "text-left" : "text-right"
                    }`}
                  >
                    {" "}
                    <div className="" style={{ color: "#0C66E4" }}>
                      {" "}
                      {t.workOrders.drag_img}
                    </div>{" "}
                    <p
                      className="mb-0"
                      style={{ color: "#788CA5", fontSize: "12px" }}
                    >
                      {" "}
                      {t.common.valid_files_video}
                    </p>{" "}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )
      ) : attachments?.file?.length > 0 ? (
        <>
          {attachments.file.map((file, index) => renderFile(file, index))}

          <div className="warrenty-file-dropzone mt-5 d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <span className="mt-1">
                <FilesIcon />
              </span>
              <div className="pl-4 pr-4 d-flex align-items-center">
                <p className="p1 m-0">{t.common.files}</p>
                <span style={{ color: "#788CA5" }} className="ml-3">
                  {t.common.files_attachments}
                </span>
              </div>
            </div>
            <div>
              <AddIconWithCircle />
            </div>
          </div>
        </>
      ) : (
        <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <span className="mt-1">
              <FilesIcon />
            </span>
            <div className="pl-4 pr-4 d-flex align-items-center">
              <p className="p1 m-0">{t.common.files}</p>
              <span style={{ color: "#788CA5" }} className="ml-3">
                {t.common.files_attachments}
              </span>
            </div>
          </div>
          <div>
            <AddIconWithCircle />
          </div>
        </div>
      )}
    </div>
  );
};
// const FileUpload = ({
//   onFileChange,
//   name = "",
//   attachments = {},
//   setAttachments = () => {},
// }) => {
//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop: (acceptedFiles) => {
//       onFileChange(acceptedFiles, name);
//     },
//   });
//   const t = useSelector(selectTranslations);
//   //TO DO : Customize and Style this Drag and Drop to Upload box as you want🧑‍💻😊
//   return (
//     <div {...getRootProps()}>
//       <input {...getInputProps()} />
//       {name === "image" ? (
//         attachments?.image?.name ? (
//           <div className="image-dropzone text-center mb-5">
//             <label>
//               {attachments?.image?.name}
//               <span
//                 className="ml-3 mr-3 c-pointer"
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   setAttachments({
//                     ...attachments,
//                     image: null,
//                   });
//                 }}
//               >
//                 <DeleteIcon />
//               </span>
//             </label>
//           </div>
//         ) : (
//           <div className="image-dropzone text-center mb-5">
//             <div>
//               <p className="p1">
//                 <span className="mr-2 ml-2">
//                   <AddImage />
//                 </span>
//                 <span className="mt-1">{t.workOrders.drag_img}</span>
//                 <span className="mt-1" style={{ color: "#0C66E4" }}>
//                   {" "}
//                   {t.workOrders.upload_a_file}
//                 </span>{" "}
//               </p>
//             </div>
//           </div>
//         )
//       ) : attachments?.file?.name ? (
//         <div className="warrenty-file-dropzone text-center">
//           <label>
//             {attachments?.file?.name}
//             <span
//               className="ml-3 mr-3 c-pointer"
//               onClick={(e) => {
//                 e.stopPropagation();
//                 setAttachments({
//                   ...attachments,
//                   file: null,
//                 });
//               }}
//             >
//               <DeleteIcon />
//             </span>
//           </label>
//         </div>
//       ) : (
//         <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
//           <div className="d-flex ml-4 mr-4">
//             <TextSnippet />
//             <div className="pl-4 pr-4">
//               <p className="p1 m-0">{t.workOrders.form.file}</p>
//               <p className="p2 m-0">{t.workOrders.form.attach_file}</p>
//             </div>
//           </div>
//           <div>
//             <AddIconWithCircle />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };
function Edit({
  targetedData,
  toggled,
  untoggle,
  dueDateParam,
  orderTypeParam,
  request,
  setRefresh,
  refresh,
  seriesEdit,
  setSeriesEdit,
  setFilterOptions,
  setSelectedDates,
  setSearchTitle,
  roleData,
  allIds,
}) {
  const [partsData, setPartsData] = useState([]);
  const { DisplayFormattedText } = useDisplayFormattedText();
  const {
    userPermissions,
    checkForLinkPermission,
  } = usePermissionsSimplified();
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const organisationTimezone = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.timezone;
    } else {
      return null;
    }
  });

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const getLocalTime = (offset) => {
    // `offset` is the numeric timezone offset (e.g., 2 for UTC+2, 3 for UTC+3)
    const now = new Date(); // Get the current time in local timezone
    const utcMilliseconds = now.getTime() + now.getTimezoneOffset() * 60000; // Convert local time to UTC
    const localMilliseconds = utcMilliseconds + offset * 3600000; // Add timezone offset in milliseconds

    const localDate = new Date(localMilliseconds);

    return localDate.toTimeString().slice(0, 5); // Format as "HH:mm"
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#E9F2FF",
        paddingTop: "8px", // override the cell padding for head cells
      },
    },
    cells: {
      style: {
        paddingTop: "20px",
        paddingBottom: "8px",
      },
    },
  };

  const [partSearchTitle, setPartSearchTitle] = useState("");
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [initChange, setInitChange] = useState(false);
  const [selectedTablePartData, setSelectedTablePartData] = useState([]);
  const [selectedFinalPartData, setSelectedFinalPartData] = useState([]);
  const [allPartsData, setAllPartsData] = useState([]);
  const [prevPartsData, setPrevPartsData] = useState([]);
  const [initPartsData, setInitPartsData] = useState([]);
  const [selectedInvLoc, setSelectedInvLoc] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  // const [addPartModal, toggleAddPartModal] = useState(false);
  const [addPartModal, setAddPartModal] = useState(false);
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [fetchingPartsData, setFetchingPartsData] = useState(true);
  const { setSuccessModal, SuccessModal } = useSuccessModal();
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const [buttonStatus, setButtonStatus] = useState(0);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [successModal, toggleSuccessModal] = useState(false);
  const [fetchingCustomer, setFetchingCustomer] = useState(true);
  const [fetchingLocation, setFetchingLocation] = useState(true);
  const [fetchingPeople, setFetchingPeople] = useState(true);
  const [fetchingTeams, setFetchingTeams] = useState(true);
  const [fetchingAssets, setFetchingAssets] = useState(true);
  const [fetchingParts, setFetchingParts] = useState(true);
  const [fetchingProcedures, setFetchingProcedures] = useState(true);
  const [fetchingWeeks, setFetchingWeeks] = useState(true);
  const [fetchingMonths, setFetchingMonths] = useState(true);
  const [fetchingMonthsDays, setFetchingMonthsDays] = useState(true);
  const [fetchingYears, setFetchingYears] = useState(true);
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState({
    customers: [],
    locations: [],
    inv_location: [],
    people: [],
    teams: [],
    assets: [],
    parts: [],
    area: [],
    partsData: [],
    sets: [],
    procedures: [],
    weeks: [],
    months: [],
    monthsDays: [],
    repeatEvery: [
      {
        key: 0,
        value: "daily",
        text: t.workOrders.details_view.daily,
      },

      {
        key: 1,
        value: "weekly",
        text: t.workOrders.details_view.weekly,
      },
      {
        key: 2,
        value: "monthly",
        text: t.workOrders.details_view.monthly,
      },
      {
        key: 1,
        value: "yearly",
        text: t.workOrders.details_view.yearly,
      },
    ],
    priority: [
      {
        key: 0,
        value: "low",
        text: t.workOrders.form.priority_options[1],
      },

      {
        key: 1,
        value: "medium",
        text: t.workOrders.form.priority_options[2],
      },
      {
        key: 2,
        value: "high",
        text: t.workOrders.form.priority_options[3],
      },
    ],
    years: [],
    orderTypes: [
      {
        key: 0,
        value: "reactive",
        text: t.workOrders.form.order_type_1,
      },
      {
        key: 1,
        value: "scheduled",
        text: t.workOrders.form.order_type_2,
      },
    ],
    categories: [
      {
        key: 0,
        value: "damage",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Damage />
            </span>
            {t.workOrders.form.category_options[0]}
          </span>
        ),
      },
      {
        key: 1,
        value: "corrective action Downtime",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CorrectiveIcon />
            </span>
            {t.workOrders.form.category_options[1]}
          </span>
        ),
      },
      {
        key: 2,
        value: "planned Downtime",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <PlannedIcon />
            </span>
            {t.workOrders.form.category_options[2]}
          </span>
        ),
      },
      {
        key: 3,
        value: "unplanned Downtime",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <UnPlannedIcon />
            </span>
            {t.workOrders.form.category_options[3]}
          </span>
        ),
      },
      {
        key: 4,
        value: "inspection",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CategoryInspection />
            </span>
            {t.workOrders.form.category_options[4]}
          </span>
        ),
      },
      {
        key: 5,
        value: "electrical",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Electrical />
            </span>
            {t.workOrders.form.category_options[5]}
          </span>
        ),
      },

      {
        key: 6,
        value: "mechanical",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CategoryMechanical />
            </span>
            {t.workOrders.form.category_options[6]}
          </span>
        ),
      },

      {
        key: 7,
        value: "HVAC",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Refrigeration />
            </span>
            {t.workOrders.work_order_category.HVAC}
          </span>
        ),
      },
      {
        key: 8,
        value: "preventive maintenance",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CatgoryPreventive />
            </span>
            {t.workOrders.form.category_options[8]}
          </span>
        ),
      },
      {
        key: 9,
        value: "project",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Project />
            </span>
            {t.workOrders.form.category_options[9]}
          </span>
        ),
      },
      {
        key: 10,
        value: "safety",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Safety />
            </span>
            {t.workOrders.form.category_options[10]}
          </span>
        ),
      },

      {
        key: 11,
        value: "sop",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <OperationProcedure />
            </span>
            {t.workOrders.form.category_options[11]}
          </span>
        ),
      },
    ],
  });

  const [data, setData] = useState({
    title: "",
    closeOut: "",
    description: "",
    customer: "",
    location: "",
    orderType: "reactive",
    dueDate: format(new Date(), "dd-MM-yyyy"),
    dueTime: "",
    startDate: format(new Date(), "yyyy-MM-dd"),
    startTime: getLocalTime(organisationTimezone.split("+")[1]),
    repeatEvery: "daily",
    daysRepeat: {
      sat: true,
      sun: true,
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
    },
    repeatEveryWeek: 1,
    repeatEveryMonth: 1,
    repeatMonthDay: "d1",
    repeatEveryYear: 1,
    endRepeat: 10,
    estimationTime: 0,
    laborCost: 0,
    priority: "low",

    category: "",
    people: [],
    teams: [],
    assets: [],
    parts: [],
    area: [],
    partsData: [],
    sets: [],
    procedure: "",
    endDate: format(new Date(), "dd-MM-yyyy"),
    endType: "on",
    qrcode: "",
  });
  // on to go popups
  const [addCustomerModal, toggleAddCustomerModal] = useState(false);
  const [addLocationModal, toggleAddLocationModal] = useState(false);
  const [addPeopleModal, toggleAddPeopleModal] = useState(false);
  const [addTeamModal, toggleAddTeamModal] = useState(false);
  const [addProcedureModal, toggleAddProcedureModal] = useState(false);
  const [addAssetModal, toggleAddAssetModal] = useState(false);
  // const [addPartModal, toggleAddPartModal] = useState(false);

  const [customerText, setCustomerText] = useState("");
  const [locationText, setLocationText] = useState("");
  const [peopleText, setPeopleText] = useState("");
  const [teamText, setTeamText] = useState("");
  const [procedureText, setProcedureText] = useState("");
  const [assetText, setAssetText] = useState("");
  const [partText, setPartText] = useState("");
  const [attachments, setAttachments] = useState({
    image: null,
    file: [],
  });

  const handleRowSelected = (row, checked, selectedPartsData = []) => {
    let allSelectedRows = [...selectedPartsData];
    let selectedRows = [...prevPartsData];

    if (checked) {
      allSelectedRows.push({ ...row, selectedPart: true, selectedPart: true });
      selectedRows.push({ ...row, selectedPart: true });
    } else {
      allSelectedRows = allSelectedRows.filter((el) => el?.id !== row?.id);
      selectedRows = selectedRows.filter((el) => el?.id !== row?.id);
    }

    const updatedData = [...partsData].map((item) => ({
      ...item,
      selectedPart: allSelectedRows.some((el) => el.id === item.id),
    }));

    const updatedInitData = [...initPartsData].map((item) => ({
      ...item,
      selectedPart: allSelectedRows.some((el) => el.id === item.id),
    }));

    // Check if all rows are selected to update "Select All" checkbox
    const allRowsSelected = updatedData.every((item) => item.selectedPart);

    setPartsData(updatedData);
    setInitPartsData(updatedInitData);
    setSelectedTablePartData(allSelectedRows);
    setPrevPartsData(selectedRows);
    setAreAllSelected(allRowsSelected);
  };

  const handleSelectAll = (checked) => {
    let updatedData = [...partsData];
    let selectedRows = [...prevPartsData];
    let allSelectedRowsData = [...selectedTablePartData]; // Clone the existing selected data

    if (checked) {
      // If checked, mark all rows as selected, but skip if disabled
      updatedData = updatedData.map((item) =>
        item.disable
          ? item // Skip if disabled
          : { ...item, selectedPart: true }
      );

      // Add the selected parts to allSelectedRowsData, avoiding duplicates
      updatedData.forEach((item) => {
        if (!item.disable) {
          const alreadySelected = allSelectedRowsData.some(
            (selectedItem) => selectedItem.id === item.id
          );

          if (!alreadySelected) {
            allSelectedRowsData.push({ ...item, selectedPart: true });
            selectedRows.push({ ...item, selectedPart: true });
          }
        }
      });
    } else {
      // If unchecked, mark all rows as deselected, but skip if disabled
      updatedData = updatedData.map((item) =>
        item.disable
          ? item // Skip if disabled
          : { ...item, selectedPart: false }
      );

      // Remove the deselected parts from allSelectedRowsData
      allSelectedRowsData = allSelectedRowsData.filter(
        (selectedItem) =>
          !updatedData.some(
            (updatedItem) =>
              updatedItem.id === selectedItem.id && !updatedItem.disable
          )
      );

      selectedRows = allSelectedRowsData.filter(
        (selectedItem) =>
          !updatedData.some(
            (updatedItem) =>
              updatedItem.id === selectedItem.id && !updatedItem.disable
          )
      );
    }

    const updatedInitData = [...initPartsData].map((item) => ({
      ...item,
      selectedPart:
        !item.disable && allSelectedRowsData.some((el) => el.id === item.id),
    }));

    // Update state
    setPartsData(updatedData);
    setInitPartsData(updatedInitData);
    setSelectedTablePartData(allSelectedRowsData); // Update selected rows
    setPrevPartsData(selectedRows); // Update previous state
    setAreAllSelected(checked); // Update the "Select All" state
  };

  const handleAddPartData = async (setFieldValue) => {
    setAddPartModal(false);
    setPrevPartsData([]);
    setPartSearchTitle("");
    setSelectedInvLoc("");
    setCurrentPage(1);
    setSelectedFinalPartData(selectedTablePartData);
    // setFieldValue('orderItems.items', selectedTablePartData);
  };
  let allQtyOptions = [
    {
      key: 0,
      value: "pieces",
      text: t.parts.qty_unit.piece,
    },
    {
      key: 1,
      value: "items",
      text: t.parts.qty_unit.items,
    },
    {
      key: 2,
      value: "units",
      text: t.parts.qty_unit.unit,
    },
    {
      key: 3,
      value: "liters (L)",
      text: selectedLanguage == "en" ? "l" : "لتر",
    },
    {
      key: 4,
      value: "gallons (gal)",
      text: selectedLanguage == "en" ? "gal" : "جالون",
    },
    {
      key: 5,
      value: "cubic meters (m³)",
      text: selectedLanguage == "en" ? "m³" : "م³",
    },
    {
      key: 6,
      value: "cubic feet (ft³)",
      text: selectedLanguage == "en" ? "ft³" : "ق³",
    },
    {
      key: 7,
      value: "barrels",
      text: t.parts.vol_unit.barrels,
    },
    {
      key: 8,
      value: "kilograms (kg)",
      text: selectedLanguage == "en" ? "kg" : "كجم",
    },
    {
      key: 9,
      value: "pounds (lb)",
      text: selectedLanguage == "en" ? "lb" : "رطل",
    },

    {
      key: 10,
      value: "tons",
      text: t.parts.mass_unit.tons,
    },
    {
      key: 11,
      value: "metric tonnes",
      text: t.parts.mass_unit.m_tons,
    },
  ];

  const areAnyDaysSelected = Object?.values(data?.daysRepeat).some(
    (day) => day
  );

  const HandleUnitType = (data) => {
    let unitType;
    if (data) {
      unitType = allQtyOptions.find((el) => el.value == data);
    }
    return data ? unitType?.text : null;
  };

  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };

  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const roundedValue = Math.round(value * factor) / factor;
    const formattedValue = roundedValue.toFixed(decimalPlaces);

    // Remove trailing zeros after decimal point
    if (formattedValue.indexOf(".") !== -1) {
      return formattedValue.replace(/\.?0+$/, "");
    }

    return Number(formattedValue);
  };

  const addParts = async (selectedPartsData = [], fetchdata = false) => {
    setAreAllSelected(false);
    setInitChange(true);
    // setAddPartModal(true);
    if (partsData?.length <= 0 || fetchdata) {
      setLoading(true);
      const fetchData = await getAllWOPartsData(organisationId);

      if (fetchData) {
        if (fetchData?.length == 0) {
          setLoading(false);
          // setNoPartModal(true);
        } else {
          let allPartData = fetchData?.map((part) => {
            return {
              id: `${part.countId}-${part?.inventoryLocation?.id}`,
              part: part?.id,
              type: part.type,
              countId: part?.countId,
              name: part?.name,
              totalCost:
                part?.inventoryLocation?.unitCost !== null
                  ? handleDecimalPoints(part?.inventoryLocation?.unitCost)
                  : 0,
              quantity: 1,
              unitCost:
                part?.inventoryLocation?.unitCost !== null
                  ? handleDecimalPoints(part?.inventoryLocation?.unitCost)
                  : 0,
              unitType: part?.measuringUnit,
              selectedPart: false,
              location: part?.inventoryLocation?.id,
              location_name: part?.inventoryLocation?.name,
              asset: part?.relatedAsset,
              status: part?.inventoryLocation?.status,
              disable:
                part?.inventoryLocation?.status == "outOfStock" ? true : false,
              availableQty:
                part?.inventoryLocation?.availableQuantity !== null
                  ? part?.inventoryLocation?.availableQuantity
                  : 0,
            };
          });
          const filteredParts = fetchData
            ?.map((part) => {
              // let selectedPart = selectedPartsData.find(
              //   (el) => el?.id == part?.id
              // );

              const isSelected = selectedPartsData.some(
                (selectedPart) => selectedPart.id === part.id
              );

              if (isSelected) {
                return null; // Skip this part
              } else {
                return {
                  id: `${part.countId}-${part?.inventoryLocation?.id}`,
                  type: part.type,
                  part: part?.id,
                  countId: part?.countId,
                  name: part?.name,
                  // totalCost: part?.unitCost
                  //   ? handleDecimalPoints(part?.unitCost)
                  //   : 0,
                  totalCost:
                    part?.inventoryLocation?.unitCost !== null
                      ? handleDecimalPoints(part?.inventoryLocation?.unitCost)
                      : 0,
                  quantity: 1,
                  unitCost:
                    part?.inventoryLocation?.unitCost !== null
                      ? handleDecimalPoints(part?.inventoryLocation?.unitCost)
                      : 0,
                  // unitCost: part?.unitCost
                  //   ? handleDecimalPoints(part?.unitCost)
                  //   : 0,
                  unitType: part?.measuringUnit,
                  selectedPart: false,
                  location: part?.inventoryLocation?.id,
                  location_name: part?.inventoryLocation?.name,
                  asset: part?.relatedAsset,
                  status: part?.inventoryLocation?.status,
                  disable:
                    part?.inventoryLocation?.status == "outOfStock"
                      ? true
                      : false,
                  availableQty: part?.inventoryLocation?.availableQuantity
                    ? part?.inventoryLocation?.availableQuantity
                    : 0,
                };
              }
            })
            .filter((part) => part !== null);

          setPartsData(filteredParts);
          setInitPartsData(filteredParts);
          setAllPartsData(allPartData);
          setLoading(false);
          if (!fetchdata) {
            setAddPartModal(true);
          }
        }
      } else {
        toast.error(t.common.something_wrong);
        setLoading(false);
        if (!fetchdata) {
          setAddPartModal(true);
        }
      }
      setLoading(false);
    } else {
      setLoading(true);
      setAddPartModal(true);
      const filteredParts = allPartsData
        ?.map((part) => {
          const isSelected = selectedPartsData.some(
            (selectedPart) => selectedPart.id === part.id
          );
          if (isSelected) {
            return null; // Skip this part
          } else {
            return {
              id: part?.id,
              type: part?.type,
              countId: part?.countId,
              part: part?.part,
              name: part?.name,
              totalCost: part.totalCost,
              quantity: part?.quantity ? part?.quantity : null,
              unitCost: part?.unitCost,
              unitType: part?.unitType,
              selectedPart: false,
              location: part?.location,
              location_name: part?.location_name,
              asset: part?.asset,
              status: part?.status,
              disable: part?.disable,
              availableQty: part?.availableQty ? part.availableQty : 0,

              // selectedPart: false,
              // id: part.id,
              // countId: part.countId,
              // name: part.name,
              // totalCost: part.totalCost,
              // quantity: part?.quantity ? part?.quantity : null,
              // unitCost: part.unitCost,
              // unitType: part.unitType,
              // availableQty: part.availableQty,
              // orderedQuantity: part.orderedQuantity,
              // fillMax: part?.fillMax,
            };
          }
        })
        .filter((part) => part !== null);
      setPartsData(filteredParts);
      setInitPartsData(filteredParts);
      setLoading(false);
    }
  };

  const removeFromSelectedParts = (index, selectedPartsData = []) => {
    const updatedSelectedPartsData = [...selectedPartsData];
    updatedSelectedPartsData[index].selectedPart = false;
    updatedSelectedPartsData.splice(index, 1);

    setSelectedFinalPartData(updatedSelectedPartsData);
    handleRowSelected(null, false, updatedSelectedPartsData); // Update the state
  };

  let _newData = initPartsData;

  useEffect(() => {
    // Clone the data to apply filters without mutating the original data
    let filteredData = [..._newData];
    setCurrentPage(1);
    // Apply partSearchTitle filter
    if (partSearchTitle.length > 0) {
      const re = new RegExp(_.escapeRegExp(partSearchTitle), "i");
      const isMatch = (result) => re.test(result?.name);
      filteredData = _.filter(filteredData, isMatch);
    }

    // Apply selectedInvLoc filter
    if (selectedInvLoc) {
      filteredData = filteredData.filter(
        (part) => part?.location === selectedInvLoc
      );
    }

    // Check if all parts are selected and set state
    if (filteredData.length > 0) {
      const allSelectedDisable = filteredData.every(
        (item) => item.disable === true
      );

      if (!allSelectedDisable) {
        const allSelected = filteredData
          .filter((item) => item.disable === false) // Only include non-disabled items
          .every((item) => item.selectedPart === true);
        setPartsData(filteredData);

        setAreAllSelected(allSelected);
      } else {
        setPartsData(filteredData);
        setAreAllSelected(false);
      }
      // const allSelected = filteredData.every((item) => item?.selectedPart === true);
    } else {
      setPartsData([]);
    }
  }, [partSearchTitle, selectedInvLoc]);

  const [validation, setValidation] = useState({
    title: {
      error: false,
      pattern: (title) => title != "",
      skipped: false,
      message: t.common.required_field,
    },
    category: {
      error: true,
      pattern: (category) => category != "",
      skipped: false,
      message: t.common.required_field,
    },

    daysRepeat: {
      // pattern: (customer) => customer != '',
      skipped: false,
      message: t.common.required_field,
      error: data?.orderType === "scheduled" ? !areAnyDaysSelected : false,
    },
    // customer: {
    //   error: false,
    //   pattern: (customer) => customer != '',
    //   skipped: false,
    //   message: t.common.required_field,
    // },
    endRepeat: {
      error:
        data?.orderType === "scheduled" &&
        data?.endType === "after" &&
        data?.endRepeat == "",
      pattern: (endRepeat) => endRepeat !== "",
      skipped: false,
      message: t.common.required_field,
    },
    endDate: {
      error:
        data?.orderType === "scheduled" &&
        data?.endType === "on" &&
        data?.endDate == null,
      pattern: (endRepeat) => endRepeat !== "",
      skipped: false,
      message: t.common.required_field,
    },
    location: {
      error: false,
      pattern: (location) => location != "",
      skipped: false,
      message: t.common.required_field,
    },
    // orderType: {
    //   error: false,
    //   pattern: (orderType) =>
    //     orderType != '' &&
    //     options.orderTypes.some((el) => el.value == orderType),
    //   skipped: false,
    //   message: t.common.required_field,
    // },
    // dueDate: {
    //   error: false,
    //   pattern: (date) =>
    //     moment(date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'),
    //   skipped: false,
    //   message: 'Invalid date',
    // },
    // dueTime: {
    //   error: false,
    //   pattern: (time) =>
    //     moment(time).format('HH:mm') === moment(time).format('HH:mm') &&
    //     time != '',
    //   skipped: false,
    //   message: 'Invalid time',
    // },

    procedure: {
      error: false,
      pattern: (procedure) => true,
      skipped: false,
      message: t.common.required_field,
    },
  });

  const [validForm, setValidForm] = useState(false);

  const fetchParts = async (id, areaId) => {
    let the_options_parts = [];
    let the_options_sets = [];
    const response = await getAllParts(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      [],
      [id],
      [areaId]
    );

    if (response?.response?.length > 0) {
      response.response.forEach((el) => {
        // Find the inventory entry that matches the id and areaId
        const matchedInventory = el.attributes.inventory.find(
          (inv) => inv.location.data.id == id && inv.area.data.id == areaId
        );

        // Use the matched inventory's quantity or default to 0 if no match is found
        const quantity = matchedInventory ? matchedInventory.quantity : 0;

        the_options_parts.push({
          key: `${el.id}`,
          value: `${el.id}`,
          text: `${el.attributes.name}`,
          description: `${
            quantity !== 0
              ? `${t.parts.table.qty_in_stock}: ${quantity} ${HandleUnitType(
                  el?.attributes?.measuringUnit
                )}`
              : t.parts.status.out_of_stock
          }`,
          disabled: quantity == 0 ? true : false,
        });
      });
    }
    const setResponse = await getAllSets(
      organisationId,
      "",
      1,
      99999,
      [],
      id,
      areaId
    );

    const inventoryData = filterInventoryData(
      setResponse?.response,
      id,
      areaId
    );

    setOptions((prev) => {
      return {
        ...prev,

        partsData: the_options_parts,
        sets: inventoryData,
      };
    });
  };

  const fetchArea = async (id) => {
    const response = await getAllAreaByLocationId(organisationId, id);

    let the_options_area = [];
    if (response.length > 0) {
      response.forEach((el) => {
        the_options_area.push({
          key: el.id,
          value: `${el.id}`,
          text: `${el.attributes.name}`,
        });
      });
    }

    setOptions((prev) => {
      return {
        ...prev,
        partsData: [],
        sets: [],
        area: the_options_area,
      };
    });
  };

  const resetForm = () => {
    setData({
      title: "",
      description: "",
      closeOut: "",
      customer: "",
      location: "",
      orderType: "reactive",
      startDate: format(new Date(), "yyyy-MM-dd"),
      startTime: getLocalTime(organisationTimezone.split("+")[1]),
      dueDate: format(new Date(), "yyyy-MM-dd"),
      dueTime: "",
      repeatEvery: "daily",
      daysRepeat: {
        sat: true,
        sun: true,
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
      },
      repeatEveryWeek: 1,
      repeatEveryMonth: 1,
      repeatMonthDay: "d1",
      repeatEveryYear: 1,
      endRepeat: 10,
      estimationTime: 0,
      laborCost: 0,
      priority: "low",

      category: "",
      people: [],
      teams: [],
      assets: [],
      parts: [],
      procedure: "",
      endDate: format(new Date(), "yyyy-MM-dd"),
      endType: "on",
      qrcode: "",
      area: [],
      partsData: [],
      sets: [],
    });

    setPartSearchTitle("");
    setSelectedTablePartData([]);
    setSelectedFinalPartData([]);
    setAllPartsData([]);
    setPrevPartsData([]);
    setInitPartsData([]);
    setSelectedInvLoc("");
    setPartsData([]);
    setCurrentPage(1);
    setAttachments({
      image: null,
      file: [],
    });

    setFetchingPartsData(true);

    setOptions({
      customers: [],
      locations: [],
      people: [],
      teams: [],
      assets: [],
      parts: [],
      area: [],
      partsData: [],
      inv_location: [],
      sets: [],
      procedures: [],
      priority: [
        {
          key: 0,
          value: "low",
          text: t.workOrders.form.priority_options[1],
        },

        {
          key: 1,
          value: "medium",
          text: t.workOrders.form.priority_options[2],
        },
        {
          key: 2,
          value: "high",
          text: t.workOrders.form.priority_options[3],
        },
      ],
      repeatEvery: [
        {
          key: 0,
          value: "daily",
          text: t.workOrders.details_view.daily,
        },

        {
          key: 1,
          value: "weekly",
          text: t.workOrders.details_view.weekly,
        },
        {
          key: 2,
          value: "monthly",
          text: t.workOrders.details_view.monthly,
        },
        {
          key: 1,
          value: "yearly",
          text: t.workOrders.details_view.yearly,
        },
      ],
      weeks: [],
      months: [],
      monthsDays: [],
      years: [],
      orderTypes: [
        {
          key: 0,
          value: "reactive",
          text: t.workOrders.form.order_type_1,
        },
        {
          key: 1,
          value: "scheduled",
          text: t.workOrders.form.order_type_2,
        },
      ],
      categories: [
        {
          key: 0,
          value: "damage",
          text: (
            <span>
              <span className="ml-2 mr-2">
                <Damage />
              </span>
              {t.workOrders.form.category_options[0]}
            </span>
          ),
        },
        {
          key: 1,
          value: "corrective action Downtime",
          text: (
            <span>
              <span className="ml-2 mr-2">
                <CorrectiveIcon />
              </span>
              {t.workOrders.form.category_options[1]}
            </span>
          ),
        },
        {
          key: 2,
          value: "planned Downtime",
          text: (
            <span>
              <span className="ml-2 mr-2">
                <PlannedIcon />
              </span>
              {t.workOrders.form.category_options[2]}
            </span>
          ),
        },
        {
          key: 3,
          value: "unplanned Downtime",
          text: (
            <span>
              <span className="ml-2 mr-2">
                <UnPlannedIcon />
              </span>
              {t.workOrders.form.category_options[3]}
            </span>
          ),
        },
        {
          key: 4,
          value: "inspection",
          text: (
            <span>
              <span className="ml-2 mr-2">
                <CategoryInspection />
              </span>
              {t.workOrders.form.category_options[4]}
            </span>
          ),
        },
        {
          key: 5,
          value: "electrical",
          text: (
            <span>
              <span className="ml-2 mr-2">
                <Electrical />
              </span>
              {t.workOrders.form.category_options[5]}
            </span>
          ),
        },

        {
          key: 6,
          value: "mechanical",
          text: (
            <span>
              <span className="ml-2 mr-2">
                <CategoryMechanical />
              </span>
              {t.workOrders.form.category_options[6]}
            </span>
          ),
        },

        {
          key: 7,
          value: "HVAC",
          text: (
            <span>
              <span className="ml-2 mr-2">
                <Refrigeration />
              </span>
              {t.workOrders.work_order_category.HVAC}
            </span>
          ),
        },
        {
          key: 8,
          value: "preventive maintenance",
          text: (
            <span>
              <span className="ml-2 mr-2">
                <CatgoryPreventive />
              </span>
              {t.workOrders.form.category_options[8]}
            </span>
          ),
        },
        {
          key: 9,
          value: "project",
          text: (
            <span>
              <span className="ml-2 mr-2">
                <Project />
              </span>
              {t.workOrders.form.category_options[9]}
            </span>
          ),
        },
        {
          key: 10,
          value: "safety",
          text: (
            <span>
              <span className="ml-2 mr-2">
                <Safety />
              </span>
              {t.workOrders.form.category_options[10]}
            </span>
          ),
        },

        {
          key: 11,
          value: "sop",
          text: (
            <span>
              <span className="ml-2 mr-2">
                <OperationProcedure />
              </span>
              {t.workOrders.form.category_options[11]}
            </span>
          ),
        },
      ],
    });

    setFetchingCustomer(true);
    setFetchingLocation(true);
    setFetchingPeople(true);
    setFetchingTeams(true);
    setFetchingAssets(true);
    setFetchingParts(true);
    setFetchingProcedures(true);
    setFetchingWeeks(true);
    setFetchingMonths(true);
    setFetchingMonthsDays(true);
    setFetchingYears(true);

    setValidation({
      title: {
        error: false,
        pattern: (title) => title != "",
        skipped: false,
        message: t.common.required_field,
      },
      category: {
        error: false,
        pattern: (category) => category != "",
        skipped: false,
        message: t.common.required_field,
      },
      daysRepeat: {
        // pattern: (customer) => customer != '',
        skipped: false,
        message: t.common.required_field,
        error: data?.orderType === "scheduled" ? !areAnyDaysSelected : false,
      },

      endRepeat: {
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "after" &&
          data?.endRepeat == "",
        pattern: (endRepeat) => endRepeat !== "",
        skipped: false,
        message: t.common.required_field,
      },
      endDate: {
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "on" &&
          data?.endDate == null,
        pattern: (endRepeat) => endRepeat !== "",
        skipped: false,
        message: t.common.required_field,
      },

      // customer: {
      //   error: false,
      //   pattern: (customer) => customer != '',
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      location: {
        error: false,
        pattern: (location) => location != "",
        skipped: false,
        message: t.common.required_field,
      },
      // orderType: {
      //   error: false,
      //   pattern: (orderType) =>
      //     orderType != '' &&
      //     options.orderTypes.some((el) => el.value == orderType),
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      // dueDate: {
      //   error: false,
      //   pattern: (date) =>
      //     moment(date).format('YYYY-MM-DD') ===
      //     moment(date).format('YYYY-MM-DD'),
      //   skipped: false,
      //   message: 'Invalid date',
      // },
      // dueTime: {
      //   error: false,
      //   pattern: (time) =>
      //     moment(time).format('HH:mm') === moment(time).format('HH:mm') &&
      //     time != '',
      //   skipped: false,
      //   message: 'Invalid time',
      // },
      procedure: {
        error: false,
        pattern: (procedure) => true,
        skipped: false,
        message: t.common.required_field,
      },
    });

    setModalWidth(Math.max(window.innerWidth / 2, 514));

    setValidForm(false);

    setInitChange(false);
  };

  const onCertificateFileChange = (files, name) => {
    setInitChange(true);
    let newFiles = [];
    Array.from(files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });

    setAttachments({
      ...attachments,
      [name]: [...attachments[name], ...newFiles],
    });
  };
  const getInvLocations = async () => {
    const fetchData = await fetchInvLocationsOptions(organisationId);

    let parentOptions = [];

    if (fetchData?.length > 0) {
      fetchData.forEach((el) => {
        parentOptions.push({
          key: el.id,
          value: el.id,
          text: el.name,
          disabled: el?.disable,
          image: { src: location_icon },
          className: "main-location-dropdown-option",
        });

        if (el?.subLocations?.length > 0) {
          el.subLocations.forEach((subEl) => {
            parentOptions.push({
              key: subEl.id,
              value: subEl.id,
              text: subEl.name,
              image: { src: sub_location_icon },
              className: "sub-location-dropdown-option",
              parentId: el.id, // Keep track of the parent
            });
          });
        }
      });
      setOptions({
        ...options,
        inv_location: parentOptions,
      });

      return parentOptions;
    }
  };

  const handleClosePartModal = () => {
    let newAddedData = [...prevPartsData];
    let parts = [...selectedTablePartData];

    parts = parts?.filter(
      (part) => !newAddedData.some((addedPart) => addedPart.id == part.id)
    );

    setAddPartModal(false);
    setPartSearchTitle("");
    setSelectedInvLoc("");
    setCurrentPage(1);
    setSelectedTablePartData(parts);
    setSelectedFinalPartData(parts);
  };

  const transformDataSet = (dataArray) => {
    return dataArray?.map((data) => ({
      set: data?.part, // Set the `set` field to `part` value
      location: data?.location ? data?.location : null, // Use `asset` if available, otherwise default to 58
      unitCost: data?.unitCost !== null ? Number(data?.unitCost) : null, // Use unitCost if positive, else 100
      quantity: data?.quantity !== null ? data?.quantity : 1, // Use quantity if positive, else 5
      totalCost: data?.totalCost !== null ? Number(data?.totalCost) : null,
      ...(data?.temp_id ? { id: data.temp_id } : {}), // Calculate or default
    }));
  };

  const transformDataPart = (dataArray) => {
    return dataArray?.map((data) => ({
      part: data?.part, // Set the `set` field to `part` value
      location: data?.location ? data?.location : null, // Use `asset` if available, otherwise default to 58
      unitCost: data?.unitCost !== null ? Number(data?.unitCost) : null,
      // Use unitCost if positive, else 100
      quantity: data?.quantity !== null ? data?.quantity : 1, // Use quantity if positive, else 5
      totalCost: data?.totalCost !== null ? Number(data?.totalCost) : null,
      ...(data?.temp_id ? { id: data.temp_id } : {}),
      // Calculate or default
    }));
  };

  const mergeAndTransformData = (parts = [], sets = []) => {
    return [
      ...parts.map((item) => ({
        countId: item?.part?.data?.attributes?.countId,
        part: item?.part?.data?.id,
        unitType: item?.part?.data?.attributes?.measuringUnit,
        type: "Part",
        id: `${item?.part?.data?.attributes?.countId}-${item?.location?.data?.id}`,
        temp_id: item?.id,
        name: item?.part?.data?.attributes?.name,
        location_name: item?.location?.data?.attributes?.name,
        location: item?.location?.data?.id,
        quantity: item?.quantity !== null ? item?.quantity : 0,
        unitCost: item?.unitCost !== null ? Number(item?.unitCost) : 0,
        totalCost: item?.totalCost !== null ? Number(item?.totalCost) : 0,
      })),
      ...sets.map((item) => ({
        type: "Set",
        part: item?.set?.data?.id,
        unitType: null,
        id: `${item?.set?.data?.attributes?.countId}-${item?.location?.data?.id}`,
        temp_id: item?.id,
        countId: item?.set?.data?.attributes?.countId,
        name: item?.set?.data?.attributes?.name,
        location_name: item?.location?.data?.attributes?.name,
        location: item?.location?.data?.id,
        quantity: item?.quantity !== null ? item?.quantity : 0,
        unitCost: item?.unitCost !== null ? Number(item?.unitCost) : 0,
        totalCost: item?.totalCost !== null ? Number(item?.totalCost) : 0,
      })),
    ];
  };

  useEffect(() => {
    if (toggled) {
      fetchOptions();
      getInvLocations();
      if (targetedData) {
        setData({
          title: targetedData.attributes.title,
          description: targetedData.attributes.description,
          closeOut: targetedData?.attributes?.closeOutNotes
            ? targetedData?.attributes?.closeOutNotes
            : "",
          customer: targetedData.attributes.customer.data?.id,
          location: targetedData.attributes.location.data?.id,
          orderType: targetedData.attributes.orderType,
          dueDate: format(
            new Date(targetedData.attributes.dueDate),
            "yyyy-MM-dd"
          ),
          startDate: targetedData?.attributes?.startDate
            ? format(
                new Date(targetedData?.attributes?.startDate),
                "yyyy-MM-dd"
              )
            : format(new Date(), "yyyy-MM-dd"),
          endDate: targetedData?.attributes?.endDate
            ? format(new Date(targetedData.attributes.endDate), "yyyy-MM-dd")
            : format(new Date(), "yyyy-MM-dd"),
          endType: targetedData?.attributes?.endType
            ? targetedData?.attributes?.endType
            : "on",
          dueTime: targetedData?.attributes?.dueTime
            ? format(
                new Date(
                  targetedData.attributes.dueDate +
                    "T" +
                    `${targetedData.attributes.dueTime || "00:00:00"}`
                ),
                "HH:mm" // Use "HH" instead of "kk" for 24-hour format to avoid "24:00"
              )
            : null,

          startTime: targetedData?.attributes?.startTime
            ? format(
                new Date(
                  targetedData.attributes.startDate +
                    "T" +
                    `${targetedData.attributes.startTime || "00:00:00"}`
                ),
                "HH:mm" // Use "HH" instead of "kk" for 24-hour format to avoid "24:00"
              )
            : getLocalTime(organisationTimezone.split("+")[1]),

          repeatEvery: targetedData.attributes.repeatEvery,
          daysRepeat: {
            sat: targetedData.attributes.daysRepeat?.sat,
            sun: targetedData.attributes.daysRepeat?.sun,
            mon: targetedData.attributes.daysRepeat?.mon,
            tue: targetedData.attributes.daysRepeat?.tue,
            wed: targetedData.attributes.daysRepeat?.wed,
            thu: targetedData.attributes.daysRepeat?.thu,
            fri: targetedData.attributes.daysRepeat?.fri,
          },
          repeatEveryWeek: targetedData.attributes.repeatEveryWeek,
          repeatEveryMonth: targetedData.attributes.repeatEveryMonth,
          repeatMonthDay: targetedData.attributes.repeatMonthDay,
          repeatEveryYear: targetedData.attributes.repeatEveryYear,
          endRepeat: targetedData.attributes.endRepeat,

          estimationTime: targetedData.attributes.estimationTime
            ? targetedData.attributes.estimationTime
            : 0,
          laborCost: targetedData.attributes.laborCost
            ? targetedData.attributes.laborCost
            : 0,
          priority: targetedData.attributes.priority
            ? targetedData.attributes.priority
            : "low",
          category: targetedData.attributes.category
            ? targetedData.attributes.category
            : "",

          qrcode: targetedData.attributes.id,
          people:
            targetedData.attributes.people.data?.length > 0
              ? targetedData.attributes.people.data.map((el_user) => el_user.id)
              : [],
          teams:
            targetedData.attributes.teams.data?.length > 0
              ? targetedData.attributes.teams.data.map((el_team) => el_team.id)
              : [],
          assets:
            targetedData.attributes.assets.data?.length > 0
              ? targetedData.attributes.assets.data.map(
                  (el_asset) => el_asset.id
                )
              : [],
          parts:
            targetedData.attributes.parts.data?.length > 0
              ? targetedData.attributes.parts.data.map((el_part) => el_part.id)
              : [],
          partsData:
            targetedData.attributes.parts.data?.length > 0
              ? targetedData.attributes.parts.data.map((el_part) =>
                  String(el_part.id)
                )
              : [],
          sets:
            targetedData.attributes.sets.data?.length > 0
              ? targetedData.attributes.sets.data.map((el_part) =>
                  String(el_part.id)
                )
              : [],
          area: targetedData?.attributes?.area?.data?.id
            ? String(targetedData?.attributes?.area?.data?.id)
            : null,
          procedure: targetedData.attributes.procedure.data?.id
            ? targetedData.attributes.procedure.data?.id
            : "",
          status: targetedData.attributes.status,
        });

        const selectedFinalData = mergeAndTransformData(
          targetedData?.attributes?.part,
          targetedData?.attributes?.set
        );

        addParts(selectedFinalData, true);
        setSelectedTablePartData(selectedFinalData);
        setSelectedFinalPartData(selectedFinalData);
        setAttachments({
          ...attachments,
          image: targetedData?.attributes.image?.data
            ? {
                id: targetedData?.attributes.image?.data?.id,
                file: urlToFile(
                  targetedData?.attributes.image?.data?.attributes.url,
                  targetedData?.attributes.image?.data?.attributes.name
                ),
                name: targetedData?.attributes.image?.data?.attributes.name,
                type: targetedData?.attributes.image?.data?.attributes.mime,
                cancelPopup: false,
                isChanged: false,
              }
            : null,
          file:
            targetedData?.attributes?.attachments &&
            targetedData?.attributes?.attachments?.data?.length
              ? targetedData?.attributes.attachments?.data?.map((one) => {
                  return {
                    id: one?.id,
                    file: urlToFile(
                      one?.attributes?.url,
                      one?.attributes?.name
                    ),
                    name: one?.attributes?.name,
                    type: one?.attributes?.mime,
                    cancelPopup: false,
                    isChanged: false,
                  };
                })
              : [],
        });
      }
      // }
    }
  }, [targetedData, toggled]);

  const filterInventoryData = (response, locationId, areaId) => {
    let filteredInventory = [];

    response.forEach((item) => {
      let matchCount = 0;
      let setMatchCount = 0;

      // Iterate through each part's inventory
      item?.attributes?.parts?.length > 0 &&
        item.attributes.parts.forEach((part) => {
          // Check each inventory item for the selected location match
          part?.part?.data?.attributes?.inventory &&
            part.part.data.attributes.inventory.forEach((inv) => {
              if (
                inv.location.data.id === locationId &&
                inv.area.data.id === areaId &&
                inv.quantity !== 0
              ) {
                matchCount++;
              }
            });
        });

      // If both parts' inventories match the selected location, push to filteredInventory
      if (item?.attributes?.parts?.length !== 0) {
        if (
          matchCount >= 2 &&
          !filteredInventory.some((inv) => inv.key == item.id)
        ) {
          filteredInventory.push({
            key: `${item.id}`,
            value: `${item.id}`,
            text: item?.attributes?.name,
            description: "",
            disabled: false,
          });
        } else if (!filteredInventory.some((inv) => inv.key == item.id)) {
          filteredInventory.push({
            key: `${item.id}`,
            value: `${item.id}`,
            text: item?.attributes?.name,
            description: t?.parts?.status.out_of_stock,
            disabled: true,
          });
        }
      }

      item?.attributes?.sets?.length > 0 &&
        item.attributes.sets.forEach((set) => {
          set?.set?.data?.attributes?.parts &&
            set.set.data.attributes.parts.forEach((part_single) => {
              // Check each inventory item for the selected location match
              part_single?.part?.data?.attributes?.inventory &&
                part_single.part.data.attributes.inventory.forEach((inv) => {
                  if (
                    inv.location.data.id === locationId &&
                    inv.area.data.id === areaId &&
                    inv.quantity !== 0
                  ) {
                    setMatchCount++;
                  }
                });
            });
          if (setMatchCount >= 2) {
            if (
              item?.attributes?.parts?.length == 0 &&
              !filteredInventory.some((inv) => inv.key == item.id)
            ) {
              filteredInventory.push({
                key: `${item.id}`,
                value: `${item.id}`,
                text: item?.attributes?.name,
                description: "",
                disabled: false,
              });
            }

            if (
              !filteredInventory.some((inv) => inv.key == set?.set?.data?.id)
            ) {
              filteredInventory.push({
                key: `${set?.set?.data?.id}`,
                value: `${set?.set?.data?.id}`,
                text: set?.set?.data?.attributes?.name,
                description: "",
                disabled: false,
              });
            }
          } else {
            if (
              item?.attributes?.parts?.length === 0 &&
              !filteredInventory.some((inv) => inv.key == item.id)
            ) {
              filteredInventory.push({
                key: `${item.id}`,
                value: `${item.id}`,
                text: item?.attributes?.name,
                description: t.parts.status.out_of_stock,
                disabled: true,
              });
            }

            if (
              !filteredInventory.some((inv) => inv.key == set?.set?.data?.id)
            ) {
              filteredInventory.push({
                key: `${set?.set?.data?.id}`,
                value: `${set?.set?.data?.id}`,
                text: set?.set?.data?.attributes?.name,
                description: t.parts.status.out_of_stock,
                disabled: true,
              });
            }
          }
        });
    });

    return filteredInventory;
  };

  useEffect(() => {
    if (data?.location) {
      if (!fetchingPartsData) {
        setData({
          ...data,
          area: "",
          partsData: [],
          sets: [],
        });
      }

      fetchArea(data?.location);
    }
  }, [data?.location]);

  useEffect(() => {
    if (data?.location && data?.area) {
      if (!fetchingPartsData) {
        setData({
          ...data,
          partsData: [],
          sets: [],
        });
        setOptions((prev) => {
          return {
            ...prev,
            partsData: [],
            sets: [],
          };
        });
      }

      setFetchingPartsData(false);
      fetchParts(data?.location, data?.area);
    }
  }, [data?.area]);

  //Fetch all options
  const fetchOptions = async () => {
    const [
      customers,
      locations,
      people,
      teams,
      fetchData,
      // parts,
      procedures,
      weeks,
      months,
      monthDays,
      years,
      // sets,
      // area,
      // partsData,
      // sets,
    ] = await Promise.all([
      getAllCustomers(
        organisationId,
        "",
        1,
        99999,
        !(roleData === "super_admin")
          ? targetedData?.attributes?.location?.data?.id
            ? [targetedData?.attributes?.location?.data?.id]
            : allIds
          : targetedData?.attributes?.location?.data?.id
          ? [targetedData?.attributes?.location?.data?.id]
          : []
      ),
      getAllParentLocations(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        ["name:asc"]
      ),
      getActiveUsersByRole(
        ["user", "supervisor", "limited_admin", "admin", "requester"],
        organisationId,
        !(roleData === "super_admin")
          ? targetedData?.attributes?.location?.data?.id
            ? [targetedData?.attributes?.location?.data?.id]
            : allIds
          : targetedData?.attributes?.location?.data?.id
          ? [targetedData?.attributes?.location?.data?.id]
          : []
      ),
      getAllTeams(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        !(roleData === "super_admin")
          ? targetedData?.attributes?.location?.data?.id
            ? [targetedData?.attributes?.location?.data?.id]
            : allIds
          : targetedData?.attributes?.location?.data?.id
          ? [targetedData?.attributes?.location?.data?.id]
          : []
      ),
      getAllAssetsOption(
        organisationId,
        "all_asset",
        1,
        9999,
        !(roleData === "super_admin")
          ? targetedData?.attributes?.location?.data?.id
            ? [targetedData?.attributes?.location?.data?.id]
            : []
          : targetedData?.attributes?.location?.data?.id
          ? [targetedData?.attributes?.location?.data?.id]
          : [],
        [],
        "",
        "",
        [],
        ["name:asc"]
      ),
      // getAllPartsByAssets(
      //   targetedData?.attributes.assets.data?.map((asset) => asset.id),
      //   organisationId
      // ),
      getAllProcedures(false, organisationId),
      fetchWeekOptions(),
      fetchMonthOptions(),
      fetchMonthDaysOptions(),
      fetchYearsOptions(),
      // getAllSets(organisationId),
      // getAllAreaByLocationId(
      //   organisationId,
      //   targetedData.attributes.location.data?.id
      // ),
      // getAllParts(
      //   organisationId,
      //   '',
      //   1,
      //   99999,
      //   [],
      //   [],
      //   [],
      //   [],
      //   [targetedData.attributes.location.data?.id],
      //   [targetedData.attributes.area.data?.id]
      // ),
      // getAllSets(
      //   organisationId,
      //   '',
      //   1,
      //   99999,
      //   [],
      //   targetedData.attributes.location.data?.id,
      //   targetedData.attributes.area.data?.id
      // ),
    ]);

    let parentOptions = [];
    let subOptions = [];

    let customers_options = [];
    if (customers.response) {
      customers_options = customers.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
          locationId: el?.attributes?.location?.data?.id || "",
        };
      });

      setFetchingCustomer(false);
    }

    let the_options = [];
    if (locations?.response?.length > 0) {
      locations.response.forEach((el) => {
        if (!(roleData === "super_admin")) {
          // Check if the element's ID is included in allIds
          if (allIds?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
              className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (allIds.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
              (subEl) => allIds?.includes(subEl.id)
            );

            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });

              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
            }
          }
        } else {
          parentOptions.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: location_icon },
            className: "main-location-dropdown-option",
          });

          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      parentOptions.forEach((parentOption) => {
        the_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            the_options.push(subOption);
          }
        });
      });
      setFetchingLocation(false);
    }

    let people_options = [];
    if (people.data) {
      people_options = people.data.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          description: `${teams_string_array.join(" , ")}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });

      setFetchingPeople(false);
    }

    let teams_options = [];
    if (teams?.response) {
      teams_options = teams.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });

      setFetchingTeams(false);
    }
    let assetOptions = [];
    let parentToChildMap = new Map();
    let childToGrandChildMap = new Map();
    let hierarchicalOptions = [];
    let inventoryData = [];
    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        const isDisposed = el?.attributes?.status === "dispose";

        // Access rule: Non-admin users can't access certain assets
        const isActive =
          !isDisposed &&
          (!(roleData === "super_admin")
            ? targetedData?.attributes?.location?.data?.id
              ? targetedData?.attributes?.location?.data?.id ==
                el?.attributes?.location?.data?.id
              : allIds?.includes(el?.attributes?.location?.data?.id)
            : true);

        // Filter out disposed or inactive assets with no descendants
        if (
          isDisposed ||
          (!isActive && !el?.attributes?.storedAssets?.length)
        ) {
          return;
        }

        // Create parent asset option
        const parentAsset = {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { src: parent_icon }, // Parent asset icon
          className: "main-location-dropdown-option",
          disabled: !isActive, // Disable only for non-admin roles
        };

        let hasAccessibleChildren = false;
        let hasAccessibleGrandchildren = false;

        parentToChildMap.set(el.id, []);
        assetOptions.push(parentAsset);

        // Collect child assets
        if (el?.attributes?.storedAssets?.length > 0) {
          el.attributes.storedAssets.forEach((childEl) => {
            if (childEl?.childAsset?.data) {
              const childAsset = childEl.childAsset.data;
              const childIsDisposed =
                childAsset?.attributes?.status === "dispose";

              const childIsActive =
                !childIsDisposed &&
                (!(roleData === "super_admin")
                  ? targetedData?.attributes?.location?.data?.id
                    ? targetedData?.attributes?.location?.data?.id ==
                      childAsset?.attributes?.location?.data?.id
                    : allIds?.includes(
                        childAsset?.attributes?.location?.data?.id
                      )
                  : true);

              // Filter out disposed or inactive child assets with no grandchildren
              if (
                childIsDisposed ||
                (!childIsActive && !childEl?.grandChildAsset?.data?.length)
              ) {
                return;
              }

              // Track whether there are any accessible children
              if (childIsActive) hasAccessibleChildren = true;

              const childOption = {
                key: childAsset.id,
                value: childAsset.id,
                text: `${childAsset.attributes.name}`,
                image: { src: child_icon }, // Child asset icon
                className: "sub-location-dropdown-option",
                parentId: el.id,
                disabled: !childIsActive, // Disable child based on role
              };

              parentToChildMap.get(el.id).push(childOption);
              childToGrandChildMap.set(childAsset.id, []);

              // Collect grandchild assets
              if (childEl?.grandChildAsset?.data?.length > 0) {
                childEl.grandChildAsset.data.forEach((grandChildEl) => {
                  const grandChildIsDisposed =
                    grandChildEl?.attributes?.status === "dispose";

                  const grandChildIsActive =
                    !grandChildIsDisposed &&
                    (!(roleData === "super_admin")
                      ? targetedData?.attributes?.location?.data?.id
                        ? targetedData?.attributes?.location?.data?.id ==
                          grandChildEl?.attributes?.location?.data?.id
                        : allIds?.includes(
                            grandChildEl?.attributes?.location?.data?.id
                          )
                      : true);

                  // Filter out disposed or inactive grandchild assets
                  if (grandChildIsDisposed || !grandChildIsActive) {
                    return;
                  }

                  // Track whether there are any accessible grandchildren
                  if (grandChildIsActive) hasAccessibleGrandchildren = true;

                  const grandChildOption = {
                    key: grandChildEl.id,
                    value: grandChildEl.id,
                    text: `${grandChildEl.attributes.name}`,
                    image: { src: gchild_icon }, // Grandchild asset icon
                    className: "grandchild-asset-dropdown-option",
                    parentId: el.id,
                    childParentId: childAsset.id,
                    disabled: !grandChildIsActive, // Disable based on role
                  };

                  childToGrandChildMap
                    .get(childAsset.id)
                    .push(grandChildOption);
                });
              }
            }
          });
        }

        // Ensure the parent asset is displayed if it has no accessible children or grandchildren
        if (!hasAccessibleChildren && !hasAccessibleGrandchildren && isActive) {
          // Clear the child options and only show the parent
          parentToChildMap.set(el.id, []);
        }
      });

      // Sort child and grandchild options alphabetically within each parent and child context
      parentToChildMap.forEach((children) => {
        children.sort((a, b) => a.text.localeCompare(b.text));
      });

      childToGrandChildMap.forEach((grandChildren) => {
        grandChildren.sort((a, b) => a.text.localeCompare(b.text));
      });

      // Combine all the options into a hierarchical structure
      assetOptions.forEach((parentOption) => {
        hierarchicalOptions.push(parentOption);

        const children = parentToChildMap.get(parentOption.key) || [];
        children.forEach((childOption) => {
          hierarchicalOptions.push(childOption);

          const grandChildren = childToGrandChildMap.get(childOption.key) || [];
          grandChildren.forEach((grandChildOption) => {
            hierarchicalOptions.push(grandChildOption);
          });
        });
      });
      // setOptions((prev) => {
      //       return {
      //         ...prev,
      //         assets: hierarchicalOptions,
      //       };
      //     });

      //     return hierarchicalOptions;
    }

    // if (sets?.response) {
    //   inventoryData = filterInventoryData(
    //     sets?.response
    //     // id,
    //     // areaId
    //   );
    //   // setOptions((prev) => {
    //   //   return {
    //   //     ...prev,

    //   //     sets: inventoryData,
    //   //   };
    //   // });
    // }

    // let the_options_area = [];
    // if (area.length > 0) {
    //   area.forEach((el) => {
    //     the_options_area.push({
    //       key: `${el.id}`,
    //       value: `${el.id}`,
    //       text: `${el.attributes.name}`,
    //     });
    //   });
    // }

    // let the_options_parts = [];
    // if (partsData?.response?.length > 0) {
    //   partsData.response.forEach((el) => {
    //     the_options_parts.push({
    //       key: `${el.id}`,
    //       value: `${el.id}`,
    //       text: `${el.attributes.name}`,
    //       description: `${
    //         el.attributes.status !== 'outOfStock'
    //           ? ` ${t.parts.table.qty_in_stock}: ${el?.attributes?.qtyInStock} ${el?.attributes?.measuringUnit}`
    //           : t.parts.status.out_of_stock
    //       } `,
    //       disabled: el.attributes.status == 'outOfStock' ? true : false,
    //     });
    //   });
    // }

    // const inventoryData = filterInventoryData(
    //   sets?.response,
    //   targetedData?.attributes?.location?.data?.id,
    //   targetedData?.attributes?.area?.data?.id,
    // );

    // let parts_options = [];
    // if (parts) {
    //   parts_options = parts.map((el) => {
    //     const quantity = el.attributes.quantity ? el.attributes.quantity : 0;
    //     // const the_image = el.attributes.image.data
    //     //   ? IMAGES_URL + el.attributes.image.data?.attributes.url
    //     //   : `http://gravatar.com/avatar/${md5(
    //     //       el?.attributes.name
    //     //     )}?d=identicon`;
    //     return {
    //       key: el.id,
    //       value: el.id,
    //       text: el.attributes.name + '(' + quantity + ')',
    //       // image: { avatar: true, src: the_image, size: 'tiny' },
    //     };
    //   });

    //   setFetchingParts(false);
    // }

    let procedures_options = [];
    if (procedures?.response) {
      procedures_options = procedures.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.title,
        };
      });

      setFetchingProcedures(false);
    }

    if (weeks) {
      setFetchingWeeks(false);
    }

    if (months) {
      setFetchingMonths(false);
    }

    if (monthDays) {
      setFetchingMonthsDays(false);
    }

    if (years) {
      setFetchingYears(false);
    }

    setOptions((prev) => {
      return {
        ...prev,
        customers: customers.response?.length > 0 ? customers_options : [],
        locations: locations?.response?.length > 0 ? the_options : [],
        people: people.data?.length > 0 ? people_options : [],
        teams: teams?.response?.length > 0 ? teams_options : [],
        assets: fetchData?.response?.length > 0 ? hierarchicalOptions : [],
        // parts: parts?.length > 0 ? parts_options : [],
        procedures: procedures.response?.length > 0 ? procedures_options : [],

        weeks: weeks,
        months: months,
        monthDays: monthDays,
        years: years,
        // sets: inventoryData,
      };
    });

    // setOptions({
    //   ...options,
    //   customers: customers.response?.length > 0 ? customers_options : [],
    //   locations: locations?.length > 0 ? locations_options : [],
    //   people: people.data?.length > 0 ? people_options : [],
    //   teams: teams.response?.length > 0 ? teams_options : [],
    //   assets: assets.response?.length > 0 ? assets_options : [],
    //   // parts: parts?.length > 0 ? parts_options : [],
    //   procedures: procedures.response?.length > 0 ? procedures_options : [],

    //   weeks: weeks,
    //   months: months,
    //   monthDays: monthDays,
    //   years: years,
    //   // area: area.length > 0 ? the_options_area : [],
    //   // partsData: partsData?.response.length > 0 ? the_options_parts : [],
    //   // sets:inventoryData,
    // });
  };

  const onFileChange = (files, name) => {
    setInitChange(true);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setAttachments({
      ...attachments,
      [name]: {
        file: files[0],
        id: randomnumber,
        name: files[0].name,
        type: files[0].type,
        cancelPopup: false,
        isChanged: true,
      },
    });
  };

  useEffect(() => {
    if (dueDateParam)
      setData({
        ...data,
        dueDate: dueDateParam
          ? format(new Date(dueDateParam), "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
      });
  }, [dueDateParam]);

  /*
   * The function `fetchWeekOptions` generates an array of objects representing week options
   */
  const fetchWeekOptions = () => {
    let weeks = [];

    for (let i = 1; i < 21; i++) {
      const obj = {
        key: i,
        value: i,
        text: i,
      };

      weeks.push(obj);
    }

    return weeks;
  };

  /*
   * The function `fetchMonthOptions` generates an array of objects representing months from 1 to 12.
   */
  const fetchMonthOptions = () => {
    let months = [];

    for (let i = 1; i < 25; i++) {
      const obj = {
        key: i,
        value: i,
        text: i,
      };

      months.push(obj);
    }
    return months;
  };

  /*
   * The function `fetchMonthDaysOptions` generates an array of objects representing options for selecting days in a month, with special handling for the suffixes 'st', 'nd', 'rd', and 'th'.
   */
  const fetchMonthDaysOptions = () => {
    let monthsDays = [];

    for (let i = 1; i < 32; i++) {
      let day_th = "th";
      if (i == 1) day_th = "st";
      if (i == 2) day_th = "nd";
      if (i == 3) day_th = "rd";

      const obj = {
        key: i,
        value: "d" + i,
        text: i > 28 ? i + day_th + " (or last)" : i + day_th,
      };

      monthsDays.push(obj);
    }

    setOptions({
      ...options,
      monthsDays: monthsDays,
    });
  };

  /*
   * The function `fetchYearsOptions` generates an array of objects representing years
   */
  const fetchYearsOptions = () => {
    let years = [];

    for (let i = 1; i < 11; i++) {
      const obj = {
        key: i,
        value: i,
        text: i,
      };

      years.push(obj);
    }

    return years;
  };

  const fetchCustomerOptions = async () => {
    const fetchData = await await getAllCustomers(
      organisationId,
      "",
      1,
      99999,
      !(roleData === "super_admin")
        ? data?.location
          ? [data?.location]
          : allIds
        : data?.location
        ? [data?.location]
        : []
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
          locationId: el?.attributes?.location?.data?.id || "",
        };
      });
      setOptions({
        ...options,
        customers: the_options,
      });

      return the_options;
    }
  };

  const fetchLocationsOptions = async (customerID) => {
    const fetchData = await getAllParentLocations(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      ["name:asc"]
    );
    let the_options = [];
    let parentOptions = [];
    let subOptions = [];
    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        if (!(roleData === "super_admin")) {
          // Check if the element's ID is included in allIds
          if (allIds?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
              className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (allIds.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
              (subEl) => allIds?.includes(subEl.id)
            );

            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });

              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
            }
          }
        } else {
          // Add parent option
          parentOptions.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: location_icon },
            className: "main-location-dropdown-option",
          });

          // Collect sub-options
          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });

      // Sort sub-options alphabetically
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      // Merge parent options with sorted sub-options
      parentOptions.forEach((parentOption) => {
        the_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            the_options.push(subOption);
          }
        });
      });

      // Update the options state
      setOptions((prev) => ({
        ...prev,
        locations: the_options,
      }));

      return the_options;
    }
  };

  const fetchPeopleOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ["user", "supervisor", "limited_admin", "admin", "requester"],
      organisationId,
      !(roleData === "super_admin")
        ? data?.location
          ? [data?.location]
          : allIds
        : data?.location
        ? [data?.location]
        : []
    );

    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          description: `${teams_string_array.join(" , ")}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions({
        ...options,
        people: the_options,
      });

      return the_options;
    }
  };

  const fetchTeamsOptions = async () => {
    const fetchData = await getAllTeams(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      !(roleData === "super_admin")
        ? data?.location
          ? [data?.location]
          : allIds
        : data?.location
        ? [data?.location]
        : []
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions({
        ...options,
        teams: the_options,
      });

      return the_options;
    }
  };

  const fetchAssetsOptions = async (customerID, locations) => {
    const fetchData = await getAllAssetsOption(
      organisationId,
      "all_asset",
      1,
      9999,
      data?.location
        ? [data?.location]
        : !(roleData === "super_admin")
        ? data?.location
          ? [data?.location]
          : []
        : [],
      [],
      "",
      "",
      [],
      ["name:asc"]
    );

    let assetOptions = [];
    let parentToChildMap = new Map();
    let childToGrandChildMap = new Map();
    let hierarchicalOptions = [];

    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        const isDisposed = el?.attributes?.status === "dispose";

        // Access rule: Non-admin users can't access certain assets
        const isActive =
          !isDisposed &&
          (!(roleData === "super_admin")
            ? data?.location
              ? data?.location == el?.attributes?.location?.data?.id
              : allIds?.includes(el?.attributes?.location?.data?.id)
            : true);

        // Filter out disposed or inactive assets with no descendants
        if (
          isDisposed ||
          (!isActive && !el?.attributes?.storedAssets?.length)
        ) {
          return;
        }

        // Create parent asset option
        const parentAsset = {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { src: parent_icon }, // Parent asset icon
          className: "main-location-dropdown-option",
          disabled: !isActive, // Disable only for non-admin roles
        };

        let hasAccessibleChildren = false;
        let hasAccessibleGrandchildren = false;

        parentToChildMap.set(el.id, []);
        assetOptions.push(parentAsset);

        // Collect child assets
        if (el?.attributes?.storedAssets?.length > 0) {
          el.attributes.storedAssets.forEach((childEl) => {
            if (childEl?.childAsset?.data) {
              const childAsset = childEl.childAsset.data;
              const childIsDisposed =
                childAsset?.attributes?.status === "dispose";

              const childIsActive =
                !childIsDisposed &&
                (!(roleData === "super_admin")
                  ? data?.location
                    ? data?.location ==
                      childAsset?.attributes?.location?.data?.id
                    : allIds?.includes(
                        childAsset?.attributes?.location?.data?.id
                      )
                  : true);

              // Filter out disposed or inactive child assets with no grandchildren
              if (
                childIsDisposed ||
                (!childIsActive && !childEl?.grandChildAsset?.data?.length)
              ) {
                return;
              }

              // Track whether there are any accessible children
              if (childIsActive) hasAccessibleChildren = true;

              const childOption = {
                key: childAsset.id,
                value: childAsset.id,
                text: `${childAsset.attributes.name}`,
                image: { src: child_icon }, // Child asset icon
                className: "sub-location-dropdown-option",
                parentId: el.id,
                disabled: !childIsActive, // Disable child based on role
              };

              parentToChildMap.get(el.id).push(childOption);
              childToGrandChildMap.set(childAsset.id, []);

              // Collect grandchild assets
              if (childEl?.grandChildAsset?.data?.length > 0) {
                childEl.grandChildAsset.data.forEach((grandChildEl) => {
                  const grandChildIsDisposed =
                    grandChildEl?.attributes?.status === "dispose";

                  const grandChildIsActive =
                    !grandChildIsDisposed &&
                    (!(roleData === "super_admin")
                      ? data?.location
                        ? data?.location ==
                          grandChildEl?.attributes?.location?.data?.id
                        : allIds?.includes(
                            grandChildEl?.attributes?.location?.data?.id
                          )
                      : true);

                  // Filter out disposed or inactive grandchild assets
                  if (grandChildIsDisposed || !grandChildIsActive) {
                    return;
                  }

                  // Track whether there are any accessible grandchildren
                  if (grandChildIsActive) hasAccessibleGrandchildren = true;

                  const grandChildOption = {
                    key: grandChildEl.id,
                    value: grandChildEl.id,
                    text: `${grandChildEl.attributes.name}`,
                    image: { src: gchild_icon }, // Grandchild asset icon
                    className: "grandchild-asset-dropdown-option",
                    parentId: el.id,
                    childParentId: childAsset.id,
                    disabled: !grandChildIsActive, // Disable based on role
                  };

                  childToGrandChildMap
                    .get(childAsset.id)
                    .push(grandChildOption);
                });
              }
            }
          });
        }

        // Ensure the parent asset is displayed if it has no accessible children or grandchildren
        if (!hasAccessibleChildren && !hasAccessibleGrandchildren && isActive) {
          // Clear the child options and only show the parent
          parentToChildMap.set(el.id, []);
        }
      });

      // Sort child and grandchild options alphabetically within each parent and child context
      parentToChildMap.forEach((children) => {
        children.sort((a, b) => a.text.localeCompare(b.text));
      });

      childToGrandChildMap.forEach((grandChildren) => {
        grandChildren.sort((a, b) => a.text.localeCompare(b.text));
      });

      // Combine all the options into a hierarchical structure
      assetOptions.forEach((parentOption) => {
        hierarchicalOptions.push(parentOption);

        const children = parentToChildMap.get(parentOption.key) || [];
        children.forEach((childOption) => {
          hierarchicalOptions.push(childOption);

          const grandChildren = childToGrandChildMap.get(childOption.key) || [];
          grandChildren.forEach((grandChildOption) => {
            hierarchicalOptions.push(grandChildOption);
          });
        });
      });
      setOptions((prev) => {
        return {
          ...prev,
          assets: hierarchicalOptions,
        };
      });

      return hierarchicalOptions;
    }
  };

  // const fetchPartsOptions = async (assetsID) => {
  //   const fetchData = await getAllPartsByAssets(assetsID, organisationId);

  //   if (fetchData) {
  //     let the_options = fetchData.map((el) => {
  //       const quantity = el.attributes.quantity ? el.attributes.quantity : 0;
  //       // const the_image = el.attributes.image.data
  //       //   ? IMAGES_URL + el.attributes.image.data?.attributes.url
  //       //   : `http://gravatar.com/avatar/${md5(
  //       //       el?.attributes.name
  //       //     )}?d=identicon`;
  //       return {
  //         key: el.id,
  //         value: el.id,
  //         text: el.attributes.name + '(' + quantity + ')',
  //         description: el.attributes.asset.data?.attributes.name,
  //         // image: { avatar: true, src: the_image, size: 'tiny' },
  //       };
  //     });

  //     setOptions({
  //       ...options,
  //       parts: the_options,
  //     });

  //     return the_options;
  //   }
  // };

  const fetchProceduresOptions = async () => {
    const fetchData = await getAllProcedures(false.valueOf, organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.title,
        };
      });

      setOptions({
        ...options,
        procedures: the_options,
      });

      return the_options;
    }
  };

  /*
   * The `onValuesChange` function in JavaScript handles changes in form field values, updating state based on the type of field (boolean or dropdown) and performing validation.
   */
  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    const boolean_fields = ["includeDueTime"];
    const dropdown_fields = [
      "customer",
      "location",
      "orderType",
      "category",
      "code",
      "people",
      "teams",
      "assets",
      "parts",
      "partsData",
      "area",
      "sets",
      "procedure",
      "repeatEveryWeek",
      "repeatEveryMonth",
      "repeatMonthDay",
      "repeatEveryYear",
      "endType",
    ];

    if (!boolean_fields.includes(name) || !dropdown_fields.includes(name))
      setData({
        ...data,
        [name]: event.target.value,
      });

    if (boolean_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.checked,
      });
    }

    if (dropdown_fields.includes(name)) {
      if (name == "location") {
        setData({
          ...data,
          [name]: value.value,
          customer: "",
          people: [],
          teams: [],
          assets: [],
        });

        setOptions({
          ...options,
          customers: [],
          people: [],
          teams: [],
          assets: [],
        });
      } else if (name == "orderType") {
        setData({
          ...data,
          [name]: value.value,
          startDate: format(new Date(), "yyyy-MM-dd"),
          startTime: getLocalTime(organisationTimezone.split("+")[1]),
          dueDate: dueDateParam
            ? format(new Date(dueDateParam), "yyyy-MM-dd")
            : format(new Date(), "yyyy-MM-dd"),
          dueTime: "",
          endDate: format(new Date(), "yyyy-MM-dd"),
        });
        // resulteNewData = {
        //   ...data,
        //   [name]: actualValue || value.value,
        //   startDate: format(new Date(), "yyyy-MM-dd"),
        //   startTime: getLocalTime(organisationTimezone.split("+")[1]),
        //   dueDate: dueDateParam
        //     ? format(new Date(dueDateParam), "yyyy-MM-dd")
        //     : format(new Date(), "yyyy-MM-dd"),
        //   dueTime: "",
        //   endDate: format(new Date(), "yyyy-MM-dd"),
        // };
      } else {
        setData({
          ...data,
          [name]: value.value,
        });
      }
    }
  };

  /*
   * The `cancelHandler` function resets form fields, hides modals, and updates validation status for various form fields.
   */
  const cancelHandler = () => {
    resetForm();
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    untoggle();
    toggleCancelModal(false);
    setNewCancelModal(false);
    setInitChange(false);
    setButtonStatus(0);
    setValidation({
      title: {
        error: false,
        pattern: (title) => title != "",
        skipped: false,
        message: t.common.required_field,
      },
      category: {
        error: false,
        pattern: (category) => category != "",
        skipped: false,
        message: t.common.required_field,
      },

      // customer: {
      //   error: false,
      //   pattern: (customer) => customer != '',
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      location: {
        error: false,
        pattern: (location) => location != "",
        skipped: false,
        message: t.common.required_field,
      },

      procedure: {
        error: false,
        pattern: (procedure) => true,
        skipped: false,
        message: t.common.required_field,
      },

      endDate: {
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "on" &&
          data?.endDate == null,
        pattern: (endRepeat) => endRepeat !== "",
        skipped: false,
        message: t.common.required_field,
      },

      endRepeat: {
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "after" &&
          data?.endRepeat === "",
        pattern: (endRepeat) => endRepeat !== "",
        skipped: false,
        message: t.common.required_field,
      },
    });
  };

  const onFilesChange = (e) => {
    let newFiles = [];
    Array.from(e.target.files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });

    newFiles = attachments.concat(newFiles);

    // console.log('new files', newFiles);

    setAttachments(newFiles);
  };

  const uploadFilessHandler = async (files = []) => {
    // console.log(files);
    let theFiles = files.filter((el) => el.isChanged);
    theFiles = theFiles.map((el) => el.file);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  const approveOrder = async (orderID) => {
    const approveOrderResponse = await updateWorkRequestStatus(
      request.id,
      "approved",
      orderID,
      ""
    );

    return approveOrderResponse;
  };

  /*
   * The `validationHandler` function updates validation properties based on data inputs and checks for any errors in the data.
   */
  const validationHandler = async () => {
    setValidation({
      ...validation,
      title: {
        ...validation.title,
        error: validation.title.pattern(data.title) ? false : true,
        skipped: true,
      },
      category: {
        ...validation.category,
        error: validation.category.pattern(data.category) ? false : true,
        skipped: true,
      },

      // customer: {
      //   ...validation.customer,
      //   error: validation.customer.pattern(data.customer) ? false : true,
      //   skipped: true,
      // },
      location: {
        ...validation.location,
        error: validation.location.pattern(data.location) ? false : true,
        skipped: true,
      },
      endRepeat: {
        ...validation.endRepeat,
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "after" &&
          data?.endRepeat === "",
        skipped: true,
      },

      endDate: {
        ...validation.endDate,
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "on" &&
          data?.endDate == null,
        skipped: true,
      },

      daysRepeat: {
        ...validation.daysRepeat,
        error: data?.orderType === "scheduled" ? !areAnyDaysSelected : false,
      },
      // orderType: {
      //   ...validation.orderType,
      //   error: validation.orderType.pattern(data.orderType) ? false : true,
      //   skipped: true,
      // },
      // dueDate: {
      //   ...validation.dueDate,
      //   error: validation.dueDate.pattern(data.dueDate) ? false : true,
      //   skipped: true,
      // },
      // dueTime: {
      //   ...validation.dueTime,
      //   error: validation.dueTime.pattern(data.dueTime) ? false : true,
      //   skipped: true,
      // },
      procedure: {
        ...validation.procedure,
        error: validation.procedure.pattern(data.procedure) ? false : true,
        skipped: true,
      },
    });
  };

  useEffect(() => {
    const validationToArray = Object.entries(validation);
    const cond = validationToArray.some((el) => el[1].error && el[1].skipped);

    if (initChange) setValidForm(!cond);
  }, [validation, initChange]);

  useEffect(() => {
    validationHandler();
  }, [data]);

  const updateWorkOrderHandler = async () => {
    const validationToArray = Object.entries(validation);
    const errorsInData = validationToArray.some((el) => el[1].error);
    if (!errorsInData) {
      const parts = selectedFinalPartData.filter(
        (item) => item.type === "Part"
      );
      const sets = selectedFinalPartData.filter((item) => item.type === "Set");

      let setData = transformDataSet(sets);
      let partData = transformDataPart(parts);

      setButtonStatus(1);
      const assetImageReponse =
        attachments.image && attachments.image.isChanged
          ? await uploadFile([attachments.image.file])
          : null;

      const warrantyFileResponse =
        attachments.file && attachments?.file?.length
          ? await uploadFilessHandler(attachments.file)
          : null;

      let theAttachments =
        warrantyFileResponse?.status == 200
          ? warrantyFileResponse.data.map((el) => el.id)
          : [];
      let unchangedAttachments = attachments.file.filter((el) => !el.isChanged);
      unchangedAttachments =
        unchangedAttachments.length > 0
          ? unchangedAttachments.map((el) => el.id)
          : [];
      // console.log(unchangedAttachments)
      const toBeSendedCertificateFiles = theAttachments.concat(
        unchangedAttachments
      );

      let sendedDueTime = data?.dueTime
        ? format(new Date(data.dueDate + "T" + data?.dueTime), "kk:mm:ss.SSS")
        : null;

      let sendedStartTime =
        data?.orderType == "reactive"
          ? data?.startTime
            ? format(
                new Date(data?.startDate + "T" + data?.startTime),
                "kk:mm:ss.SSS"
              )
            : format(
                new Date(
                  data?.startDate +
                    "T" +
                    getLocalTime(organisationTimezone.split("+")[1])
                ),
                "kk:mm:ss.SSS"
              )
          : null;

      await updateAsset2Handler(
        assetImageReponse?.status == 200
          ? assetImageReponse.data[0].id
          : attachments?.image?.id
          ? attachments?.image.id
          : null,
        toBeSendedCertificateFiles,
        sendedDueTime,
        partData,
        setData,
        sendedStartTime
      );
    } else {
      setButtonStatus(0);
      scrollToError();
    }
  };

  const updateAsset2Handler = async (
    image,
    file,
    sendedDueTime,
    partData,
    setData,
    sendedStartTime
  ) => {
    const response = await updateWorkOrder(
      Number(targetedData?.id),
      data?.title ? String(data.title) : null,
      data?.description ? String(data.description) : null,

      data.customer ? data.customer : null,
      data.location,
      data.orderType,
      format(new Date(data.dueDate), "yyyy-MM-dd"),

      sendedDueTime ? sendedDueTime : "23:59:00.000",

      data.repeatEvery,
      data.daysRepeat,
      data.repeatEveryWeek,
      data.repeatEveryMonth,
      data.repeatMonthDay,
      data.repeatEveryYear,
      data.endRepeat,
      Number(data.estimationTime),
      Number(data.laborCost),
      data.priority,
      data.category,
      data.people,
      data.teams,
      data.assets,
      partData,
      // data?.partsData?.length > 0 ? data?.partsData : [],
      data.procedure ? data.procedure : null,
      image,
      file,
      data.status,
      data.endType === "on"
        ? data.endDate
          ? format(new Date(data.endDate), "yyyy-MM-dd")
          : null
        : null,
      data.endType,
      data.qrcode ? true : false,
      targetedData?.attributes?.orderType == "reactive" &&
        data?.orderType == "scheduled"
        ? true
        : seriesEdit,
      organisationId,
      // data?.sets?.length > 0 ? data.sets : [],
      setData,
      data?.area ? Number(data.area) : null,
      data?.orderType == "reactive"
        ? format(new Date(data?.startDate), "yyyy-MM-dd")
        : null,
      sendedStartTime,
      data?.closeOut ? data?.closeOut : null
    );

    if (response && response.status == 200) {
      if (request) {
        const approveResponse = await approveOrder(response.data.id);
        if (approveResponse.status == 200) {
          setButtonStatus(0);
          untoggle();
          resetForm();
          setResponseData({
            id: response.data.id,
            name: response.data.title,
            qrcode: response.data.qrcode,
          });

          toggleSuccessModal(true);
          const currentPathname = window.location.pathname;
          setRefresh(!refresh);
          if (currentPathname == "/work-orders") {
            setSearchTitle("");
            setSelectedDates([]);
            setFilterOptions({
              serialNumber: "",

              people: [],
              teams: [],
              customers: [],
              locations: [],
              createdByUser: [],

              status: [],
              priority: [],
              categories: [],
            });
          }
          toast.success(t.workOrders.form.updated.msg);
        }
      } else {
        untoggle();
        setButtonStatus(0);
        resetForm();
        setResponseData({
          id: response.data.id,
          name: response.data.title,
        });
        setSeriesEdit(false);
        toggleSuccessModal(true);
        const currentPathname = window.location.pathname;

        setRefresh(!refresh);
        if (currentPathname == "/work-orders") {
          setSearchTitle("");
          setSelectedDates([]);
          setFilterOptions({
            serialNumber: "",

            people: [],
            teams: [],
            customers: [],
            locations: [],
            createdByUser: [],

            status: [],
            priority: [],
            categories: [],
          });
        }
        toast.success(t.workOrders.form.updated.msg);
      }
    } else if (response.status == 400) {
      if (response.error && response.error.message) {
        if (
          response.error.message ==
          "Insufficient quantity available for the part added in this scheduled WO"
        ) {
          toast.error(t.workOrders.part_error);
        } else if (
          response.error.message ==
          "Insufficient quantity available for the sets added in this scheduled WO"
        ) {
          toast.error(t.workOrders.set_error);
        } else {
          toast.error(response.error.message);
        }
      } else {
        toast.error(t.common.something_wrong);
      }
    } else {
      toast.error(t.common.something_wrong);
      untoggle();
    }
    setButtonStatus(0);
  };

  // add popups
  const handleAddCustomer = (e, { value }) => {
    setCustomerText(value);

    toggleAddCustomerModal(true);
  };

  const returnAddCustomer = (id) => {
    fetchCustomerOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          customer: id,
        });
      }
    });
  };

  const conditionalRowStyles = [
    {
      when: (row) => row?.disable == true,
      // Condition to check
      style: {
        backgroundColor: "#e4e4e4", // Apply red color to the row
      },
    },
  ];

  const handleAddProcedure = (e, { value }) => {
    setProcedureText(value);
    toggleAddProcedureModal(true);
  };

  const returnAddProcedure = (id) => {
    fetchProceduresOptions().then((resp_data) => {
      if (resp_data.response.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          procedure: id,
        });
      }
    });
  };

  const handleAddLocation = (e, { value }) => {
    setLocationText(value);
    toggleAddLocationModal(true);
  };

  const returnAddLocation = (id) => {
    fetchLocationsOptions(data.customer).then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          location: id,
        });
      }
    });
  };

  const handleAddTeam = (e, { value }) => {
    setTeamText(value);
    toggleAddTeamModal(true);
  };

  const returnAddTeam = (id) => {
    fetchTeamsOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        const picked_before = data.teams;

        picked_before.push(id);
        setData({
          ...data,
          teams: picked_before,
        });
      }
    });
  };

  const handleAddPeople = (e, { value }) => {
    setPeopleText(value);
    toggleAddPeopleModal(true);
  };

  const returnAddPeople = (id) => {
    fetchPeopleOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        const picked_before = data.people;

        picked_before.push(id);
        setData({
          ...data,
          people: picked_before,
        });
      }
    });
  };

  const handleAddAsset = (e, { value }) => {
    setAssetText(value);
    toggleAddAssetModal(true);
  };

  const returnAddAsset = (id) => {
    fetchAssetsOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        const picked_before = data.assets;

        picked_before.push(id);
        setData({
          ...data,
          assets: picked_before,
        });
      }
    });
  };

  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );

  return (
    <>
      <div
        style={{ maxWidth: `${modalWidth}px` }}
        clearing={true}
        className={
          toggled ? "aside-modal active procedure" : "aside-modal procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">
                {t.workOrders.form.form_title.edit} #
                {targetedData?.attributes?.countId}
              </div>
              <div>
                <span
                  className="c-pointer"
                  style={{ position: "relative" }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == "en"
                        ? "bottom center"
                        : "bottom center"
                    }
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    arrowAlign="center"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              height: "90vh",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
            className="body"
          >
            {buttonStatus == 1 ? (
              <Dimmer active inverted>
                <Loader inverted content={t.common.loading} />
              </Dimmer>
            ) : (
              ""
            )}
            <Form.Field>
              <label className="label-item">
                {t.workOrders.table.title}
                <Asterisk color="#172B4D" />
              </label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.workOrders.form.title_name}
                disabled={
                  (request && request?.title) ||
                  targetedData?.attributes?.status === "inReview" ||
                    targetedData?.attributes?.status === "onHold"
                    ? true
                    : false
                }
                value={data.title}
                onChange={onValuesChange("title")}
                error={validation.title.error && validation.title.skipped}
                onBlur={(e) => {
                  if (validation.title.pattern(data.title)) {
                    setValidation({
                      ...validation,
                      title: {
                        ...validation.title,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      title: {
                        ...validation.title,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.title.error && validation.title.skipped ? (
                <div className="label-error">{validation.title.message}</div>
              ) : (
                ""
              )}
            </Form.Field>

            <FileUpload
              onFileChange={onFileChange}
              name="image"
              attachments={attachments}
              setAttachments={setAttachments}
            />

            <Form style={{ marginBottom: "20px" }}>
              <Form.Field>
                <label className="label-item mt-5">
                  {t.workOrders.form.description}
                </label>
                <TextArea
                  autoComplete="new-password"
                  value={data.description}
                  onChange={onValuesChange("description")}
                  rows={3}
                  disabled={
                    targetedData?.attributes?.status === "inReview" ||
                    targetedData?.attributes?.status === "onHold"
                  }
                  fluid
                  placeholder={t.workOrders.form.description_text}
                />
              </Form.Field>
            </Form>

            <Form.Field>
              <label className="label-item mt-5">
                {" "}
                <label className="label-item">
                  {t.workOrders.table.priority}
                </label>
              </label>
              <Dropdown
                placeholder={t.workOrders.table.add_priority}
                fluid
                selection
                disabled={
                  targetedData?.attributes?.status === "inReview" ||
                  targetedData?.attributes?.status === "onHold"
                }
                options={options.priority}
                value={data.priority}
                noResultsMessage={t.common.no_results_found}
                onChange={(e, value) => {
                  // onValuesChange('customer')
                  // console.log(value, e);
                  let findPriority = value.options.find(
                    (one) => one.value == value.value
                  );
                  if (findPriority) {
                    setData({
                      ...data,
                      priority: value.value,
                    });
                  }
                }}
              />
            </Form.Field>
            {/* {userPermissions?.procedure?.view ? ( */}
            <Form.Field>
              <label className="label-item">
                {t.workOrders.form.procedure}
              </label>
              <Dropdown
                placeholder={t.workOrders.form.procedure_placeholder}
                fluid
                clearable
                selection
                disabled={
                  targetedData?.attributes?.status === "inReview" ||
                  targetedData?.attributes?.status === "onHold"
                }
                noResultsMessage={t.common.no_results_found}
                search
                //allowAdditions
                onAddItem={handleAddProcedure}
                options={options.procedures}
                value={data.procedure}
                onChange={onValuesChange("procedure")}
                onOpen={fetchProceduresOptions}
              />
            </Form.Field>
            {/* ) : null} */}

            <Form.Field>
              <label className="label-item">
                {t.workOrders.table.category} <Asterisk color="#172B4D" />
              </label>
              <Dropdown
                placeholder={t.workOrders.table.category}
                fluid
                selection
                disabled={
                  targetedData?.attributes?.status === "inReview" ||
                  targetedData?.attributes?.status === "onHold"
                }
                clearable
                search
                options={options.categories}
                noResultsMessage={t.common.no_results_found}
                value={data.category}
                error={validation.category.error && validation.category.skipped}
                onChange={onValuesChange("category")}
                onBlur={(e) => {
                  if (validation.category.pattern(data.category)) {
                    setValidation({
                      ...validation,
                      category: {
                        ...validation.category,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      category: {
                        ...validation.category,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.category.error && validation.category.skipped ? (
                <div className="label-error">{validation.category.message}</div>
              ) : (
                ""
              )}
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.workOrders.table.location}
                <Asterisk color="#172B4D" />
              </label>
              <Dropdown
                placeholder={t.workOrders.form.location_placeholder}
                fluid
                disabled={
                  targetedData?.attributes?.status === "inReview" ||
                  targetedData?.attributes?.status === "onHold"
                }
                search={(options, query) => {
                  const filteredOptions = [];
                  const addedKeys = new Set(); // To track and avoid duplicate entries

                  options.forEach((option) => {
                    const isParent = !option.parentId;
                    const matches = option.text
                      .toLowerCase()
                      .includes(query.toLowerCase());

                    // If the parent matches, add it and all related sub-locations
                    if (isParent && matches) {
                      if (!addedKeys.has(option.key)) {
                        filteredOptions.push(option);
                        addedKeys.add(option.key);
                      }
                      options.forEach((subOption) => {
                        if (
                          subOption.parentId === option.key &&
                          !addedKeys.has(subOption.key)
                        ) {
                          filteredOptions.push(subOption);
                          addedKeys.add(subOption.key);
                        }
                      });
                    }
                    // If a sub-location matches, add both the sub-location and its parent
                    else if (!isParent && matches) {
                      const parentOption = options.find(
                        (opt) => opt.key === option.parentId
                      );
                      if (parentOption && !addedKeys.has(parentOption.key)) {
                        filteredOptions.push(parentOption);
                        addedKeys.add(parentOption.key);
                      }
                      if (!addedKeys.has(option.key)) {
                        filteredOptions.push(option);
                        addedKeys.add(option.key);
                      }
                    }
                  });

                  return filteredOptions;
                }}
                selection
                className="loc-dropdown"
                //allowAdditions
                clearable
                noResultsMessage={t.common.no_results_found}
                onAddItem={handleAddLocation}
                // disabled={!data.customer}
                options={options.locations}
                value={data.location}
                onChange={onValuesChange("location")}
                onOpen={fetchLocationsOptions}
                error={
                  validation.location.error &&
                  validation.location.skipped &&
                  data.customer != ""
                }
                onBlur={(e) => {
                  if (validation.location.pattern(data.location)) {
                    setValidation({
                      ...validation,
                      location: {
                        ...validation.location,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      location: {
                        ...validation.location,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.location.error && validation.location.skipped ? (
                <div className="label-error">{validation.location.message}</div>
              ) : (
                ""
              )}
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.parts.filter.area}</label>
              <Dropdown
                placeholder={t.parts.form.add_area}
                fluid
                disabled={
                  (!data.location && !(roleData === "super_admin")) ||
                  targetedData?.attributes?.status === "inReview" ||
                    targetedData?.attributes?.status === "onHold"
                }
                clearable
                selection
                noResultsMessage={t.common.no_results_found}
                search
                // onAddItem={handleAddLocation}
                options={options.area}
                // onOpen={fetchLocationsOptions}
                value={data.area}
                onChange={onValuesChange("area")}
                // error={
                //   validation.location.error &&
                //   validation.location.skipped &&
                //   data.customer != ''
                // }
                // onBlur={(e) => {
                //   if (validation.location.pattern(data.location)) {
                //     setValidation({
                //       ...validation,
                //       location: {
                //         ...validation.location,
                //         error: false,
                //         skipped: true,
                //       },
                //     });
                //   } else {
                //     setValidation({
                //       ...validation,
                //       location: {
                //         ...validation.location,
                //         error: true,
                //         skipped: true,
                //       },
                //     });
                //   }
                // }}
              />
              {/* {validation.location.error && validation.location.skipped ? (
              <div className="label-error">{validation.location.message}</div>
            ) : (
              ''
            )} */}
            </Form.Field>
            {/* {userPermissions?.customer && userPermissions?.customer?.view ? ( */}
            <Form.Field>
              <label className="label-item">
                {t.workOrders.table.customer}
              </label>
              <Dropdown
                placeholder={t.workOrders.form.customer_placeholder}
                //allowAdditions
                search
                disabled={
                  targetedData?.attributes?.status === "inReview" ||
                  targetedData?.attributes?.status === "onHold"
                }
                // disabled={
                //   !data.location &&
                //   !(roleData === "super_admin" || roleData === "admin")
                // }
                fluid
                selection
                noResultsMessage={t.common.no_results_found}
                options={options.customers}
                value={data.customer}
                onChange={onValuesChange("customer")}
                onOpen={fetchCustomerOptions}
                clearable
              />
            </Form.Field>
            {/* ) : null} */}

            {/* {userPermissions?.asset?.view ? ( */}
            <Form.Field>
              <label className="label-item">{t.workOrders.form.assets}</label>
              <Dropdown
                placeholder={t.workOrders.form.assets_placeholder}
                fluid
                // disabled={
                //   !data.location &&
                //   !(roleData === "super_admin" || roleData === "admin")
                // }
                selection
                disabled={
                  targetedData?.attributes?.status === "inReview" ||
                  targetedData?.attributes?.status === "onHold"
                }
                search
                multiple
                className="people-dropdown"
                //allowAdditions
                onOpen={fetchAssetsOptions}
                options={options.assets}
                noResultsMessage={t.common.no_results_found}
                value={data.assets}
                onChange={(e, value) => {
                  // Find the currently selected options
                  const selectedOptions = options.assets.filter((option) =>
                    value?.value.includes(option.value)
                  );

                  let newSelectedValues = [...value?.value];

                  selectedOptions.forEach((option) => {
                    // When selecting a parent asset
                    if (
                      !option?.disabled &&
                      option.className === "main-location-dropdown-option"
                    ) {
                      // Find child assets of this parent
                      const childOptions = options.assets.filter(
                        (subOption) =>
                          subOption.parentId === option.key &&
                          !subOption.disabled
                      );

                      // Add child assets to the selected values if they are not disabled
                      newSelectedValues = [
                        ...newSelectedValues,
                        ...childOptions.map((child) => child.value),
                      ];

                      // For each child, find its grandchildren (if the child is selected)
                      childOptions.forEach((child) => {
                        const grandChildren = options.assets.filter(
                          (grandChild) =>
                            grandChild.childParentId === child.key &&
                            !grandChild.disabled
                        );

                        // Add grandchildren to the selected values if they are not disabled
                        newSelectedValues = [
                          ...newSelectedValues,
                          ...grandChildren.map(
                            (grandChild) => grandChild.value
                          ),
                        ];
                      });
                    }

                    // When selecting a child asset
                    if (
                      !option?.disabled &&
                      option.className === "sub-location-dropdown-option"
                    ) {
                      // Find grandchild assets of this child
                      const grandChildren = options.assets.filter(
                        (grandChild) =>
                          grandChild.childParentId === option.key &&
                          !grandChild.disabled
                      );

                      // Add grandchildren to the selected values if they are not disabled
                      newSelectedValues = [
                        ...newSelectedValues,
                        ...grandChildren.map((grandChild) => grandChild.value),
                      ];
                    }
                  });

                  // Update state with new selected values
                  setData({
                    ...data,
                    assets: [...new Set(newSelectedValues)], // Ensure unique values only
                  });
                }}
              />
            </Form.Field>

            <label className="label-item mb-0">
              {t.workOrders.form.part_set}
            </label>
            {selectedFinalPartData?.length > 0 ? (
              <>
                <div className="scroll-data" style={{ overflow: "auto" }}>
                  <div className={"mt-6"}>
                    <div className={`kanbar-header`}>
                      <div class="grid-container-element set wo-form2">
                        <div
                          style={{
                            minWidth: "86px",
                            backgroundColor: "#e9f2ff",
                          }}
                          class="grid-child-element set"
                        >
                          {t.workOrders.table.ID}
                        </div>
                        <div
                          style={{
                            minWidth: "140px",
                            backgroundColor: "#e9f2ff",
                          }}
                          class="grid-child-element set"
                        >
                          {t.workOrders.details_view.name}
                        </div>
                        <div
                          style={{
                            minWidth: "140px",
                            backgroundColor: "#e9f2ff",
                          }}
                          class="grid-child-element set"
                        >
                          {t.parts.form.inv_loc}
                        </div>
                        <div
                          style={{
                            minWidth: "120px",
                            backgroundColor: "#e9f2ff",
                          }}
                          class="grid-child-element set"
                        >
                          {t.parts.table.quantity}
                        </div>{" "}
                        <div
                          style={{
                            minWidth: "130px",
                            textAlign: "center",
                            backgroundColor: "#e9f2ff",
                          }}
                          class="grid-child-element set"
                        >
                          {t.parts.form.unit_cost}
                        </div>
                        <div
                          style={{
                            minWidth: "130px",
                            textAlign: "center",
                            backgroundColor: "#e9f2ff",
                          }}
                          class="grid-child-element set"
                        >
                          {t.parts.form.total_cost}
                        </div>
                        <div
                          style={{
                            minWidth: "40px",
                            backgroundColor: "#e9f2ff",
                          }}
                          class="grid-child-element set"
                        ></div>
                      </div>
                    </div>
                  </div>

                  {selectedFinalPartData.map((part, index) => (
                    <div
                      className="grid-container-element set wo-form2 form"
                      key={index}
                    >
                      <div
                        className="grid-child-element set form"
                        style={{
                          padding: "14px 9px",
                          minWidth: "86px",
                        }}
                      >
                        {part?.countId ? part.countId : null}
                      </div>

                      <div
                        className="grid-child-element set form"
                        style={{
                          padding: "14px 9px",
                          minWidth: "140px",
                        }}
                      >
                        {DisplayFormattedText(part?.name, 13)}
                      </div>
                      <div
                        className="grid-child-element set form"
                        style={{
                          padding: "14px 9px",
                          minWidth: "140px",
                        }}
                      >
                        {DisplayFormattedText(part?.location_name, 13)}
                      </div>
                      <div
                        className="wrap-input-set grid-child-element set form"
                        style={{
                          minWidth: "120px",
                        }}
                      >
                        <Input
                          autoComplete="new-password"
                          style={{ width: "52px", height: "34px" }}
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          onKeyDown={(evt) => handleKeyDown(evt)}
                          value={part?.quantity}
                          className="d-flex"
                          min="1"
                          onChange={(e) => {
                            let allPartData = [...selectedFinalPartData];

                            if (Number(e.target.value)) {
                              let value = parseFloat(e.target.value).toFixed(4);

                              allPartData[index].quantity = Number(value);

                              let totalCostData = handleDecimalPoints(
                                Number(value * part?.unitCost)
                              );
                              allPartData[index].totalCost = totalCostData;

                              setSelectedFinalPartData(allPartData);
                            } else {
                              allPartData[index].quantity = "";

                              allPartData[index].totalCost = 0;

                              setSelectedFinalPartData(allPartData);
                            }
                          }}
                          error={selectedFinalPartData[index]?.quantity == ""}
                        >
                          <input />

                          <span
                            className={` align-items-center justify-content-center ${
                              selectedLanguage == "en" ? "ml-2" : "mr-2"
                            }`}
                            style={
                              part?.unitType
                                ? {
                                    backgroundColor: "#E9F2FF",
                                    color: "#0C66E4",
                                    padding: "3px 4px",
                                    fontSize: "14px",
                                    minWidth:
                                      selectedLanguage == "en"
                                        ? "33px"
                                        : "48px",
                                    textAlign: "center",
                                  }
                                : {}
                            }
                          >
                            {part?.unitType
                              ? HandleUnitType(part?.unitType)
                              : null}
                          </span>
                        </Input>
                        {selectedFinalPartData[index]?.quantity == "" ? (
                          <span className="label-error">
                            {t.common.required_field}
                          </span>
                        ) : null}
                      </div>
                      <div
                        className={
                          selectedLanguage == "en"
                            ? "grid-child-element set form mt-2"
                            : "grid-child-element set form mt-2"
                        }
                        style={{
                          minWidth: "130px",
                        }}
                      >
                        <div
                          style={{
                            width: "75px",
                            display: "inline-block",
                            textAlign: "end",
                          }}
                          className="number-cost-set"
                        >
                          {DisplayFormattedText(String(part?.unitCost), 7)}
                        </div>
                        <span
                          className={` align-items-center justify-content-center ${
                            selectedLanguage == "en" ? "ml-2" : "mr-2"
                          }`}
                          style={{
                            backgroundColor: "#E9F2FF",
                            color: "#0C66E4",
                            padding: "9px 3px 9px 2px",
                            fontSize: "14px",
                          }}
                        >
                          {organisationCurrency}
                        </span>
                      </div>

                      <div
                        className={
                          selectedLanguage == "en"
                            ? "grid-child-element set form mt-2"
                            : "grid-child-element set form mt-2"
                        }
                        style={{
                          minWidth: "130px",
                        }}
                      >
                        <div
                          style={{
                            width: "75px",
                            display: "inline-block",
                            textAlign: "end",
                          }}
                          className="number-cost-set"
                        >
                          {DisplayFormattedText(String(part?.totalCost), 7)}
                        </div>
                        <span
                          className={` align-items-center justify-content-center ${
                            selectedLanguage == "en" ? "ml-2" : "mr-2"
                          }`}
                          style={{
                            backgroundColor: "#E9F2FF",
                            color: "#0C66E4",
                            padding: "9px 3px 9px 2px",
                            fontSize: "14px",
                            minWidth: "33px",
                            textAlign: "end",
                          }}
                        >
                          {organisationCurrency}
                        </span>
                      </div>

                      <div
                        onClick={() => {
                          removeFromSelectedParts(index, selectedFinalPartData);
                        }}
                        className="grid-child-element set form mt-2"
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : null}

            <button
              className={`buttun-type-link ${
                selectedFinalPartData?.length > 0 ? "mt-5" : "mt-2"
              }  mb-7`}
              basic
              type="button"
              style={{ background: "transparent" }}
              disabled={
                targetedData?.attributes?.status === "inReview" ||
                targetedData?.attributes?.status === "onHold"
              }
              // disabled={!values?.locations}
              onClick={() => addParts(selectedTablePartData)}
            >
              <span className="customer-add-btn">
                {" "}
                <CustomerPlusIcon />{" "}
              </span>

              <p className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                {t.workOrders.form.add_part_set}
              </p>
            </button>
            {/* ) : null} */}
            {/* {userPermissions?.part?.view ? ( */}
            <>
              {/* <label className="label-item">{t.parts.tab.first}</label>
              <div className="wrapper-parts-dropdown">
                <PartsDropdown
                  title={t.workOrders.form.select_part}
                  options={options.partsData}
                  // disabled={!data?.location || !data.area}
                  selectedValues={data.partsData}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setData({
                        ...data,
                        partsData: [...data.partsData, e.target.value],
                      });
                    } else {
                      let filteredLocations = data.partsData.filter(
                        (el) => el !== e.target.value
                      );
                      setData({
                        ...data,
                        partsData: filteredLocations,
                      });
                    }
                  }}
                  icon={false}
                  image={false}
                  // onRemovePart={handleRemovePart}
                />
              </div>
              <label className="label-item mt-5">{t.parts.tab.second}</label>
              <div className="wrapper-parts-dropdown">
                <PartsDropdown
                  title={t.workOrders.form.select_set}
                  options={options.sets}
                  // disabled={!data?.location || !data.area}
                  selectedValues={data.sets}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setData({
                        ...data,
                        sets: [...data.sets, e.target.value],
                      });
                    } else {
                      let filteredLocations = data.sets.filter(
                        (el) => el !== e.target.value
                      );
                      setData({
                        ...data,
                        sets: filteredLocations,
                      });
                    }
                  }}
                  icon={false}
                  image={false}
                />
              </div> */}
            </>
            {/* ) : null}*/}
            <Form.Field>
              <label className={"label-item"}>{t.workOrders.form.people}</label>
              <Dropdown
                placeholder={t.workOrders.form.people_placeholder}
                fluid
                selection
                disabled={
                  targetedData?.attributes?.status === "inReview" ||
                  targetedData?.attributes?.status === "onHold"
                }
                // disabled={
                //   !data.location &&
                //   !(roleData === "super_admin" || roleData === "admin")
                // }
                noResultsMessage={t.common.no_results_found}
                search
                //allowAdditions
                multiple
                onAddItem={handleAddPeople}
                options={options.people}
                value={data.people}
                onChange={onValuesChange("people")}
                onOpen={fetchPeopleOptions}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.workOrders.form.teams}</label>
              <Dropdown
                placeholder={t.workOrders.form.teams_placeholder}
                fluid
                selection
                // disabled={
                //   !data.location &&
                //   !(roleData === "super_admin" || roleData === "admin")
                // }
                noResultsMessage={t.common.no_results_found}
                search
                multiple
                disabled={
                  targetedData?.attributes?.status === "inReview" ||
                  targetedData?.attributes?.status === "onHold"
                }
                //allowAdditions
                onAddItem={handleAddTeam}
                options={options.teams}
                value={data.teams}
                onChange={onValuesChange("teams")}
                onOpen={fetchTeamsOptions}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.workOrders.form.order_type}
              </label>
              <Dropdown
                placeholder={t.workOrders.form.add_order_type}
                fluid
                noResultsMessage={t.common.no_results_found}
                selection
                disabled={
                  targetedData?.attributes?.orderType == "scheduled" ||
                  targetedData?.attributes?.status === "inReview" ||
                    targetedData?.attributes?.status === "onHold"
                }
                options={options.orderTypes}
                value={data.orderType}
                onChange={onValuesChange("orderType")}
                // error={validation.orderType.error && validation.orderType.skipped}
                // onBlur={(e) => {
                //   if (validation.orderType.pattern(data.orderType)) {
                //     setValidation({
                //       ...validation,
                //       orderType: {
                //         ...validation.orderType,
                //         error: false,
                //         skipped: true,
                //       },
                //     });
                //   } else {
                //     setValidation({
                //       ...validation,
                //       orderType: {
                //         ...validation.orderType,
                //         error: true,
                //         skipped: true,
                //       },
                //     });
                //   }
                // }}
              />
              {/* {validation.orderType.error && validation.orderType.skipped ? (
              <div className="label-error">{validation.orderType.message}</div>
            ) : (
              ''
            )} */}
            </Form.Field>

            {data.orderType == "reactive" ? (
              <>
                <Grid className="mb-0" columns={2}>
                  <GridColumn className="pb-0">
                    <Form.Field>
                      <label className="label-item">
                        {t.workOrders.details_view.start_date}
                      </label>

                      <div
                        className="change-date-time-inll position-relative"
                        style={{ marginBottom: "-13px" }}
                      >
                        <DatePicker
                          minDate={new Date().setHours(0, 0, 0, 0)}
                          highlightToday={false}
                          disabled={
                            targetedData?.attributes?.status === "inReview" ||
                            targetedData?.attributes?.status === "onHold"
                          }
                          style={{ width: "100%" }}
                          value={
                            data.startDate ? new Date(data.startDate) : null
                          }
                          onChange={(value) => {
                            if (value && value.valueOf()) {
                              setData({
                                ...data,
                                startDate: format(
                                  value.valueOf(),
                                  "yyyy-MM-dd"
                                ),
                                dueDate: format(value.valueOf(), "yyyy-MM-dd"),
                              });
                            } else {
                              setData({
                                ...data,
                                startDate: format(new Date(), "yyyy-MM-dd"),
                                dueDate: format(new Date(), "yyyy-MM-dd"),
                              });
                            }
                          }}
                          showMonthDropdown
                          showYearDropdown
                          placeholder={t.workOrders.form.add_due_date}
                          className={"active"}
                          weekDays={[
                            t.workOrders.details_view.sun,
                            t.workOrders.details_view.mon,
                            t.workOrders.details_view.tue,
                            t.workOrders.details_view.wed,
                            t.workOrders.details_view.thu,
                            t.workOrders.details_view.fri,
                            t.workOrders.details_view.sat,
                          ]}
                          months={[
                            t.common.january,
                            t.common.february,
                            t.common.march,
                            t.common.april,
                            t.common.may,
                            t.common.june,
                            t.common.july,
                            t.common.august,
                            t.common.september,
                            t.common.october,
                            t.common.november,
                            t.common.december,
                          ]}
                          format="DD/MM/YYYY"
                        ></DatePicker>
                        <img
                          className="position-absolute"
                          style={
                            selectedLanguage == "en"
                              ? { top: "8px", right: "7px" }
                              : { top: "8px", left: "7px" }
                          }
                          src={calendar_icon}
                        />
                      </div>
                    </Form.Field>
                  </GridColumn>
                  {/* {data?.includeDueTime ? ( */}
                  <GridColumn className="pb-0">
                    <Form.Field>
                      <label className="label-item">
                        {t.workOrders.details_view.start_time}

                        {/* <Icon name="asterisk" color="red" size="small" /> */}
                      </label>
                      <div className="position-relative">
                        <Input
                          autoComplete="new-password"
                          type="time"
                          disabled={
                            targetedData?.attributes?.status === "inReview" ||
                            targetedData?.attributes?.status === "onHold"
                          }
                          fluid
                          placeholder={t.workOrders.form.add_due_time}
                          // disabled={!data.includeDueTime}
                          value={data.startTime}
                          onChange={onValuesChange("startTime")}
                          onClick={(e) =>
                            e?.target?.showPicker && e.target.showPicker()
                          }
                        >
                          {/* <input /> */}
                        </Input>

                        <img
                          className="mb-5 position-absolute"
                          style={
                            selectedLanguage == "en"
                              ? { top: "8px", right: "7px" }
                              : { top: "8px", left: "7px" }
                          }
                          src={clock_icon}
                        />
                      </div>
                    </Form.Field>
                  </GridColumn>
                </Grid>
                {/* ) : null} */}
              </>
            ) : null}

            <Grid
              className="mt-0"
              columns={data?.orderType == "reactive" ? 2 : 1}
            >
              <GridColumn className="pt-0 pb-0">
                <Form.Field>
                  <label className="label-item">
                    {data.orderType === "scheduled"
                      ? t.workOrders.details_view.start_date
                      : t.workOrders.table.dueDate}
                  </label>

                  <div className="change-date-time-inll position-relative">
                    <DatePicker
                      key={"ssd"}
                      minDate={new Date().setHours(0, 0, 0, 0)}
                      style={{ width: "100%" }}
                      maxDate={
                        !seriesEdit &&
                        data?.endType == "on" &&
                        data?.orderType !== "reactive"
                          ? new Date(data.endDate)
                          : ""
                      }
                      disabled={["inReview", "onHold"].includes(
                        targetedData?.attributes?.status
                      )}
                      value={data.dueDate ? new Date(data.dueDate) : new Date()}
                      onChange={(value) => {
                        if (value && value.valueOf()) {
                          if (!seriesEdit) {
                            setData({
                              ...data,
                              dueDate: format(value.valueOf(), "yyyy-MM-dd"),
                            });
                          } else {
                            setData({
                              ...data,
                              dueDate: format(value.valueOf(), "yyyy-MM-dd"),
                              endDate: format(value.valueOf(), "yyyy-MM-dd"),
                            });
                          }
                        } else {
                          if (!seriesEdit) {
                            setData({
                              ...data,
                              dueDate: format(new Date(), "yyyy-MM-dd"),
                            });
                          } else {
                            setData({
                              ...data,
                              dueDate: format(value.valueOf(), "yyyy-MM-dd"),
                              endDate: format(value.valueOf(), "yyyy-MM-dd"),
                            });
                          }
                        }
                      }}
                      highlightToday={false}
                      showMonthDropdown
                      showYearDropdown
                      placeholder={t.workOrders.form.add_due_date}
                      className={"active"}
                      // locale={lang == 'ar' ? arabic_ar : ''}
                      weekDays={[
                        t.workOrders.details_view.sun,
                        t.workOrders.details_view.mon,
                        t.workOrders.details_view.tue,
                        t.workOrders.details_view.wed,
                        t.workOrders.details_view.thu,
                        t.workOrders.details_view.fri,
                        t.workOrders.details_view.sat,
                      ]}
                      months={[
                        t.common.january,
                        t.common.february,
                        t.common.march,
                        t.common.april,
                        t.common.may,
                        t.common.june,
                        t.common.july,
                        t.common.august,
                        t.common.september,
                        t.common.october,
                        t.common.november,
                        t.common.december,
                      ]}
                      format="DD/MM/YYYY"
                    />

                    <img
                      className="position-absolute"
                      style={
                        selectedLanguage == "en"
                          ? { top: "8px", right: "7px" }
                          : { top: "8px", left: "7px" }
                      }
                      src={calendar_icon}
                    />
                  </div>
                  {/* <span className='mr-3'><CalenderGreyIcon/></span> */}
                  {/* </div> */}

                  {/* <Checkbox
                className=""
                label={
                  data.orderType === "scheduled"
                    ? t.workOrders.form.include_start_time
                    : t.workOrders.form.include_due_time
                }
                checked={data.includeDueTime}
                onChange={onValuesChange("includeDueTime")}
                // style={{marginBottom:"15px"}}
              /> */}
                </Form.Field>
              </GridColumn>
              {/* </Grid.Column>
            <Grid.Column> */}
              {/* {data?.includeDueTime ? ( */}
              <GridColumn className="pt-0">
                <Form.Field>
                  <label className="label-item">
                    {data.orderType === "scheduled"
                      ? t.workOrders.details_view.start_time
                      : t.workOrders.form.due_time}
                  </label>
                  <div className="position-relative">
                    <Input
                      autoComplete="new-password"
                      type="time"
                      fluid
                      disabled={
                        targetedData?.attributes?.status === "inReview" ||
                        targetedData?.attributes?.status === "onHold"
                      }
                      placeholder={t.workOrders.form.add_due_time}
                      // disabled={!data.includeDueTime}
                      value={data.dueTime}
                      onChange={onValuesChange("dueTime")}
                      onClick={(e) =>
                        e?.target?.showPicker && e.target.showPicker()
                      }
                      // error={
                      //   validation.dueTime.error &&
                      //   validation.dueTime.skipped &&
                      //   data.includeDueTime
                      // }
                      // onBlur={(e) => {
                      //   if (validation.dueTime.pattern(data.dueTime)) {
                      //     setValidation({
                      //       ...validation,
                      //       dueTime: {
                      //         ...validation.dueTime,
                      //         error: false,
                      //         skipped: true,
                      //       },
                      //     });
                      //   } else {
                      //     setValidation({
                      //       ...validation,
                      //       dueTime: {
                      //         ...validation.dueTime,
                      //         error: true,
                      //         skipped: true,
                      //       },
                      //     });
                      //   }
                      // }}
                    >
                      {/* <input /> */}
                      {/* <Label style={{ height: '100%' }}>Hours</Label> */}
                      {/* <span className='mr-3'> <ClockIcon/></span> */}
                    </Input>
                    <img
                      className="position-absolute"
                      style={
                        selectedLanguage == "en"
                          ? { top: "8px", right: "7px" }
                          : { top: "8px", left: "7px" }
                      }
                      src={clock_icon}
                    />
                  </div>
                  {/* {validation.dueTime.error &&
                validation.dueTime.skipped &&
                data.includeDueTime ? (
                  <div className="label-error">
                    {validation.dueTime.message}
                  </div>
                ) : (
                  ''
                )} */}
                </Form.Field>
              </GridColumn>{" "}
            </Grid>
            {/* ) : null} */}

            {data.orderType != "reactive" ? (
              <>
                <label className="label-item">
                  <label
                    className={
                      data.orderType !== "reactive"
                        ? "label-item"
                        : "label-item"
                    }
                  >
                    {t.workOrders.details_view.repeat_every}
                  </label>
                </label>
                <div
                  className={
                    data.repeatEvery != "daily"
                      ? "repeat-container-flex"
                      : "repeat-container"
                  }
                >
                  <Form.Field>
                    <div className="d-flex gap-4">
                      <Dropdown
                        placeholder="Repeat Everly"
                        className="repeat-everly"
                        fluid
                        selection
                        noResultsMessage={t.common.no_results_found}
                        disabled={
                          (!seriesEdit &&
                            targetedData?.attributes?.orderType ==
                              "scheduled") ||
                          targetedData?.attributes?.status === "inReview" ||
                            targetedData?.attributes?.status === "onHold"
                        }
                        options={options.repeatEvery}
                        value={data.repeatEvery}
                        onChange={(e, value) => {
                          // onValuesChange('customer')
                          // console.log(value, e);
                          let findPriority = value.options.find(
                            (one) => one.value == value.value
                          );
                          if (findPriority.value == "daily") {
                            setData({
                              ...data,
                              repeatEvery: "daily",
                              daysRepeat: {
                                sat: true,
                                sun: true,
                                mon: true,
                                tue: true,
                                wed: true,
                                thu: true,
                                fri: true,
                              },
                            });
                          } else if (findPriority.value == "weekly") {
                            setData({
                              ...data,
                              repeatEvery: "weekly",
                              daysRepeat: {
                                sat: false,
                                sun: true,
                                mon: false,
                                tue: false,
                                wed: false,
                                thu: false,
                                fri: false,
                              },
                            });
                          } else if (findPriority.value == "monthly") {
                            setData({ ...data, repeatEvery: "monthly" });
                          } else if (findPriority.value == "yearly") {
                            setData({ ...data, repeatEvery: "yearly" });
                          }
                        }}
                      />
                    </div>
                  </Form.Field>
                  {/* <Form.Field>
                <label className="label-item">
                  {t.workOrders.details_view.repeat_on}
                </label>
                <Button.Group basic fluid>
                  <Button
                    onClick={() => {
                      setData({
                        ...data,
                        repeatEvery: 'daily',
                        daysRepeat: {
                          sat: true,
                          sun: true,
                          mon: true,
                          tue: true,
                          wed: true,
                          thu: true,
                          fri: true,
                        },
                      });
                    }}
                    className={data.repeatEvery == 'daily' ? 'active' : ''}
                  >
                    {t.workOrders.details_view.daily}
                  </Button>

                  <Button
                    onClick={() => {
                      setData({
                        ...data,
                        repeatEvery: 'weekly',
                        daysRepeat: {
                          sat: false,
                          sun: true,
                          mon: false,
                          tue: false,
                          wed: false,
                          thu: false,
                          fri: false,
                        },
                      });
                    }}
                    className={data.repeatEvery == 'weekly' ? 'active' : ''}
                  >
                    {t.workOrders.details_view.weekly}
                  </Button>

                  <Button
                    onClick={() => {
                      setData({ ...data, repeatEvery: 'monthly' });
                    }}
                    className={data.repeatEvery == 'monthly' ? 'active' : ''}
                  >
                    {t.workOrders.details_view.monthly}
                  </Button>

                  <Button
                    onClick={() => {
                      setData({ ...data, repeatEvery: 'yearly' });
                    }}
                    className={data.repeatEvery == 'yearly' ? 'active' : ''}
                  >
                    {t.workOrders.details_view.yearly}
                  </Button>
                </Button.Group>
              </Form.Field> */}
                  {data.repeatEvery != "daily" ? (
                    data.repeatEvery == "weekly" ? (
                      <>
                        <Form.Field>
                          <div className="flexbox align-center">
                            {t.workOrders.details_view.every_day}
                            <Dropdown
                              style={{ margin: "0 5px" }}
                              inline
                              search
                              noResultsMessage={t.common.no_results_found}
                              disabled={
                                (!seriesEdit &&
                                  targetedData?.attributes?.orderType ==
                                    "scheduled") ||
                                targetedData?.attributes?.status ===
                                  "inReview" ||
                                  targetedData?.attributes?.status === "onHold"
                              }
                              options={options.weeks}
                              className="week-dropdown"
                              value={data.repeatEveryWeek}
                              placeholder={t.workOrders.details_view.select_no}
                              onChange={onValuesChange("repeatEveryWeek")}
                              onOpen={fetchWeekOptions}
                            />
                            {t.workOrders.details_view.week_on}
                          </div>
                        </Form.Field>
                      </>
                    ) : data.repeatEvery == "monthly" ? (
                      <Form.Field>
                        <div className="flexbox align-center">
                          {t.workOrders.details_view.every_day}
                          <Dropdown
                            style={{ margin: "0 5px" }}
                            inline
                            noResultsMessage={t.common.no_results_found}
                            search
                            disabled={
                              (!seriesEdit &&
                                targetedData?.attributes?.orderType ==
                                  "scheduled") ||
                              targetedData?.attributes?.status === "inReview" ||
                                targetedData?.attributes?.status === "onHold"
                            }
                            className="week-dropdown"
                            options={options.months}
                            value={data.repeatEveryMonth}
                            placeholder={t.workOrders.details_view.select_no}
                            onChange={onValuesChange("repeatEveryMonth")}
                            onOpen={fetchMonthOptions}
                          />
                          {t.workOrders.details_view.month_on}
                          <Dropdown
                            style={{ margin: "0 5px" }}
                            inline
                            search
                            noResultsMessage={t.common.no_results_found}
                            disabled={
                              (!seriesEdit &&
                                targetedData?.attributes?.orderType ==
                                  "scheduled") ||
                              targetedData?.attributes?.status === "inReview" ||
                                targetedData?.attributes?.status === "onHold"
                            }
                            className="week-dropdown"
                            options={options.monthsDays}
                            value={data.repeatMonthDay}
                            placeholder={t.workOrders.details_view.select_day}
                            onChange={onValuesChange("repeatMonthDay")}
                            onOpen={fetchMonthDaysOptions}
                          />
                        </div>
                      </Form.Field>
                    ) : data.repeatEvery == "yearly" ? (
                      <Form.Field>
                        <div className="flexbox align-center">
                          {t.workOrders.details_view.every_day}
                          <Dropdown
                            style={{ margin: "0 5px" }}
                            inline
                            noResultsMessage={t.common.no_results_found}
                            disabled={
                              (!seriesEdit &&
                                targetedData?.attributes?.orderType ==
                                  "scheduled") ||
                              targetedData?.attributes?.status === "inReview" ||
                                targetedData?.attributes?.status === "onHold"
                            }
                            search
                            className="week-dropdown"
                            options={options.years}
                            value={data.repeatEveryYear}
                            placeholder={t.workOrders.details_view.select_no}
                            onChange={onValuesChange("repeatEveryYear")}
                            onOpen={fetchYearsOptions}
                          />
                          {t.workOrders.details_view.year}
                        </div>
                      </Form.Field>
                    ) : (
                      ""
                    )
                  ) : (
                    <Form.Field>
                      <Button.Group basic fluid className="basic-btn">
                        <Button
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                sun: !data.daysRepeat.sun,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.sun ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.sun}
                        </Button>

                        <Button
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                mon: !data.daysRepeat.mon,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.mon ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.mon}
                        </Button>

                        <Button
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                tue: !data.daysRepeat.tue,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.tue ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.tue}
                        </Button>

                        <Button
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                wed: !data.daysRepeat.wed,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.wed ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.wed}
                        </Button>

                        <Button
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                thu: !data.daysRepeat.thu,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.thu ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.thu}
                        </Button>

                        <Button
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                fri: !data.daysRepeat.fri,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.fri ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.fri}
                        </Button>

                        <Button
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                sat: !data.daysRepeat.sat,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.sat ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.sat}
                        </Button>
                      </Button.Group>
                      {validation.daysRepeat.error ? (
                        <div className="label-error">
                          {t.common.required_field}
                        </div>
                      ) : (
                        ""
                      )}
                    </Form.Field>
                  )}
                </div>
                {data.repeatEvery == "weekly" ? (
                  <div className="weekly-btns">
                    {/* <Form.Field>
                      <div className="flexbox align-center">
                        Every
                        <Dropdown
                          style={{ margin: '0 5px' }}
                          inline
                          search
                          options={options.weeks}
                          className="week-dropdown"
                          value={data.repeatEveryWeek}
                          placeholder={'Select number'}
                          onChange={onValuesChange('repeatEveryWeek')}
                          onOpen={fetchWeekOptions}
                        />
                        Week on
                      </div>
                    </Form.Field> */}

                    <Form.Field>
                      <Button.Group basic fluid className="basic-btn">
                        <Button
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                sun: !data.daysRepeat.sun,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.sun ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.sun}
                        </Button>

                        <Button
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                mon: !data.daysRepeat.mon,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.mon ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.mon}
                        </Button>

                        <Button
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                tue: !data.daysRepeat.tue,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.tue ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.tue}
                        </Button>

                        <Button
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                wed: !data.daysRepeat.wed,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.wed ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.wed}
                        </Button>

                        <Button
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                thu: !data.daysRepeat.thu,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.thu ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.thu}
                        </Button>

                        <Button
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                fri: !data.daysRepeat.fri,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.fri ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.fri}
                        </Button>

                        <Button
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                sat: !data.daysRepeat.sat,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.sat ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.sat}
                        </Button>
                      </Button.Group>
                      {validation.daysRepeat.error ? (
                        <div className="label-error">
                          {t.common.required_field}
                        </div>
                      ) : (
                        ""
                      )}
                    </Form.Field>
                  </div>
                ) : null}
                <Form.Field>
                  <label className="label-item">
                    {t.workOrders.details_view.ends}
                  </label>

                  <Form>
                    {/* <Form.Field>
                  {/* <Form.Field>
          On
        </Form.Field> */}
                    <div className="d-flex align-items-center side-dropdown gap-5">
                      <div>
                        <Radio
                          // label={t.workOrders.details_view.on}
                          name="radioGroup"
                          value="on"
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          checked={data.endType === "on"}
                          onChange={onValuesChange("endType")}
                        />
                      </div>
                      {data.endType === "on" ? (
                        <div className="mt-4 mb-5">
                          <div className="">
                            <DatePicker
                              value={
                                data.endDate ? new Date(data.endDate) : null
                              }
                              minDate={new Date(data.dueDate)}
                              disabled={
                                (!data?.dueDate && data.endType !== "on") ||
                                (!seriesEdit &&
                                  targetedData?.attributes?.orderType ==
                                    "scheduled") ||
                                targetedData?.attributes?.status == "inReview"
                              }
                              onChange={(value) => {
                                if (value && value.valueOf() && data.dueDate) {
                                  setData({
                                    ...data,
                                    endDate: format(
                                      value.valueOf(),
                                      "yyyy-MM-dd"
                                    ),
                                  });
                                  setValidation({
                                    ...validation,
                                    endDate: {
                                      ...validation.endDate,
                                      error: false,
                                      skipped: true,
                                    },
                                  });
                                } else {
                                  setData({
                                    ...data,
                                    endDate: null,
                                  });

                                  setValidation({
                                    ...validation,
                                    endDate: {
                                      ...validation.endDate,
                                      error: true,
                                      skipped: true,
                                    },
                                  });
                                }
                              }}
                              showMonthDropdown
                              showYearDropdown
                              placeholder={t.vendors.form.add_end_date}
                              className={"active"}
                              // locale={lang == 'ar' ? arabic_ar : ''}
                              weekDays={[
                                t.workOrders.details_view.sun,
                                t.workOrders.details_view.mon,
                                t.workOrders.details_view.tue,
                                t.workOrders.details_view.wed,
                                t.workOrders.details_view.thu,
                                t.workOrders.details_view.fri,
                                t.workOrders.details_view.sat,
                              ]}
                              months={[
                                t.common.january,
                                t.common.february,
                                t.common.march,
                                t.common.april,
                                t.common.may,
                                t.common.june,
                                t.common.july,
                                t.common.august,
                                t.common.september,
                                t.common.october,
                                t.common.november,
                                t.common.december,
                              ]}
                              format="DD/MM/YYYY"
                            ></DatePicker>

                            <img
                              className="date-picker-image"
                              style={{ top: "7px" }}
                              src={calendar_icon}
                            />
                          </div>
                          {validation?.endDate?.error &&
                          validation?.endDate?.skipped ? (
                            <div className="label-error">
                              {validation?.endDate?.message}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : null}
                    </div>
                    <div className="d-flex align-items-center side-dropdown ">
                      <div>
                        <Radio
                          label={t.workOrders.details_view.after}
                          name="radioGroup"
                          value="after"
                          disabled={
                            (!seriesEdit &&
                              targetedData?.attributes?.orderType ==
                                "scheduled") ||
                            targetedData?.attributes?.status === "inReview" ||
                              targetedData?.attributes?.status === "onHold"
                          }
                          checked={data?.endType && data?.endType === "after"}
                          onChange={onValuesChange("endType")}
                        />
                      </div>
                      {data?.endType && data?.endType === "after" ? (
                        <div className="mt-4 mb-5 d-flex ">
                          <Input
                            autoComplete="new-password"
                            className="work-input"
                            type="number"
                            onKeyDown={(evt) => handleKeyDown(evt)}
                            min="0"
                            placeholder="Enter number of orders"
                            value={data?.endRepeat && data.endRepeat}
                            onChange={onValuesChange("endRepeat")}
                            error={
                              validation.endRepeat.error &&
                              validation.endRepeat.skipped
                            }
                            onBlur={(e) => {
                              if (
                                validation.endRepeat.pattern(data.endRepeat)
                              ) {
                                setValidation({
                                  ...validation,
                                  endRepeat: {
                                    ...validation.endRepeat,
                                    error: false,
                                    skipped: true,
                                  },
                                });
                              } else {
                                setValidation({
                                  ...validation,
                                  endRepeat: {
                                    ...validation.endRepeat,
                                    error: true,
                                    skipped: true,
                                  },
                                });
                              }
                            }}
                            disabled={
                              // data.endType !== 'after' &&
                              (!seriesEdit &&
                                targetedData?.attributes?.orderType ==
                                  "scheduled") ||
                              targetedData?.attributes?.status === "inReview" ||
                                targetedData?.attributes?.status === "onHold"
                            }
                          >
                            {/* <Label style={{ height: '100%' }}>Orders</Label> */}
                          </Input>
                          <span
                            className={
                              selectedLanguage == "en"
                                ? "label-item mt-1 ml-3"
                                : "label-item mt-1 mr-3"
                            }
                          >
                            {t.workOrders.title_small}
                          </span>
                          {validation?.endRepeat?.error &&
                          validation?.endRepeat?.skipped ? (
                            <div className="label-error">
                              {validation?.endRepeat?.message}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : null}
                    </div>
                  </Form>
                </Form.Field>
              </>
            ) : (
              ""
            )}

            <Form.Field>
              <label
                className={
                  data?.orderType !== "reactive"
                    ? "label-item mt-5"
                    : "label-item"
                }
              >
                {" "}
                {t.workOrders.details_view.estimation_time}
              </label>

              <Input
                autoComplete="new-password"
                type="number"
                onKeyDown={(evt) => handleKeyDown(evt)}
                min="0"
                fluid
                disabled={
                  targetedData?.attributes?.status === "inReview" ||
                  targetedData?.attributes?.status === "onHold"
                }
                placeholder={t.workOrders.form.time_placeholder}
                value={data.estimationTime}
                onChange={onValuesChange("estimationTime")}
              >
                <input />
                {/* <Label style={{ height: '100%' }}>Hours</Label> */}
                <span
                  className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                    selectedLanguage == "en" ? "ml-5" : "mr-5"
                  }`}
                  // onClick={() => {
                  //   setOpenJobTitleAddModal(true);
                  // }}
                  style={{
                    padding: "5px",
                    backgroundColor: "#E9F2FF",
                    color: "#0C66E4",
                  }}
                >
                  {t.common.hrs}
                </span>
              </Input>
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.workOrders.form.labor_cost}
              </label>

              <Input
                autoComplete="new-password"
                type="number"
                onKeyDown={(evt) => handleKeyDown(evt)}
                min="0"
                disabled={
                  targetedData?.attributes?.status === "inReview" ||
                  targetedData?.attributes?.status === "onHold"
                }
                fluid
                placeholder={t.workOrders.form.add_labor_cost}
                value={data.laborCost}
                onChange={onValuesChange("laborCost")}
              >
                <input />
                <span
                  className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                    selectedLanguage == "en" ? "ml-5" : "mr-5"
                  }`}
                  // onClick={() => {
                  //   setOpenJobTitleAddModal(true);
                  // }}
                  style={{ backgroundColor: "#E9F2FF", color: "#0C66E4" }}
                >
                  {organisationCurrency}
                </span>
              </Input>
            </Form.Field>

            <Form style={{ marginBottom: "20px" }}>
              <Form.Field>
                <label className="label-item ">
                  {t.workOrders.form.close_out}
                </label>
                <TextArea
                  autoComplete="new-password"
                  value={data?.closeOut}
                  onChange={onValuesChange("closeOut")}
                  rows={3}
                  fluid
                  placeholder={t.workOrders.form.add_close_out}
                />
              </Form.Field>
            </Form>
            <Form.Field>
              <FileUpload
                onFileChange={onFileChange}
                name="file"
                onCertificateFileChange={onCertificateFileChange}
                attachments={attachments}
                setAttachments={setAttachments}
              />
            </Form.Field>

            <Divider />
            <div className="form-button-container">
              <button
                onClick={() =>
                  initChange
                    ? setNewCancelModal(initChange && true)
                    : cancelHandler()
                }
                className="form-action-button"
              >
                {t.common.cancel}
              </button>

              <button
                onClick={updateWorkOrderHandler}
                className="form-action-button blue"
                disabled={buttonStatus == 1}
              >
                {t.common.edit}
              </button>
            </div>
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

      {/* <Modal
        size="large"
        open={addPartModal}
        className="assets-modal part-list-modal"
        style={{ height: "770px" }}
        onClose={() => {
          handleClosePartModal();
        }}
      >
        <div className="header-container">
          <span className="assets-header">
            {t.workOrders.form.add_part_set}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                handleClosePartModal();
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content className="mt-2">
          <div style={{ gap: "10px" }} className="d-flex">
            <Form.Field style={{ minHeight: "43px" }}>
              <Dropdown
                placeholder={t.parts.form.inv_loc}
                style={{ minWidth: "300px", padding: "6px 2px 1px 12px" }}
                className="loc-dropdown"
                selection
                noResultsMessage={t.common.no_results_found}
                search
                clearable
                // onAddItem={handleAddLocation}
                options={options.inv_location}
                // onOpen={fetchLocationsOptions}
                value={selectedInvLoc}
                onChange={(e, value) => {
                  setSelectedInvLoc(value?.value);
                }}
              />
            </Form.Field>
            <div
              style={{ width: "-webkit-fill-available" }}
              className="set-input"
            >
              <span className="search-svg-set">
                <SearchIcon />
              </span>
              <Input
                style={{ width: "-webkit-fill-available" }}
                placeholder={t.workOrders.form.search_parts}
                value={partSearchTitle}
                onChange={(e) => setPartSearchTitle(e.target.value)}
              />
            </div>
          </div>
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted content={t.common.loading} />
            </Dimmer>
          ) : (
            <>
              <div
                style={{ }}
                className="parts-listing-container mt-5  bulk-upload-table"
              >
                <DataTable
                  columns={[
                    {
                      name: (
                        <div>
                          <Checkbox
                            checked={areAllSelected}
                            disabled={partsData?.every((item) => item?.disable == true)}
                            onChange={(e, value) =>
                              handleSelectAll(value.checked)
                            }
                            // onClick={() => handleRowAllSelected()}
                            // onChange={(e, value) => {
                            //   handleRowSelected(
                            //     row,
                            //     value.checked,

                            //     selectedTablePartData
                            //   );
                            // }}
                          />
                        </div>
                      ),
                      width: "50px",
                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <Checkbox
                              checked={row?.selectedPart}
                              disabled={row?.disable}
                              className="set-checkbox-gery"
                              // defaultChecked={row?.selectedPart}
                              onChange={(e, value) => {
                                if (!row?.disable) {
                                  handleRowSelected(
                                    row,
                                    value.checked,
                                    selectedTablePartData
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "100px",
                      name: t.workOrders.table.ID,
                      //   name: t.categories.table.name,
                      sortField: "name",
                      sortable: true,
                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.countId ? row?.countId : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "240px",
                      name: t.workOrders.details_view.name,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.name ? row?.name : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "200px",
                      name: t.parts.form.inv_loc,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.location_name ? row?.location_name : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "200px",
                      name: t.assets.add,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.asset ? row?.asset : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "125px",
                      name: t.parts.form.avl_qty,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.type == "Set" ? (
                                row?.status == "outOfStock" ? (
                                  <span style={{ color: "#788CA5" }}>
                                    {t.parts.status.out_of_stock}
                                  </span>
                                ) : null
                              ) : row?.status == "outOfStock" ? (
                                <span style={{ color: "#788CA5" }}>
                                  {t.parts.status.out_of_stock}
                                </span>
                              ) : row?.availableQty ? (
                                row?.availableQty
                              ) : (
                                0
                              )}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "125px",
                      name: t.parts.form.unit_cost,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="">
                              {row?.unitCost ? row?.unitCost : 0}
                            </span>
                            <span
                              className={` align-items-center justify-content-center ${
                                selectedLanguage == "en" ? "ml-2" : "mr-2"
                              }`}
                              style={{
                                backgroundColor: "#E9F2FF",
                                color: "#0C66E4",
                                padding: "9px 3px 9px 2px",
                                fontSize: "14px",
                                display: "inline-block",
                              }}
                            >
                              {organisationCurrency}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                  ]}
                  data={getPaginatedData(partsData)}
                  // data={partsData}
                  // fixedHeader
                  customStyles={customStyles}
                  onRowClicked={(row) => {
                    if (!row?.disable) {
                      handleRowSelected(
                        row,
                        row?.selectedPart ? false : true,
                        selectedTablePartData
                      );
                    }
                  }}
                  noDataComponent={<span>{t.sets.no_data}</span>}
                />
              </div>

             { partsData?.length > 0 && <div className="d-flex justify-content-end">
                <Pagination
                  ofString={t.common.of}
                  total={partsData.length}
                  prevItem={
                    selectedLanguage === "en" ? <ArrowLeft /> : <ArrowRight />
                  }
                  nextItem={
                    selectedLanguage === "en" ? <ArrowRight /> : <ArrowLeft />
                  }
                  page={currentPage}
                  limit={pageSize}
                  handleNext={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                  handlePrevious={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                  parts={true}
                />
              </div> }
            </>
          )}
        </Modal.Content>
        {!loading && (
          <div
            className={`modal-button-container ${
              selectedLanguage == "en" ? "mr-8" : "ml-10"
            }`}
          >
            <button
              className="modal-form-action-button black"
              onClick={() => {
                handleClosePartModal();
              }}
            >
              {t.assets.form.cancel}
            </button>

            <button
              onClick={() => handleAddPartData()}
              className="modal-form-action-button light-blue"
            >
              {t.common.add}
            </button>
          </div>
        )}
      </Modal> */}
      {/* success modal */}
      <SuccessModal
        closeCallback={() => {
          const currentPathname = window.location.pathname;
          setRefresh(!refresh);
          if (currentPathname == "/work-orders") {
            setSearchTitle("");
            setSelectedDates([]);
            setFilterOptions({
              serialNumber: "",

              people: [],
              teams: [],
              customers: [],
              locations: [],
              createdByUser: [],

              status: [],
              priority: [],
              categories: [],
            });
          }
        }}
      >
        <span>{t.workOrders.form.updated.msg}</span>
      </SuccessModal>

      {/* cancel modal */}
      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: "24px" }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>
      <Modal
        size="large"
        open={addPartModal}
        className="assets-modal part-list-modal"
        style={{ height: "770px" }}
        onClose={() => {
          handleClosePartModal();
        }}
      >
        <div className="header-container">
          <span className="assets-header">
            {t.workOrders.form.add_part_set}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                handleClosePartModal();
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content className="mt-2">
          <div style={{ gap: "10px" }} className="d-flex">
            <Form.Field style={{ minHeight: "43px" }}>
              <Dropdown
                placeholder={t.parts.form.inv_loc}
                style={{ minWidth: "300px", padding: "6px 2px 1px 12px" }}
                className={
                  selectedLanguage == "en" ? "loc-dropdown" : "loc-dropdown wos"
                }
                selection
                noResultsMessage={t.common.no_results_found}
                search
                clearable
                // onAddItem={handleAddLocation}
                options={options.inv_location}
                // onOpen={fetchLocationsOptions}
                value={selectedInvLoc}
                onChange={(e, value) => {
                  setSelectedInvLoc(value?.value);
                }}
              />
            </Form.Field>
            <div
              style={{ width: "-webkit-fill-available" }}
              className="set-input"
            >
              <span className="search-svg-set">
                <SearchIcon />
              </span>
              <Input
                style={{ width: "-webkit-fill-available" }}
                placeholder={t.workOrders.form.search_parts}
                value={partSearchTitle}
                onChange={(e) => setPartSearchTitle(e.target.value)}
              />
            </div>
          </div>
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted content={t.common.loading} />
            </Dimmer>
          ) : (
            <>
              <div style={{}} className="  mt-5  bulk-upload-table">
                <DataTable
                  className="wo-table-div"
                  conditionalRowStyles={conditionalRowStyles}
                  columns={[
                    {
                      name: (
                        <div>
                          <Checkbox
                            checked={areAllSelected}
                            disabled={partsData?.every(
                              (item) => item?.disable == true
                            )}
                            onChange={(e, value) =>
                              handleSelectAll(value.checked)
                            }
                            // onClick={() => handleRowAllSelected()}
                            // onChange={(e, value) => {
                            //   handleRowSelected(
                            //     row,
                            //     value.checked,

                            //     selectedTablePartData
                            //   );
                            // }}
                          />
                        </div>
                      ),
                      width: "50px",
                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <Checkbox
                              checked={row?.selectedPart}
                              disabled={row?.disable}
                              className="set-checkbox-gery"
                              // defaultChecked={row?.selectedPart}
                              onChange={(e, value) => {
                                if (!row?.disable) {
                                  handleRowSelected(
                                    row,
                                    value.checked,
                                    selectedTablePartData
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "100px",
                      name: t.workOrders.table.ID,
                      //   name: t.categories.table.name,
                      sortField: "name",
                      sortable: true,
                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.countId ? row?.countId : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "240px",
                      name: t.workOrders.details_view.name,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.name
                                ? DisplayFormattedText(row?.name, 23)
                                : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "200px",
                      name: t.parts.form.inv_loc,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.location_name
                                ? DisplayFormattedText(row?.location_name, 20)
                                : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "200px",
                      name: t.assets.title,
                      cell: (row) => {
                        const locationData = row?.asset;
                        if (locationData?.length > 0) {
                          const firstLocation = locationData[0]?.name;
                          const remainingCount = locationData.length - 1;
                          const remainingLocations = locationData
                            .slice(1)
                            .map((location) => location?.name)
                            .join(", ");
                          const displayText =
                            remainingCount > 0
                              ? `${firstLocation} ... +${remainingCount}`
                              : firstLocation;
                          return (
                            <span
                              title={locationData
                                .map((location) => location?.name)
                                .join(", ")}
                            >
                              {displayText}
                            </span>
                          );
                          // const displayText = locationData
                          //   .map((location) => {
                          //     const nameParts = location?.attributes?.name;

                          //     return nameParts;
                          //   })
                          //   .join(', '); // Adjust the join character as needed
                          // return DisplayFormattedText(displayText, 20);
                        } else {
                          return "";
                        }
                      },

                      // cell: (row) => (
                      //   <div className="d-flex align-items-center">
                      //     <div>
                      //       <span className="id-text-style">
                      //         {/* {row?.asset ? row?.asset : ""} */}
                      //       </span>
                      //     </div>
                      //   </div>
                      // ),
                    },
                    {
                      minWidth: "150px",
                      name: t.parts.form.avl_qty,
                      right: true,
                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.type == "Set" ? (
                                row?.status == "outOfStock" ? (
                                  <div
                                    style={{
                                      color: "#788CA5",
                                      width: "103px",
                                      textAlign: "end",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {t.parts.status.out_of_stock}
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      color: "black",
                                      width: "103px",
                                      textAlign: "end",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {t.parts.status.inStock}
                                  </div>
                                )
                              ) : row?.status == "outOfStock" ? (
                                <div
                                  style={{
                                    color: "#788CA5",
                                    width: "103px",
                                    textAlign: "end",
                                    fontWeight: 400,
                                  }}
                                >
                                  {t.parts.status.out_of_stock}
                                </div>
                              ) : row?.availableQty ? (
                                <>
                                  <div className="id-text-style d-flex align-items-center">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        style={{
                                          textAlign: "end",
                                          minWidth: "65px", // Set a fixed width based on the longest expected value
                                          // Align text to the right within the fixed space
                                          display: "inline-block",
                                        }}
                                      >
                                        {row?.availableQty
                                          ? row?.availableQty
                                          : 0}
                                      </span>
                                      <span
                                        className={`align-items-center justify-content-center ${
                                          selectedLanguage === "en"
                                            ? "ml-2"
                                            : "mr-2"
                                        }`}
                                        style={{
                                          backgroundColor: "#E9F2FF",
                                          color: "#0C66E4",
                                          minWidth:
                                            selectedLanguage == "en"
                                              ? "33px"
                                              : "48px",
                                          padding: "9px 3px 9px 2px",
                                          fontSize: "14px",
                                          display: "inline-block",
                                          textAlign: "center",
                                        }}
                                      >
                                        {row?.unitType
                                          ? HandleUnitType(row?.unitType)
                                          : null}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                0
                              )}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "150px",
                      name: t.parts.form.unit_cost,
                      right: true,
                      style: {
                        justifyContent: "end", // Align header text to the right
                      },
                      cell: (row) => (
                        <div className="id-text-style d-flex align-items-center">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{
                                textAlign: "end",
                                minWidth: "65px", // Set a fixed width based on the longest expected value
                                // Align text to the right within the fixed space
                                display: "inline-block",
                              }}
                            >
                              {row?.unitCost ? row?.unitCost : 0}
                            </span>
                            <span
                              className={`align-items-center justify-content-center ${
                                selectedLanguage === "en" ? "ml-2" : "mr-2"
                              }`}
                              style={{
                                backgroundColor: "#E9F2FF",
                                color: "#0C66E4",
                                minWidth: "33px",
                                padding: "9px 3px 9px 2px",
                                fontSize: "14px",
                                display: "inline-block",
                              }}
                            >
                              {organisationCurrency}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                  ]}
                  data={getPaginatedData(partsData)}
                  // data={partsData}
                  fixedHeader
                  customStyles={customStyles}
                  onRowClicked={(row) => {
                    if (!row.disable) {
                      handleRowSelected(
                        row,
                        row?.selectedPart ? false : true,
                        selectedTablePartData
                      );
                    }
                  }}
                  noDataComponent={<span>{t.sets.no_data_wo}</span>}
                />
              </div>

              {partsData && partsData?.length > 0 && (
                <div className="d-flex justify-content-end">
                  <Pagination
                    ofString={t.common.of}
                    total={partsData.length}
                    prevItem={
                      selectedLanguage === "en" ? <ArrowLeft /> : <ArrowRight />
                    }
                    nextItem={
                      selectedLanguage === "en" ? <ArrowRight /> : <ArrowLeft />
                    }
                    page={currentPage}
                    limit={pageSize}
                    handleNext={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    handlePrevious={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                    parts={true}
                  />
                </div>
              )}
            </>
          )}
        </Modal.Content>
        {!loading && (
          <div
            className={`modal-button-container ${
              selectedLanguage == "en" ? "mr-8" : "ml-10"
            }`}
          >
            <button
              className="modal-form-action-button black"
              onClick={() => {
                handleClosePartModal();
              }}
            >
              {t.assets.form.cancel}
            </button>

            <button
              onClick={() => handleAddPartData()}
              className="modal-form-action-button light-blue"
            >
              {t.common.add}
            </button>
          </div>
        )}
      </Modal>
      {/* <Modal
        size="large"
        open={addPartModal}
        className="assets-modal part-list-modal"
        style={{ height: "770px" }}
        onClose={() => {
          handleClosePartModal();
        }}
      >
        <div className="header-container">
          <span className="assets-header">
            {t.workOrders.form.add_part_set}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                handleClosePartModal();
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content className="mt-2">
          <div style={{ gap: "10px" }} className="d-flex">
            <Form.Field style={{ minHeight: "43px" }}>
              <Dropdown
                placeholder={t.parts.form.inv_loc}
                style={{ minWidth: "300px", padding: "6px 2px 1px 12px" }}
                className="loc-dropdown"
                selection
                noResultsMessage={t.common.no_results_found}
                search
                clearable
                // onAddItem={handleAddLocation}
                options={options.inv_location}
                // onOpen={fetchLocationsOptions}
                value={selectedInvLoc}
                onChange={(e, value) => {
                  setSelectedInvLoc(value?.value);
                }}
              />
            </Form.Field>
            <div
              style={{ width: "-webkit-fill-available" }}
              className="set-input"
            >
              <span className="search-svg-set">
                <SearchIcon />
              </span>
              <Input
                style={{ width: "-webkit-fill-available" }}
                placeholder={t.workOrders.form.search_parts}
                value={partSearchTitle}
                onChange={(e) => setPartSearchTitle(e.target.value)}
              />
            </div>
          </div>
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted content={t.common.loading} />
            </Dimmer>
          ) : (
            <>
              <div
                style={{}}
                className="parts-listing-container mt-5  bulk-upload-table"
              >
                <DataTable
                  columns={[
                    {
                      name: (
                        <div>
                          <Checkbox
                            checked={areAllSelected}
                            disabled={partsData?.every(
                              (item) => item?.disable == true
                            )}
                            onChange={(e, value) =>
                              handleSelectAll(value.checked)
                            }
                            // onClick={() => handleRowAllSelected()}
                            // onChange={(e, value) => {
                            //   handleRowSelected(
                            //     row,
                            //     value.checked,

                            //     selectedTablePartData
                            //   );
                            // }}
                          />
                        </div>
                      ),
                      width: "50px",
                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <Checkbox
                              checked={row?.selectedPart}
                              disabled={row?.disable}
                              className="set-checkbox-gery"
                              // defaultChecked={row?.selectedPart}
                              onChange={(e, value) => {
                                if (!row?.disable) {
                                  handleRowSelected(
                                    row,
                                    value.checked,
                                    selectedTablePartData
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "100px",
                      name: t.workOrders.table.ID,
                      //   name: t.categories.table.name,
                      sortField: "name",
                      sortable: true,
                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.countId ? row?.countId : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "240px",
                      name: t.workOrders.details_view.name,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.name ? row?.name : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "200px",
                      name: t.parts.form.inv_loc,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.location_name ? row?.location_name : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "200px",
                      name: t.assets.add,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.asset ? row?.asset : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "125px",
                      name: t.parts.form.avl_qty,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.type == "Set" ? (
                                row?.status == "outOfStock" ? (
                                  <span style={{ color: "#788CA5" }}>
                                    {t.parts.status.out_of_stock}
                                  </span>
                                ) : null
                              ) : row?.status == "outOfStock" ? (
                                <span style={{ color: "#788CA5" }}>
                                  {t.parts.status.out_of_stock}
                                </span>
                              ) : row?.availableQty ? (
                                row?.availableQty
                              ) : (
                                0
                              )}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "125px",
                      name: t.parts.form.unit_cost,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="">
                              {row?.unitCost ? row?.unitCost : 0}
                            </span>
                            <span
                              className={` align-items-center justify-content-center ${
                                selectedLanguage == "en" ? "ml-2" : "mr-2"
                              }`}
                              style={{
                                backgroundColor: "#E9F2FF",
                                color: "#0C66E4",
                                padding: "9px 3px 9px 2px",
                                fontSize: "14px",
                                display: "inline-block",
                              }}
                            >
                              {organisationCurrency}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                  ]}
                  data={getPaginatedData(partsData)}
                  // data={partsData}
                  // fixedHeader
                  customStyles={customStyles}
                  onRowClicked={(row) => {
                    if (!row.disable) {
                      handleRowSelected(
                        row,
                        row?.selectedPart ? false : true,
                        selectedTablePartData
                      );
                    }
                  }}
                  noDataComponent={<span>{t.sets.no_data}</span>}
                />
              </div>

              {partsData?.length > 0 && (
                <div className="d-flex justify-content-end">
                  <Pagination
                    ofString={t.common.of}
                    total={partsData.length}
                    prevItem={
                      selectedLanguage === "en" ? <ArrowLeft /> : <ArrowRight />
                    }
                    nextItem={
                      selectedLanguage === "en" ? <ArrowRight /> : <ArrowLeft />
                    }
                    page={currentPage}
                    limit={pageSize}
                    handleNext={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    handlePrevious={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                    parts={true}
                  />
                </div>
              )}
            </>
          )}
        </Modal.Content>
        {!loading && (
          <div
            className={`modal-button-container ${
              selectedLanguage == "en" ? "mr-8" : "ml-10"
            }`}
          >
            <button
              className="modal-form-action-button black"
              onClick={() => {
                handleClosePartModal();
              }}
            >
              {t.assets.form.cancel}
            </button>

            <button
              onClick={() => handleAddPartData()}
              className="modal-form-action-button light-blue"
            >
              {t.common.add}
            </button>
          </div>
        )}
      </Modal> */}
      {/* on the go popups */}
      <AddCustomerModal
        toggled={addCustomerModal}
        untoggle={() => {
          toggleAddCustomerModal(false);
        }}
        returnAddCustomer={returnAddCustomer}
        title={customerText}
      />

      <AddLocationModal
        toggled={addLocationModal}
        untoggle={() => {
          toggleAddLocationModal(false);
        }}
        customer={data.customer}
        returnAddLocation={returnAddLocation}
        title={locationText}
      />

      <AddPeopleModal
        toggled={addPeopleModal}
        untoggle={() => {
          toggleAddPeopleModal(false);
        }}
        returnAddPeople={returnAddPeople}
        title={peopleText}
      />

      <AddTeamModal
        toggled={addTeamModal}
        untoggle={() => {
          toggleAddTeamModal(false);
        }}
        returnAddTeam={returnAddTeam}
        title={teamText}
      />

      <AddProcedureModal
        toggled={addProcedureModal}
        untoggle={() => {
          toggleAddProcedureModal(false);
        }}
        returnAddProcedure={returnAddProcedure}
        title={procedureText}
      />

      <AddAssetModal
        toggled={addAssetModal}
        untoggle={() => {
          toggleAddAssetModal(false);
        }}
        returnAddAsset={returnAddAsset}
        title={assetText}
      />

      {/* <AddPartModal
        toggled={addPartModal}
        untoggle={() => {
          toggleAddPartModal(false);
        }}
        returnAddPart={returnAddPart}
        title={partText}
      /> */}
    </>
  );
}

export default Edit;
