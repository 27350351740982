import React, { useState, useEffect, createRef } from "react";
import {
  Card,
  Loader,
  Segment,
  Header,
  Dimmer,
  Modal,
  Dropdown,
  Image,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  List,
  Grid,
  Popup,
} from "semantic-ui-react";
import location_icon from "../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import { toast } from "react-toastify";
import { format } from "date-fns";
import {
  getActiveUsersByRole,
  getAllJobTitles,
  getAllLocations,
} from "../../config/functions";
import { useCancelModal } from "../../../hooks/Messages/useCancelModal";

import { toAbsoluteUrl } from "../../../_metronic/_helpers";

import { Country, State, City } from "country-state-city";

import {
  uploadFile,
  country_codes,
  updateUserRole,
  updateUserStatusToPending,
  createNewUser,
  getAllParentLocations,
  getAllTeams,
  getUsersByRole,
  createNewUserProfile,
  validateEmail,
  verifyPersonEmailAndPhone,
  IMAGES_URL,
} from "../../config/functions";
import { at, cloneDeep } from "lodash";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import PeopleForm from "./PeopleForm";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import { PhoneNumberUtil } from "google-libphonenumber";
import { selectTranslations } from "../../config/i18n/slice";
import {
  AdminGreyIcon,
  AdminIcon,
  CloseIcon,
  LimitedAdminIcon,
  RequesterIcon,
  TechnicianIcon,
  ViewerIcon,
} from "../../../Assets/Icons/svg";
import ToolTip from "react-power-tooltip";
import { he } from "date-fns/locale";
import { Height } from "@material-ui/icons";
import { fetchPeopleInitData } from "../../../redux/reducers/peopleReducer";
import { Resizable } from "re-resizable";
import useNewCancelModal from "../../../hooks/Messages/useNewCncelModal";
const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const firstTabValidationFields = [
  "firstName",
  "lastName",
  "department",
  "supervisor",
  "role",
  // 'whpw',
  // 'whpd',
  // "contractStart",
  // "contractEnd",
  // "badgeId",
  // "registrationNo",
  // "workPermitNo",

  // 'username',
  "email",
  "phone",
  "jobTitle",
  "hourRate",
  "shifts",
  "days",
  "locations",
];
const secondTabValidationFields = ["emergencyPhone"];

function Add({
  toggled,
  untoggle,
  refresh,
  setRefresh,
  setFilterOptions,
  setSearchTitle,
  setSortData,
  roleData,
  allIds,
}) {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const dispatch = useDispatch();
  const { initData } = useSelector((state) => ({
    initData: state.people.peopleInitData,
  }));
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );

  const PersonValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(t.common.required_field),
    lastName: Yup.string().required(t.common.required_field),
    role: Yup.string().required(t.common.required_field),
    // department: Yup.string().required(t.common.required_field),
    // whpw: Yup.string().required(t.common.required_field),
    // whpd: Yup.string().required(t.common.required_field),
    // contractStart: Yup.string().required(t.common.required_field),
    // contractEnd: Yup.string().required(t.common.required_field),
    // badgeId: Yup.string().required(t.common.required_field),
    // registrationNo: Yup.string().required(t.common.required_field),
    // workPermitNo: Yup.string().required(t.common.required_field),

    tab: Yup.number(),
    countryCode: Yup.string(),
    // username: Yup.string().required(t.common.required_field),
    email: Yup.string()
      .email(t.people.invalid_email)
      .required(t.common.required_field),
    phone: Yup.string()
      .required(t.common.required_field)
      .test("correct-format", t.people.invalid_phone, function(value) {
        if (isPhoneValid(value)) {
          console.log('value: ', value);
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    emergencyPhone: Yup.string().test(
      "correct-format",
      t.people.invalid_phone,
      function(value) {
        if (!value || isPhoneValid(value)) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }
    ),

    // hourRate: Yup.string()
    //   .matches(/^\d+$/, 'Should contain only numeric values')
    //   .required(t.common.required_field),
    shifts: Yup.object()
      .shape({
        shiftA: Yup.boolean().required(),
        shiftB: Yup.boolean().required(),
        shiftC: Yup.boolean().required(),
      })
      .test("at-least-one-true", t.common.required_field, function(object) {
        const { shiftA, shiftB, shiftC } = object;

        // Check if at least one value is true
        if (shiftA || shiftB || shiftC) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    days: Yup.object()
      .shape({
        sat: Yup.boolean().required(),
        sun: Yup.boolean().required(),
        mon: Yup.boolean().required(),
        tue: Yup.boolean().required(),
        wed: Yup.boolean().required(),
        thu: Yup.boolean().required(),
        fri: Yup.boolean().required(),
      })
      .test("at-least-one-true", t.common.required_field, function(object) {
        const { sat, sun, mon, tue, wed, thu, fri } = object;

        // Check if at least one value is true
        if (sat || sun || mon || tue || wed || thu || fri) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    locations: Yup.array()
      .of(Yup.number())
      .min(1, t.common.required_field)
      .required(t.common.required_field),
    // jobTitle: Yup.string().required(t.common.required_field),
  });

  const PersonLimitedValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(t.common.required_field),
    lastName: Yup.string().required(t.common.required_field),
    role: Yup.string().required(t.common.required_field),
    supervisor: Yup.string().required(t.common.required_field),
    // department: Yup.string().required(t.common.required_field),
    // whpw: Yup.string().required(t.common.required_field),
    // whpd: Yup.string().required(t.common.required_field),
    // contractStart: Yup.string().required(t.common.required_field),
    // contractEnd: Yup.string().required(t.common.required_field),
    // badgeId: Yup.string().required(t.common.required_field),
    // registrationNo: Yup.string().required(t.common.required_field),
    // workPermitNo: Yup.string().required(t.common.required_field),

    tab: Yup.number(),
    countryCode: Yup.string(),
    // username: Yup.string().required(t.common.required_field),
    email: Yup.string()
      .email(t.people.invalid_email)
      .required(t.common.required_field),
    phone: Yup.string()
      .required(t.common.required_field)
      .test("correct-format", t.people.invalid_phone, function(value) {
        if (isPhoneValid(value)) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    emergencyPhone: Yup.string().test(
      "correct-format",
      t.people.invalid_phone,
      function(value) {
        if (!value || isPhoneValid(value)) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }
    ),

    // hourRate: Yup.string()
    //   .matches(/^\d+$/, 'Should contain only numeric values')
    //   .required(t.common.required_field),
    shifts: Yup.object()
      .shape({
        shiftA: Yup.boolean().required(),
        shiftB: Yup.boolean().required(),
        shiftC: Yup.boolean().required(),
      })
      .test("at-least-one-true", t.common.required_field, function(object) {
        const { shiftA, shiftB, shiftC } = object;

        // Check if at least one value is true
        if (shiftA || shiftB || shiftC) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    days: Yup.object()
      .shape({
        sat: Yup.boolean().required(),
        sun: Yup.boolean().required(),
        mon: Yup.boolean().required(),
        tue: Yup.boolean().required(),
        wed: Yup.boolean().required(),
        thu: Yup.boolean().required(),
        fri: Yup.boolean().required(),
      })
      .test("at-least-one-true", t.common.required_field, function(object) {
        const { sat, sun, mon, tue, wed, thu, fri } = object;

        // Check if at least one value is true
        if (sat || sun || mon || tue || wed || thu || fri) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    locations: Yup.array()
      .of(Yup.number())
      .min(1, t.common.required_field)
      .required(t.common.required_field),
    // jobTitle: Yup.string().required(t.common.required_field),
  });

  const PersonTechnicianValidationSchema = Yup.object().shape({
    supervisor: Yup.string().required(t.common.required_field),
    role: Yup.string().required(t.common.required_field),
    // whpd: Yup.string().required(t.common.required_field),
    // contractStart: Yup.string().required(t.common.required_field),
    // contractEnd: Yup.string().required(t.common.required_field),
    // badgeId: Yup.string().required(t.common.required_field),
    // registrationNo: Yup.string().required(t.common.required_field),
    // workPermitNo: Yup.string().required(t.common.required_field),
    firstName: Yup.string().required(t.common.required_field),
    lastName: Yup.string().required(t.common.required_field),
    // department: Yup.string().required(t.common.required_field),
    tab: Yup.number(),
    countryCode: Yup.string(),
    // username: Yup.string().required(t.common.required_field),
    email: Yup.string()
      .email(t.people.invalid_email)
      .nullable(),
    phone: Yup.string()
      .required(t.common.required_field)
      .test("correct-format", t.people.invalid_phone, function(value) {
        if (isPhoneValid(value)) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    emergencyPhone: Yup.string().test(
      "correct-format",
      t.people.invalid_phone,
      function(value) {
        if (!value || isPhoneValid(value)) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }
    ),

    // hourRate: Yup.string()
    //   .matches(/^\d+$/, 'Should contain only numeric values')
    //   .required(t.common.required_field),
    shifts: Yup.object()
      .shape({
        shiftA: Yup.boolean().required(),
        shiftB: Yup.boolean().required(),
        shiftC: Yup.boolean().required(),
      })
      .test("at-least-one-true", t.common.required_field, function(object) {
        const { shiftA, shiftB, shiftC } = object;

        // Check if at least one value is true
        if (shiftA || shiftB || shiftC) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    days: Yup.object()
      .shape({
        sat: Yup.boolean().required(),
        sun: Yup.boolean().required(),
        mon: Yup.boolean().required(),
        tue: Yup.boolean().required(),
        wed: Yup.boolean().required(),
        thu: Yup.boolean().required(),
        fri: Yup.boolean().required(),
      })
      .test("at-least-one-true", t.common.required_field, function(object) {
        const { sat, sun, mon, tue, wed, thu, fri } = object;

        // Check if at least one value is true
        if (sat || sun || mon || tue || wed || thu || fri) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    locations: Yup.array()
      .of(Yup.number())
      .min(1, t.common.required_field)
      .required(t.common.required_field),
    // jobTitle: Yup.string().required(t.common.required_field),
  });

  const PersonRequesterValidationSchema = Yup.object().shape({
    role: Yup.string().required(t.common.required_field),
    locations: Yup.array()
      .of(Yup.number())
      .min(1, t.common.required_field)
      .required(t.common.required_field),
    firstName: Yup.string().required(t.common.required_field),
    lastName: Yup.string().required(t.common.required_field),
    tab: Yup.number(),
    countryCode: Yup.string(),
    // username: Yup.string().required(t.common.required_field),
    email: Yup.string()
      .email(t.people.invalid_email)
      .nullable(),
    phone: Yup.string()
      .required(t.common.required_field)
      .test("correct-format", t.people.invalid_phone, function(value) {
        if (isPhoneValid(value)) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
  });
  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });
  const [initChange, setInitChange] = useState(true);
  const [activePane, setActivePane] = useState(0);
  const [errorPane, setErrorPane] = useState({
    pane0: false,
    pane1: false,
  });
  const [personalImgId, setPersonalImgId] = useState();
  const [file, setFile] = useState(""); //
  const [imageSrc, setImageSrc] = useState("");
  const [buttonStatus, setButtonStatus] = useState(0);
  const [isLoadingActive, activateLoading] = useState(false);
  const [isAsideModalOpen, toggleAsideModalOpen] = useState(false);
  const [successModal, toggleSuccessModal] = useState(false);
  // const [cancelModal, toggleCancelModal] = useState(false);
  const [responseData, setResponseData] = useState({});

  const [dropdownOptions, setDropdownOptions] = useState({
    locations: {
      initOptions: [],
      options:[]
    },
    roles: [
      {
        key: 0,
        value: 5,
        text: t.people.add_modal.admin,
        icon: <AdminGreyIcon />,
        description: t.people.add_modal.admin_description,
      },
      // {
      //   key: 89,
      //   value: 7,
      //   text: 'Supervisor',
      //   icon: 'handshake outline',
      //   description: 'Can add orders, assets, locations, people',
      // },
      {
        key: 2,
        value: 9,
        text: t.people.add_modal.limited_admin,
        icon: <LimitedAdminIcon />,
        description: t.people.add_modal.limited_admin_description,
      },
      {
        key: 1,
        value: 8,
        text: t.people.add_modal.technician,
        icon: <TechnicianIcon />,
        description: t.people.add_modal.user_description,
      },

      {
        key: 3,
        value: 3,
        text: t.people.add_modal.viewer,
        icon: <ViewerIcon />,
        description: t.people.add_modal.viewer_description,
      },
      {
        key: 2,
        value: 6,
        text: t.people.add_modal.requester,
        icon: <RequesterIcon />,
        description: t.people.add_modal.requester_desc,
      },
      // {
      //   key: 3,
      //   value: 3,
      //   text: 'Viewer only',
      //   icon: 'sitemap',
      //   description:
      //     'Lorem ipsum is placeholder text commonly used in the graphic',
      // },
    ],

    martialStatus: [
      {
        key: 0,
        value: "single",
        text: t.people.personal_info_form.martial_option.single,
      },

      {
        key: 1,
        value: "married",
        text: t.people.personal_info_form.martial_option.married,
      },
    ],
    userRole: [
      {
        key: 0,
        value: 5,
        text: t.people.add_modal.admin,
      },

      {
        key: 9,
        value: 9,
        text: t.people.add_modal.limited_admin,
      },
      {
        key: 8,
        value: 8,
        text: t.people.add_modal.technician,
      },
      {
        key: 3,
        value: 3,
        text: t.people.add_modal.viewer,
      },
      {
        key: 6,
        value: 6,
        text: t.people.add_modal.requester,
      },
    ],
    limitedRoles: [
      {
        key: 9,
        value: 9,
        text: t.people.add_modal.limited_admin,
      },
      {
        key: 8,
        value: 8,
        text: t.people.add_modal.technician,
      },
      {
        key: 3,
        value: 3,
        text: t.people.add_modal.viewer,
      },
      {
        key: 6,
        value: 6,
        text: t.people.add_modal.requester,
      },
    ],
    childrenNumber: [
      {
        key: 0,
        value: 0,
        text: "0",
      },

      {
        key: 1,
        value: 1,
        text: "1",
      },
      {
        key: 2,
        value: 2,
        text: "2",
      },
      {
        key: 3,
        value: 3,
        text: "3",
      },
      {
        key: 4,
        value: 4,
        text: "4",
      },
      {
        key: 5,
        value: 5,
        text: "5",
      },
      {
        key: 6,
        value: 6,
        text: "6",
      },
      {
        key: 7,
        value: 7,
        text: "7",
      },
    ],

    studyFiled: [
      {
        key: 0,
        value: "Agriculture and Forestry",
        text: t.people.studyField.agriculture,
      },

      {
        key: 1,
        value: "Architecture and Urban Planning",
        text: t.people.studyField.agricultureUrban,
      },
      {
        key: 2,
        value: "Arts and Humanities",
        text: t.people.studyField.arts,
      },
      {
        key: 3,
        value: "Astronomy and Astrophysics",
        text: t.people.studyField.astronomy,
      },
      {
        key: 4,
        value: "Business and Economics",
        text: t.people.studyField.economics,
      },
      {
        key: 5,
        value: "Communication and Media Studies",
        text: t.people.studyField.mediaStudy,
      },
      {
        key: 6,
        value: "Computer Science and Information Technology",
        text: t.people.studyField.computerScience,
      },
      {
        key: 7,
        value: "Cultural Studies",
        text: t.people.studyField.cultural,
      },
      {
        key: 8,
        value: "Education",
        text: t.people.studyField.education,
      },
      {
        key: 9,
        value: "Engineering and Technology",
        text: t.people.studyField.engineering,
      },
      {
        key: 10,
        value: "Environmental Science",
        text: t.people.studyField.environmental,
      },
      {
        key: 11,
        value: "Fine Arts and Design",
        text: t.people.studyField.fine_arts,
      },
      {
        key: 12,
        value: "Geology and Earth Sciences",
        text: t.people.studyField.geology,
      },
      {
        key: 13,
        value: "Health and Medicine",
        text: t.people.studyField.health,
      },
      {
        key: 14,
        value: "Hospitality and Tourism",
        text: t.people.studyField.hospitality,
      },
      {
        key: 15,
        value: "Law and Legal Studies",
        text: t.people.studyField.law,
      },
      {
        key: 16,
        value: "Library and Information Science",
        text: t.people.studyField.library,
      },
      {
        key: 17,
        value: "Linguistics",
        text: t.people.studyField.linguistics,
      },
      {
        key: 18,
        value: "Mathematics and Statistics",
        text: t.people.studyField.mathematics,
      },
      {
        key: 19,
        value: "Natural Sciences",
        text: t.people.studyField.naturalSciences,
      },
      {
        key: 20,
        value: "Performing Arts",
        text: t.people.studyField.performingArts,
      },
      {
        key: 21,
        value: "Philosophy",
        text: t.people.studyField.philosophy,
      },
      {
        key: 22,
        value: "Physical Education and Sports Science",
        text: t.people.studyField.physicalEducation,
      },
      {
        key: 23,
        value: "Political Science",
        text: t.people.studyField.politicalScience,
      },
      {
        key: 24,
        value: "Psychology",
        text: t.people.studyField.psychology,
      },
      {
        key: 25,
        value: "Public Administration",
        text: t.people.studyField.publicAdministration,
      },
      {
        key: 26,
        value: "Religious Studies",
        text: t.people.studyField.religiousStudies,
      },
      {
        key: 27,
        value: "Social Sciences",
        text: t.people.studyField.socialSciences,
      },
      {
        key: 28,
        value: "Sociology",
        text: t.people.studyField.sociology,
      },
    ],

    educationLevel: [
      {
        key: 0,
        value: "highSchoolDegree",
        text: t.people.personal_info_form.education_options.high_school,
      },
      {
        key: 1,
        value: "diplomaDegree",
        text: t.people.personal_info_form.education_options.diploma,
      },
      {
        key: 2,
        value: "bachelorsDegree",
        text: t.people.personal_info_form.education_options.bachelors,
      },
      {
        key: 3,
        value: "mastersDegree",
        text: t.people.personal_info_form.education_options.masters,
      },
      {
        key: 4,
        value: "doctorateDegree",
        text: t.people.personal_info_form.education_options.doctorate,
      },
      {
        key: 5,
        value: "other",
        text: t.people.personal_info_form.education_options.other,
      },
    ],
    nationalities: [],

    gender: [
      {
        key: 0,
        value: "male",
        text: t.people.personal_info_form.gender_options.gender_male,
      },

      {
        key: 1,
        value: "female",
        text: t.people.personal_info_form.gender_options.gender_female,
      },
    ],

    users: [],
    teams: [],
    jobTitle: [],
  });
  const [data, setData] = useState({
    locations: [],
    selectedLocation: [],
    teams: [],
    hourRate: "",
    whpd: "",
    whpw: "",
    department: "",
    contractStart: "",
    contractEnd: "",
    shifts: {
      shiftA: true,
      shiftB: false,
      shiftC: false,
    },
    days: {
      sat: false,
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
    },
    firstName: "",
    lastName: "",
    username: "",
    address: "",
    email: "",
    countryCode: "962",
    phone: "",
    martialStatus: "single",
    emergencyName: "",
    emergencyCountryCode: "962",
    emergencyPhone: "",
    educationLevel: "highSchoolDegree",
    fieldOfStudy: "",
    nationality: "",
    identificationNo: "",
    passportNo: "",
    gender: "male",
    dob: null,
    cob: "",
    childrenNo: "",
    visaNo: "",
    workPermitNo: "",
    visaExpireDate: null,
    supervisor: "",
    jobTitle: "",
    role: "",
    registrationNo: "",
    badgeId: "",
  });

  const [attachments, setAttachments] = useState({
    contractCopy: null,
    personalPicture: null,
    passportCopy: null,
    idCopyFront: null,
    visaCopy: null,
    covidCopy: [],
  });

  const [validation, setValidation] = useState({
    firstName: {
      error: false,
      pattern: (firstName) => firstName != "",
      skipped: false,
      message: t.common.required_field,
    },
    lastName: {
      error: false,
      pattern: (lastName) => lastName != "",
      skipped: false,
      message: t.common.required_field,
    },
    // username: {
    //   error: false,
    //   pattern: (username) => username != '',
    //   skipped: false,
    //   message: t.common.required_field,
    // },
    email: {
      error: false,
      pattern: (email) => validateEmail(email),
      skipped: false,
      message: t.people.invalid_email,
    },
    phone: {
      error: false,
      pattern: (phone) => phone != "",
      skipped: false,
      message: t.common.required_field,
    },
    jobTitle: {
      error: false,
      pattern: (title) => title != "",
      skipped: false,
      message: t.common.required_field,
    },
    hourRate: {
      error: false,
      pattern: (hourRate) => hourRate != "",
      skipped: false,
      message: t.common.required_field,
    },
    shifts: {
      error: false,
      pattern: (shifts) => shifts.some((el) => el != false),
      skipped: false,
      message: t.common.required_field,
    },
    days: {
      error: false,
      pattern: (days) => days.some((el) => el != false),
      skipped: false,
      message: t.common.required_field,
    },
    locations: {
      error: false,
      pattern: (locations) =>
        locations && locations.length > 0 && locations != "",
      skipped: false,
      message: t.common.required_field,
    },
    // whpd: {
    //   error: false,
    //   pattern: (whpd) => whpd != '',
    //   skipped: false,
    //   message: t.common.required_field,
    // },
    // whpw: {
    //   error: false,
    //   pattern: (whpw) => whpw != '',
    //   skipped: false,
    //   message: t.common.required_field,
    // },

    // contractStart: {
    //   error: false,
    //   pattern: (contractStart) =>
    //     moment(contractStart).format('YYYY-MM-DD') ===
    //       moment(contractStart).format('YYYY-MM-DD') || contractStart != '',
    //   skipped: false,
    //   message: 'Invalid date',
    // },
    // contractEnd: {
    //   error: false,
    //   pattern: (contractEnd) =>
    //     moment(contractEnd).format('YYYY-MM-DD') ===
    //       moment(contractEnd).format('YYYY-MM-DD') || contractEnd != '',
    //   skipped: false,
    //   message: 'Invalid date',
    // },

    // dob: {
    //   error: false,
    //   pattern: (dob) =>
    //     moment(dob).format('YYYY-MM-DD') === moment(dob).format('YYYY-MM-DD') ||
    //     dob != '',
    //   skipped: false,
    //   message: 'Invalid date',
    // },

    // identificationNo: {
    //   error: false,
    //   pattern: (num) => num != '',
    //   skipped: false,
    //   message: t.common.required_field,
    // },
  });

  const [validForm, setValidForm] = useState(false);

  const resetForm = () => {
    setData({
      locations: [],
      selectedLocation: [],
      teams: [],
      hourRate: "",
      whpd: "",
      whpw: "",
      department: "",
      contractStart: "",
      contractEnd: "",
      shifts: {
        shiftA: true,
        shiftB: false,
        shiftC: false,
      },
      days: {
        sat: false,
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
      },
      firstName: "",
      lastName: "",
      // username: '',
      address: "",
      email: "",
      countryCode: "962",
      phone: "",
      martialStatus: "single",
      emergencyName: "",
      emergencyCountryCode: "962",
      emergencyPhone: "",
      educationLevel: "highSchoolDegree",
      fieldOfStudy: "",
      nationality: "",
      identificationNo: "",
      passportNo: "",
      gender: "male",
      dob: null,
      cob: "",
      childrenNo: "",
      visaNo: "",
      workPermitNo: "",
      visaExpireDate: null,
      supervisor: "",
      jobTitle: "",
      role: "",
      registrationNo: "",
      badgeId: "",
    });

    setModalWidth(Math.max(window.innerWidth / 2, 514));

    setAttachments({
      contractCopy: null,
      personalPicture: null,
      passportCopy: null,
      idCopyFront: null,
      visaCopy: null,
      covidCopy: [],
    });

    setFile('');
    setImageSrc('')
    setPersonalImgId(null)

    setActivePane(0);
  };

  // const [attachmentsResponse, setAttachmentsResponse] = useState([]);
  const contractCopyRef = createRef();
  const personalPictureRef = createRef();
  const passportCopyRef = createRef();
  const idCopyFrontRef = createRef();
  const visaCopyRef = createRef();
  const covidCopyRef = createRef();

  const fetchLocations = async () => {
    const fetchData = await getAllParentLocations(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      ["name:asc"]
    );
    let the_options = [];
    let parentOptions = [];
    let subOptions = [];
    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        if (!(roleData === "super_admin")) {
          // Check if the element's ID is included in allIds
          if (allIds?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
 className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (allIds?.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
              (subEl) => allIds?.includes(subEl.id)
            );

            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
 className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });

              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
            }
          }
        } else {
          // Add parent option
          parentOptions.push({
            key: el.id,
            value: el.id,
            disabled: false,
            text: el.attributes.name,
            image: { src: location_icon },
 className: "main-location-dropdown-option",
          });

          // Collect sub-options
          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });

      // Sort sub-options alphabetically
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      // Merge parent options with sorted sub-options
      parentOptions.forEach((parentOption) => {
        the_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            the_options.push(subOption);
          }
        });
      });

      // Update the options state
      setDropdownOptions((prev) => ({
        ...prev,
        locations:{
          options:the_options,
          initOptions:the_options,
        } 
      }));

      return the_options;
    }
  };

  const fetchCountries = () => {
    const fetchData = Country.getAllCountries();
    let the_options = [];
    if (fetchData) {
      the_options = fetchData.map((el) => {
        return {
          key: el?.isoCode?.toLowerCase(),
          value: el?.isoCode,
          text: el.name,
          flag: el?.isoCode?.toLowerCase(),
          // image: {
          //   avatar: true,
          //   src: `https://flagcdn.com/w20/${el?.isoCode?.toLowerCase()}.png`,
          //   size: 'tiny',
          // },
        };
      });

      setDropdownOptions({ ...dropdownOptions, nationalities: the_options });
    }
  };

  const fetchJobTitleData = async () => {
    const fetchData = await getAllJobTitles(organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          // value: el.id,
          value: el.id,
          text: el.attributes.title,
        };
      });

      setDropdownOptions({
        ...dropdownOptions,
        jobTitle: the_options,
      });

      return the_options;
    }
  };
  // const jobTitleCreateSuccess = (res) => {
  //   fetchJobTitleData();

  // };

  const onFileChange = (files, name) => {
    setInitChange(true);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;

    if(name == "personalPicture"){
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImageSrc(reader.result);
      });
      reader.readAsDataURL(files[0]);
  
  
      setFile({
        name: files[0]?.name,
        type: files[0]?.type,
      });
  
    }else{
      setAttachments({
        ...attachments,
        [name]: {
          file: files[0],
          id: randomnumber,
          name: files[0].name,
          type: files[0].type,
          cancelPopup: false,
          isChanged: true,
        },
      });
    }

  

  };

  const onCertificateFileChange = (files, name) => {
    setInitChange(true);
    let newFiles = [];
    Array.from(files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });
    setAttachments({
      ...attachments,
      [name]: [...attachments[name], ...newFiles],
    });
  };

  // const onValuesChange = (name) => (event, value) => {
  //   event.persist();
  //   setInitChange(true);
  //   const boolean_fields = ['hasWorkPermit'];
  //   const dropdown_fields = [
  //     'locations',
  //     'martialStatus',
  //     'educationLevel',
  //     'countryCode',
  //     'emergencyCountryCode',
  //     'nationality',
  //     'gender',
  //     'supervisor',
  //     'role',
  //     'cob',
  //   ];

  //   // console.log(data.role)

  //   if (!boolean_fields.includes(name) || !dropdown_fields.includes(name))
  //     setData((data) => {
  //       let newData = {
  //         ...cloneDeep(data),
  //         [name]: event.target.value,
  //       };
  //       validationHandler(newData);
  //       return newData;
  //     });

  //   if (boolean_fields.includes(name)) {
  //     setData((data) => {
  //       let newData = {
  //         ...cloneDeep(data),
  //         [name]: value.checked,
  //       };
  //       validationHandler(newData);
  //       return newData;
  //     });
  //     // setData({
  //     //   ...data,
  //     //   [name]: value.checked,
  //     // });
  //   }

  //   if (dropdown_fields.includes(name)) {
  //     setData((data) => {
  //       let newData = {
  //         ...cloneDeep(data),
  //         [name]: value.value,
  //       };
  //       validationHandler(newData);
  //       return newData;
  //     });
  //     // setData({
  //     //   ...data,
  //     //   [name]: value.value,
  //     // });
  //   }
  // };

  // const validationHandler = (formData = null) => {
  //   setValidForm(false);
  //   if (!formData) {
  //     formData = cloneDeep(data);
  //   }
  //   const shiftsArray = [
  //     formData.shifts.shiftA,
  //     formData.shifts.shiftB,
  //     formData.shifts.shiftC,
  //   ];

  //   const daysArray = [
  //     formData.days.sat,
  //     formData.days.sun,
  //     formData.days.mon,
  //     formData.days.tue,
  //     formData.days.wed,
  //     formData.days.thu,
  //     formData.days.fri,
  //   ];
  //   console.log(formData);

  //   const pane0Errors = [
  //     validation.firstName.pattern(formData.firstName),
  //     validation.lastName.pattern(formData.lastName),
  //     validation.username.pattern(formData.username),
  //     validation.email.pattern(formData.email),
  //     validation.phone.pattern(formData.phone),
  //     // validation.identificationNo.pattern(formData.identificationNo),
  //     // validation.dob.pattern(formData.dob),
  //   ];

  //   const pane1Errors = [
  //     validation.jobTitle.pattern(formData.jobTitle),
  //     validation.hourRate.pattern(formData.hourRate),
  //     validation.locations.pattern(formData.locations),
  //     // validation.whpw.pattern(formData.whpw),
  //     // validation.contractStart.pattern(formData.contractStart),
  //     // validation.contractEnd.pattern(formData.contractEnd),
  //     validation.days.pattern(daysArray),
  //     validation.shifts.pattern(shiftsArray),
  //   ];

  //   setErrorPane({
  //     ...errorPane,
  //     pane0: pane0Errors.includes(false),
  //     pane1: pane1Errors.includes(false),
  //   });

  //   let updatedValidationData = {
  //     ...validation,
  //     // whpd: {
  //     //   ...validation.whpd,
  //     //   error: validation.whpd.pattern(formData.whpd) ? false : true,
  //     //   skipped: true,
  //     // },
  //     // whpw: {
  //     //   ...validation.whpw,
  //     //   error: validation.whpw.pattern(formData.whpw) ? false : true,
  //     //   skipped: true,
  //     // },

  //     // contractStart: {
  //     //   ...validation.contractStart,
  //     //   error: validation.contractStart.pattern(formData.contractStart)
  //     //     ? false
  //     //     : true,
  //     //   skipped: true,
  //     // },
  //     // contractEnd: {
  //     //   ...validation.contractEnd,
  //     //   error: validation.contractEnd.pattern(formData.contractEnd) ? false : true,
  //     //   skipped: true,
  //     // },

  //     firstName: {
  //       ...validation.firstName,
  //       error: validation.firstName.pattern(formData.firstName) ? false : true,
  //       skipped: true,
  //     },
  //     lastName: {
  //       ...validation.lastName,
  //       error: validation.lastName.pattern(formData.lastName) ? false : true,
  //       skipped: true,
  //     },
  //     username: {
  //       ...validation.username,
  //       error: validation.username.pattern(formData.username) ? false : true,
  //       skipped: true,
  //     },
  //     email: {
  //       ...validation.email,
  //       error: validation.email.pattern(formData.email) ? false : true,
  //       skipped: true,
  //     },

  //     phone: {
  //       ...validation.phone,
  //       error: validation.phone.pattern(formData.phone) ? false : true,
  //       skipped: true,
  //     },
  //     locations: {
  //       ...validation.locations,
  //       error: validation.locations.pattern(formData.locations) ? false : true,
  //       skipped: true,
  //     },

  //     // dob: {
  //     //   ...validation.dob,
  //     //   error: validation.dob.pattern(formData.dob) ? false : true,
  //     //   skipped: true,
  //     // },

  //     jobTitle: {
  //       ...validation.jobTitle,
  //       error: validation.jobTitle.pattern(formData.jobTitle) ? false : true,
  //       skipped: true,
  //     },
  //     // identificationNo: {
  //     //   ...validation.identificationNo,
  //     //   error: validation.identificationNo.pattern(formData.identificationNo)
  //     //     ? false
  //     //     : true,
  //     //   skipped: true,
  //     // },

  //     shifts: {
  //       ...validation.shifts,
  //       error: validation.shifts.pattern(shiftsArray) ? false : true,
  //       skipped: true,
  //     },

  //     days: {
  //       ...validation.days,
  //       error: validation.days.pattern(daysArray) ? false : true,
  //       skipped: true,
  //     },

  //     hourRate: {
  //       ...validation.hourRate,
  //       error: validation.hourRate.pattern(formData.hourRate) ? false : true,
  //       skipped: true,
  //     },
  //   };

  //   setValidation(updatedValidationData);
  //   if (pane0Errors.includes(false) || pane1Errors.includes(false)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // useEffect(() => {
  //   const validationToArray = Object.entries(validation);
  //   const cond = validationToArray.some((el) => el[1].error && el[1].skipped);
  //   // console.log(validationToArray);

  //   if (initChange) {
  //     setValidForm(!cond);
  //     // setErrorPane({
  //     //   ...errorPane,
  //     //   pane0: cond,
  //     //   pane1: cond,
  //     // });
  //   }
  //   // console.log(data);
  // }, [validation, initChange]);
  // useEffect(() => {
  //   validationHandler();
  // }, [data]);

  const uploadFilessHandler = async (files = []) => {
    // console.log(files);
    let theFiles = files.filter((el) => el.isChanged);
    theFiles = theFiles.map((el) => el.file);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  const createNewUserHandler = async (data) => {
    // console.log(data);
    try {
      setButtonStatus(1);

      const contractCopyResponse =
        attachments.contractCopy && attachments.contractCopy.isChanged
          ? await uploadFile([attachments.contractCopy.file])
          : null;

      // const personalPictureResponse =
      //   attachments.personalPicture && attachments.personalPicture.isChanged
      //     ? await uploadFile([attachments.personalPicture.file])
      //     : null;

      const passportCopyResponse =
        attachments.passportCopy && attachments.passportCopy.isChanged
          ? await uploadFile([attachments.passportCopy.file])
          : null;
      const idFrontCopyResponse =
        attachments.idCopyFront && attachments.idCopyFront.isChanged
          ? await uploadFile([attachments.idCopyFront.file])
          : null;

      const visaCopyResponse =
        attachments.visaCopy && attachments.visaCopy.isChanged
          ? await uploadFile([attachments.visaCopy.file])
          : null;
      const covidCopyResponse =
        attachments.covidCopy && attachments.covidCopy.length
          ? await uploadFilessHandler(attachments.covidCopy)
          : null;

      const response = await createNewUser(
        data.firstName.trim() + " " + data.lastName.trim(),
        data.role,
        data.phone,
        data.email ? data.email : null,
        data.teams ? data.teams : null,
        data.firstName.trim(),
        data.lastName.trim(),
        data.role == 8 || data.role == 9 ? data?.supervisor || null : null,
        data.selectedLocation ? data.selectedLocation : null,
        organisationId,
        currentUserId,
        personalImgId ? personalImgId : null,
        // personalPictureResponse?.data[0].id
        //   ? personalPictureResponse?.data[0].id
        //   : null
      );

      // console.log(response);

      if (response.status == 200) {
        // console.log(response.data);
        setResponseData({
          id: response.data.user.id,
          name: response.data.user.firstName,
        });

        const roleUpdated = await updateUserRole(
          data.role,
          response.data.user.id
          // organisationId
        );

        if (roleUpdated.status == 200) {
          // const pendingUser = await updateUserStatusToPending(
          //   response.data.user.id
          // );

          // if (pendingUser.status == 200) {
            let resData = await createUserProfileHandler(
              response.data.user.id,

              contractCopyResponse?.status == 200
                ? contractCopyResponse.data[0].id
                : null,
                personalImgId ? personalImgId : null,
              // personalPictureResponse?.status == 200
              //   ? personalPictureResponse.data[0].id
              //   : null,
              passportCopyResponse?.status == 200
                ? passportCopyResponse.data[0].id
                : null,
              idFrontCopyResponse?.status == 200
                ? idFrontCopyResponse.data[0].id
                : null,

              visaCopyResponse?.status == 200
                ? visaCopyResponse.data[0].id
                : null,
              covidCopyResponse?.status == 200
                ? covidCopyResponse.data.map((el) => el.id)
                : [],
              data
            );
            if (resData == 200) {
              toast.success(t.people.title + " " + t.common.added_success);
              if (initData?.length == 0) {
                const fetchData = await dispatch(
                  fetchPeopleInitData({
                    role: [
                      "user",
                      "supervisor",
                      "view_only",
                      "admin",
                      "requester",
                      "limited_admin",
                      "super_admin",
                    ],
                    organisationId,
                    roleData,
                    allIds,
                  })
                );
              }

              setSortData([]);
              setFilterOptions({
                role: [],
                supervisors: [],
                jobTitle: [],
                teams: [],
                status: [],
                
                rate: {
                  from: null,
                  to: null,
                },

                
              });
              setSearchTitle("");
            }
          // }
        }
      }
    } catch (err) {
      if (
        err.message == "This email is already exists" ||
        err.message == "This phone number and email already exist" ||
        err.message == "This phone is already exists"
      ) {
        // hasError = true;
        toast.error(
          t.people.form_validation_errors[err.message] || err.message
        );
      } else {
        toast.error(err.message);
      }
      setButtonStatus(0);
    }
  };

  const changeTab = async (tab, errors, setFieldTouched, values) => {
    let errorFields = Object.keys(errors);
    let hasError = false;
    if (activePane > tab) {
      setActivePane(tab);
      return;
    }
    if (tab == 1) {
      if (errorFields && errorFields.length) {
        for (let i = 0; i < firstTabValidationFields.length; i++) {
          if (errorFields.includes(firstTabValidationFields[i])) {
            hasError = true;
            setFieldTouched(firstTabValidationFields[i], true);
          }
        }
      }
      if (!hasError) {
        let validateEmailAndPhone = await verifyPersonEmailAndPhone(
          values.userId || null,
          values.email ? values.email : null,
          values.phone,
          organisationId,
          values.role
        );
        if (validateEmailAndPhone && validateEmailAndPhone.status != 200) {
          hasError = true;
          toast.error(
            t.people.form_validation_errors[validateEmailAndPhone.error] ||
              validateEmailAndPhone.error
          );
        }
      }
    } else if (tab == 2) {
      if (errorFields && errorFields.length) {
        for (let i = 0; i < firstTabValidationFields.length; i++) {
          if (errorFields.includes(firstTabValidationFields[i])) {
            hasError = true;
            setFieldTouched(firstTabValidationFields[i], true);
          }
        }
      }

      if (!hasError) {
        let validateEmailAndPhone = await verifyPersonEmailAndPhone(
          values.userId || null,
          values.email ? values.email : null,
          values.phone,
          organisationId,
          values.role
        );
        if (validateEmailAndPhone && validateEmailAndPhone.status != 200) {
          hasError = true;
          toast.error(
            t.people.form_validation_errors[validateEmailAndPhone.error] ||
              validateEmailAndPhone.error
          );
        }
      }
      if (errorFields && errorFields.length) {
        for (let i = 0; i < firstTabValidationFields.length; i++) {
          if (errorFields.includes(firstTabValidationFields[i])) {
            hasError = true;
            setFieldTouched(firstTabValidationFields[i], true);
          }
        }

        for (let i = 0; i < secondTabValidationFields.length; i++) {
          if (errorFields.includes(secondTabValidationFields[i])) {
            hasError = true;
            setFieldTouched(secondTabValidationFields[i], true);
          }
        }
      }
    }
    if (!hasError) {
      setActivePane(tab);
    }
  };

  const createUserProfileHandler = async (
    id,
    contractCopy,
    personalPicture,
    passportCopy,
    idCopyFront,
    visaCopy,
    covidCopy,
    data
  ) => {
    const response = await createNewUserProfile(
      id,
      data.phone,
      String(data.address),
      String(data.emergencyName),
      data.emergencyPhone || null,
      String(data.educationLevel),
      String(data.fieldOfStudy),
      String(data.martialStatus),
      String(data.nationality),
      String(data.passportNo),
      String(data.identificationNo),
      data.dob ? format(new Date(data.dob), "yyyy-MM-dd") : null,
      String(data.cob),
      String(data.gender),
      Number(data.childrenNo),
      String(data.visaNo),
      data.visaExpireDate
        ? format(new Date(data.visaExpireDate), "yyyy-MM-dd")
        : null,
      data?.jobTitle ? Number(data?.jobTitle) : null,
      String(data.department),
      Number(data.hourRate),
      Number(data.whpd),
      Number(data.whpw),
      data.contractStart
        ? format(new Date(data.contractStart), "yyyy-MM-dd")
        : null,
      data.contractEnd
        ? format(new Date(data.contractEnd), "yyyy-MM-dd")
        : null,
      Number(data.registrationNo),
      String(data.badgeId),
      data.shifts,
      String(data.workPermitNo),
      data.days,
      contractCopy,
      idCopyFront,
      personalPicture,
      passportCopy,
      visaCopy,
      covidCopy
    );

    if (response.status == 200) {
      setButtonStatus(0);
      cancelHandler();
      toggleSuccessModal(true);
    }

    return response.status;
  };

  const cancelHandler = () => {
    resetForm();
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    setNewCancelModal(false);
    toggleAsideModalOpen(false);
    // toggleCancelModal(false);
    activateLoading(false);
    setInitChange(false);
  };

  return (
    <>
      <Modal
        size="tiny"
        open={toggled}
        onClose={() => {
          untoggle();
          cancelHandler();
        }}
        className={
          selectedLanguage == "ar"
            ? "assets-modal user-type-popup-rtl"
            : "assets-modal"
        }
      >
        {isLoadingActive ? (
          <Dimmer active inverted>
            <Loader inverted content={t.common.loading} />
          </Dimmer>
        ) : (
          ""
        )}
        <div className="header-container">
          <span className="assets-header">{t.people.add_person}</span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                untoggle();
                cancelHandler();
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
        <Modal.Content>
          <List style={{ gap: "20px" }}>
            {dropdownOptions.roles.map((el) => {
              return (
                <Segment
                  key={el.key}
                  id={el.value}
                  className="flexbox people-popup"
                  style={
                    data.role == el.value
                      ? {
                          background: "#E9F2FF",
                          borderColor: "#0C66E4",
                          cursor: "pointer",
                        }
                      : { cursor: "pointer" }
                  }
                  onClick={() => {
                    setData({
                      ...data,
                      role: el.value,
                    });
                  }}
                >
                  <span>{el.icon}</span>

                  <div
                    className="people-modal-text"
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  >
                    <div style={{ background: "transparent" }}>{el.text}</div>
                    <p>{el.description}</p>
                  </div>
                </Segment>
              );
            })}
          </List>
        </Modal.Content>

        <div
          className={`modal-button-container mt-4 ${
            selectedLanguage == "en" ? "mr-8" : "ml-10"
          }`}
        >
          <button
            onClick={() => {
              untoggle();
              cancelHandler();
            }}
            className="modal-form-action-button "
            style={{ color: "black" }}
          >
            {t.common.cancel}
          </button>

          <button
            disabled={!data.role}
            onClick={() => {
              activateLoading(true);
              setTimeout(() => {
                untoggle(false);
                toggleAsideModalOpen(true);
              }, 1000);
            }}
            className="modal-form-action-button light-blue"
          >
            {t.common.next}
          </button>
        </div>
      </Modal>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          isAsideModalOpen
            ? "aside-modal preview active procedure"
            : "aside-modal preview procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header detail-header">
            <div className="flexbox justify-between align-center">
              <div className="name">
                {t.people.personal_info_form.add_person}
              </div>
              <div className="flexbox align-center workorder-header-buttons">
                <span
                  className="procedure-header-button c-pointer"
                  style={{ position: "relative" }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() => {
                    initChange ? setNewCancelModal(true) : cancelHandler();
                  }}
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == "en"
                        ? "bottom center"
                        : "bottom center"
                    }
                    arrowAlign="center"
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          {/* Formik Component */}
          <div className="">
            <PeopleForm
              setData={setData}
              setPersonalImgId={setPersonalImgId}
              setFile={setFile}
              setImageSrc={setImageSrc}
              imageSrc={imageSrc}
              personalImgId={personalImgId}
              file={file}
              organisationId={organisationId}
              setDropdownOptions={setDropdownOptions}
              dropdownOptions={dropdownOptions}
              roleData={roleData}
              fetchJobTitleData={fetchJobTitleData}
              data={data}
              
              PersonValidationSchema={
                // data?.role == 8
                //   ? PersonTechnicianValidationSchema
                //   : data?.role == 6
                //   ? PersonRequesterValidationSchema
                // :
                PersonValidationSchema
              }
              PersonTechnicianValidationSchema={
                PersonTechnicianValidationSchema
              }
              PersonRequesterValidationSchema={PersonRequesterValidationSchema}
              PersonLimitedValidationSchema={PersonLimitedValidationSchema}
              handleSubmit={createNewUserHandler}
              activePane={activePane}
              changeTab={changeTab}
              fetchCountries={fetchCountries}
              // fetchTeamsData={fetchTeamsData}
              // fetchUsersData={fetchUsersData}
              fetchLocations={fetchLocations}
              cancelHandler={cancelHandler}
              // toggleCancelModal={toggleCancelModal}
              setNewCancelModal={setNewCancelModal}
              onFileChange={onFileChange}
              initChange={initChange}
              buttonStatus={buttonStatus}
              attachments={attachments}
              setAttachments={setAttachments}
              userProfileLoc = {allIds}
              onCertificateFileChange={onCertificateFileChange}
            />
          </div>
        </Resizable>
      </div>
      <div
        className={
          isAsideModalOpen
            ? "aside-modal-background active"
            : "aside-modal-background"
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

      {/* <Modal
      size="tiny"
      open={successModal}
      onClose={() => {
        toggleSuccessModal(false);
        setRefresh(!refresh);
      }}
    >
      <Modal.Content>
        <div style={{ textAlign: 'center' }}>
          <div className="checkmark-wrapper">
            <Icon name="checkmark" color="white" size="huge" />
          </div>
          <p className="status-modal-popup-message">
            <b>{responseData.name}</b> {t.common.added_success}
          </p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            toggleSuccessModal(false);
            setRefresh(!refresh);
          }}
        >
          {t.common.ok}
        </Button>
      </Modal.Actions>
    </Modal> */}

      {/* <Modal
      size="tiny"
      open={cancelModal && initChange}
      onClose={() => toggleCancelModal(true)}
    >
      <Modal.Content className="cancel-text">
        <p>{t.common.cancel_statement}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => toggleCancelModal(false)}>
          {t.common.keep}
        </Button>
        <Button negative onClick={() => cancelHandler()}>
          {t.common.yes_cancel}
        </Button>
      </Modal.Actions>
    </Modal> */}
      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: "24px" }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>
    </>
  );
}

export default Add;
