import React, { useState } from "react";
import WorkOrderSearchDropdown from "../../../components/WorkOrder/WorkOrderSearchDropdown";
import DateDropdown from "../../../components/common/DateDropdown";
import { selectTranslations } from "../../../config/i18n/slice";
import { useSelector } from "react-redux";
import CategoryDropdown from "../../../components/common/CategoryDropdown";
import { useEffect } from "react";
import calendar_icon from "../../../../Assets/Icons/calender-icon.svg";
import parent_icon from "../../../../Assets/Icons/parentAsset.png";
import child_icon from "../../../../Assets/Icons/childAsset.png";
import gchild_icon from "../../../../Assets/Icons/grandChild.png";
import location_icon from "../../../../Assets/Icons/loc_option_parent.png";
import sub_location_icon from "../../../../Assets/Icons/pin_drop.png";
import {
  getActiveUsersByRole,
  getAllAreaByLocationId,
  getAllAssets,
  getAllAssetsOption,
  getAllCategories,
  getAllCustomers,
  getAllLocations,
  getAllParentLocations,
  getAllTeams,
  getAllVendros,
  getUserDataDashBoard,
  getUsersByRole,
  IMAGES_URL,
  toggle,
} from "../../../config/functions";
import { LetteredAvatarImageUrl } from "../../../components/common/userProfilePlaceholder";
import _, { isEqual } from "lodash";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  ActionBtnIcon,
  CloseIcon,
  DashboardFilterIcon,
  DownloadIcon,
  RefreshIcon,
} from "../../../../Assets/Icons/svg";
import { DropdownItem, DropdownMenu } from "semantic-ui-react";
import PriorityDropdown from "../../../components/common/PriorityDropdown";
import DatePicker from "react-multi-date-picker";
import { toast } from "react-toastify";
import ToolTip from "react-power-tooltip";
import { nanoid } from "nanoid";
import LocationSearchDropdown from "../../../components/WorkOrder/LocationSearchDropdown";

const MaintenanceFilter = ({
  setFilterOptions,
  currentTime,
  setCurrentTime,
  filterOptions,
  setFiltersTrigger,
  filtersTrigger,
  setOtherFilter,
  otherFilter,
  createSelectedDates,
  setCreateSelectedDates,
  setCustomSelectedDates,
  customSelectedDates,
  hasNoFilterValues,
  warrentySelectedDates,
  setWarrentySelectedDates,
  setUpdatedSelectedDates,
  updatedDates,
  allIds,
  role,
}) => {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });


  const [showTooltip, setShowTooltip] = useState(false);
  const [showRefreshTooltip, setShowRefreshTooltip] = useState(false);

  useEffect(() => {
    // Function to format the date and time
    const formatDate = (date) => {
      const options = {
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      const formatter = new Intl.DateTimeFormat("en-US", options);
      return `${formatter.format(date)}`;
    };

    // Function to set the current time
    const setTime = () => {
      const now = new Date();
      setCurrentTime(formatDate(now));
    };

    // Set the initial time after 3 seconds
    const timeoutId = setTimeout(() => {
      setTime();

      // Optionally update the time periodically (e.g., every minute)
      const intervalId = setInterval(() => {
        setTime();
      }, 60000); // 60000 milliseconds = 1 minute

      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }, 2000); // 3000 milliseconds = 3 seconds

    // Clean up the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, []);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });

  const [filterSearch, setFilterSearch] = useState({
    location: "",
   
    people: "",

    teams: "",
    categories: "",
    asset: "",

  });



  const handleOptionChange = async (name, value) => {
    let otherFilterValue = otherFilter;
    otherFilterValue[name] = value;
    // const filterData = await getUserDataDashBoard(
    //   user?.id,
    //   otherFilterValue,
    //   true
    // );
  };

  useEffect(() => {
    (async function() {


      let the_options_location = [];

      let the_options_teams = [];
      let the_options_user = [];
      let the_options_people = [];
      let the_options_category = [];
      let the_created_user = [];
      let the_options_asset = [];
 

      const fetchDataAsset = await getAllAssetsOption(
        organisationId,
        "all_asset",
        1,
        9999,
        !(role === "super_admin") ? allIds : [],
        [],
        "",
        "",
        [],
        ["name:asc"]
      );

      let assetOptions = [];
      let parentToChildMap = new Map();
      let childToGrandChildMap = new Map();
      let hierarchicalOptions = [];
      if (fetchDataAsset?.response?.length > 0) {
        fetchDataAsset.response.forEach((el) => {
          const isDisposed = el?.attributes?.status === "dispose";

          // Access rule: Non-admin users can't access certain assets
          const isActive =
            !isDisposed &&
            (!(role === "super_admin")
              ? allIds?.includes(el?.attributes?.location?.data?.id)
              : true);

          // Filter out disposed or inactive assets with no descendants
          if (
            isDisposed ||
            (!isActive && !el?.attributes?.storedAssets?.length)
          ) {
            return;
          }

         
        
          // Create parent asset option
          const parentAsset = {
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: parent_icon }, // Parent asset icon
            className: "main-location-dropdown-option",
            disabled: !isActive, // Disable only for non-admin roles
          };

          let hasAccessibleChildren = false;
          let hasAccessibleGrandchildren = false;

          parentToChildMap.set(el.id, []);
          assetOptions.push(parentAsset);

          // Collect child assets
          if (el?.attributes?.storedAssets?.length > 0) {
            el.attributes.storedAssets.forEach((childEl) => {
              if (childEl?.childAsset?.data) {
                const childAsset = childEl.childAsset.data;
                const childIsDisposed =
                  childAsset?.attributes?.status === "dispose";

                const childIsActive =
                  !childIsDisposed &&
                  (!(role === "super_admin")
                    ? allIds?.includes(
                        childAsset?.attributes?.location?.data?.id
                      )
                    : true);

                // Filter out disposed or inactive child assets with no grandchildren
                if (
                  childIsDisposed ||
                  (!childIsActive && !childEl?.grandChildAsset?.data?.length)
                ) {
                  return;
                }

                // Track whether there are any accessible children
                if (childIsActive) hasAccessibleChildren = true;

               
                // manufacturerSet.add(childAsset?.attributes?.manufacturer); 
                const childOption = {
                  key: childAsset.id,
                  value: childAsset.id,
                  text: `${childAsset.attributes.name}`,
                  image: { src: child_icon }, // Child asset icon
                  className: "sub-location-dropdown-option",
                  parentId: el.id,
                  disabled: !childIsActive, // Disable child based on role
                };

                parentToChildMap.get(el.id).push(childOption);
                childToGrandChildMap.set(childAsset.id, []);

                // Collect grandchild assets
                if (childEl?.grandChildAsset?.data?.length > 0) {
                  childEl.grandChildAsset.data.forEach((grandChildEl) => {
                    const grandChildIsDisposed =
                      grandChildEl?.attributes?.status === "dispose";

                    const grandChildIsActive =
                      !grandChildIsDisposed &&
                      (!(role === "super_admin")
                        ? allIds?.includes(
                            grandChildEl?.attributes?.location?.data?.id
                          )
                        : true);

                    // Filter out disposed or inactive grandchild assets
                    if (grandChildIsDisposed || !grandChildIsActive) {
                      return;
                    }

                    // Track whether there are any accessible grandchildren
                    if (grandChildIsActive) hasAccessibleGrandchildren = true;
                    // manufacturerSet.add(grandChildEl?.attributes?.manufacturer); 
                    const grandChildOption = {
                      key: grandChildEl.id,
                      value: grandChildEl.id,
                      text: `${grandChildEl.attributes.name}`,
                      image: { src: gchild_icon }, // Grandchild asset icon
                      className: "grandchild-asset-dropdown-option",
                      parentId: el.id,
                      childParentId: childAsset.id,
                      disabled: !grandChildIsActive, // Disable based on role
                    };

                    childToGrandChildMap
                      .get(childAsset.id)
                      .push(grandChildOption);
                  });
                }
              }
            });
          }

          // Ensure the parent asset is displayed if it has no accessible children or grandchildren
          if (
            !hasAccessibleChildren &&
            !hasAccessibleGrandchildren &&
            isActive
          ) {
            // Clear the child options and only show the parent
            parentToChildMap.set(el.id, []);
          }
        });

        // Sort child and grandchild options alphabetically within each parent and child context
        parentToChildMap.forEach((children) => {
          children.sort((a, b) => a.text.localeCompare(b.text));
        });

        childToGrandChildMap.forEach((grandChildren) => {
          grandChildren.sort((a, b) => a.text.localeCompare(b.text));
        });

        // Combine all the options into a hierarchical structure
        assetOptions.forEach((parentOption) => {
          hierarchicalOptions.push(parentOption);

          const children = parentToChildMap.get(parentOption.key) || [];
          children.forEach((childOption) => {
            hierarchicalOptions.push(childOption);

            const grandChildren =
              childToGrandChildMap.get(childOption.key) || [];
            grandChildren.forEach((grandChildOption) => {
              hierarchicalOptions.push(grandChildOption);
            });
          });
        });
      }

      const fetchDataCategory = await getAllCategories(organisationId);
      if (fetchDataCategory.response) {
        fetchDataCategory.response.forEach((el) => {
          the_options_category.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: `${el.attributes.name}`,
          });
        });
      }
      const fetchTeamsData = await getAllTeams(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        !(role === "super_admin") ? allIds : []
      );

      if (fetchTeamsData?.response) {
        fetchTeamsData.response.map((el) => {
          the_options_teams.push({
            key: el.id,
            value: `${el.id}`,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: LetteredAvatarImageUrl(`${el.attributes.name}`),
              style: { width: "16px", height: "16px" },
            },
          });
        });
      }
      const fetchDataLocation = await getAllParentLocations(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        ["name:asc"]
      );
      // getAllParentLocations,
      let the_options = [];
      let parentOptions = [];
      let subOptions = [];
      if (fetchDataLocation?.response?.length > 0) {
        fetchDataLocation.response.forEach((el) => {
          if (!(role === "super_admin")) {
            // Check if the element's ID is included in allIds
            if (allIds?.includes(el.id)) {
              // Add parent option normally
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: false, // Parent option enabled
              });

              // Collect sub-options
              if (el?.attributes?.subLocations?.data?.length > 0) {
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
              }
            } else {
              // If the element's ID is not in allIds, check sub-locations
              const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
                (subEl) => allIds?.includes(subEl.id)
              );

              if (hasMatchingSubLocation) {
                // Add parent option as disabled
                parentOptions.push({
                  key: el.id,
                  value: el.id,
                  text: el.attributes.name,
                  image: { src: location_icon },
                  className: "main-location-dropdown-option",
                  disabled: true, // Disable the parent option
                });

                // Collect matching sub-options
                el?.attributes?.subLocations?.data?.length > 0 &&
                  el.attributes.subLocations.data.forEach((subEl) => {
                    if (allIds?.includes(subEl.id)) {
                      subOptions.push({
                        key: subEl.id,
                        value: subEl.id,
                        text: subEl.attributes.name,
                        image: { src: sub_location_icon },
                        className: "sub-location-dropdown-option",
                        parentId: el.id, // Keep track of the parent
                      });
                    }
                  });
              }
            }
          } else {
            // Add parent option
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              className: "main-location-dropdown-option",
              image: { src: location_icon },
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                subOptions.push({
                  key: subEl.id,
                  value: subEl.id,
                  text: subEl.attributes.name,
                  image: { src: sub_location_icon },
                  className: "sub-location-dropdown-option",
                  parentId: el.id, // Keep track of the parent
                });
              });
            }
          }
        });

        // Sort sub-options alphabetically
        subOptions.sort((a, b) => a.text.localeCompare(b.text));

        // Merge parent options with sorted sub-options
        parentOptions.forEach((parentOption) => {
          the_options.push(parentOption);
          // Add sorted sub-options belonging to this parent
          subOptions.forEach((subOption) => {
            if (subOption.parentId === parentOption.key) {
              the_options.push(subOption);
            }
          });
        });

        // Update the options state
        // setOptions((prev) => ({
        //   ...prev,
        //   locations: the_options,
        // }));

        // return the_options;
      }

      const fetchDataPeople = await getUsersByRole(
        [
          "user",
          "view_only",
          "admin",
          "requester",
          "super_admin",
          "limited_admin",
        ],
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        [],
        [],
        {},
        [],
        !(role === "super_admin") ? allIds : []
      );

      if (fetchDataPeople.response) {
        fetchDataPeople.response.map((el) => {
          the_options_user.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.firstName + " " + el.lastName,
            image: {
              avatar: true,
              src: el?.profileImage?.url
                ? IMAGES_URL + el?.profileImage?.url
                : LetteredAvatarImageUrl(el.firstName + " " + el.lastName),
              style: { width: "20px", height: "20px" },
            },
          });
        });
      }
      setFiltersTrigger({
        ...filtersTrigger,
        
        categories: {
          ...filtersTrigger.categories,
          initOptions: the_options_category,
          options: the_options_category,
        },
       
        location: {
          ...filtersTrigger.location,
          initOptions: the_options,
          options: the_options,
        },
        asset: {
          ...filtersTrigger.asset,
          initOptions: hierarchicalOptions,
          options: hierarchicalOptions,
        },
        // createdByUser: {
        //   ...filtersTrigger.createdByUser,
        //   initOptions: the_options_people,
        //   options: the_options_people,
        // },
        teams: {
          ...filtersTrigger.teams,
          initOptions: the_options_teams,
          options: the_options_teams,
        },
        people: {
          ...filtersTrigger.people,
          initOptions: the_options_user,
          options: the_options_user,
        },
      });
    })();
  }, []);


  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.location), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      location: {
        initOptions: filtersTrigger.location.initOptions,
        options:
          filterSearch.location.length > 0
            ? _.filter(filtersTrigger.location.initOptions, isMatch)
            : filtersTrigger.location.initOptions,
      },
    });
  }, [filterSearch.location]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.asset), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      asset: {
        initOptions: filtersTrigger.asset.initOptions,
        options:
          filterSearch.asset.length > 0
            ? _.filter(filtersTrigger.asset.initOptions, isMatch)
            : filtersTrigger.asset.initOptions,
      },
    });
  }, [filterSearch.asset]);


  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.people), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      people: {
        initOptions: filtersTrigger.people.initOptions,
        options:
          filterSearch.people.length > 0
            ? _.filter(filtersTrigger.people.initOptions, isMatch)
            : filtersTrigger.people.initOptions,
      },
    });
  }, [filterSearch.people]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.categories), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      categories: {
        initOptions: filtersTrigger.categories.initOptions,
        options:
          filterSearch.categories.length > 0
            ? _.filter(filtersTrigger.categories.initOptions, isMatch)
            : filtersTrigger.categories.initOptions,
      },
    });
  }, [filterSearch.categories]);
  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.teams), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      teams: {
        initOptions: filtersTrigger.teams.initOptions,
        options:
          filterSearch.teams.length > 0
            ? _.filter(filtersTrigger.teams.initOptions, isMatch)
            : filtersTrigger.teams.initOptions,
      },
    });
  }, [filterSearch.teams]);

  const handleSearchLocationChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      location: e.target.value,
    });
  };

  const handleSearchTeamsChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      teams: e.target.value,
    });
  };
  const handleSearchAssetChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      asset: e.target.value,
    });
  };
  const handleSearchCategoryChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      categories: e.target.value,
    });
  };
  const handleSearchAssigneesChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      people: e.target.value,
    });
  };

  return (
    <>
      <div className="dashboard-filters d-flex justify-content-between align-items-baseline ">
        <div style={{ gap: "20px", flexWrap: "wrap" }} className="d-flex">
         
          {otherFilter.location ? (
            <LocationSearchDropdown
              title={t.workOrders.table.location}
              options={filtersTrigger.location.options}
              selectedValues={filterOptions.location}
              searchValue={filterSearch.location}
              handleSearchValue={handleSearchLocationChange}
              onClick={(value) => {
                let finalValue = [value.value];
                if (!value?.parentId) {
                  let subData = filtersTrigger.location.initOptions.filter(
                    (el) => el?.parentId === value.value
                  );
  
                  let subDataValues = subData?.map((item) => item?.value);
                  let subValues = subData?.map((item) => item?.value);
  
                  subDataValues.push(value.value);
                  let filterOptionsValues = filterOptions.location;
  
                  let filteredSubDataValues = subDataValues.filter((value) =>
                    filterOptionsValues.includes(value)
                  );
  
                  let valuesNotInFilterOptions = subValues.filter(
                    (value) => !filterOptionsValues.includes(value)
                  );
                  let valuesInFilterOptions = subValues.filter((value) =>
                    filterOptionsValues.includes(value)
                  );
  
                  if (filteredSubDataValues?.length > 0) {
                    if (
                      subValues.every((value) =>
                        filterOptionsValues.includes(value)
                      ) &&
                      !filterOptionsValues.includes(value.value)
                    ) {
                      finalValue = [value.value];
                    } else if (
                      valuesNotInFilterOptions?.length > 0 &&
                      filterOptionsValues?.includes(value.value)
                    ) {
                      finalValue = [...valuesInFilterOptions, value.value];
                    } else if (valuesNotInFilterOptions?.length > 0) {
                      finalValue = [...valuesNotInFilterOptions, value.value];
                    } else {
                      finalValue = [...filteredSubDataValues];
                    }
                  } else {
                    finalValue = [...subDataValues];
                  }
                }
  
                let newArray = toggle(filterOptions.location, finalValue);
                setFilterOptions({
                  ...filterOptions,
                  location: newArray,
                });
  
                setFilterSearch({
                  ...filterSearch,
                  location: "",
                });
              }}
            />
          ) : null}

{otherFilter?.asset ? (
            <LocationSearchDropdown
              title={t.assets.asset_name_data}
              options={filtersTrigger.asset.options}
              selectedValues={filterOptions.asset}
              searchValue={filterSearch.asset}
              handleSearchValue={handleSearchAssetChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.asset, value.value);

                setFilterOptions({
                  ...filterOptions,
                  asset: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  asset: "",
                });
              }}
            />
          ) : null}
          {otherFilter?.category ? (
            <WorkOrderSearchDropdown
              icon={false}
              image={false}
              title={t.dashboard.asset_card.asset_category_label}
              options={filtersTrigger.categories.options}
              selectedValues={filterOptions.categories}
              searchValue={filterSearch.categories}
              handleSearchValue={handleSearchCategoryChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.categories, value.value);

                setFilterOptions({
                  ...filterOptions,
                  categories: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  categories: "",
                });
              }}
            />
          ) : null}





          {otherFilter.people ? (
            <WorkOrderSearchDropdown
              title={t.workRequests.form.people}
              options={filtersTrigger.people.options}
              selectedValues={filterOptions.people}
              searchValue={filterSearch.people}
              handleSearchValue={handleSearchAssigneesChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.people, value.value);

                setFilterOptions({
                  ...filterOptions,
                  people: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  people: "",
                });
              }}
            />
          ) : null}

          {otherFilter?.teams ? (
            <WorkOrderSearchDropdown
              icon={false}
              image={false}
              title={t.teams.add}
              options={filtersTrigger.teams.options}
              selectedValues={filterOptions.teams}
              searchValue={filterSearch.teams}
              handleSearchValue={handleSearchTeamsChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.teams, value.value);

                setFilterOptions({
                  ...filterOptions,
                  teams: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  teams: "",
                });
              }}
            />
          ) : null}


          <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 d-flex">
            <UncontrolledDropdown className="" direction="down">
              <DropdownToggle
                style={{ position: "relative", cursor: "pointer" }}
                onMouseOver={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                color="primary"
                className="option-toggle"
              >
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position={selectedLanguage == "ar" ? "top right" : "top left"}
                  show={showTooltip}
                  textBoxWidth={selectedLanguage == "ar" ? "85px" : "80px"}
                  arrowAlign={selectedLanguage == "ar" ? "center" : "start"}
                  fontSize="12px"
                  fontWeight="400"
                  fontFamily={
                    selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                  }
                  padding="4px 4px 4px 8px"
                  borderRadius="5px"
                  static
                  moveRight="10px"
                  moveUp={selectedLanguage == "ar" ? "3px" : "4px"}
                >
                  <span className="work-order-tooltip">
                    {t.dashboard.cards.other_filters}
                  </span>
                </ToolTip>
                <DashboardFilterIcon />{" "}
              </DropdownToggle>
              <DropdownMenu className={selectedLanguage == "ar" ? "rtl" : ""}>
                {/* {role !== 'view_only' ? (
  <>
    <DropdownItem>
      <ShareIcon /> {t.common.share}
    </DropdownItem>
    <DropdownItem className="divider" />
  </>
) : null} */}
                <DropdownItem className="divider" />
                <DropdownItem
                  className={otherFilter?.location ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.location === false) {
                      setOtherFilter({
                        ...otherFilter,
                        location: true,
                      });

                      handleOptionChange("location", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        location: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("location", false);
                    }
                  }}
                >
                  {t.workOrders.table.location}
                </DropdownItem>
                <DropdownItem className="divider" />
                <DropdownItem
                  className={otherFilter?.asset ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.asset === false) {
                      setOtherFilter({
                        ...otherFilter,
                        asset: true,
                      });

                      handleOptionChange("asset", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        asset: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("asset", false);
                    }
                  }}
                >
                  {t.assets.asset_name_data}
                </DropdownItem>
                <DropdownItem className="divider" />
                <DropdownItem
                  className={otherFilter?.category ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.category === false) {
                      setOtherFilter({
                        ...otherFilter,
                        category: true,
                      });

                      handleOptionChange("category", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        category: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("category", false);
                    }
                  }}
                >
                  {t.dashboard.asset_card.asset_category_label}
                </DropdownItem>
                <DropdownItem className="divider" />
            
                
                <DropdownItem
                  className={otherFilter?.people ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.people === false) {
                      setOtherFilter({
                        ...otherFilter,
                        people: true,
                      });

                      handleOptionChange("people", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        people: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("people", false);
                    }
                  }}
                >
                  {t.workRequests.form.people}
                </DropdownItem>
                <DropdownItem className="divider" />
                <DropdownItem
                  className={otherFilter?.teams ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.teams === false) {
                      setOtherFilter({
                        ...otherFilter,
                        teams: true,
                      });

                      handleOptionChange("teams", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        teams: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("teams", false);
                    }
                  }}
                >
                  {t.workOrders.form.teams}
                </DropdownItem>
                {/* <DropdownItem className="divider" />
    <DropdownItem onClick={() => generatePDF()}>
      <PdfIcon /> {t.common.download_pdf}
    </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          {!hasNoFilterValues()  ? (
            <div className="form-button-container mt-3">
              <button
                // content={t.procedures.form.submit.add}
                // primary
                onClick={() => {
                  if (
                    !hasNoFilterValues()
                  ) {
                    setFilterOptions({
                        people: [],
                        teams: [],
                        location: [],
                        categories: [],
                        asset: [],
                    });
                  
                  }
                }}
                // loading={buttonStatus == 1}
                style={{ marginTop: "-10px" }}
                className="form-action-button dashboard"
              >
                {t.dashboard.cards.clear}
              </button>
            </div>
          ) : null}
        </div>

        <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 d-flex">
          <div
            style={
              selectedLanguage == "en"
                ? { minWidth: "180px" }
                : { minWidth: "188px" }
            }
            className="mt-3"
          >
            {otherFilter?.refresh
              ? `${t.dashboard.cards.refreshed} ${otherFilter?.refresh}`
              : `${t.dashboard.cards.refreshed} ${currentTime}`}
          </div>
          <p
            onMouseOver={() => setShowRefreshTooltip(true)}
            onMouseLeave={() => setShowRefreshTooltip(false)}
            style={{
              position: "relative",
              cursor: "pointer",
            }}
            onClick={() => {
              handleOptionChange("refresh", currentTime);
              setFilterOptions({
                ...filterOptions,
              });
            }}
            className="dashboard-refresh-btn c-pointer ml-2 mr-2"
          >
            <ToolTip
              color="#fff"
              backgroundColor="#172B4D"
              position={selectedLanguage == "ar" ? "top right" : "top right"}
              show={showRefreshTooltip}
              textBoxWidth={selectedLanguage == "ar" ? "53px" : "60px"}
              arrowAlign={selectedLanguage == "ar" ? "center" : "start"}
              fontSize="12px"
              fontWeight="400"
              fontFamily={
                selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
              }
              padding="4px 4px 4px 8px"
              borderRadius="5px"
              static
              moveRight="12px"
              moveUp={selectedLanguage == "ar" ? "3px" : "4px"}
              // moveDown="10px"
            >
              <span
                style={{ padding: "0px 10px" }}
                className="work-order-tooltip"
              >
                {t.dashboard.cards.refresh}
              </span>
            </ToolTip>
            <RefreshIcon />
          </p>
        </div>
      </div>
    </>
  );
};

export default MaintenanceFilter;
