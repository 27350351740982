import React, { useState, useEffect, createRef } from "react";
import {
  List,
  TextArea,
  Header,
  Modal,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  Popup,
  Dimmer,
  Loader,
  Checkbox,
  Image,
} from "semantic-ui-react";
import * as auth from "../../modules/Auth/_redux/authRedux";
import location_icon from "../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import calendar_icon from "../../../Assets/Icons/calender-icon.svg";
// import { useCancelModal } from '../../../hooks/Messages/useCancelModal';

import {
  createNewVendor,
  currencies,
  country_codes,
  uploadFile,
  validateEmail,
  getAllLocations,
  handleKeyDown,
  getUserData,
  getAllParentLocations,
} from "../../config/functions";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import { Country, State, City } from "country-state-city";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectTranslations } from "../../config/i18n/slice";
import { scrollToError } from "../../../utilFunctions/scrollToError";
import { useDropzone } from "react-dropzone";
import {
  AddIcon,
  AddIconWithCircle,
  AddImage,
  BillingInfoIcon,
  CloseIcon,
  ContactInfoIcon,
  DeleteIcon,
  EditWhiteIcon,
  ImgPhotos,
  InfoIcon,
  InfoIconBlue,
  Mp4File,
  PngPhotos,
  TextSnippet,
} from "../../../Assets/Icons/svg";
import useCancelModal from "../../../hooks/Messages/useCancelModal";
import Asterisk from "../../components/common/Asterisk";
import DatePicker from "react-multi-date-picker";
import { format } from "date-fns";
import ToolTip from "react-power-tooltip";
import { fetchVendorInitData } from "../../../redux/reducers/vendorReducer";
import { Resizable } from "re-resizable";
import useNewCancelModal from "../../../hooks/Messages/useNewCncelModal";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import AddLocation from "../Locations/add";
import ImageCrop from "../MyCompanyProfile/ImageCrop";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const FileUpload = ({
  onFileChange,
  name = "",
  attachments = {},
  setAttachments = () => {},
  setImageSrc = () => {},
  setFile = () => {},
  setPersonalImgId = () => {},
  // setPersonalImgId
}) => {
  const fileTypes = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "image/webp",
      "image/heic",
    ],
    documents: [
      "text/plain",
      "application/pdf",
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    video: ["video/mp4", "video/quicktime", "video/webm", "video/hevc"],
  };

  const { getRootProps, getInputProps } = useDropzone({
    // accept: Object.fromEntries(fileTypes.images.map((type) => [type, []])),
    onDrop: (acceptedFiles) => {
      const allowedFileTypes = [...fileTypes.images];

      // Validate file types
      const invalidFiles = acceptedFiles.filter(
        (file) => !allowedFileTypes.includes(file.type)
      );

      // Show toast if there are invalid files
      if (invalidFiles?.length > 0) {
        toast.error(t.common.invalid_msg);
        return;
      }

      let totalSize = organisationImage?.attachmentsSize
        ? organisationImage?.attachmentsSize
        : 200;
      const validFiles = acceptedFiles.filter((file) => {
        const isImage = fileTypes.images.includes(file.type);
        const isDocument = fileTypes.documents.includes(file.type);

        const isVideo = fileTypes.video.includes(file.type);

        const validSize =
          (isVideo || isImage || isDocument) &&
          file.size < totalSize * 1024 * 1024;

        if (!validSize) {
          toast.error(
            `${t.common.file_upload_valid} ${totalSize} ${t.common.file_upload_second}`
          );
          return false;
        }
        return true;
      });

      onFileChange(validFiles, name);
    },
  });
  const t = useSelector(selectTranslations);
  const organisationImage = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation;
    } else {
      return null;
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  //TO DO : Customize and Style this Drag and Drop to Upload box as you want🧑‍💻😊
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === "image" ? (
        attachments?.image?.name ? (
          <div style={{ position: "relative" }}>
            
          <Image
            avatar
            title={attachments?.image?.name}
            src={`${attachments?.image?.url}`}
            style={{ width: "80px", height: "80px" }}
          ></Image>
          <span
            className={`people-circle-image ${
              selectedLanguage == "en" ? "" : "rtl-people"
            }`}
          >
            <span
              className={`edit-container ${
                selectedLanguage == "en" ? "" : "rtl-edit"
              }`}
            >
              <EditWhiteIcon /> {t.common.edit}
            </span>
          </span>

          <span
            className="ml-3 mr-3 c-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setPersonalImgId(null);
              setFile("");
              setImageSrc("");
              setAttachments({
                ...attachments,
                image: null,
              });
            }}
          >
            <DeleteIcon />
          </span>
        </div>
        ) : (
          <div className="image-dropzone  text-center mb-5">
            <div>
              <p className="p1">
                <div className="d-flex align-items-start justify-content-center ">
                  <div>
                    {" "}
                    <ImgPhotos />
                  </div>
                  <div
                    className={`mr-2 ml-2 ${
                      selectedLanguage == "en" ? "text-left" : "text-right"
                    }`}
                  >
                    {" "}
                    <div className="" style={{ color: "#0C66E4" }}>
                      {" "}
                      {t.common.upload_img}
                    </div>{" "}
                    <p
                      className="mb-0"
                      style={{ color: "#788CA5", fontSize: "12px" }}
                    >
                      {" "}
                      {t.common.valid_files_img}
                    </p>{" "}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )
      ) : attachments?.warrantyFile?.name ? (
        <div className="warrenty-file-dropzone text-center">
          <label>
            {attachments?.warrantyFile?.name}
            <span
              className="ml-3 mr-3 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setAttachments({
                  ...attachments,
                  warrantyFile: null,
                });
              }}
            >
              <DeleteIcon />
            </span>
          </label>
        </div>
      ) : (
        <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
          <div className="d-flex ml-4 mr-4">
            <TextSnippet />
            <div className="pl-4 pr-4">
              <p className="p1 m-0">Warranty file</p>
              <p className="p2 m-0">Attach a file to this asset</p>
            </div>
          </div>
          <div>
            <AddIconWithCircle />
          </div>
        </div>
      )}
    </div>
  );
};
function Add(props) {
  const {
    toggled,
    untoggle,
    refresh,
    setRefresh,
    setFilterOptions,
    setSearchTitle,
    role,
    allIds,
    setAllIds,
    setToggleAddModal,
  } = props;
  const t = useSelector(selectTranslations);
  const { userPermissions } = usePermissionsSimplified();
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const dispatch = useDispatch();
  const { initData } = useSelector((state) => ({
    initData: state.vendor.vendorInitData,
  }));
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [personalImgId, setPersonalImgId] = useState();
  const [imageSrc, setImageSrc] = useState("");
  const [file, setFile] = useState(""); //
  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });
  const [phone, setPhone] = useState(false);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const [initChange, setInitChange] = useState(false);
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const [activePane, setActivePane] = useState(0);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [responseData, setResponseData] = useState({});
  const [showTeamsTooltip, setTeamsTooltip] = useState(false);
  const [selectedPhoneCountry, setSelectedPhoneCountry] = useState("");
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [logo, setLogo] = useState(null);

  const logoRef = createRef();
  const [attachments, setAttachments] = useState({
    image: null,
  });
  const [options, setOptions] = useState({
    locations: [],
    Industry: [
      {
        key: "industry1",
        value: "Commercial Real Estate Companies",
        text: t.customers.industry.commercial,
      },
      {
        key: "industry2",
        value: "Corporate Offices",
        text: t.customers.industry.corporate,
      },
      {
        key: "industry3",
        value: "Manufacturing Companies",
        text: t.customers.industry.manufacturing,
      },
      {
        key: "industry4",
        value: "Healthcare Organizations",
        text: t.customers.industry.healthcare,
      },
      {
        key: "industry5",
        value: "Educational Institutions",
        text: t.customers.industry.educational,
      },
      {
        key: "industry6",
        value: "Government and Public Sector Organizations",
        text: t.customers.industry.government,
      },
      {
        key: "industry7",
        value: "Hospitality and Entertainment Companies",
        text: t.customers.industry.hospitality,
      },
      {
        key: "industry8",
        value: "Data Centers",
        text: t.customers.industry.data_centers,
      },
      {
        key: "industry9",
        value: "Retail Companies",
        text: t.customers.industry.retails,
      },
      {
        key: "industry10",
        value: "Sports and Recreation Facilities",
        text: t.customers.industry.sports,
      },
    ],
    preferences: [
      {
        key: "1",
        value: "English",
        text: "English",
      },
      {
        key: "2",
        value: "العربية",
        text: "العربية",
      },
    ],

    Mode: [
      {
        key: "1",
        value: "cash",
        text: t.vendors.mode.cash,
      },
      {
        key: "2",
        value: "credit card",
        text: t.vendors.mode.credit_card,
      },
      {
        key: "3",
        value: "bank transfer",
        text: t.vendors.mode.bank,
      },
      {
        key: "4",
        value: "procurement card",
        text: t.vendors.mode.procurement_card,
      },
      {
        key: "5",
        value: "check",
        text: t.vendors.mode.check,
      },
      {
        key: "6",
        value: "cliq",
        text: t.vendors.mode.cliq,
      },
    ],
    accountType: [
      {
        key: "0",
        value: "salaries account",
        text: t.vendors.account.salary,
      },
      {
        key: "1",
        value: "basic bank account",
        text: t.vendors.account.basic,
      },
      {
        key: "2",
        value: "time deposit account",
        text: t.vendors.account.time_deposit,
      },
      {
        key: "3",
        value: "current account",
        text: t.vendors.account.current,
      },
      {
        key: "4",
        value: "time deposit with prizes",
        text: t.vendors.account.time_deposit_price,
      },
      {
        key: "5",
        value: "POS current account",
        text: t.vendors.account.pos,
      },
    ],
  });

  const fetchLocationsOptions = async (updatedAllIds = []) => {
    setOptions({
      ...options,
      locations: [],
    });

    let finalIds = [...allIds];

    if (updatedAllIds?.length > 0) {
      finalIds = [...updatedAllIds];
    }
    const fetchData = await getAllParentLocations(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      ["name:asc"]
    );
    let the_options = [];
    let parentOptions = [];
    let subOptions = [];
    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        if (!(role === "super_admin")) {
          // Check if the element's ID is included in allIds
          if (finalIds?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
              className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (finalIds?.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
              (subEl) => finalIds?.includes(subEl.id)
            );

            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });

              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (finalIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
            }
          }
        } else {
          // Add parent option
          parentOptions.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: location_icon },
            className: "main-location-dropdown-option",
          });

          // Collect sub-options
          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });

      // Sort sub-options alphabetically
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      // Merge parent options with sorted sub-options
      parentOptions.forEach((parentOption) => {
        the_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            the_options.push(subOption);
          }
        });
      });

      // Update the options state
      setOptions((prev) => ({
        ...prev,
        locations: the_options,
      }));

      return the_options;
    }
  };

  const [cancelModal, toggleCancelModal] = useState(false);
  const [successModal, toggleSuccessModal] = useState(false);
  const [customerData, setCustomerData] = useState({
    startDate: "",
    preferences: "English",
    endDate: "",
    credit_days: "",
    pre_process_days: "",
    name: "",
    address: "",
    location: "",
    countryCode: "962",
    contact_name: "",
    contact_phone: "",
    contact_email: "",
    isSame: false,
    phone: "",
    website: "",
    email: "",
    serviceType: "",
    description: "",
    countries_options: [],
    country: "",
    cities_options: [],
    city: "",
    billing_name: "",
    billing_postal_code: "",
    billing_address: "",
    billing_addressLine2: "",
    billing_addressLine3: "",
    billing_rate: 0,
    billing_currency: "JOD",
    Industry: "",
    mode: "",
    bank: "",
    account_no: "",
    account_type: "",
    IBAN: "",
    disbursement_currency: "JOD",
  });
  const [validation, setValidation] = useState({
    name: {
      error: true,
      pattern: (name) => name != "",
      skipped: false,
      message: t.common.required_field,
    },
    email: {
      error: true,
      pattern: (email) => email === "" || validateEmail(email),
      skipped: false,
      message: t.common.invalid_email,
    },
    contact_email: {
      error: true,
      pattern: (email) => email === "" || validateEmail(email),
      skipped: false,
      message: t.common.invalid_email,
    },
    phone: {
      error: false,
      pattern: (phone) => phone === "" || isPhoneValid(phone),
      skipped: false,
      message: t.common.required_field,
    },
    contact_phone: {
      error: false,
      pattern: (phone) => phone === "" || isPhoneValid(phone),
      skipped: false,
      message: t.common.required_field,
    },
  });
  // const [customFields, setCustomData] = useState([]);

  const resetForm = () => {
    setCustomerData({
      startDate: "",
      preferences: "English",
      endDate: "",
      credit_days: "",
      pre_process_days: "",
      name: "",
      location: "",
      address: "",
      countryCode: "962",
      isSame: false,
      contact_name: "",
      contact_phone: "",
      contact_email: "",
      phone: "",
      website: "",
      email: "",
      serviceType: "",
      description: "",
      countries_options: [],
      country: "",
      cities_options: [],
      city: "",
      billing_name: "",
      billing_postal_code: "",
      billing_address: "",
      billing_addressLine2: "",
      billing_addressLine3: "",
      billing_rate: 0,
      billing_currency: "JOD",
      Industry: "",
      mode: "",
      bank: "",
      account_no: "",
      account_type: "",
      IBAN: "",
      disbursement_currency: "JOD",
    });
    setValidation({
      name: {
        error: true,
        pattern: (name) => name != "",
        skipped: false,
        message: t.common.required_field,
      },
      email: {
        error: true,
        pattern: (email) => email === "" || validateEmail(email),
        skipped: false,
        message: t.common.invalid_email,
      },
      contact_email: {
        error: true,
        pattern: (email) => email === "" || validateEmail(email),
        skipped: false,
        message: t.common.invalid_email,
      },
      phone: {
        error: false,
        pattern: (phone) => phone === "" || isPhoneValid(phone),
        skipped: false,
        message: t.common.required_field,
      },
      contact_phone: {
        error: false,
        pattern: (phone) => phone === "" || isPhoneValid(phone),
        skipped: false,
        message: t.common.required_field,
      },
      
    });
    setInitChange(false);
    setFile('');
    setImageSrc('')
    setPersonalImgId(null);
    setAttachments({
      image: null,
    });
    setActivePane(0);
    setModalWidth(Math.max(window.innerWidth / 2, 514));
  };
  const onFileChange = (files, name) => {
 
    // console.log('show', e.target.files[0]);
    setInitChange(true);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImageSrc(reader.result);
    });
    reader.readAsDataURL(files[0]);

    setFile({
      name: files[0]?.name,
      type: files[0]?.type,
    });
    // setAttachments({
    //   ...attachments,
    //   [name]: {
    //     file: files[0],
    //     id: randomnumber,
    //     name: files[0].name,
    //     type: files[0].type,
    //     cancelPopup: false,
    //     isChanged: true,
    //   },
    // });
  };

  useEffect(() => {
    if (toggled) {
      const countries = Country.getAllCountries();
      if (countries) {
        const _g_options = countries.map((el) => {
          return {
            key: el?.isoCode?.toLowerCase(),
            value: el?.isoCode,
            text: el.name,
            flag: el?.isoCode?.toLowerCase(),
          };
        });
        setCustomerData((pre) => ({ ...pre, countries_options: _g_options }));
        fetchLocationsOptions();
      }
    }
  }, [toggled]);

  const fetchCities = (code) => {
    const fetchData = State.getStatesOfCountry(code);

    if (fetchData.length > 0) {
      // console.log(fetchData);
      let the_options = fetchData.map((el) => {
        return {
          key: el.isoCode,
          value: el.isoCode,
          text: el.name,
        };
      });
      setCustomerData({
        ...customerData,
        country: code,
        cities_options: the_options,
      });
    }
  };

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    let currentFieldValue = "";
    const dropdown_fields = [
      "billing_currency",
      "countryCode",
      "country",
      "preferences",
      "city",
      "location",
      "Industry",
      "mode",
      "account_type",
      "disbursement_currency",
    ];

    if (!dropdown_fields.includes(name)) {
      currentFieldValue = event.target.value;
      if (
        name !== "name" ||
        name !== "email" ||
        name !== "phone" ||
        !customerData.isSame
      ) {
        setCustomerData({
          ...customerData,
          [name]: event.target.value,
        });
      }

      if (customerData.isSame) {
        if (name === "name") {
          setCustomerData({
            ...customerData,
            ["contact_name"]: event.target.value,
            ["name"]: event.target.value,
          });
        } else if (name === "email") {
          setCustomerData({
            ...customerData,
            ["contact_email"]: event.target.value,
            ["email"]: event.target.value,
          });
        } else if (name === "phone") {
          setCustomerData({
            ...customerData,
            ["contact_phone"]: event.target.value,
            ["phone"]: event.target.value,
          });
        }
      }
    }
    if (dropdown_fields.includes(name)) {
      currentFieldValue = value.value;
      if (name === "country") {
        setCustomerData({
          ...customerData,
          [name]: value.value,
          city: "",
        });
      } else {
        setCustomerData({
          ...customerData,
          [name]: value.value,
        });
      }
    }
    let fieldValidation = validation[name];
    if (fieldValidation) {
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
        },
      };
      // console.log('current', currentValidation);
      setValidation(currentValidation);
    }
  };

  const validationHandler = () => {
    let currentValidation = {
      ...validation,
      name: {
        ...validation.name,
        error: validation.name.pattern(customerData.name) ? false : true,
        skipped: true,
      },
      email: {
        ...validation.email,
        error: validation.email.pattern(customerData.email) ? false : true,
        skipped: true,
      },
      contact_email: {
        ...validation.contact_email,
        error: validation.contact_email.pattern(customerData.contact_email)
          ? false
          : true,
        skipped: true,
      },
    };
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some((el) => el[1].error);
    return errorsInData;
  };

  useEffect(() => {
    const fetchData = State.getStatesOfCountry(customerData.country);

    if (fetchData.length > 0) {
      // console.log(fetchData);
      let the_options = fetchData.map((el) => {
        return {
          key: el.isoCode,
          value: el.isoCode,
          text: el.name,
        };
      });
      setCustomerData({
        ...customerData,
        cities_options: the_options,
      });
    } else {
      setCustomerData({
        ...customerData,
        cities_options: [],
      });
    }
  }, [customerData.country]);

  // console.log(customerData);

  const createNewCustomerHandler = async () => {
    let errorsInData = validationHandler();
    if (!errorsInData) {
      setButtonStatus(1);
      // const logoUploadReponse =
      //   attachments.image && attachments.image.isChanged
      //     ? await uploadFile([attachments.image.file])
      //     : null;

      // const logoId =
      //   logoUploadReponse?.status == 200 ? logoUploadReponse.data[0].id : null;

      const billingInformation = {
        name: customerData.billing_name,
        country: customerData?.country ? customerData.country : null,
        city: customerData?.city ? customerData.city : null,
        postalCode: customerData.billing_postal_code,
        address: customerData.billing_address,
        addressLine2: customerData.billing_addressLine2,
        addressLine3: customerData.billing_addressLine3,
        rate: customerData?.billing_rate ? customerData?.billing_rate : null,
        currency: customerData.billing_currency || null,
        preProcessDays: customerData.pre_process_days
          ? Number(customerData.pre_process_days)
          : null,
        paymentDetails: {
          mode: customerData?.mode ? customerData?.mode : null,
          bank: customerData?.bank,
          accountType: customerData?.account_type
            ? customerData?.account_type
            : null,
          accountNo: customerData?.account_no
            ? Number(customerData?.account_no)
            : null,
          IBAN: customerData?.IBAN ? Number(customerData?.IBAN) : null,
          disbursementCurrency: customerData?.disbursement_currency,
        },
      };
      const contactInformation = {
        name: customerData.contact_name,
        phone: customerData?.contact_phone ? customerData?.contact_phone : null,
        email: customerData?.contact_email ? customerData.contact_email : null,

        // isSame: customerData.isSame,
      };

      const response = await createNewVendor(
        customerData.name,
        customerData.address,
        customerData?.phone ? customerData.phone : null,
        customerData.website,
        customerData?.email ? customerData.email : null,
        customerData.serviceType,
        customerData.description,
        billingInformation,
        personalImgId,
        organisationId,
        currentUserId,
        customerData.Industry || null,
        contactInformation,
        customerData?.credit_days ? Number(customerData.credit_days) : null,
        customerData?.startDate ? customerData.startDate : null,
        customerData?.endDate ? customerData.endDate : null,
        customerData?.location ? customerData.location : null,
        customerData?.preferences ? customerData?.preferences : "English"

        // customFields
      );
      // console.log(response);

      if (response.status == 200) {
        setButtonStatus(0);
        untoggle();
        resetForm();
        setFilterOptions({
          locations: [],
          cities: [],
        });
        setSearchTitle("");

        setResponseData({
          name: response.data.name,
        });

        toast.success(t.vendors.vendor + " " + t.common.added_success);

        if (initData?.length == 0) {
          const fetchData = await dispatch(
            fetchVendorInitData({ organisationId, role, allIds })
          );
        }

        // toggleSuccessModal(true);
      } else if (response.status == 400) {
        if (response.error && response.error.message) {
          toast.error(response.error.message);
          // untoggle();
        } else {
          toast.error(t.common.something_wrong);
          untoggle();
          resetForm();
        }
      }
      setButtonStatus(0);
    } else {
      scrollToError();
    }
  };

  const changeTab = async (tab, errors, setFieldTouched, values) => {
    let errorsInData = validationHandler();

    if (!errorsInData) {
      setActivePane(tab);
      return;
    } else {
      scrollToError();
    }
  };

  // const generatedCustomDataFields = () => {
  //   return customFields.map((field, index) => {
  //     return (
  //       <Grid columns="equal">
  //         <Grid.Column>
  //           <Form.Field>
  //             <label className="label-item">Label</label>
  //             <Input
  //               autoComplete="new-password"
  //               fluid
  //               placeholder="Field Label"
  //               value={field.label}
  //               onChange={handleCustomDataChange(index, "label")}
  //             />
  //           </Form.Field>
  //         </Grid.Column>
  //         <Grid.Column>
  //           <Form.Field>
  //             <label className="label-item">Value</label>
  //             <Input
  //               autoComplete="new-password"
  //               fluid
  //               placeholder="Field Label"
  //               value={field.value}
  //               onChange={handleCustomDataChange(index, "value")}
  //             />
  //           </Form.Field>
  //         </Grid.Column>

  //         <div
  //           className="buttun-type-link remove"
  //           basic
  //           onClick={removeThisData(field.id)}
  //         >
  //           <Icon name="trash alternate outline" />
  //           Remove
  //         </div>
  //       </Grid>
  //     );
  //   });
  // };

  // const addCustomData = () => {
  //   // console.log("clicked");
  //   setCustomData([
  //     ...customFields,
  //     {
  //       id: customFields.length,
  //       label: "",
  //       value: "",
  //     },
  //   ]);
  // };

  // const handleCustomDataChange = (id, type) => (event) => {
  //   // 1. Make a shallow copy of the items
  //   let newfields = [...customFields];
  //   // 2. Make a shallow copy of the item you want to mutate
  //   let item = { ...customFields[id] };
  //   // 3. Replace the property you're intested in

  //   if (type == "label") item.label = event.target.value;
  //   if (type == "value") item.value = event.target.value;
  //   // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
  //   newfields[id] = item;
  //   // 5. Set the state to our new copy
  //   setCustomData(newfields);
  // };

  // const removeThisData = (id) => (e) => {
  //   e.stopPropagation();
  //   const index = id;
  //   let newfields = [...customFields];
  //   newfields.splice(index, 1);

  //   setCustomData(newfields);
  // };

  const cancelHandler = () => {
    resetForm();
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    untoggle();
    setNewCancelModal(false);
    setInitChange(false);
  };
  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled
            ? "aside-modal preview active procedure"
            : "aside-modal preview procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between align-center">
              <div className="name">{t.vendors.form.add_vendor}</div>

              <div className="flexbox align-center workorder-header-buttons">
                <span
                  className="procedure-header-button c-pointer"
                  style={{ position: "relative" }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == "en"
                        ? "bottom center"
                        : "bottom center"
                    }
                    arrowAlign="center"
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          <div
            className="body p-6"
            style={{
              height: "90vh",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
          >
            {buttonStatus == 1 ? (
              <Dimmer active inverted>
                <Loader inverted content={t.common.loading} />
              </Dimmer>
            ) : (
              ""
            )}
            <Button.Group
              className="add-people-btn mt-5"
              basic
              fluid
              style={{ width: "71%" }}
              size="medium"
            >
              <Button
                active={activePane == 0}
                onClick={() => changeTab(0)}
                type="button"
              >
                {t.vendors.table.vendor_info}
              </Button>

              <Button
                className="add-people-tab"
                active={activePane == 1}
                onClick={() => changeTab(1)}
                type="button"
              >
                {t.customers.form.billing_information}
              </Button>

              {/* <Button
              active={activePane == 2}
              onClick={() => {
                // setFieldValue('tab', 2).then((errors) => {
                //   changeTab(2, errors, setFieldTouched, values);
                //   if (errors) {
                //     scrollToError();
                //   }
                // });
              }}
              type="button"
            >
              {t.people.attachments_form.attachment_title}
            </Button> */}
            </Button.Group>
            <Divider className="mt-0" style={{ width: "70%" }} />

            <div>
              <div style={{ display: activePane == 0 ? "block" : "none" }}>
              <FileUpload
                  onFileChange={onFileChange}
                  name="image"
                  setImageSrc={setImageSrc}
                  setFile={setFile}
                  attachments={attachments}
                  setAttachments={setAttachments}
                />

{imageSrc && (
                  <ImageCrop
                    // ratio={1 / 1}
                    src={imageSrc}
                    minWidth={20}
                    minHeight={20}
                    fileData={file}
                    onAccept={async(croppedImageUrl) => {
                      //console.log('Cropped', croppedImageUrl);
                      let CroppedFile = new File([croppedImageUrl], file.name, {
                        type: file.type,
                      });
                      //console.log(CroppedFile);
                      var randomnumber =
                        Math.floor(Math.random() * (0 - 100 + 1)) + 100;

                        const personalPictureResponse = await uploadFile([CroppedFile]);

                        if (personalPictureResponse?.status == 200) {
                          let fileData = personalPictureResponse?.data[0]?.url;
                          setPersonalImgId(personalPictureResponse.data[0].id);
                          setImageSrc('');
                          setFile("");
                          setAttachments({
                            ...attachments,
                            ["image"]: {
                              file: CroppedFile,
                              id: randomnumber,
                              name: CroppedFile.name,
                              type: CroppedFile.type,
                              cancelPopup: false,
                              isChanged: true,
                              url: fileData ? fileData : null,
                            },
                          });
                        }else{
                          toast.error(t.common.something_wrong)
                        }
                   
                      // setAttachments({
                      //   ...attachments,
                      //   profileImage: {
                      //     file: CroppedFile,
                      //     id: randomnumber,
                      //     name: CroppedFile.name,
                      //     type: CroppedFile.type,
                      //     cancelPopup: false,
                      //     isChanged: true,
                      //   },
                      // });
                      // Resizer.imageFileResizer(
                      //   croppedImageUrl,
                      //   300,
                      //   300,
                      //   'PNG',
                      //   100,
                      //   0,
                      //   async (uri) => {
                      //     let data = {
                      //       fileName:
                      //         file.name.replace(/[\W_]+/g, '_') +
                      //         '.' +
                      //         file.type.split('/')[1],
                      //       source: uri,
                      //       folder: 'job',
                      //     };
                      //     api(`file`, data, 'post', true)
                      //       .then((data) => {
                      //         setFieldValue('image', data.data);
                      //       })
                      //       .catch((err) => {
                      //         Toast.error(
                      //           t(`apiResponses.${err.error}`) ||
                      //             t('generalValidation.somethingWrongTry')
                      //         );
                      //       });
                      //   },
                      //   'base64',
                      //   300,
                      //   300
                      // );
                      // };
                      // reader.readAsDataURL(croppedImageUrl);
                    }}
                    onCancel={() => {
                      setImageSrc("");
                      setFile("");
                    }}
                  />
                )}


                <Form.Field>
                  <label className="label-item mt-3">
                    {t.vendors.table.name}
                    <Asterisk color="#172B4D" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_name}
                    value={customerData.name}
                    onChange={onValuesChange("name")}
                    error={validation.name.error && validation.name.skipped}
                    onBlur={(e) => {
                      if (validation.name.pattern(customerData.name)) {
                        setValidation({
                          ...validation,
                          name: {
                            ...validation.name,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          name: {
                            ...validation.name,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.name.error && validation.name.skipped ? (
                    <div className="label-error">{validation.name.message}</div>
                  ) : (
                    ""
                  )}
                </Form.Field>

                <Form style={{ marginBottom: "20px" }}>
                  <Form.Field>
                    <label className="label-item">
                      {t.customers.form.description}
                    </label>
                    <TextArea
                      autoComplete="new-password"
                      value={customerData.description}
                      onChange={onValuesChange("description")}
                      rows={4}
                      fluid
                      placeholder={t.customers.form.add_description}
                    />
                  </Form.Field>
                </Form>

                <Form.Field>
                  <label className="label-item mt-7">
                    {t.customers.form.location}{" "}
                    <span
                      className="c-pointer"
                      style={{ position: "relative" }}
                      onMouseOver={() => setTeamsTooltip(true)}
                      onMouseLeave={() => setTeamsTooltip(false)}
                    >
                      {showTeamsTooltip ? (
                        <>
                          <InfoIconBlue />
                          <ToolTip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "ar"
                                ? "left top"
                                : "right top"
                            }
                            show={showTeamsTooltip}
                            textBoxWidth="100px"
                            arrowAlign="center"
                            fontSize="9px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="4px 14px 4px 7px"
                            lineHeight="16px"
                            borderRadius="6px"
                            static
                            moveDown="0px"
                          >
                            <span className="work-order-preview-tooltip parts">
                              {t.customers.form.served}
                            </span>
                          </ToolTip>
                        </>
                      ) : (
                        <InfoIcon />
                      )}
                    </span>
                    {/* <Icon name="asterisk" color="red" size="small" /> */}
                  </label>
                  <div className="flexbox">
                    <Dropdown
                      placeholder={t.customers.form.add_location}
                      fluid
                      className="loc-dropdown"
                      selection
                      clearable
                      noResultsMessage={t.common.no_results_found}
                      //allowAdditions
                      // multiple
                      search
                      // onAddItem={handleAddLocation}
                      // disabled={!data.customer}
                      options={options.locations}
                      value={customerData.location}
                      onChange={onValuesChange("location")}
                    />
                    {userPermissions?.location?.create &&
                    userPermissions?.location?.view ? (
                      <span
                        className="ml-2 mr-2 p-1 d-flex align-items-center justify-content-center c-pointer"
                        onClick={() => {
                          setToggleAsideModal(true);
                          setToggleAddModal(false);
                        }}
                        style={{ backgroundColor: "#E9F2FF" }}
                      >
                        <AddIcon />
                      </span>
                    ) : null}{" "}
                  </div>
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {" "}
                    {t.customers.form.phone}
                  </label>
                  <PhoneInput
                    defaultCountry={"jo"}
                    // forceDialCode={true}
                    value={customerData.phone}
                    placeholder={t.customers.form.add_number}
                    onChange={(value) => {
                      // console.log('values', value);
                      if (!phone) {
                        setPhone(true);
                        return;
                      }
                      setCustomerData({
                        ...customerData,
                        phone: value || "",
                      });
                      if (customerData.isSame) {
                        setCustomerData({
                          ...customerData,
                          phone: value || "",
                          contact_phone: value || "",
                        });
                      }
                      if (!value) {
                        setValidation({
                          ...validation,
                          phone: {
                            ...validation.phone,
                            error: false,
                            skipped: false,
                            message: t.common.required_field,
                            pattern: (phone) =>
                              phone === "" || isPhoneValid(phone),
                          },
                        });
                      } else if (value && isPhoneValid(value)) {
                        setValidation({
                          ...validation,
                          phone: {
                            ...validation.phone,
                            error: false,
                            skipped: true,
                            pattern: (phone) =>
                              phone === "" || isPhoneValid(phone),
                          },
                        });
                      } else if (value && !isPhoneValid(value)) {
                        setValidation({
                          ...validation,
                          phone: {
                            ...validation.phone,
                            error: true,
                            skipped: true,
                            message: t.customers.form.invalid_phone,
                            pattern: (phone) =>
                              phone === "" || isPhoneValid(phone),
                          },
                        });
                      }
                    }}
                    error={
                      validation?.phone?.error && validation?.phone?.skipped
                    }
                  />
                  {validation?.phone?.error && validation?.phone?.skipped ? (
                    <div className="label-error">
                      {validation?.phone?.message}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <Input
                  autoComplete="new-password"
                  label={
                    <Dropdown
                      button
                      basic
                      search
                      defaultValue="962"
                      options={country_codes}
                      onChange={onValuesChange('countryCode')}
                    />
                  }
                  type="number"
                  fluid
                  placeholder="Phone"
                  value={customerData.phone}
                  onChange={onValuesChange('phone')}
                /> */}
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.customers.form.email}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_email}
                    value={customerData.email}
                    onChange={onValuesChange("email")}
                    className="email-password-ltr"
                    error={validation.email.error && validation.email.skipped}
                    onBlur={(e) => {
                      if (validation.email.pattern(customerData.email)) {
                        setValidation({
                          ...validation,
                          email: {
                            ...validation.email,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          email: {
                            ...validation.email,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.email.error && validation.email.skipped ? (
                    <div className="label-error">
                      {validation.email.message}
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.customers.form.website}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_website}
                    value={customerData.website}
                    onChange={onValuesChange("website")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.customers.form.service_type}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_service_type}
                    value={customerData.serviceType}
                    onChange={onValuesChange("serviceType")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">{t.common.industry}</label>
                  <Dropdown
                    placeholder={t.customers.form.add_industry}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    clearable
                    selection
                    search
                    options={options.Industry}
                    value={customerData.Industry}
                    onChange={onValuesChange("Industry")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.vendors.form.credit_days}
                  </label>
                  <div className="estimation-time-div">
                    <Input
                      type="number"
                      onKeyDown={(evt) => handleKeyDown(evt)}
                      min="0"
                      fluid
                      placeholder={t.vendors.form.add_credit_days}
                      value={customerData.credit_days}
                      onChange={onValuesChange("credit_days")}
                    ></Input>
                  </div>
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.vendors.form.start_date}
                  </label>
                  <div className="change-date-time-inll position-relative">
                    <DatePicker
                      style={{ width: "100%" }}
                      value={
                        customerData.startDate
                          ? new Date(customerData.startDate)
                          : null
                      }
                      onChange={(value) => {
                        if (value && value.valueOf()) {
                          setCustomerData({
                            ...customerData,
                            startDate: format(value.valueOf(), "yyyy-MM-dd"),
                            endDate: null,
                          });
                        } else {
                          setCustomerData({
                            ...customerData,
                            startDate: null,
                            endDate: null,
                          });
                        }
                      }}
                      showMonthDropdown
                      showYearDropdown
                      placeholder={t.vendors.form.add_start_date}
                      className={"active"}
                      // locale={lang == 'ar' ? arabic_ar : ''}
                      weekDays={[
                        t.workOrders.details_view.sun,
                        t.workOrders.details_view.mon,
                        t.workOrders.details_view.tue,
                        t.workOrders.details_view.wed,
                        t.workOrders.details_view.thu,
                        t.workOrders.details_view.fri,
                        t.workOrders.details_view.sat,
                      ]}
                      months={[
                        t.common.january,
                        t.common.february,
                        t.common.march,
                        t.common.april,
                        t.common.may,
                        t.common.june,
                        t.common.july,
                        t.common.august,
                        t.common.september,
                        t.common.october,
                        t.common.november,
                        t.common.december,
                      ]}
                      format="DD/MM/YYYY"
                    ></DatePicker>

                    <img
                      className="position-absolute"
                      style={
                        selectedLanguage == "en"
                          ? { top: "8px", right: "7px" }
                          : { top: "8px", left: "7px" }
                      }
                      src={calendar_icon}
                    />
                  </div>
                </Form.Field>
                <Form.Field style={{ minHeight: "71px" }}>
                  <label className="label-item">
                    {t.vendors.form.end_date}
                  </label>
                  <div className="change-date-time-inll position-relative">
                    <DatePicker
                      style={{ width: "100%" }}
                      minDate={new Date(customerData.startDate)}
                      disabled={!customerData?.startDate}
                      value={
                        customerData.endDate && customerData.startDate
                          ? new Date(customerData.endDate)
                          : null
                      }
                      onChange={(value) => {
                        if (
                          value &&
                          value.valueOf() &&
                          customerData.startDate
                        ) {
                          setCustomerData({
                            ...customerData,
                            endDate: format(value.valueOf(), "yyyy-MM-dd"),
                          });
                        } else {
                          setCustomerData({
                            ...customerData,
                            endDate: null,
                          });
                        }
                      }}
                      showMonthDropdown
                      showYearDropdown
                      placeholder={t.vendors.form.add_end_date}
                      className={"active"}
                      // locale={lang == 'ar' ? arabic_ar : ''}
                      weekDays={[
                        t.workOrders.details_view.sun,
                        t.workOrders.details_view.mon,
                        t.workOrders.details_view.tue,
                        t.workOrders.details_view.wed,
                        t.workOrders.details_view.thu,
                        t.workOrders.details_view.fri,
                        t.workOrders.details_view.sat,
                      ]}
                      months={[
                        t.common.january,
                        t.common.february,
                        t.common.march,
                        t.common.april,
                        t.common.may,
                        t.common.june,
                        t.common.july,
                        t.common.august,
                        t.common.september,
                        t.common.october,
                        t.common.november,
                        t.common.december,
                      ]}
                      format="DD/MM/YYYY"
                    ></DatePicker>

                    <img
                      className="position-absolute"
                      style={
                        selectedLanguage == "en"
                          ? { top: "8px", right: "7px" }
                          : { top: "8px", left: "7px" }
                      }
                      src={calendar_icon}
                    />
                  </div>
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-7">
                    {t.customers.form.preferences}
                    {/* <Icon name="asterisk" color="red" size="small" /> */}
                  </label>
                  <Dropdown
                    placeholder={t.customers.form.add_preferences}
                    fluid
                    selection
                    noResultsMessage={t.common.no_results_found}
                    //allowAdditions
                    // multiple
                    search
                    // onAddItem={handleAddLocation}
                    // disabled={!data.customer}
                    options={options.preferences}
                    value={customerData.preferences}
                    onChange={onValuesChange("preferences")}
                  />
                </Form.Field>

                <Divider className="mt-5" />

                <div className="billing-info mt-7  d-flex">
                  <ContactInfoIcon />
                  <p
                    style={{ fontSize: "16px" }}
                    className={` mt-1 required-text ${
                      selectedLanguage == "en" ? "ml-2" : "mr-2"
                    }`}
                  >
                    {t.customers.form.contact_information}
                  </p>
                </div>

                <Form.Field>
                  <label className="label-item mt-8">
                    {t.customers.contact_name}
                  </label>
                  <Input
                    // disabled={customerData.isSame}
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_contact_name}
                    value={customerData.contact_name}
                    onChange={onValuesChange("contact_name")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.customers.contact_phone}
                  </label>
                  <PhoneInput
                    // disabled={customerData.isSame}
                    // disableDialCodePrefill={!customerData.isSame ? true : false}
                    defaultCountry="jo"
                    // forceDialCode={true}
                    value={customerData.contact_phone}
                    placeholder={t.customers.form.add_number}
                    onChange={(value) => {
                      // console.log('values', value);
                      if (!phone) {
                        setPhone(true);
                        return;
                      }
                      setCustomerData({
                        ...customerData,
                        contact_phone: value || "",
                      });
                      if (!value) {
                        setValidation({
                          ...validation,
                          contact_phone: {
                            ...validation.contact_phone,
                            error: false,
                            skipped: false,
                            message: t.common.required_field,
                            pattern: (phone) =>
                              phone === "" || isPhoneValid(phone),
                          },
                        });
                      } else if (value && isPhoneValid(value)) {
                        setValidation({
                          ...validation,
                          contact_phone: {
                            ...validation.contact_phone,
                            error: false,
                            skipped: true,
                            pattern: (phone) =>
                              phone === "" || isPhoneValid(phone),
                          },
                        });
                      } else if (value && !isPhoneValid(value)) {
                        setValidation({
                          ...validation,
                          contact_phone: {
                            ...validation.contact_phone,
                            error: true,
                            skipped: true,
                            message: t.customers.form.invalid_phone,
                            pattern: (phone) =>
                              phone === "" || isPhoneValid(phone),
                          },
                        });
                      }
                    }}
                    error={
                      validation?.contact_phone?.error &&
                      validation?.contact_phone?.skipped
                    }
                  />
                  {validation?.contact_phone?.error &&
                  validation?.contact_phone?.skipped ? (
                    <div className="label-error">
                      {validation?.contact_phone?.message}
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Field>

                <Form.Field style={{ minHeight: "71px" }}>
                  <label className="label-item mt-1">
                    {t.customers.contact_email}
                  </label>
                  <Input
                    // disabled={customerData.isSame}
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_contact_email}
                    value={customerData.contact_email}
                    onChange={onValuesChange("contact_email")}
                    className="email-password-ltr"
                    error={
                      validation.contact_email.error &&
                      // !customerData.isSame
                      //  &&
                      validation.contact_email.skipped
                    }
                    onBlur={(e) => {
                      if (
                        validation.contact_email.pattern(
                          customerData.contact_email
                        )
                      ) {
                        setValidation({
                          ...validation,
                          contact_email: {
                            ...validation.contact_email,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        if (
                          !customerData.isSame &&
                          customerData.contact_email !== ""
                        ) {
                          setValidation({
                            ...validation,
                            contact_email: {
                              ...validation.contact_email,
                              error: true,
                              skipped: true,
                            },
                          });
                        } else {
                          setValidation({
                            ...validation,
                            contact_email: {
                              ...validation.contact_email,
                              error: false,
                              skipped: false,
                            },
                          });
                        }
                      }
                    }}
                  />
                  {validation.contact_email.error &&
                  // !customerData.isSame

                  validation.contact_email.skipped ? (
                    <div className="label-error">
                      {validation.contact_email.message}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div className="mt-4 mb-4">
                  <Checkbox
                    checked={customerData.isSame}
                    label={t.customers.same_info}
                    onChange={(e, value) => {
                      if (value.checked) {
                        setCustomerData({
                          ...customerData,
                          isSame: true,
                          contact_email: customerData.email,
                          contact_phone: customerData.phone,
                          contact_name: customerData.name,
                        });
                      } else {
                        setCustomerData({
                          ...customerData,
                          isSame: false,
                          contact_email: '',
                          contact_phone: '',
                          contact_name: '',
                        });
                      }
                    }}
                  />
                </div> */}
                </Form.Field>

                {/* <Form.Field>
                    <label className="label-item">{t.vendors.form.logo}</label>
                    {logo ? (
                      <List divided relaxed>
                        <Popup
                          key={logo?.id}
                          content={t.common.remove_file}
                          on="click"
                          pinned
                          open={logo?.cancelPopup}
                          trigger={
                            <List.Item
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                let currentFile = logo;
                                currentFile.cancelPopup = !currentFile.cancelPopup;
                                setLogo(currentFile);
                              }}
                            >
                              <List.Icon
                                name={
                                  logo?.type.match('image.*')
                                    ? 'image'
                                    : logo?.type.match('pdf')
                                    ? 'file pdf'
                                    : 'file'
                                }
                                size="large"
                                color="black"
                                verticalAlign="middle"
                              />
                              <List.Content>
                                <List.Header>{logo?.name}</List.Header>
                              </List.Content>
                            </List.Item>
                          }
                        >
                          <Header as="h4">{t.common.remove_file}</Header>
                          <Button
                            color="black"
                            onClick={(e) => {
                              e.stopPropagation();
                              let currentFile = logo;
                              currentFile.cancelPopup = false;
                              setLogo(currentFile);
                            }}
                          >
                            {t.common.keep}
                          </Button>
                          <Button
                            color="red"
                            onClick={(e) => {
                              e.stopPropagation();
                              setLogo(null);
                            }}
                          >
                            {t.common.yes_remove}
                          </Button>
                        </Popup>
                      </List>
                    ) : (
                      <div>
                        <input
                          ref={logoRef}
                          type="file"
                          accept="image/*"
                          hidden
                          onChange={(e) => onFileChange(e)}
                        />
                        <div
                          className="buttun-type-link"
                          onClick={() => logoRef.current.click()}
                        >
                          <Icon name="add" />
                          {t.common.add}
                        </div>
                        <p
                          style={{
                            fontSize: '12px',
                            marginTop: '5px',
                            color: '#7e7e7e',
                          }}
                        >
                          {t.common.allowed_type} jpg, jpeg, png
                        </p>
                      </div>
                    )}
                  </Form.Field> */}
              </div>

              <div style={{ display: activePane == 1 ? "block" : "none" }}>
                <Form.Field>
                  <label className="label-item mt-7">
                    {t.vendors.form.name}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_name}
                    value={customerData.billing_name}
                    onChange={onValuesChange("billing_name")}
                  />
                </Form.Field>

                <Form.Field autoComplete="off">
                  <label className="label-item">
                    {t.customers.form.country}
                  </label>
                  <Dropdown
                    placeholder={t.locations.form.country_add}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    clearable
                    search
                    selection
                    options={customerData.countries_options}
                    value={customerData.country}
                    onChange={onValuesChange("country")}
                  />
                </Form.Field>

                <Form.Field autoComplete="off">
                  <label className="label-item">{t.customers.form.city}</label>
                  <Dropdown
                    placeholder={t.locations.form.city_add}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    clearable
                    search
                    selection
                    disabled={customerData.cities_options.length < 1}
                    options={customerData.cities_options}
                    value={customerData.city}
                    onChange={onValuesChange("city")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.customers.form.postal_code}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_postal}
                    value={customerData.billing_postal_code}
                    onChange={onValuesChange("billing_postal_code")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.customers.form.address}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_address}
                    value={customerData.billing_address}
                    onChange={onValuesChange("billing_address")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.customers.form.address_line_2}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_address}
                    value={customerData.billing_addressLine2}
                    onChange={onValuesChange("billing_addressLine2")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.customers.form.address_line_3}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_address}
                    value={customerData.billing_addressLine3}
                    onChange={onValuesChange("billing_addressLine3")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">{t.customers.form.rate}</label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    type="number"
                    onKeyDown={(evt) => handleKeyDown(evt)}
                    min="0"
                    placeholder={t.customers.form.add_rate}
                    value={customerData.billing_rate}
                    onChange={onValuesChange("billing_rate")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {" "}
                    {t.customers.form.currency}
                  </label>
                  <Dropdown
                    placeholder={t.customers.form.add_currency}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    search
                    selection
                    clearable
                    options={currencies}
                    value={customerData.billing_currency}
                    onChange={onValuesChange("billing_currency")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.vendors.form.pre_days}
                  </label>
                  <div className="estimation-time-div">
                    <Input
                      type="number"
                      onKeyDown={(evt) => handleKeyDown(evt)}
                      min="0"
                      fluid
                      placeholder={t.vendors.form.add_pre_days}
                      value={customerData.pre_process_days}
                      onChange={onValuesChange("pre_process_days")}
                    ></Input>
                  </div>
                </Form.Field>

                <Divider className="m-0" />

                <div className="billing-info mt-7  d-flex">
                  <BillingInfoIcon />
                  <p
                    style={{ fontSize: "16px" }}
                    className={` mt-1 required-text ${
                      selectedLanguage == "en" ? "ml-2" : "mr-2"
                    }`}
                  >
                    {t.vendors.form.payment_info}
                  </p>
                </div>

                <Form.Field>
                  <label className="label-item mt-7">
                    {t.vendors.form.mode}
                  </label>
                  <Dropdown
                    placeholder={t.vendors.form.add_mode}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    clearable
                    selection
                    search
                    options={options.Mode}
                    value={customerData.mode}
                    onChange={onValuesChange("mode")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.vendors.form.bank}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.vendors.form.add_bank}
                    value={customerData.bank}
                    onChange={onValuesChange("bank")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.vendors.form.account}
                  </label>
                  <Dropdown
                    placeholder={t.vendors.form.add_account}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    clearable
                    selection
                    search
                    options={options.accountType}
                    value={customerData.account_type}
                    onChange={onValuesChange("account_type")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.vendors.form.account_no}
                  </label>
                  <Input
                    type="number"
                    onKeyDown={(evt) => handleKeyDown(evt)}
                    min="0"
                    autoComplete="new-password"
                    fluid
                    placeholder={t.vendors.form.add_account_no}
                    value={customerData.account_no}
                    onChange={onValuesChange("account_no")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.vendors.form.IBAN}
                  </label>
                  <Input
                    type="number"
                    onKeyDown={(evt) => handleKeyDown(evt)}
                    min="0"
                    autoComplete="new-password"
                    fluid
                    placeholder={t.vendors.form.IBAN_placeholder}
                    value={customerData.IBAN}
                    onChange={onValuesChange("IBAN")}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.vendors.form.disbursement_currency}
                  </label>
                  <Dropdown
                    placeholder={t.vendors.form.add_disbursement_currency}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    search
                    selection
                    clearable
                    options={currencies}
                    value={customerData.disbursement_currency}
                    onChange={onValuesChange("disbursement_currency")}
                  />
                </Form.Field>
                {/* <Form.Field>
                  <label className="label-item mt-1">
                    {t.vendors.form.disbursement_currency}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.vendors.form.add_disbursement_currency}
                    value={customerData.disbursement_currency}
                    onChange={onValuesChange("disbursement_currency")}
                  />
                </Form.Field> */}
              </div>
            </div>

            {/* {generatedCustomDataFields()} */}
            {/* <div className="buttun-type-link" basic onClick={addCustomData}>
            <Icon name="add" />
            Custom Data
          </div>

          <Divider /> */}

            <div
              className={`form-button-container ${
                activePane == 1 ? "mt-8" : "mt-8"
              }`}
            >
              <button
                content={t.common.cancel}
                className="form-action-button"
                basic
                type="button"
                onClick={() =>
                  initChange ? setNewCancelModal(true) : cancelHandler()
                }
              >
                {t.common.cancel}
              </button>
              {activePane == 1 ? (
                <button
                  content={t.common.submit}
                  primary
                  type="button"
                  onClick={createNewCustomerHandler}
                  className="form-action-button blue"
                >
                  {t.common.submit}
                </button>
              ) : (
                <button
                  content={t.common.next}
                  className="form-action-button blue"
                  type="button"
                  primary
                  onClick={() => {
                    // setFieldValue('tab', activePane + 1).then((errors) => {
                    changeTab(activePane + 1);
                    //   if (errors) {
                    //     scrollToError();
                    //   }
                    // });
                  }}
                >
                  {t.common.next}
                </button>
              )}
            </div>

            {/* <div>
            <Button
              content={t.vendors.form.submit}
              primary
              onClick={createNewCustomerHandler}
              loading={buttonStatus == 1}
            />

            <Button
              content={t.common.cancel}
              basic
              onClick={() =>
                initChange ? toggleCancelModal(true) : cancelHandler()
              }
            />
          </div> */}
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

      {/* success modal */}
      {/* <Modal
        size="tiny"
        open={successModal}
        onClose={() => {
          toggleSuccessModal(false);
          resetForm();
          setRefresh(!refresh);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper">
              <Icon name="checkmark" color="white" size="huge" />
            </div>
            <p className="status-modal-popup-message">
              {t.vendors.form.vendor_added_success}
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              toggleSuccessModal(false);
              resetForm();
              setRefresh(!refresh);
            }}
          >
            {t.common.ok}
          </Button>
        </Modal.Actions>
      </Modal> */}

      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: "24px" }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>
      {toggleAsideModal && (
        <AddLocation
          toggled={toggleAsideModal}
          allIds={allIds}
          role={role}
          currentUserId={currentUserId}
          untoggle={() => {
            setToggleAsideModal(false);
            setToggleAddModal(true);
            setModalWidth(Math.max(window.innerWidth / 2, 514));
            // setSelectedParentLocation(null);
          }}
          setRefresh={setRefresh}
          refresh={refresh}
          parentLocation={null}
          fromCustomer={true}
          handleSuccess={async (res) => {
            if (!(role === "super_admin")) {
              setCustomerData({
                ...customerData,
                location: res?.data?.id,
              });

              let updatedAllIds = [...allIds];
              const [userData] = await Promise.all([getUserData(user.id)]);

              dispatch(props.fulfillUser(userData));
              userData?.locations?.length &&
                userData.locations.forEach((location) => {
                  if (!updatedAllIds.some((inv) => inv == location?.id)) {
                    updatedAllIds.push(location?.id);
                  }

                  location.subLocations.forEach((subLocation) => {
                    if (!updatedAllIds.some((inv) => inv == subLocation?.id)) {
                      updatedAllIds.push(subLocation?.id);
                    }
                  });
                });

              setAllIds(updatedAllIds);
              await fetchLocationsOptions(updatedAllIds);
            } else {
              setToggleAsideModal(false);
              setToggleAddModal(true);

              setCustomerData({
                ...customerData,
                location: res?.data?.id,
              });
            }
          }}
        />
      )}

      {/* <Modal
        size="tiny"
        open={cancelModal && initChange}
        onClose={() => setNewCancelModal(false)}
      >
        <Modal.Content className="cancel-text">
          <p>{t.common.cancel_statement}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleCancelModal(false)}>
            {t.common.keep}
          </Button>
          <Button negative onClick={() => cancelHandler()}>
            {t.common.yes_cancel}
          </Button>
        </Modal.Actions>
      </Modal> */}
    </>
  );
}

export default connect(null, auth.actions)(Add);
