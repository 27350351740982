import React from "react";
import Asterisk from "../../../../components/common/Asterisk";
import {
  Dropdown,
  Form,
  Grid,
  GridColumn,
  Input,
  Modal,
} from "semantic-ui-react";
import { format } from "date-fns";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import clock_icon from "../../../../../Assets/Icons/clock.svg";
import calendar_icon from "../../../../../Assets/Icons/calender-icon.svg";
import moment, { duration } from "moment";
import DatePicker from "react-multi-date-picker";
import ReactTimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../../config/i18n/slice";
import CustomTimeInput from "../customInput";
import { CloseIcon } from "../../../../../Assets/Icons/svg";
const LaborModal = ({
  handleAssign,
  options,
  fetchPeopleOptions,
  onValuesChange,
  addLaborModal,
  validation,
  setValidation,
  setaddLaborModal,
  setLaborData,
  setIsEdit,
  laborData,
  isEdit,
}) => {
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });
  const organisationTimezone = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.timezone;
    } else {
      return null;
    }
  });

  const getLocalDateTime = (offset) => {
    const now = new Date(); // Get the current time in local timezone
    const utcMilliseconds = now.getTime() + now.getTimezoneOffset() * 60000; // Convert local time to UTC
    const localMilliseconds = utcMilliseconds + offset * 3600000; // Add timezone offset in milliseconds

    const localDate = new Date(localMilliseconds);

    return format(localDate, "yyyy-MM-dd hh:mm a");
  };
  return (
    <Modal
      className="assets-modal"
      size="small"
      open={addLaborModal}
      onClose={() => {
        setaddLaborModal(false);
        setValidation({
          people: {
            error: false,
            pattern: (code) => code != "",
            skipped: false,
            message: t.common.required_field,
          },
          duration: {
            error: false,
            pattern: (code) => code != "" || code != "00:00",
            skipped: false,
            message: t.common.required_field,
          },
        });
        setLaborData({
          people: "",
          startDate: getLocalDateTime(organisationTimezone?.split("+")[1]),
          duration: "",
          hourRate: "",
          category: "Other Time",
        });
        setIsEdit(false);
      }}
    >
      <div className="header-container">
        <span className="assets-header">
          {isEdit ? t.workOrders.labor.edit_time : t.workOrders.labor.add_time}
        </span>
        <div>
          <span
            className="c-pointer text-right"
            onClick={() => {
              setaddLaborModal(false);
              setIsEdit(false);
              setValidation({
                people: {
                  error: false,
                  pattern: (code) => code != "",
                  skipped: false,
                  message: t.common.required_field,
                },
                duration: {
                  error: false,
                  pattern: (code) => code !== "" && code !== "00:00", // Return false if code is "" or "00:00"
                  skipped: false,
                  message: t.common.required_field,
                },
              });
              setLaborData({
                people: "",
                startDate: getLocalDateTime(
                  organisationTimezone?.split("+")[1]
                ),
                duration: "",
                hourRate: "",
                category: "Other Time",
              });
            }}
          >
            <CloseIcon />
          </span>
        </div>
      </div>

      <Modal.Content className="pb-0">
        {/* <label>{t.categories.import_categories}</label> */}

        <div className="assignee-data">
          <Grid className="mb-0" columns={2}>
            <GridColumn className="pb-0">
              <Form.Field>
                <label className="label-item">
                  {t.workOrders.labor.started_at}
                </label>

                <div
                  className="change-date-time-inll position-relative"
                  style={{ marginBottom: "-13px" }}
                >
                  <DatePicker
                    minDate={new Date().setHours(0, 0, 0, 0)}
                    highlightToday={false}
                    className="active labor-date-picker"
                    style={{ width: "100%" }}
                    value={
                      laborData?.startDate
                        ? new Date(laborData?.startDate)
                        : null
                    }
                    plugins={[
                      <TimePicker
                        hideSeconds={true} // Optional: Hides seconds in time picker
                      />,
                    ]}
                    onChange={(value) => {
                      if (value && value.valueOf()) {
                        setLaborData({
                          ...laborData,
                          startDate: format(
                            value.valueOf(),
                            "yyyy-MM-dd  hh:mm a"
                          ), // Format for date and time with AM/PM
                        });
                      } else {
                        setLaborData({
                          ...laborData,
                          startDate: format(new Date(), "yyyy-MM-dd hh:mm a"),
                        });
                      }
                    }}
                    showMonthDropdown
                    showYearDropdown
                    placeholder={t.workOrders.form.add_due_date}
                    weekDays={[
                      t.workOrders.details_view.sun,
                      t.workOrders.details_view.mon,
                      t.workOrders.details_view.tue,
                      t.workOrders.details_view.wed,
                      t.workOrders.details_view.thu,
                      t.workOrders.details_view.fri,
                      t.workOrders.details_view.sat,
                    ]}
                    months={[
                      t.common.january,
                      t.common.february,
                      t.common.march,
                      t.common.april,
                      t.common.may,
                      t.common.june,
                      t.common.july,
                      t.common.august,
                      t.common.september,
                      t.common.october,
                      t.common.november,
                      t.common.december,
                    ]}
                    format="DD/MM/YYYY hh:mm a" // Date and Time format with AM/PM
                    enableTimePicker // Enable time selection
                    disableClock={false} // Optional: Include clock picker UI
                    disableCalendar={false} // Ensure calendar remains enabled
                  ></DatePicker>
                  <img
                    className="position-absolute"
                    style={
                      selectedLanguage == "en"
                        ? { top: "8px", right: "7px" }
                        : { top: "8px", left: "7px" }
                    }
                    src={calendar_icon}
                  />
                </div>
              </Form.Field>
            </GridColumn>
       

            <GridColumn className="pb-0">
              <Form.Field>
                <label className="label-item">
                  {t.workOrders.labor.duration}
                  <Asterisk color="#172B4D" />
                </label>
                <div className="position-relative">
                  <ReactTimePicker
                    //  onChange={(value)=>onValuesChange("duration",value)}
                    onChange={(value) => {
                     
                      if (value?.length > 0 ) {
                      

                        if (value == "00:00") {
                          setValidation({
                            ...validation,
                            duration: {
                              ...validation.duration,
                              error: true,
                              skipped: true,
                              message: t.common.required_field,
                            },
                          });
                        }else if(value?.split(':')[0] > 24){
                          setValidation({
                            ...validation,
                            duration: {
                              ...validation.duration,
                              error: true,
                              skipped: true,
                              message: "Invalid duration",
                            },
                          });
                        }else{
                          setValidation({
                            ...validation,
                            duration: {
                              ...validation.duration,
                              error: false,
                              error: false,
                           
                            },
                          });
                        }
                          setLaborData({
                            ...laborData,
                            duration: value, // Save as "HH:mm" format
                          });
                        
                    
                      } else {
                        
                        // setLaborData({
                        //   ...laborData,
                        //   duration: "",
                        // });

                        setValidation({
                          ...validation,
                          duration: {
                            ...validation.duration,
                            error: true,
                            skipped: true,
                            message: "Invalid duration",
                          },
                        });
                      }
                    }}
                    format="HH:mm"
                    clearIcon={false}
                    disableClock
                    className={"ui fluid input"}
                    autoFocus={false}
                    required
                    value={laborData?.duration}
                    minutePlaceholder="MM"
                    hourPlaceholder="HH"
                    
                  ></ReactTimePicker>
                  {/* <Input
                    autoComplete="new-password"
                    type="time"
                    fluid
                    error={
                      validation?.duration?.error &&
                      validation?.duration?.skipped
                    }
                    placeholder={t.workOrders.labor.duration}
                    className="without_ampm"
                    value={laborData?.duration}
                    onChange={onValuesChange("duration")}
                    onClick={(e) =>
                      e?.target?.showPicker && e.target.showPicker()
                    }
                  ></Input> */}

                  <img
                    className="mb-5 position-absolute"
                    style={
                      selectedLanguage == "en"
                        ? { top: "8px", right: "7px" }
                        : { top: "8px", left: "7px" }
                    }
                    src={clock_icon}
                  />
                </div>
                {validation.duration.error && validation.duration.skipped ? (
                  <div className="label-error">
                    {validation.duration.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field>
            </GridColumn>
          </Grid>

          <Grid className="mb-0 mt-0 mt-5" columns={2}>
            <GridColumn className="pt-0 pb-0">
              <Form.Field style={{ minHeight: "70px" }}>
                <label className="label-item">
                  {t.workOrders.filter.assignees}
                  <Asterisk color="#172B4D" />
                </label>
                <Dropdown
                  placeholder={t.workOrders.form.select_assignees}
                  fluid
                  selection
                  noResultsMessage={t.common.no_results_found}
                  search
                  error={
                    validation?.people?.error && validation?.people?.skipped
                  }
                  className="custom-dropdown-menu2"
                  options={options.people}
                  value={laborData.people}
                  onChange={onValuesChange("people")}
                  disabled={isEdit}
                  onOpen={fetchPeopleOptions}
                  onBlur={(e) => {
                    if (validation.people.pattern(laborData.people)) {
                      setValidation({
                        ...validation,
                        people: {
                          ...validation.people,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        people: {
                          ...validation.people,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />

                {validation.people.error && validation.people.skipped ? (
                  <div className="label-error">{validation.people.message}</div>
                ) : (
                  ""
                )}
              </Form.Field>
            </GridColumn>
            {/* {data?.includeDueTime ? ( */}
            <GridColumn className="pt-0">
              <Form.Field style={{ minHeight: "70px" }}>
                <label className="label-item">
                  {t.people.work_info_form.hour_rate}
                  {/* {values?.role == 6 ? null : <Asterisk color="#172B4D" />} */}
                </label>
                <Input
                  autoComplete="new-password"
                  type="number"
                  fluid
                  placeholder={t.people.personal_info_form.add_hr_rate}
                  value={laborData.hourRate}
                  onChange={(e) => {
                    let valueData = Number(e.target.value);
                    if (e.target.value) {
                      let hrRate = Number(valueData.toFixed(2));
                      setLaborData({
                        ...laborData,
                        hourRate: String(hrRate),
                      });
                    } else if (!e.target.value) {
                      setLaborData({
                        ...laborData,
                        hourRate: "",
                      });
                    } else {
                      setLaborData({
                        ...laborData,
                        hourRate: "",
                      });
                    }
                  }}
                >
                  <input />

                  <span
                    className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                      selectedLanguage == "en" ? "ml-5" : "mr-5"
                    }`}
                    // onClick={() => {
                    //   setOpenJobTitleAddModal(true);
                    // }}
                    style={{ backgroundColor: "#E9F2FF", color: "#0C66E4" }}
                  >
                    {organisationCurrency}
                  </span>
                  {/* <Label style={{ height: '100%' }}>
                    {organisationCurrency}
                  </Label> */}
                </Input>
              </Form.Field>
            </GridColumn>
          </Grid>

          <Grid className="mb-0 mt-0 mt-5" columns={2}>
            {/* {data?.includeDueTime ? ( */}
            <GridColumn className="pt-0">
              <Form.Field>
                <label className="label-item">
                  {t.workOrders.table.category}

                  {/* <Icon name="asterisk" color="red" size="small" /> */}
                </label>
                <Dropdown
                  fluid
                  selection
                  noResultsMessage={t.common.no_results_found}
                  search
                  // ////allowAdditions
                  maxHeight={50}
                  className="custom-dropdown-menu2"
                  upward
                  options={options.category}
                  value={laborData.category}
                  onChange={onValuesChange("category")}
                  // onOpen={fetchPeopleOptions}
                />
              </Form.Field>
            </GridColumn>
          </Grid>
        </div>
        {/* <Input
id="barcodePopup"
type="file"
fluid
//   value={selectedFile}
onChange={(e) => {
if (e.target.files && e.target.files.length) {
  setSelectedFile(e.target.files[0]);
}
}}
/>
<div className="buttun-type-link mt-4" basic onClick={handleDownload}>
{t.categories.download_sample}
</div> */}
      </Modal.Content>

      <div
        className={`modal-button-container ${
          selectedLanguage == "en" ? "mr-6" : "ml-10"
        }`}
      >
        <button
          onClick={() => {
            setaddLaborModal(false);
            setIsEdit(false);
            setValidation({
              people: {
                error: false,
                pattern: (code) => code != "",
                skipped: false,
                message: t.common.required_field,
              },
              duration: {
                error: false,
                pattern: (code) => code !== "" && code !== "00:00", // Return false if code is "" or "00:00"
                skipped: false,
                message: t.common.required_field,
              },
            });
            setLaborData({
              people: "",
              startDate: getLocalDateTime(organisationTimezone?.split("+")[1]),
              duration: "",
              hourRate: "",
              category: "Other Time",
            });
          }}
          className="modal-form-action-button"
        >
          {t.assets.form.cancel}
        </button>

        <button
          onClick={() => handleAssign()}
          className="modal-form-action-button light-blue"
        >
          {isEdit ? t.common.edit : t.common.add}
        </button>
      </div>
    </Modal>
  );
};

export default LaborModal;
