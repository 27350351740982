import { filter } from 'lodash';
import React, { useEffect, useState, createRef } from 'react';
import _ from 'lodash';
import {
  Checkbox,
  Table,
  Button,
  Header,
  Pagination,
  Label,
  Icon,
  Input,
  Dropdown,
  Grid,
  Menu,
  DropdownMenu,
  DropdownItem,
  Divider,
} from 'semantic-ui-react';
import {  useHistory } from 'react-router';
import location_icon from "../../../Assets/Icons/loc_option_parent.png";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";

import { toast } from 'react-toastify';
import moment from 'moment';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import calendar_icon from '../../../Assets/Icons/calender-icon.svg';
import LocationSearchDropdown from "../../components/WorkOrder/LocationSearchDropdown";
import {
  getAllLocations,
  getAllCustomers,
  getAllTeams,
  humanize,
  toggle,
  getAllParentLocations,
} from '../../config/functions';
import { selectTranslations } from '../../config/i18n/slice';

import { Country, State, City } from 'country-state-city';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionBtnIcon,
  DownloadIcon,
  PdfIcon,
  SearchIcon,
  ShareIcon,
  UploadIcon,
  WhiteDownChevron,
} from '../../../Assets/Icons/svg';
import ToolTip from "react-power-tooltip";
import CategoryDropdown from '../../components/common/CategoryDropdown';
import PriorityDropdown from '../../components/common/PriorityDropdown';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import DatePicker from 'react-multi-date-picker';
import DownloadExcelFileWithData from '../../../services/DownloadExcelFileWithData';
import DownloadPdfFileWithData from '../../../services/DownloadPdfFileWithData';
import { fetchAllVendorData, fetchVendorWithFilter } from '../../../redux/reducers/vendorReducer';
import { nanoid } from 'nanoid';

const Filters = ({
  data,
  setDataByFilter,
  hasStatus,
  fileData = [],
  setFilterOptions,
  setSearchTitle,
  searchTitle,
  debounceValue,
  setloader,
  setSelectedStartDates,
  selectedEndDates,
  selectedStartDates,
  setSelectedEndDates,
  filterOptions,
  loader,
  sortData,
  role,
  allIds
}) => {
  const t = useSelector(selectTranslations);
  const history = useHistory();
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const [showTooltip, setShowTooltip] = useState(false);
  let modeOptions = [
    {
      key: '1',
      value: 'cash',
      text: t.vendors.mode.cash,
    },
    {
      key: '2',
      value: 'credit card',
      text: t.vendors.mode.credit_card,
    },
    {
      key: '3',
      value: 'bank transfer',
      text: t.vendors.mode.bank,
    },
    {
      key: '4',
      value: 'procurement card',
      text: t.vendors.mode.procurement_card,
    },
    {
      key: '5',
      value: 'check',
      text: t.vendors.mode.check,
    },
    {
      key: '6',
      value: 'cliq',
      text: t.vendors.mode.cliq,
    },
  ];

  let accountTypeOption = [
    {
      key: '0',
      value: 'salaries account',
      text: t.vendors.account.salary,
    },
    {
      key: '1',
      value: 'basic bank account',
      text: t.vendors.account.basic,
    },
    {
      key: '2',
      value: 'time deposit account',
      text: t.vendors.account.time_deposit,
    },
    {
      key: '3',
      value: 'current account',
      text: t.vendors.account.current,
    },
    {
      key: '4',
      value: 'time deposit with prizes',
      text: t.vendors.account.time_deposit_price,
    },
    {
      key: '5',
      value: 'POS current account',
      text: t.vendors.account.pos,
    },
  ];


  const [filtersTrigger, setFiltersTrigger] = useState({
    countries: {
      initOptions: [],
      options: [],
    },
    status: {
      initOptions: [],
      options: [
        {
          key: 'status0',
          value: '',
          text: t.common.all,
        },
        {
          key: 'status1',
          value: 'active',
          text: t.assets.active,
        },
        {
          key: 'status2',
          value: 'inactive',
          text: t.assets.inActive,
        },
      ],
    },
    cities: {
      initOptions: [],
      options: [],
    },
    locations: {
      initOptions: [],
      options: [],
    },
    createdAt: {
      options: [
        { key: 0, text: t.vendors.created_at_options.any_date, value: 0 },
        { key: 1, text: t.vendors.created_at_options.today, value: 1 },
        { key: 2, text: t.vendors.created_at_options.this_month, value: 2 },
        { key: 3, text: t.vendors.created_at_options.this_year, value: 3 },
        { key: 4, text: t.vendors.created_at_options.last_7_days, value: 4 },
        { key: 5, text: t.vendors.created_at_options.last_month, value: 5 },
        { key: 7, text: t.vendors.created_at_options.last_year, value: 6 },
      ],
    },
    updatedAt: {
      options: [
        { key: 0, text: t.vendors.created_at_options.any_date, value: 0 },
        { key: 1, text: t.vendors.created_at_options.today, value: 1 },
        { key: 2, text: t.vendors.created_at_options.this_month, value: 2 },
        { key: 3, text: t.vendors.created_at_options.this_year, value: 3 },
        { key: 4, text: t.vendors.created_at_options.last_7_days, value: 4 },
        { key: 5, text: t.vendors.created_at_options.last_month, value: 5 },
        { key: 7, text: t.vendors.created_at_options.last_year, value: 6 },
      ],
    },
    dueDate: {
      options: [
        { key: 0, text: 'Any Date', value: 'any_day' },
        { key: 1, text: 'Today', value: 'today' },
        { key: 2, text: 'Tomorrow', value: 'tomorrow' },
        { key: 3, text: 'Next 7 Days', value: 'next_7_days' },
        { key: 4, text: 'Next 30 Days', value: 'next_30_days' },
        { key: 5, text: 'This Month', value: 'this_month' },
        { key: 6, text: 'This Quarter', value: 'this_quarter' },
        { key: 7, text: 'This Year', value: 'this_year' },
        { key: 8, text: 'Yesterday', value: 'yesterday' },
        { key: 9, text: 'Last Week', value: 'last_week' },
        { key: 10, text: 'Last Month', value: 'last_month' },
        { key: 11, text: 'Last Quarter', value: 'last_quarter' },
        { key: 12, text: 'Last Year', value: 'last_year' },
        { key: 13, text: 'Past Due', value: 'past_due' },
        { key: 14, text: 'Unscheduled', value: 'unscheduled' },
      ],
    },
  });
  const [openCreatedAt, setOpenCreatedAt] = useState(false);
  const [openUpdatedAt, setOpenUpdatedAt] = useState(false);
  // const [filterOptions, setFilterOptions] = useState({
  //   countries: '',
  //   locations: [],
  //   cities: [],
  //   createdAt: {
  //     value: 0,
  //     text: t.vendors.filter.created_at,
  //   },
  //   updatedAt: {
  //     value: 0,
  //     text: t.vendors.filter.updated_at,
  //   },
  //   dueDate: {
  //     value: 'any_day',
  //     text: 'Any Date',
  //   },
  // });

  const [filterSearch, setFilterSearch] = useState({
    country: '',
    city: '',
    location: '',
  });

  const handleSearchLocationChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      location: e.target.value,
    });
  };

  const dispatch = useDispatch();

  // const [searchTitle, setSearchTitle] = useState('');
  // const [selectedStartDates, setSelectedStartDates] = useState([]);
  // const [selectedEndDates, setSelectedEndDates] = useState([]);
  const [resetKey, setResetKey] = useState(nanoid());
  const [resetKeyEndDate, setResetKeyEndDate] = useState(nanoid());

  const [newData, setNewData] = useState(data);

  const [openCountry, setOpenCountry] = useState(false);
  const [openCity, setOpenCity] = useState(false);
  const [openDueDate, setOpenDueDate] = useState(false);

  const closeAllFilters = () => {
    setOpenCountry(false);
    setOpenCity(false);
    setOpenDueDate(false);
    setOpenCreatedAt(false);
    setOpenUpdatedAt(false);
  };

  const handleChange = (value) => {
    if (value && value.length) {
      const dateValues = value.map((date) => date.valueOf());

      const isoDates = dateValues.map((timestamp) =>
        timestamp ? new Date(timestamp).toISOString() : ''
      );
      setSelectedStartDates(isoDates);
    }
  };

  const handleEndDateChange = (value) => {
    if (value && value.length) {
      const dateValues = value.map((date) => date.valueOf());

      const isoDates = dateValues.map((timestamp) =>
        timestamp ? new Date(timestamp).toISOString() : ''
      );
      setSelectedEndDates(isoDates);
    }
  };

  const handleDeselect = () => {
    // Deselect by setting an empty array
    setSelectedStartDates([]);
    setResetKey(nanoid());
    // setResetKeyEndDate((pre) => !pre);
  };

  const handleEndDateDeselect = () => {
    // Deselect by setting an empty array
    setSelectedEndDates([]);
    setResetKeyEndDate(nanoid());
  };

  const fetchCountryOptions = () => {
    closeAllFilters();

    setOpenCountry(true);
    let the_options = [];

    if (filtersTrigger.countries.initOptions.length < 1) {
      const fetchData = Country.getAllCountries();
      if (fetchData) {
        the_options = fetchData.map((el) => {
          return {
            key: el.isoCode,
            value: el.isoCode,
            text: el.name,
          };
        });

        setFiltersTrigger({
          ...filtersTrigger,
          countries: {
            ...filtersTrigger.countries,
            initOptions: the_options,
            options: the_options,
          },
        });
      } else {
        setFiltersTrigger({
          ...filtersTrigger,
          countries: {
            ...filtersTrigger.countries,
          },
        });
      }
    }
  };

  const fetchCitiesOptions = () => {
    closeAllFilters();

    setOpenCity(true);
    let the_options = [];
    // console.log(filterOptions);
    if (filterOptions.countries) {
      const fetchData = State.getStatesOfCountry(filterOptions.countries);
      if (fetchData) {
        the_options = fetchData.map((el) => {
          return {
            key: el.isoCode,
            value: el.isoCode,
            text: el.name,
          };
        });

        setFiltersTrigger({
          ...filtersTrigger,
          cities: {
            ...filtersTrigger.cities,
            initOptions: the_options,
            options: the_options,
          },
        });
      } else {
        setFiltersTrigger({
          ...filtersTrigger,
          cities: {
            ...filtersTrigger.cities,
          },
        });
      }
    }
  };

  const handleSearchCountryChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      country: e.target.value,
    });
  };

  const handleSearchCityChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      city: e.target.value,
    });
  };

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.country), 'i');
    const isMatch = (result) => re.test(result.text);
    setFiltersTrigger({
      ...filtersTrigger,
      countries: {
        ...filtersTrigger.countries,
        options:
          filterSearch.country.length > 0
            ? _.filter(filtersTrigger.countries.initOptions, isMatch)
            : filtersTrigger.countries.initOptions,
      },
    });
    document.body.addEventListener('click', closeAllFilters);

    // handleRequestedByTypeChange(filtersTrigger.requestedByType);
  }, [filterSearch.country]);

  useEffect(() => {
    (async function() {
      // const fetchData = await getAllCustomers(organisationId);

      // let the_options_jobTitle = [];

      let the_options_location = [];

      const fetchDataLocation = await getAllParentLocations(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        ["name:asc"]
      );

      let the_options = [];
      let parentOptions = [];
      let subOptions = [];

      if (fetchDataLocation?.response?.length > 0) {
        fetchDataLocation.response.forEach((el) => {
          if (!(role=== "super_admin")) {
            // Check if the element's ID is included in allIds
            if (allIds?.includes(el.id)) {
              // Add parent option normally
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: false, // Parent option enabled
              });

              // Collect sub-options
              if (el?.attributes?.subLocations?.data?.length > 0) {
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
              }
            } else {
              // If the element's ID is not in allIds, check sub-locations
              const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
                (subEl) => allIds?.includes(subEl.id)
              );

              if (hasMatchingSubLocation) {
                // Add parent option as disabled
                parentOptions.push({
                  key: el.id,
                  value: el.id,
                  text: el.attributes.name,
                  image: { src: location_icon },
                  className: "main-location-dropdown-option",
                  disabled: true, // Disable the parent option
                });

                // Collect matching sub-options
                el?.attributes?.subLocations?.data?.length > 0 &&
                  el.attributes.subLocations.data.forEach((subEl) => {
                    if (allIds?.includes(subEl.id)) {
                      subOptions.push({
                        key: subEl.id,
                        value: subEl.id,
                        text: subEl.attributes.name,
                        image: { src: sub_location_icon },
                        className: "sub-location-dropdown-option",
                        parentId: el.id, // Keep track of the parent
                      });
                    }
                  });
              }
            }
          } else {
            // Add parent option
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              className: "main-location-dropdown-option",
              image: { src: location_icon },
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                subOptions.push({
                  key: subEl.id,
                  value: subEl.id,
                  text: subEl.attributes.name,
                  image: { src: sub_location_icon },
                  className: "sub-location-dropdown-option",
                  parentId: el.id, // Keep track of the parent
                });
              });
            }
          }
        });

        // Sort sub-options alphabetically
        subOptions.sort((a, b) => a.text.localeCompare(b.text));

        // Merge parent options with sorted sub-options
        parentOptions.forEach((parentOption) => {
          the_options.push(parentOption);
          // Add sorted sub-options belonging to this parent
          subOptions.forEach((subOption) => {
            if (subOption.parentId === parentOption.key) {
              the_options.push(subOption);
            }
          });
        });

        // Update the options state
        // setOptions((prev) => ({
        //   ...prev,
        //   locations: the_options,
        // }));

        // return the_options;
      }

      // const fetchJobTitleData = await getAllJobTitles(organisationId);

      // if (fetchJobTitleData) {
      //   fetchJobTitleData.map((el) => {
      //     the_options_jobTitle.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.attributes.title,
      //     });
      //   });
      // }

      setFiltersTrigger({
        ...filtersTrigger,
        locations: {
          ...filtersTrigger.locations,
          initOptions: the_options,
          options: the_options,
        },
      });

      // if (fetchData) {
      //   fetchData.forEach((el) => {
      //     the_options_customer.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.attributes.name,
      //       image: {
      //         avatar: true,
      //         src: el.attributes.logo.data
      //           ? IMAGES_URL +
      //             el.attributes.logo.data.attributes.formats.thumbnail.url
      //           : LetteredAvatarImageUrl(el.attributes.name + ' '),
      //         style: { width: '20px', height: '20px' },
      //       },
      //     });
      //   });
      // }

      // const fetchTeamsData = await getAllTeams(organisationId);

      // if (fetchTeamsData) {
      //   fetchTeamsData.map((el) => {
      //     the_options_teams.push({
      //       key: el.id,
      //       value: `${el.id}`,
      //       text: el.attributes.name,
      //       image: {
      //         avatar: true,
      //         src: LetteredAvatarImageUrl(`${el.attributes.name}`),
      //         style: { width: '16px', height: '16px' },
      //       },
      //     });
      //   });
      // }

      // getAllParentLocations,
      // const fetchDataLocation = await getAllParentLocations(organisationId);

      // if (fetchDataLocation) {
      //   fetchDataLocation.forEach((el) => {
      //     the_options_location.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: `${el.attributes.name}`,
      //     });
      //   });
      // }

      // const fetchDataPeople = await getUsersByRole(
      //   [
      //     'user',
      //     'supervisor',
      //     'view_only',
      //     'admin',
      //     'requester',
      //     'super_admin',
      //   ],
      //   organisationId
      // );

      // if (fetchDataPeople) {
      //   fetchDataPeople.map((el) => {
      //     the_options_user.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.firstName + ' ' + el.lastName,
      //       image: {
      //         avatar: true,
      //         src: el?.profileImage?.url
      //           ? IMAGES_URL + el?.profileImage?.url
      //           : LetteredAvatarImageUrl(el.firstName + ' ' + el.lastName),
      //         style: { width: '20px', height: '20px' },
      //       },
      //     });
      //   });
      // }
    })();
  }, []);

  let _newData = newData;
  useEffect(() => {
    (async function() {
      setloader(true);

      await dispatch(
        fetchVendorWithFilter({
          organisationId,
          searchName: debounceValue,
          page: '1',
          pageSize: '25',
          location: filterOptions.locations,
          startDate: selectedStartDates,
          endDate: selectedEndDates,
          sort: sortData,
          role,allIds
        })
      );

      setloader(false);
    })();
    // if (filterOptions.countries.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return (
    //       el?.attributes?.billingInformation?.country &&
    //       filterOptions.countries.includes(
    //         el.attributes.billingInformation?.country
    //       )
    //     );
    //   });

    // if (selectedStartDates.length == 2) {
    //   if (selectedStartDates[0] == selectedStartDates[1]) {
    //     _newData = _newData.filter((el) => {
    //       var toDate = moment(selectedStartDates[1]).format('YYYY-MM-DD') || '';
    //       var fromDate =
    //         moment(selectedStartDates[0]).format('YYYY-MM-DD') || '';
    //       var createdAt =
    //         moment(el.attributes.startDate).format('YYYY-MM-DD') || '';

    //       return createdAt >= fromDate && createdAt <= toDate;
    //     });
    //   } else {
    //     _newData = _newData.filter((el) => {
    //       var toDate = moment(selectedStartDates[1]).format('YYYY-MM-DD') || '';
    //       var fromDate =
    //         moment(selectedStartDates[0]).format('YYYY-MM-DD') || '';
    //       var createdAt =
    //         moment(el.attributes.startDate).format('YYYY-MM-DD') || '';

    //       return createdAt >= fromDate && createdAt <= toDate;
    //     });
    //   }
    // } else if (selectedStartDates.length == 1) {
    //   _newData = _newData.filter((el) => {
    //     var fromDate = moment(selectedStartDates[0]).format('YYYY-MM-DD') || '';
    //     return el.attributes.startDate >= fromDate;
    //   });
    // }

    // if (selectedEndDates.length == 2) {
    //   if (selectedEndDates[0] == selectedEndDates[1]) {
    //     _newData = _newData.filter((el) => {
    //       var toDate = moment(selectedEndDates[1]).format('YYYY-MM-DD') || '';
    //       var fromDate = moment(selectedEndDates[0]).format('YYYY-MM-DD') || '';
    //       var createdAt =
    //         moment(el.attributes.endDate).format('YYYY-MM-DD') || '';

    //       return createdAt >= fromDate && createdAt <= toDate;
    //     });
    //   } else {
    //     _newData = _newData.filter((el) => {
    //       var toDate = moment(selectedEndDates[1]).format('YYYY-MM-DD') || '';
    //       var fromDate = moment(selectedEndDates[0]).format('YYYY-MM-DD') || '';
    //       var createdAt =
    //         moment(el.attributes.endDate).format('YYYY-MM-DD') || '';

    //       return createdAt >= fromDate && createdAt <= toDate;
    //     });
    //   }
    // } else if (selectedEndDates.length == 1) {
    //   _newData = _newData.filter((el) => {
    //     var fromDate = moment(selectedEndDates[0]).format('YYYY-MM-DD') || '';
    //     return el.attributes.endDate >= fromDate;
    //   });
    // }

    // if (filterOptions.locations.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return filterOptions.locations.includes(
    //       `${el.attributes?.location?.data?.id}`
    //     );
    //   });

    // if (filterOptions.cities.length > 0)
    //   _newData = _newData.filter((el) => {
    //     return (
    //       el?.attributes?.billingInformation?.city &&
    //       filterOptions.cities.includes(el.attributes.billingInformation?.city)
    //     );
    //   });

    // if (filterOptions.createdAt.value > 0) {
    //   if (filterOptions.createdAt.value == 1) {
    //     _newData = _newData.filter((el) => {
    //       return moment().isSame(el.attributes.createdAt, 'd');
    //     });
    //     //console.log('today');
    //   }

    //   if (filterOptions.createdAt.value == 2) {
    //     _newData = _newData.filter((el) => {
    //       return moment().isSame(el.attributes.createdAt, 'month');
    //     });
    //     //console.log('This Month');
    //   }

    //   if (filterOptions.createdAt.value == 3) {
    //     _newData = _newData.filter((el) => {
    //       return moment().isSame(el.attributes.createdAt, 'year');
    //     });
    //     //console.log('This Year');
    //   }

    //   if (filterOptions.createdAt.value == 4) {
    //     _newData = _newData.filter((el) => {
    //       var toDate = moment().format('YYYY-MM-DD');
    //       var fromDate = moment()
    //         .subtract(7, 'days')
    //         .format('YYYY-MM-DD');

    //       return (
    //         el.attributes.createdAt >= fromDate &&
    //         el.attributes.createdAt <= toDate
    //       );
    //     });
    //     //console.log('last 7 days');
    //   }

    //   if (filterOptions.createdAt.value == 5) {
    //     _newData = _newData.filter((el) => {
    //       return moment()
    //         .subtract(1, 'months')
    //         .isSame(el.attributes.createdAt, 'month');
    //     });
    //     //console.log('Last month');
    //   }

    //   if (filterOptions.createdAt.value == 6) {
    //     _newData = _newData.filter((el) => {
    //       return moment()
    //         .subtract(1, 'years')
    //         .isSame(el.attributes.createdAt, 'year');
    //     });
    //     //console.log('Last year');
    //   }
    // }

    // if (filterOptions.updatedAt.value > 0) {
    //   if (filterOptions.updatedAt.value == 1) {
    //     _newData = _newData.filter((el) => {
    //       return moment().isSame(el.attributes.updatedAt, 'd');
    //     });
    //     //console.log('today');
    //   }

    //   if (filterOptions.updatedAt.value == 2) {
    //     _newData = _newData.filter((el) => {
    //       return moment().isSame(el.attributes.updatedAt, 'month');
    //     });
    //     //console.log('This Month');
    //   }

    //   if (filterOptions.updatedAt.value == 3) {
    //     _newData = _newData.filter((el) => {
    //       return moment().isSame(el.attributes.updatedAt, 'year');
    //     });
    //     //console.log('This Year');
    //   }

    //   if (filterOptions.updatedAt.value == 4) {
    //     _newData = _newData.filter((el) => {
    //       var toDate = moment().format('YYYY-MM-DD');
    //       var fromDate = moment()
    //         .subtract(7, 'days')
    //         .format('YYYY-MM-DD');

    //       return (
    //         el.attributes.updatedAt >= fromDate &&
    //         el.attributes.updatedAt <= toDate
    //       );
    //     });
    //     //console.log('last 7 days');
    //   }

    //   if (filterOptions.updatedAt.value == 5) {
    //     _newData = _newData.filter((el) => {
    //       return moment()
    //         .subtract(1, 'months')
    //         .isSame(el.attributes.updatedAt, 'month');
    //     });
    //     //console.log('Last month');
    //   }

    //   if (filterOptions.updatedAt.value == 6) {
    //     _newData = _newData.filter((el) => {
    //       return moment()
    //         .subtract(1, 'years')
    //         .isSame(el.attributes.updatedAt, 'year');
    //     });
    //     //console.log('Last year');
    //   }
    // }

    // if (searchTitle.length > 0) {
    //   const re = new RegExp(_.escapeRegExp(searchTitle), 'i');
    //   const isMatch = (result) => re.test(result.attributes.name);

    //   _newData =
    //     searchTitle.length > 0 ? _.filter(_newData, isMatch) : _newData;
    // }

    if (_newData.length > 0) {
      setDataByFilter(_newData);
    } else {
      setDataByFilter([]);
    }
  }, [filterOptions, debounceValue, selectedStartDates, selectedEndDates]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.location), 'i');
    // console.log(re, 'res');
    const isMatch = (result) => {
      let ss = re.test(result.text);
      // console.log(ss, ' i m ss');
    };

    // console.log(
    //   _.filter(filtersTrigger.customers.initOptions, isMatch),
    //   ' i m csds'
    // );

    let data = filtersTrigger.locations.initOptions.filter((test) =>
      re.test(test.text)
    );

    setFiltersTrigger({
      ...filtersTrigger,
      locations: {
        ...filtersTrigger.locations,
        options:
          filterSearch.location.length > 0
            ? data
            : filtersTrigger.locations.initOptions,
      },
    });
  }, [filterSearch.location]);

  const headers = [
    { label: t.assets.table.ID, key: 'id' },
    { label: t.vendors.table.name, key: 'name' },
    // { label: t.assets.form.description, key: 'description' },
    { label: t.customers.form.phone, key: 'phone' },
    { label: t.people.personal_info_form.email, key: 'email' },
    { label: t.people.work_info_form.locations, key: 'location' },
    { label: t.customers.form.service_type, key: 'service_type' },
    { label: t.vendors.form.start_date, key: 'start_date' },
    { label: t.vendors.form.end_date, key: 'end_date' },
    // { label: t.customers.form.website, key: 'website' },
    // { label: t.common.industry, key: 'industry' },
    // { label: t.vendors.form.credit_days, key: 'credit_days' },

    // { label: t.customers.contact_name, key: 'contact_name' },
    // { label: t.customers.contact_email, key: 'contact_email' },
    // { label: t.customers.contact_phone, key: 'contact_phone' },
    // { label: t.customers.billing_name, key: 'billing_name' },
    // { label: t.locations.form.country, key: 'country' },
    // { label: t.locations.form.city, key: 'city' },
    // { label: t.customers.form.postal_code, key: 'postal_code' },
    // { label: t.customers.form.address, key: 'address' },
    // { label: t.customers.form.rate, key: 'rate' },
    // { label: t.customers.form.currency, key: 'currency' },
    // { label: t.vendors.form.pre_days, key: 'pre_days' },
    // { label: t.vendors.form.mode, key: 'mode' },
    // { label: t.vendors.form.bank, key: 'bank' },
    // { label: t.vendors.form.account, key: 'account' },
    // { label: t.vendors.form.acc_no, key: 'acc_no' },
    // { label: t.vendors.form.IBAN, key: 'IBAN' },
    // {
    //   label: t.vendors.form.disbursement_currency,
    //   key: 'disbursement_currency',
    // },
    // { label: t.common.created_by, key: 'createdBy' },
  ];

  const downloadXLS = async() => {
    let csvData = [];

    const arraysNotEmpty = Object.values(filterOptions).some(
      (option) => Array.isArray(option) && option.length > 0
    );

    if (!arraysNotEmpty && searchTitle == '' &&  selectedEndDates.length == 0 &&  selectedStartDates.length == 0) {
      let mydata = await dispatch(fetchAllVendorData({organisationId,role, allIds }));

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }

    for (let i = 0; i < fileData.length; i++) {
      let customers_data = [];

      customers_data.id = fileData[i].attributes?.countId;
      customers_data.name = fileData[i].attributes.name || t.common.na;

      customers_data.description = fileData[i]?.attributes?.description
        ? fileData[i].attributes.description.replace(/\s+/g, ' ').trim()
        : t.common.na;

      customers_data.email = fileData[i]?.attributes?.email
        ? fileData[i].attributes?.email
        : t.common.na;

      customers_data.phone = fileData[i]?.attributes?.phone
        ? `+${fileData[i]?.attributes?.phone}`
        : t.common.na;

      customers_data.locations = fileData[i]?.attributes?.location?.data?.id
        ? fileData[i]?.attributes?.location?.data?.attributes?.name
        : t.common.na;

      customers_data.website = fileData[i]?.attributes?.website
        ? fileData[i].attributes?.website
        : t.common.na;

      customers_data.service_type = fileData[i]?.attributes?.serviceType
        ? fileData[i].attributes?.serviceType
        : t.common.na;

      customers_data.industry = fileData[i]?.attributes?.Industry
        ? fileData[i].attributes?.Industry
        : t.common.na;

      customers_data.credit_days = fileData[i]?.attributes?.creditDays
        ? fileData[i].attributes?.creditDays
        : t.common.na;

      customers_data.start_date = fileData[i]?.attributes?.startDate
        ? moment(fileData[i]?.attributes?.startDate).format('DD.MM.YYYY')
        : t.common.na;

      customers_data.end_date = fileData[i]?.attributes?.endDate
        ? moment(fileData[i]?.attributes?.endDate).format('DD.MM.YYYY')
        : t.common.na;
      customers_data.contact_name = fileData[i]?.attributes?.contactInformation
        ?.name
        ? fileData[i]?.attributes?.contactInformation?.name
        : t.common.na;

      customers_data.contact_email = fileData[i]?.attributes?.contactInformation
        ?.email
        ? fileData[i]?.attributes?.contactInformation?.email
        : t.common.na;

      customers_data.contact_phone = fileData[i]?.attributes?.contactInformation
        ?.phone
        ? `+${fileData[i]?.attributes?.contactInformation?.phone}`
        : t.common.na;

      customers_data.billing_name = fileData[i]?.attributes?.billingInformation
        ?.name
        ? fileData[i]?.attributes?.billingInformation?.name
        : t.common.na;

      customers_data.country = fileData[i]?.attributes?.billingInformation
        ?.country
        ? Country.getCountryByCode(
            fileData[i]?.attributes?.billingInformation?.country
          )?.name
        : t.common.na;

      customers_data.city = fileData[i]?.attributes?.billingInformation?.city
        ? State.getStateByCodeAndCountry(
            fileData[i]?.attributes?.billingInformation?.city,
            fileData[i]?.attributes?.billingInformation?.country
          )?.name
        : t.common.na;

      customers_data.postal_code = fileData[i]?.attributes?.billingInformation
        ?.postalCode
        ? fileData[i]?.attributes?.billingInformation?.postalCode
        : t.common.na;

      customers_data.address = fileData[i]?.attributes?.billingInformation
        ?.address
        ? fileData[i]?.attributes?.billingInformation?.address
        : t.common.na;

      customers_data.rate = fileData[i]?.attributes?.billingInformation?.rate
        ? fileData[i]?.attributes?.billingInformation?.rate +
          ' ' +
          (fileData[i]?.attributes?.billingInformation?.currency
            ? fileData[i]?.attributes?.billingInformation?.currency
            : '')
        : t.common.na;

      customers_data.currency = fileData[i]?.attributes?.billingInformation
        ?.currency
        ? fileData[i]?.attributes?.billingInformation?.currency
        : t.common.na;

      customers_data.pre_days = fileData[i]?.attributes?.billingInformation
        ?.preProcessDays
        ? fileData[i]?.attributes?.billingInformation?.preProcessDays
        : t.common.na;

      const mode = modeOptions.find(
        (status) =>
          status.value ==
          fileData[i]?.attributes?.billingInformation?.paymentDetails?.mode
      );

      customers_data.mode = fileData[i]?.attributes?.billingInformation
        ?.paymentDetails?.mode
        ? mode?.text
        : t.common.na;

      customers_data.bank = fileData[i]?.attributes?.billingInformation
        ?.paymentDetails?.bank
        ? fileData[i]?.attributes?.billingInformation.paymentDetails?.bank
        : t.common.na;

      const acc_type = accountTypeOption.find(
        (status) =>
          status.value ==
          fileData[i]?.attributes?.billingInformation?.paymentDetails
            ?.accountType
      );

      customers_data.account = fileData[i]?.attributes?.billingInformation
        ?.paymentDetails?.accountType
        ? acc_type?.text
        : t.common.na;

      customers_data.acc_no = fileData[i]?.attributes?.billingInformation
        ?.paymentDetails?.accountNo
        ? fileData[i]?.attributes?.billingInformation.paymentDetails?.accountNo
        : t.common.na;

      customers_data.IBAN = fileData[i]?.attributes?.billingInformation
        ?.paymentDetails?.IBAN
        ? fileData[i]?.attributes?.billingInformation.paymentDetails?.IBAN
        : t.common.na;

      customers_data.disbursement_currency = fileData[i]?.attributes
        .billingInformation?.paymentDetails?.disbursementCurrency
        ? fileData[i]?.attributes?.billingInformation.paymentDetails
            ?.disbursementCurrency
        : t.common.na;

      customers_data.createdBy = fileData[i]?.attributes?.createdByUser?.data
        ? fileData[i]?.attributes?.createdByUser.data?.attributes?.firstName +
          ' ' +
          fileData[i]?.attributes?.createdByUser?.data?.attributes?.lastName
        : t.common.na;

      csvData.push([
        customers_data.id,
        customers_data.name,
        // customers_data.description,
        customers_data.phone,
        customers_data.email,

        customers_data.locations,
        // customers_data.website,
        customers_data.service_type,

        // customers_data.industry,
        // customers_data.credit_days,
        customers_data.start_date,
        customers_data.end_date,

        // customers_data.contact_name,
        // customers_data.contact_email,
        // customers_data.contact_phone,

        // customers_data.billing_name,
        // customers_data.country,
        // customers_data.city,
        // customers_data.postal_code,

        // customers_data.address,
        // customers_data.rate,
        // customers_data.currency,
        // customers_data.pre_days,

        // customers_data.mode,
        // customers_data.bank,
        // customers_data.account,
        // customers_data.acc_no,
        // customers_data.IBAN,
        // customers_data.disbursement_currency,

        // customers_data.createdBy,
      ]);
    }
    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 25 }, // Updated At column

      { wch: 25 }, // Description column
      { wch: 10 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 25 }, // Updated At column

      { wch: 25 }, // Description column
      { wch: 15 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 25 }, // Updated At column
      { wch: 10 }, // Updated At column
      { wch: 10 }, // Updated At column
      { wch: 15 }, // Updated At column

      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 15 }, // Updated At column
      { wch: 15 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
    ];
    DownloadExcelFileWithData(headers, csvData, csvColumnWidths, 'Vendors');
  };

  const generatePDF = () => {
    let csvData = [];

    for (let i = 0; i < fileData.length; i++) {
      let customers_data = [];

      customers_data.id = fileData[i].attributes?.countId;
      customers_data.name = fileData[i].attributes.name || t.common.na;

      customers_data.description = fileData[i]?.attributes?.description
        ? fileData[i].attributes.description.replace(/\s+/g, ' ').trim()
        : t.common.na;

      customers_data.email = fileData[i]?.attributes?.email
        ? fileData[i].attributes?.email
        : t.common.na;

      customers_data.phone = fileData[i]?.attributes?.phone
        ? `+${fileData[i]?.attributes?.phone}`
        : t.common.na;

      customers_data.locations = fileData[i]?.attributes?.location?.data?.id
        ? fileData[i]?.attributes?.location?.data?.attributes?.name
        : t.common.na;

      customers_data.website = fileData[i]?.attributes?.website
        ? fileData[i].attributes?.website
        : t.common.na;

      customers_data.service_type = fileData[i]?.attributes?.serviceType
        ? fileData[i].attributes?.serviceType
        : t.common.na;

      customers_data.industry = fileData[i]?.attributes?.Industry
        ? fileData[i].attributes?.Industry
        : t.common.na;

      customers_data.credit_days = fileData[i]?.attributes?.creditDays
        ? fileData[i].attributes?.creditDays
        : t.common.na;

      customers_data.start_date = fileData[i]?.attributes?.startDate
        ? moment(fileData[i]?.attributes?.startDate).format('DD.MM.YYYY')
        : t.common.na;

      customers_data.end_date = fileData[i]?.attributes?.endDate
        ? moment(fileData[i]?.attributes?.endDate).format('DD.MM.YYYY')
        : t.common.na;
      customers_data.contact_name = fileData[i]?.attributes?.contactInformation
        ?.name
        ? fileData[i]?.attributes?.contactInformation?.name
        : t.common.na;

      customers_data.contact_email = fileData[i]?.attributes?.contactInformation
        ?.email
        ? fileData[i]?.attributes?.contactInformation?.email
        : t.common.na;

      customers_data.contact_phone = fileData[i]?.attributes?.contactInformation
        ?.phone
        ? `+${fileData[i]?.attributes?.contactInformation?.phone}`
        : t.common.na;

      customers_data.billing_name = fileData[i]?.attributes?.billingInformation
        ?.name
        ? fileData[i]?.attributes?.billingInformation?.name
        : t.common.na;

      customers_data.country = fileData[i]?.attributes?.billingInformation
        ?.country
        ? Country.getCountryByCode(
            fileData[i]?.attributes?.billingInformation?.country
          )?.name
        : t.common.na;

      customers_data.city = fileData[i]?.attributes?.billingInformation?.city
        ? State.getStateByCodeAndCountry(
            fileData[i]?.attributes?.billingInformation?.city,
            fileData[i]?.attributes?.billingInformation?.country
          )?.name
        : t.common.na;

      customers_data.postal_code = fileData[i]?.attributes?.billingInformation
        ?.postalCode
        ? fileData[i]?.attributes?.billingInformation?.postalCode
        : t.common.na;

      customers_data.address = fileData[i]?.attributes?.billingInformation
        ?.address
        ? fileData[i]?.attributes?.billingInformation?.address
        : t.common.na;

      customers_data.rate = fileData[i]?.attributes?.billingInformation?.rate
        ? fileData[i]?.attributes?.billingInformation?.rate +
          ' ' +
          (fileData[i]?.attributes?.billingInformation?.currency
            ? fileData[i]?.attributes?.billingInformation?.currency
            : '')
        : t.common.na;

      customers_data.currency = fileData[i]?.attributes?.billingInformation
        ?.currency
        ? fileData[i]?.attributes?.billingInformation?.currency
        : t.common.na;

      customers_data.pre_days = fileData[i]?.attributes?.billingInformation
        ?.preProcessDays
        ? fileData[i]?.attributes?.billingInformation?.preProcessDays
        : t.common.na;

      const mode = modeOptions.find(
        (status) =>
          status.value ==
          fileData[i]?.attributes?.billingInformation?.paymentDetails?.mode
      );

      customers_data.mode = fileData[i]?.attributes?.billingInformation
        ?.paymentDetails?.mode
        ? mode?.text
        : t.common.na;

      customers_data.bank = fileData[i]?.attributes?.billingInformation
        ?.paymentDetails?.bank
        ? fileData[i]?.attributes?.billingInformation.paymentDetails?.bank
        : t.common.na;

      const acc_type = accountTypeOption.find(
        (status) =>
          status.value ==
          fileData[i]?.attributes?.billingInformation?.paymentDetails
            ?.accountType
      );

      customers_data.account = fileData[i]?.attributes?.billingInformation
        ?.paymentDetails?.accountType
        ? acc_type?.text
        : t.common.na;

      customers_data.acc_no = fileData[i]?.attributes?.billingInformation
        ?.paymentDetails?.accountNo
        ? fileData[i]?.attributes?.billingInformation.paymentDetails?.accountNo
        : t.common.na;

      customers_data.IBAN = fileData[i]?.attributes?.billingInformation
        ?.paymentDetails?.IBAN
        ? fileData[i]?.attributes?.billingInformation.paymentDetails?.IBAN
        : t.common.na;

      customers_data.disbursement_currency = fileData[i]?.attributes
        .billingInformation?.paymentDetails?.disbursementCurrency
        ? fileData[i]?.attributes?.billingInformation.paymentDetails
            ?.disbursementCurrency
        : t.common.na;

      customers_data.createdBy = fileData[i]?.attributes?.createdByUser?.data
        ? fileData[i]?.attributes?.createdByUser.data?.attributes?.firstName +
          ' ' +
          fileData[i]?.attributes?.createdByUser?.data?.attributes?.lastName
        : t.common.na;

      csvData.push([
        customers_data.id,
        customers_data.name,
        // customers_data.description,
        customers_data.phone,
        customers_data.email,

        customers_data.locations,
        // customers_data.website,
        customers_data.service_type,

        // customers_data.industry,
        // customers_data.credit_days,
        customers_data.start_date,
        customers_data.end_date,

        // customers_data.contact_name,
        // customers_data.contact_email,
        // customers_data.contact_phone,

        // customers_data.billing_name,
        // customers_data.country,
        // customers_data.city,
        // customers_data.postal_code,

        // customers_data.address,
        // customers_data.rate,
        // customers_data.currency,
        // customers_data.pre_days,

        // customers_data.mode,
        // customers_data.bank,
        // customers_data.account,
        // customers_data.acc_no,
        // customers_data.IBAN,
        // customers_data.disbursement_currency,

        // customers_data.createdBy,
      ]);
    }
    const columnWidths = [, 30, 30];

    // const columnWidths = [
    //   10,
    //   20,
    //   ,
    //   20,
    //   50,
    //   20,
    //   22,
    //   15,
    //   20,
    //   ,
    //   ,
    //   25,
    //   ,
    //   ,
    //   ,
    //   45,
    //   20,
    // ];
    DownloadPdfFileWithData(headers, csvData, 'Vendors', columnWidths, 'A3');
  };

  return (
    <>
       <div className="table-header-dashbaord team-filter filters">
      <Button.Group
              className="add-people-btn"
              basic
              fluid
              style={{ width: "26%" }}
              size="medium"
            >
              <Button
                // active={activePane == 0}
                // onClick={() => changeTab(0)}
                type="button"
                onClick={() => history.push("/customers")}
              >
                {t.customers.customer}
              </Button>

              <Button
                // className="add-people-tab"
                active={true}
                
                type="button"
               
              >
                {t.customers.vendor}
              </Button>

              {/* <Button
              active={activePane == 2}
              onClick={() => {
                // setFieldValue('tab', 2).then((errors) => {
                //   changeTab(2, errors, setFieldTouched, values);
                //   if (errors) {
                //     scrollToError();
                //   }
                // });
              }}
              type="button"
            >
              {t.people.attachments_form.attachment_title}
            </Button> */}
            </Button.Group>

            <Divider  style={{ width: "25%" }} className="mt-0 mb-8" />
      </div>
      {data?.length > 0 ? (
        <div className="filters-wrapper justify-content-between">
          <div className="flexbox table-right-dropdown">
            {/* countries */}
            <LocationSearchDropdown
            title={t.workOrders.table.location}
            options={filtersTrigger.locations.options}
            selectedValues={filterOptions.locations}
            searchValue={filterSearch.location}
            handleSearchValue={handleSearchLocationChange}
            onClick={(value) => {
              let finalValue = [value.value];
              if (!value?.parentId) {
                let subData = filtersTrigger.locations.initOptions.filter(
                  (el) => el?.parentId === value.value
                );

                let subDataValues = subData?.map((item) => item?.value);
                let subValues = subData?.map((item) => item?.value);

                subDataValues.push(value.value);
                let filterOptionsValues = filterOptions.locations;

                let filteredSubDataValues = subDataValues.filter((value) =>
                  filterOptionsValues.includes(value)
                );

                let valuesNotInFilterOptions = subValues.filter(
                  (value) => !filterOptionsValues.includes(value)
                );
                let valuesInFilterOptions = subValues.filter((value) =>
                  filterOptionsValues.includes(value)
                );

                if (filteredSubDataValues?.length > 0) {
                  if (
                    subValues.every((value) =>
                      filterOptionsValues.includes(value)
                    ) &&
                    !filterOptionsValues.includes(value.value)
                  ) {
                    finalValue = [value.value];
                  } else if (
                    valuesNotInFilterOptions?.length > 0 &&
                    filterOptionsValues?.includes(value.value)
                  ) {
                    finalValue = [...valuesInFilterOptions, value.value];
                  } else if (valuesNotInFilterOptions?.length > 0) {
                    finalValue = [...valuesNotInFilterOptions, value.value];
                  } else {
                    finalValue = [...filteredSubDataValues];
                  }
                } else {
                  finalValue = [...subDataValues];
                }
              }

              let newArray = toggle(filterOptions.locations, finalValue);
              setFilterOptions({
                ...filterOptions,
                locations: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                location: "",
              });
            }}
          />

            <div className="wo-date-picker" key={resetKey}>
              <DatePicker
                style={
                  selectedStartDates.length <= 1
                    ? { width: '154px' }
                    : { width: '181px' }
                }
                value={selectedStartDates}
                onChange={handleChange}
                range
                showMonthDropdown
                showYearDropdown
                placeholder={t.vendors.form.start_dt}
                className={'active'}
                format="DD/MM/YY"
                // locale={lang == 'ar' ? arabic_ar : ''}
                weekDays={[
                  t.workOrders.details_view.sun,
                  t.workOrders.details_view.mon,
                  t.workOrders.details_view.tue,
                  t.workOrders.details_view.wed,
                  t.workOrders.details_view.thu,
                  t.workOrders.details_view.fri,
                  t.workOrders.details_view.sat,
                ]}
                months={[
                  t.common.january,
                  t.common.february,
                  t.common.march,
                  t.common.april,
                  t.common.may,
                  t.common.june,
                  t.common.july,
                  t.common.august,
                  t.common.september,
                  t.common.october,
                  t.common.november,
                  t.common.december,
                ]}
              >
                <button
                  onClick={handleDeselect}
                  className="btn-datepicker blue"
                >
                  {t.assets.reset}
                </button>
              </DatePicker>
              <img
                className="date-picker-image procedure"
                style={selectedLanguage == "en" ? { left: '-31px', top: '-2px' }:{top: '2px'}}
                src={calendar_icon}
              />
            </div>

            <div
              className="wo-date-picker"
              style={
                selectedLanguage == 'en'
                  ? { marginLeft: '-25px' }
                  : { marginRight: '-25px' }
              }
              key={resetKeyEndDate}
            >
              <DatePicker
                style={
                  selectedEndDates.length <= 1
                    ? { width: '154px' }
                    : { width: '181px' }
                }
                value={selectedEndDates}
                onChange={handleEndDateChange}
                range
                showMonthDropdown
                showYearDropdown
                placeholder={t.vendors.form.end_dt}
                className={'active'}
                format="DD/MM/YY"
                // locale={lang == 'ar' ? arabic_ar : ''}
                weekDays={[
                  t.workOrders.details_view.sun,
                  t.workOrders.details_view.mon,
                  t.workOrders.details_view.tue,
                  t.workOrders.details_view.wed,
                  t.workOrders.details_view.thu,
                  t.workOrders.details_view.fri,
                  t.workOrders.details_view.sat,
                ]}
                months={[
                  t.common.january,
                  t.common.february,
                  t.common.march,
                  t.common.april,
                  t.common.may,
                  t.common.june,
                  t.common.july,
                  t.common.august,
                  t.common.september,
                  t.common.october,
                  t.common.november,
                  t.common.december,
                ]}
              >
                <button
                  onClick={handleEndDateDeselect}
                  className="btn-datepicker blue"
                >
                  {t.assets.reset}
                </button>
              </DatePicker>
              <img
                className="date-picker-image procedure"
                style={selectedLanguage == "en" ? { left: '-31px', top: '-2px' }:{top: '2px'}}
                src={calendar_icon}
              />
            </div>
            <div
              style={
                selectedLanguage == 'en'
                  ? { marginLeft: '-24px' }
                  : { marginRight: '-24px' }
              }
            >
              <PriorityDropdown
                title={t.assets.table.status}
                options={filtersTrigger.status.options}
                selectedValues={filterOptions.status}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions({
                      ...filterOptions,
                      status: e.target.value ? [e.target.value] : [],
                    });
                  } else {
                    setFilterOptions({
                      ...filterOptions,
                      status: [],
                    });
                  }
                }}
                icon={false}
                image={false}
                pill={false}
              />


            </div>
            {selectedEndDates?.length > 0 || selectedStartDates?.length > 0 ||
          Object.values(filterOptions).some(option => option?.length > 0) ?
          <div className="form-button-container mt-3">
            <button
              // content={t.procedures.form.submit.add}
              // primary
              onClick={() => {
                
                setSelectedEndDates([]);
                setSelectedStartDates([]);
                  setFilterOptions({
                      
    locations: [],
    cities: [],
                  });
                
             
              }}
              // loading={buttonStatus == 1}
              style={{ marginTop: "-10px" }}
              className="form-action-button dashboard"
            >
              {t.dashboard.cards.clear}
            </button>
          </div> : null}
          </div>
          <div className="d-flex" style={{gap:"10px"}}>
          <div className="table-searchbar work-order-upload">
        <span className="search-svg">
                <SearchIcon />
              </span>
              <Input
                icon={{ name: 'search' }}
                placeholder={t.locations.filter.search}
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
              />
        </div>
          <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 ">
            <UncontrolledDropdown className="mr-2" direction="down">
            <DropdownToggle
              style={{ position: "relative", cursor: "pointer" }}
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              color="primary"
              className="option-toggle"
            >
              <ToolTip
                color="#fff"
                backgroundColor="#172B4D"
                position={selectedLanguage == "ar" ? "top right" : "top left"}
                show={showTooltip}
                textBoxWidth={selectedLanguage == "ar" ? "53px" : "87px"}
                arrowAlign={selectedLanguage == "ar" ? "center" : "start"}
                fontSize="12px"
                fontWeight="400"
                fontFamily={
                  selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                }
                padding="4px 4px 4px 8px"
                borderRadius="5px"
                static
                moveRight="10px"
                moveUp={selectedLanguage == "ar" ? "-1px" : "4px"}
              >
                <span className="work-order-tooltip">
                  {t.common.page_action}
                </span>
              </ToolTip>
              <ActionBtnIcon />{" "}
            </DropdownToggle>
              <DropdownMenu className={selectedLanguage == 'ar' ? 'rtl asset' : ''}>
                {/* {role !== 'view_only' ? (
                  <>
                    <DropdownItem>
                      <ShareIcon /> {t.common.share}
                    </DropdownItem>
                    <DropdownItem className="divider" />
                  </>
                ) : null} */}

                <DropdownItem onClick={() => downloadXLS()}>
                  <DownloadIcon /> {t.common.download_sheet}
                </DropdownItem>
                <DropdownItem className="divider" />
                {/* <DropdownItem onClick={() => generatePDF()}>
                  <PdfIcon /> {t.common.download_pdf}
                </DropdownItem>
                <DropdownItem className="divider" /> */}
                {/* <DropdownItem
                // onClick={() => setAssetUploadModal(true)}
                >
                  <UploadIcon /> {t.common.upload}
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Filters;
