export default {
  title: "المواقع",
  title_small: "مواقع",
  create_location: "إنشاء الموقع",
  edit_location: "تعديل موقع",
  edit_sublocation: "تعديل موقع فرعي",
  tabs: {
    first: "عرض القائمة",
    second: "عرض الخريطة",
  },
  add: "موقع",
  filter: {
    search: "بحث",
    customers: "العملاء",
    countries: "البلدان",
    cities: "المدن",
    createdByUser: "أنشىء من قبل",
    createdAt: "أنشىئ في",
    search_by_country: "البحث حسب البلد",
    search_by_cities: "البحث حسب المدن",
    selected: "محدد",
    select_people: "اختر الافراد",
  },

  sheet: {
    name: "اسم الموقع *",
    parent_loc: "اسم الموقع الرئيسي",
    address: "العنوان *",
    area: "المنطقة",
    property_type: "نوع العقار",
  },
  sheet2: {
    name: "Location Name*",
    parent_loc: "Parent Location Name",
    address: "Address*",
    area: "Area",
    property_type: "Property Type",
  },
  upload: {
    location: "تحميل قائمة المواقع",
    location_sheet: "تحميل قائمة المواقع",
    download: "تحميل نموذج قائمة المواقع",
    file: "نموذج المواقع.xlsx",
    imported_location: "المواقع المحملة",
    	

  },

  created_at_options: {
    any_date: "أي تاريخ",
    today: "اليوم",
    this_month: "هذا الشهر",
    this_year: "هذا العام",
    last_7_days: "٧ ايام الماضية",
    last_month: "الشهر الماضي",
    last_year: "العام الماضي",
  },
  store: "مخزن",
  propertyType: {
    officeBuildings: "مباني المكاتب",
    industrialFacilities: "المنشآت الصناعية",
    retailSpaces: "منشآت البيع بالتجزئة",
    educationalInstitutions: "المؤسسات التعليمية",
    healthcareFacilities: "خدمات الرعاية الصحية",
    residentialBuildings: "المباني السكنية",
    hotelsAndHospitality: "الفنادق والضيافة",
    governmentBuildings: "المباني الحكومية",
    dataCenters: "مراكز البيانات",
    sportsAndRecreationFacilities: "المرافق الرياضية والترفيهية",
    culturalInstitutions: "المؤسسات الثقافية",
    transportationHubs: "مراكز النقل",
    laboratories: "المختبرات",
    agriculturalFacilities: "المرافق الزراعية",
    correctionalFacilities: "المرافق الإصلاحية",
    utilitiesAndEnergyFacilities: "مرافق ومنشآت الطاقة",
    commercialParkingStructures: "مواقف السيارات التجارية",
    mixedUseDevelopments: "منشآت متعددة الاستخدام",
    telecommunicationFacilities: "مرافق الاتصالات",
    financialInstitutions: "المؤسسات المالية",
    governmentHousing: "الإسكان الحكومي",
    religiousBuildings: "المباني الدينية",
    shoppingCenters: "مراكز التسوق",
    technologyAndInnovationHubs: "مراكز التكنولوجيا والابتكار",
    entertainmentVenues: "أماكن الترفيه",
  },
  table: {
    name: "الإسم",
    ID: "الرمز",
    address: "العنوان",
    country: "الدولة",
    city: "المدينة",
    customer: "العميل",
    createdByUser: "أنشىء من قبل",
    createdAt: "أنشىئ في",
    updatedAt: "تم التحديث من قبل",
    action: "إجراء",
  },
  no_location_statement: "يبدو أنك ليس لديك مواقع بعد",
  add_location_statement: "اضغط على زر '+' لإضافة أول موقع لديك",
  form: {
    address_placeholder: "بحث العنوان على خرائط جوجل",
    propertyType: "نوع العقار",
    country_add: "أضف الدولة",
    city_add: "أضف المدينة",
    select_propertyType: "اختر نوع العقار",
    add_location: "إضافة موقع",
    edit_location: "تحرير الموقع",
    add_sub_location: "إضافة موقع فرعي",
    parent_location: "الموقع الأساسي",
    location_name: "اسم الموقع",
    sub_location_name: "اسم الموقع الفرعي",
    enter_location_name: "أضف اسم الموقع",
    enter_sub_location_name: "أضف اسم الموقع الفرعي",
    address: "العنوان",
    is_sub_location: "هل الموقع الفرعي؟",
    country: "الدولة",
    city: "المدينة",
    people: "الأفراد",
    select_users: "اختر الأفراد",
    teams: "الفرق",
    select_teams: "اختر فرق العمل",
    customer: "العميل",
    select_customer: "اختر العميل",
    vendors: "المورد",
    select_vendors: "اختر المورد",
    procedure: "اللإجراءات المتبعة",
    select_procedure: "اختر اللإجراء",
    custom_data: "إضافة بيانات مخصصة",
    label: "الملصق",
    field_label: "تسمية الملصق",
    value: "القيمة",
    field_value: "قيمة الحقل",
    remove: "Remove",
    submit: "تقديم",
    cancel: "إلغاء",
    choose_from_existing_location: "اختيار من المواقع الحالية",
    details: "التفاصيل",
    map: "الخريطة",
    assignees: "المكلفين",
    sub_locations: "الموقع الفرعي",
    sub_location: "موقع فرعي",
    work_orders: "أمر العمل",
    qr_code: "رمز الاستجابة السريعة",
    map_location: "موقع الخريطة",
    geo_markers: "علامات الحدود الجغرافية",
    // assign_data_statement:
    //   "لم تقم بضبط بيانات على الخريطة بعد، انقر على "إضافة" الآن",
    // assign_geo_data_statement:
    //   "لم تقم بضبط احداثيات الحدود الجغرافية بعد،انقر على "إضافة" الآن",
  },
  work_order_fields: {
    title: "العنوان",
    ID: "الرمز",
    status: "الحالة",
    priority: "الأولوية",
    category: "الفئة",
    due_date: "تاريخ الاستحقاق",
    procedure: "اللإجراء",
  },
  work_order_status: {
    open: "متوفر",
    inProgress: "قيد التنفيذ",
    onHold: "قيد الانتظار",
    inReview: "قيد المراجعة",
    completed: "تم الإنجاز",
  },
  priority_status: {
    none: "لا يوجد",
    low: "منخفض",
    medium: "متوسط",
    high: "مرتفع",
  },
  work_order_category: {
    Damage: "ضرر",
    Electrical: "الكهرباء",
    Inspection: "تفتيش",
    Mechanical: "ميكانيكي",
    Preventive: "وقائي",
    Project: "مشروع",
    Refrigeration: "تبريد",
    Safety: "أمان",
    Sop: "إجراءات التشغيل القياسية",
  },
  due_date_status: {
    any_date: "أي تاريخ",
    today: "اليوم",
    tomorrow: "غدا",
    next_7_day: "الأيام السبعة التالية",
    next_30_day: "ال ٣٠ يوما القادمة",
    this_month: "هذا الشهر",
    this_year: "هذا العام",
    yesterday: "بالأمس",
    last_week: "الأسبوع الماضي",
    last_month: "الأسبوع الماضي",
    last_year: "العام الماضي",
    past_due: "ما بعد الموعد المقرر",
  },
  location: "موقع",
  add_on_map: "إضافة على الخريطة",
  update_on_map: "تحديث على الخريطة",
  add_location_on_map_statement: "هل تود إضافة الموقع على الخريطة؟",
  add_location_on_map: "إضافة الموقع على الخريطة",
  update_location_on_map: "تحديث الموقع على الخريطة",
  drag_marker_statement: "اسحب العلامة أو أدخل خطوط الطول والعرض أدناه:",
  location_added_on_map_success: "تم إضافة الموقع على الخريطة بنجاح",
  add_geo_coord_statement: "هل تود إضافة إحداثيات الجيوفنسينج على الخريطة؟",
  add_geo_coord: "إضافة إحداثيات الجيوفنسينج",
  click_to_add_coord: "انقر على الخريطة لإضافة الإحداثيات",
  geo_add_success: "تم إضافة الحدود الجغرافية على الخريطة بنجاح",
  update_geo_coord: "تحديث إحداثيات االحدود الجغرافية",
  latitude: "خط العرض",
  longitude: "خط الطول",
  latitude_add: "أضف خط العرض",
  longitude_add: "أضف خط الطول",
  location_updated_success: "تم تحديث الموقع بنجاح",
  sub_location_updated_success: "تم تحديث الموقع الفرعي بنجاح",
  location_map_view: "المواقع - عرض الخريطة",
  no_location_added: "ابدأ بإضافة الموقع",
  delete_location_statement: "هل أنت متأكد أنك تريد حذف هذا الموقع؟",
  delete_sublocation_statement: "هل أنت متأكد أنك تريد حذف هذا الموقع الفرعي؟",
  related_location_error: "لا يمكن حذف الموقع. مرتبط بأشكال أخرى",
  delete_location_success: "تم حذف الموقع بنجاح",
  delete_sublocation_success: "تم حذف الموقع الفرعي بنجاح",
  add_sub: "الموقع الفرعي",
  how_to_add_locations: "كيفية إضافة الموقع",
  sub_location_tooltip: "الموقع الفرعي",
  location_map: "يرجى تحديد الموقع من الخريطة",
  loc_unique: "يجب أن يكون اسم الموقع فريدًا",
  sub_unique: "يجب أن يكون اسم الموقع الفرعي فريدًا",
};
