import React, { useEffect, useState } from "react";
import {
  BlackClockIcon,
  BlackCostIcon,
  CloseIcon,
  CustomerPlusIcon,
  OptionIcon,
} from "../../../../../Assets/Icons/svg";
import { useDeletePermissionModal } from "../../../../../hooks/Messages/useDeletePermissionModal";

import { useSelector } from "react-redux";
import { selectTranslations } from "../../../../config/i18n/slice";
import {
  Dimmer,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  Grid,
  GridColumn,
  Image,
  Input,
  Loader,
  Modal,
} from "semantic-ui-react";
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from "../../../../components/common/userProfilePlaceholder";
import useDisplayFormattedText from "../../../../../hooks/useDisplayFormattedText";
import {
  addLaborTime,
  deleteLaborTabData,
  editLaborTime,
  getActiveUsersByRole,
  getLaborTabData,
  humanize,
  IMAGES_URL,
} from "../../../../config/functions";
import Asterisk from "../../../../components/common/Asterisk";

import { toast } from "react-toastify";
import { scrollToError } from "../../../../../utilFunctions/scrollToError";

import { format } from "date-fns";
import moment, { duration } from "moment";
import LaborModal from "./laborModal";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";

const Labor = ({
  modalWidth,
  data,
  setSelectedDates,
  setActiveIndex,
  setModalWidth,
  untoggle,
  setSearchTitle,
  setFilterOptions,
}) => {
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();
  const organisationTimezone = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.timezone;
    } else {
      return null;
    }
  });

  const getLocalDateTime = (offset) => {
    const now = new Date(); // Get the current time in local timezone
    const utcMilliseconds = now.getTime() + now.getTimezoneOffset() * 60000; // Convert local time to UTC
    const localMilliseconds = utcMilliseconds + offset * 3600000; // Add timezone offset in milliseconds

    const localDate = new Date(localMilliseconds);

    return format(localDate, "yyyy-MM-dd hh:mm a");
  };

  const roleData = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });
  const { DisplayFormattedText } = useDisplayFormattedText();
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const [addLaborModal, setaddLaborModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [validation, setValidation] = useState({
    people: {
      error: false,
      pattern: (code) => code != "",
      skipped: false,
      message: t.common.required_field,
    },
    duration: {
      error: false,
      pattern: (code) => code !== "" && code !== "00:00", // Return false if code is "" or "00:00"
      skipped: false,
      message: t.common.required_field,
    },
  });

  const [loading, setLoading] = useState(true);
  const [logData, setLogData] = useState();

  const [options, setOptions] = useState({
    people: [],
    category: [
      {
        key: 0,
        value: "Drive Time",
        text: t.workOrders.labor.drive_time,
      },

      {
        key: 1,
        value: "Preparation Time",
        text: t.workOrders.labor.preparation_time,
      },
      {
        key: 2,
        value: "Inspection Time",
        text: t.workOrders.labor.inspection_time,
      },
      {
        key: 3,
        value: "Testing and Verification Time",
        text: t.workOrders.labor.testing_time,
      },
      {
        key: 4,
        value: "Downtime/Waiting Time",
        text: t.workOrders.labor.downtime_time,
      },
      {
        key: 6,
        value: "Wrench Time",
        text: t.workOrders.labor.wrench_time,
      },
      {
        key: 7,
        value: "Other Time",
        text: t.workOrders.labor.other_time,
      },
    ],
  });

  const [laborData, setLaborData] = useState({
    people: "",
    startDate: getLocalDateTime(organisationTimezone?.split("+")[1]),
    duration: "",
    hourRate: "",
    category: "Other Time",
  });

  const fetchData = async () => {
    setLoading(true);
    const fetchData = await getLaborTabData(data?.id);
    if (fetchData) {
      setLogData(fetchData);
      setLoading(true);
    }
    setLoading(false);
  };

  const deleteHandler = async () => {
    const response = await deleteLaborTabData(laborData?.id, laborData?.logId);

    if (response?.status == 200) {
      toast.success(t.workOrders.labor.delete);
      fetchData();
    } else {
      toast.error(t.common.something_wrong);
    }
  };

  useEffect(() => {
    fetchData();
  }, [data]);
  const onValuesChange = (name, actualValue) => (event, value) => {

    let currentFieldValue = "";
    const dropdown_fields = ["people", "category"];

    if (dropdown_fields.includes(name)) {
      currentFieldValue = actualValue || value.value;

      let resulteNewData = {};
      if (name === "people") {
        let hrRate = options?.people?.find((el) => el?.value === value?.value);

        resulteNewData = {
          ...laborData,
          [name]: actualValue || value.value,
          hourRate: hrRate?.hrRate,
        };
      } else {
        resulteNewData = {
          ...laborData,
          [name]: actualValue || value.value,
        };
      }
      setLaborData(resulteNewData);
    } else {

      currentFieldValue = actualValue || event.target.value;
      setLaborData({
        ...laborData,
        [name]: currentFieldValue,
      });
    }
    let fieldValidation = validation[name];
    if (fieldValidation) {
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
        },
      };
      // console.log('current', currentValidation);
      setValidation(currentValidation);
    }
  };

  const fetchPeopleOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ["user", "limited_admin", "admin"],
      organisationId,
      data?.attributes?.location?.data?.id
    );
    // console.log("THE FETCH USERS", fetchData)
    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          // description: `${teams_string_array.join(" , ")}`,
          hrRate: el?.userProfile?.hourRate ? el?.userProfile?.hourRate : 0,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: the_options,
      }));

      return the_options;
    }
  };

  const handleEdit = (labor) => {
   
    setLaborData({
      people: labor?.userId,
      startDate: moment(labor?.startTime).format("YYYY-MM-DD hh:mm A"),
      duration: new Date(Number(labor?.duration)).toISOString().substr(11, 5),
      hourRate: labor?.hourRate ? labor?.hourRate : 0,
      category: labor?.category ? labor?.category : "Other Time",
      id: labor?.id,
      logId: labor?.logId,
    });



    fetchPeopleOptions();
    setIsEdit(true);
    setaddLaborModal(true);
  };

  const checkPermission = (labor) => {
    

    if (!labor?.addedByUser) {
      return false;
    } else if (["cancelled", "completed"].includes(data?.attributes?.status)) {
      return false;
    } else if (["super_admin", "admin", "limited_admin"].includes(roleData)) {
      return true;
    } else if (labor?.addedByUser?.id === currentUserId) {
      return true;
    }
    return false;
  };



  const handleAssign = async () => {

    let currentValidation = {
      ...validation,
      people: {
        ...validation.people,
        error: validation.people.pattern(laborData.people) ? false : true,
        skipped: true,
      },
      duration: {
        ...validation.duration,
        error: (validation.duration.pattern(laborData.duration)) 
        // && 
        // !laborData.duration?.split(":")[0] > 24  
        ? false : true,
        skipped: true,
      },
    };
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some((el) => el[1].error);

    if (!errorsInData) {
      let response;
      if (isEdit) {
        response = await editLaborTime(
          organisationId,
          laborData?.people,
          laborData?.hourRate ? Number(laborData?.hourRate) : 0,
          moment(laborData?.startDate, "YYYY-MM-DD hh:mm A").toISOString(),
          laborData?.duration,
          laborData?.category,
          laborData?.id,
          laborData?.logId,


        );
      } else {
        response = await addLaborTime(
          data?.id,
          organisationId,
          laborData?.people,
          laborData?.hourRate ? Number(laborData?.hourRate) : 0,
          moment(laborData?.startDate, "YYYY-MM-DD hh:mm A").toISOString(),
          laborData?.duration,
          laborData?.category
        );
      }

      if (response && response.status == 200) {
        toast.success(
          isEdit ? t.workOrders.labor.edit : t.workOrders.labor.success
        );
        // untoggle();
        // setActiveIndex(0);
        // setModalWidth(Math.max(window.innerWidth / 2, 514));
        setLaborData({
          people: "",
          startDate: getLocalDateTime(organisationTimezone?.split("+")[1]),
          duration: "",
          hourRate: "",
          category: "Other Time",
        });

        setIsEdit(false);

        setaddLaborModal(false);

        fetchData();
        // setRefresh(!refresh);

        // const currentPathname = window.location.pathname;

        // if (currentPathname == "/work-orders") {
        //   setSearchTitle("");
        //   setSelectedDates([]);
        //   setFilterOptions({
        //     serialNumber: "",

        //     people: [],
        //     teams: [],
        //     customers: [],
        //     locations: [],
        //     createdByUser: [],

        //     status: [],
        //     priority: [],
        //     categories: [],
        //   });
        // }
      } else if (response.status == 400) {
        if (response.error && response.error.message) {
          toast.error(response.error.message);
        } else {
          toast.error(t.common.something_wrong);
        }
      } else {
        toast.error(t.common.something_wrong);
        // untoggle();
        // setActiveIndex(0);
        // setModalWidth(Math.max(window.innerWidth / 2, 514));
      }
    } else {
      scrollToError();
    }
  };
  return (
    <>
      {loading ? (
        <Loader active inline="centered">
          {t.common.loading}
        </Loader>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div
                className={`labor-grey-btn ${
                  selectedLanguage == "ar" ? "ml-5" : "mr-5"
                }`}
              >
                <span
                  className={`${selectedLanguage == "ar" ? "ml-3" : "mr-3"}`}
                >
                  {" "}
                  <BlackClockIcon />
                </span>
                {t.workOrders.labor.total_time}

                <span
                  className={`${selectedLanguage == "en" ? "ml-3" : "mr-3"}`}
                >
                  {`${Math.floor(
                    moment.duration(logData?.totalTime).asMinutes()
                  )} min`}
                </span>
              </div>

              <div
                className={`labor-grey-btn ${
                  selectedLanguage == "ar" ? "ml-5" : "mr-5"
                }`}
              >
                <span
                  className={`${selectedLanguage == "ar" ? "ml-3" : "mr-3"}`}
                >
                  {" "}
                  <BlackCostIcon />
                </span>
                {t.workOrders.labor.total_cost}
                <span
                  className={`${selectedLanguage == "en" ? "ml-3" : "mr-3"}`}
                >
                  {" "}
                  {logData?.totalCost ? logData?.totalCost : 0}{" "}
                  {organisationCurrency}
                </span>
              </div>
            </div>
            <div>
              {!["completed", "cancelled"].includes(
                data?.attributes?.status
              ) && ["user", "admin", "super_admin", "limited_admin"].includes(roleData)? (
                <button
                  className={`buttun-type-link mt-1`}
                  basic
                  type="button"
                  // disabled={!values?.locations}
                  style={{ background: "transparent" }}
                  onClick={() => setaddLaborModal(true)}
                >
                  <span className="customer-add-btn">
                    {" "}
                    <CustomerPlusIcon width="20px" height="20px" />{" "}
                  </span>

                  <p className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                    {t.workOrders.labor.add_time}
                  </p>
                </button>
              ) : null}
            </div>
          </div>
          {logData?.data?.length > 0 ? (
            <div className="scroll-data" style={{ overflow: "auto" }}>
              <div className={"mt-6"}>
                <div
                  // style={{ width:fromWR ? modalPrevWidth : modalWidth}}
                  className={`kanbar-header`}
                >
                  <div class="grid-container-element set labor-form">
                    <div
                      style={{
                        minWidth: "140px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.workOrders.details_view.name}
                    </div>
                    <div
                      style={{
                        minWidth: "161px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.workOrders.labor.statDate}
                    </div>
                    <div
                      style={{
                        minWidth: "100px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.workOrders.labor.duration}
                    </div>
                    <div
                      style={{
                        minWidth: "100px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.people.table.hourly_rate}
                    </div>
                    <div
                      style={{
                        minWidth: "100px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.dashboard.cards.cost_currency}
                    </div>
                    <div
                      style={{
                        minWidth: "100px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    >
                      {t.workOrders.table.category}
                    </div>
                    <div
                      style={{
                        minWidth: "40px",
                        backgroundColor: "#e9f2ff",
                      }}
                      class="grid-child-element set"
                    ></div>
                  </div>
                </div>
              </div>

              {logData?.data?.map((part, index) => (
                <div
                  className="grid-container-element set labor-form form"
                  // key={index}
                >
                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "144px",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="text-center">
                        {part?.profileImage ? (
                          <Image
                            avatar
                            title={part?.name}
                            src={`${part?.profileImage}`}
                            style={{
                              width: "32px",
                              height: "32px",
                              right: selectedLanguage === "en" ? "6px" : "-6px",
                            }}
                          />
                        ) : (
                          <UserProfilePlaceholder
                            name={part?.name}
                            width={"32px"}
                            height={"32px"}
                            right={selectedLanguage === "en" ? "6px" : "-6px"}
                          />
                        )}
                        {/* <Image
                  avatar
                  title={row?.attributes?.name}
                  src={`${IMAGES_URL}${row?.attributes?.logo?.data.attributes.url}`}
                  style={
                    selectedLanguage === "en"
                      ? { width: "34px", height: "34px", right: "6px" }
                      : { width: "34px", height: "34px", right: "-6px" }
                  }

                  // style={
                  //   index != 0
                  //     ? {
                  //         marginLeft: '-6px',
                  //         width: '34px',
                  //         height: '34px',
                  //       }
                  //     : { width: '34px', height: '34px' }
                  // }
                /> */}
                      </div>
                      <div
                        className="username-container ml-1"
                        // onClick={() => history.push(`/people?id=${row?.id}`)}
                      >
                        <p className="user-text">
                          {DisplayFormattedText(humanize(part?.name), 7)}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "161px",
                    }}
                    // className="ml-4"
                    // style={{
                    //   width: '145px',
                    //   minWidth: '145px',
                    //   display: 'inline-grid',
                    //   fontSize: '16px',
                    //   lineHeight: '24px',
                    // }}
                  >
                    <p>
                      {moment(part?.startTime).format("DD/MM/YYYY hh:mm A")}
                    </p>
                  </div>
                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "100px",
                    }}
                    // className="ml-4"
                    // style={{
                    //   width: '145px',
                    //   minWidth: '145px',
                    //   display: 'inline-grid',
                    //   fontSize: '16px',
                    //   lineHeight: '24px',
                    // }}
                  >
                    {moment.utc(Number(part?.duration)).format("H:mm:ss")}

                    {/* {DisplayFormattedText(part?.location_name, 13)}
                     */}
                  </div>
                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "100px",
                    }}
                    // className="ml-4"
                    // style={{
                    //   width: '145px',
                    //   minWidth: '145px',
                    //   display: 'inline-grid',
                    //   fontSize: '16px',
                    //   lineHeight: '24px',
                    // }}
                  >
                    {part?.hourRate ? part?.hourRate : 0} {organisationCurrency}
                  </div>
                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "100px",
                    }}
                    // className="ml-4"
                    // style={{
                    //   width: '145px',
                    //   minWidth: '145px',
                    //   display: 'inline-grid',
                    //   fontSize: '16px',
                    //   lineHeight: '24px',
                    // }}
                  >
                    {part?.cost ? part?.cost : 0} {organisationCurrency}
                  </div>

                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "100px",
                    }}
                    // className="ml-4"
                    // style={{
                    //   width: '145px',
                    //   minWidth: '145px',
                    //   display: 'inline-grid',
                    //   fontSize: '16px',
                    //   lineHeight: '24px',
                    // }}
                  >
                    {DisplayFormattedText(
                      options?.category?.find(
                        (item) => item?.value === part?.category
                      )?.text || "",
                      8
                    )}
                  </div>
                  {checkPermission(part) ? 
                  <div
                    className="grid-child-element wo-data-form"
                    style={{
                      padding: "10px 9px",
                      minWidth: "20px",
                    }}
                  >
                    <div  className="action-custom-menu-dropdown action-custom-menu-dropdown-2 ml-1 mt-2 align-self-start">
                      <UncontrolledDropdown className="mr-2" direction="down">
                        <DropdownToggle
                          color="primary"
                          className="procedure-toggle"
                        >
                          <OptionIcon />
                        </DropdownToggle>
                        <DropdownMenu
                          className={
                            selectedLanguage == "ar"
                              ? "procedure labor rtl"
                              : "procedure labor"
                          }
                        >
                          <DropdownItem className="divider" />
                          <DropdownItem onClick={() => handleEdit(part)}>
                            {t.common.edit}
                          </DropdownItem>
                          <DropdownItem className="divider" />
                          <DropdownItem
                            onClick={() => {
                              setDeletePermissionModal(true);
                              setLaborData({
                                ...laborData,
                                id: part.id,
                                logId: part.logId,
                              });
                            }}
                          >
                            {t.common.delete}
                          </DropdownItem>{" "}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div> : null}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center mt-5">
              <p className="required-text">{t.common.na}</p>
            </div>
          )}
        </>
      )}

      <LaborModal
        addLaborModal={addLaborModal}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        setValidation={setValidation}
        setLaborData={setLaborData}
        setaddLaborModal={setaddLaborModal}
        handleAssign={handleAssign}
        laborData={laborData}
        options={options}
        validation={validation}
        fetchPeopleOptions={fetchPeopleOptions}
        onValuesChange={onValuesChange}
      />
      <DeletePermissionModal
        yesCallback={() => {
          deleteHandler();
        }}
        title={t.workOrders.labor.delete_time}
      >
        <span>{t.workOrders.labor.delete_labor}</span>
      </DeletePermissionModal>
    </>
  );
};

export default Labor;
