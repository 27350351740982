export default {
  parts_small: "parts",
  parts_inv: "parts & inventory ",
  title: "Parts Inventory",
  add: "Part",
  create_part: "Create Part",
  part_data_name: "Parts Name",
  title_parts: "Parts & Inventory",
  filter: {
    partType: "Part Type",
    asset: "Asset",
    vendor: "Vendors",
    location: "Location",
    area: "Area",
    team: "Teams",
    category: "Category",
    searchByName: "Search by name",
    searchBySerialNumber: "Search by serial number",
    barcode: "Barcode",
    createdByUser: "Created By",
    createdAt: "Created At",
    qrcode: "QR Code",
    contains: "Contains",
    search: "Search",
    reset: "Reset",
    select_category: "Select Category",
    attach_qr_code: "Attach QR Code",
    select_vendor: "Select Vendor",
    people: "People",
    select_people: "Select People",
  },

  status: {
    instock: "In stock",
    inStock: "In Stock",
    low_stock: "Low stock",
    out_of_stock: "Out of Stock",
    non_stock: "Non-stock",
  },

  type: {
    critical: "Critical",
    non_critical: "Non-Critical",
  },

  tab: {
    first: "Parts",
    second: "Sets",
  },
  table: {
    avg_unit_cost: "Avg. Unit Cost",
    qty_in_stock: "Qty in Stock",
    team: "Teams",
    created_on: "Created On",
    updated_on: "Updated On",
    name: "Name",
    unit: "Unit",
    ID: "ID",
    serialNumber: "Serial Number",
    quantity: "Quantity",
    cost: "Cost",
    barcode: "Barcode",
    area: "Area",
    category: "Category",
    vendor: "Vendors",
    createdByUser: "Created By",
    createdAt: "Created At",
    updatedAt: "Updated At",
    scheduled_quantity: "Scheduled Quantity",
    scheduled_date: "Scheduled Date",
  },

  qty_unit: {
    pic: "Pieces",
    units: "Units",
    item: "Items",
    piece: "pcs",
    items: "item",
    unit: "unit",
    qty_title: "Quantity-Based Units",
  },
  vol_unit: {
    liter: "Liters (L)",
    gallons: "Gallons (GAL)",
    barrel: "Barrels",
    cubic_mtr: "Cubic Meters (m3)",
    cubic_feet: "Cubic Feet (ft3)",
    ltr: "Liters (L)",
    gal: "Gallons (GAL)",
    cub_mtr: "Cubic Meters (m3)",
    cub_feet: "Cubic Feet (ft3)",
    barrels: "bbl",
    vol_title: "Volume-Based Units",
  },

  mass_unit: {
    kilo: "Kilograms (KG)",
    mat_tons: "Metric Tonnes",
    pounds: "Pounds (LB)",
    ton: "Tons",
    kg: "Kilograms (KG)",
    pound: "Pounds (LB)",
    tons: "ton",
    m_tons: "tonne",
    mass_title: "Mass and Weight-Based Units",
  },
  created_at_options: {
    any_date: "Any Date",
    today: "Today",
    this_month: "This Month",
    this_year: "This Year",
    last_7_days: "Last 7 Days",
    last_month: "Last Month",
    last_year: "Last Year",
  },
  form: {
    qty_tooltip: "Quantity to be added to stock",
    inv_loc: "Inventory Location",
    add_inv_loc: "Add Inventory location",
    upload_excel_asset_file: "Please upload the correct file to add parts",
    part_create_success: "Part created successfully",
    part_already_exist: "Part already exist with given name",
    min_qty_tooltip: "Minimum quantity in-stock threshold",
    max_qty_tooltip: "Maximum quantity in-stock threshold",
    people_tooltip: "People in charge",
    teams_tooltip: "Team in charge",
    add_name: "Add part name",
    select_part: "Select part type",
    add_area: "Select Area",
    enter_qty: "Add quantity",
    enter_unit_cost: "Add unit cost",
    unit_type: "Measuring Unit",
    attach_file: "Attach a file to this parts",
    store_qty: "Inventory",
    po: "POs",
    add_store: "Add Location",
    store: "Store",
    add_part: "Add Part",
    name: "Name",
    enter_part_name: "Enter Part Name",
    description: "Description",
    describe_part: "Describe Part",
    category: "Category",
    select_category: "Select Category",
    area: "Area",
    enter_part_area: "Enter Part Area",
    quantity: "Starting Quantity",
    enter_part_quantity: "Enter Part Quantity",
    value_validation: "Value should be > 0 and valid integer value",
    min_quantity: "Current Quantity",
    minimum_qty: "Min. Quantity",
    miximum_qty: "Max. Quantity",
    enter_minimum_qty: "Add minimun quantity",
    enter_miximum_qty: "Add maximun quantity",
    enter_min_quantity: "Enter Part Minimum Quantity",
    non_stock: "Non-stock",
    qr_code: "QR Code",
    serial_number: "Serial Number",
    enter_serial_number: "Enter Serial Number",
    cost: "Cost",
    enter_part_cost: "Enter Part Cost",
    cost_type: "Cost Type",
    select_cost_type: "Select measuring unit ",
    asset: "Asset",
    select_asset: "Select Asset",
    vendors: "Vendors",
    select_vendor: "Select Vendors",
    procedure: "Procedure",
    select_procedure: "Select Procedure",
    additional_part_details: "Additional Part Details",
    image: "Image",
    add: "Add",
    custom_data: "Custom Data",
    label: "Label",
    field_label: "Field Label",
    files: "Files",
    value: "Value",
    field_value: "Field Value",
    remove: "Remove",
    submit: "Submit",
    cancel: "Cancel",
    add_files: "Add Files",
    part: "Part",
    per_piece: "Per Piece",
    per_set: "Per Set",
    per_liter: "Per Liter",
    piece: "piece",
    set: "set",
    liter: "liter",
    edit_part: "Edit Part",
    pre_unit: "Per Unit",
    unit_cost: "Unit Cost",
    total_cost: "Total Cost",
    add_QR: "Generate QR/Bar Code",
    barcode: "Bar Code",
    qrcode: "QR Code",
    qty_in_stoke: "Total Qty in Stock",
    avg_cost: "Avg Unit Cost",
    avl_qty: "Available Qty",
    order_qty: "Ordered Qty",
    ordered_qty: "Ordered Quantity",
    reserved_qty: "Reserved Qty",
    min_qty: "Min Qty",
    max_qty: "Max Qty",
    qty_in_stoke_tooltip: "Total quantity in stock per location",
    min_qty_in_stoke_tooltip: "Minimum quantity in stock threshold",
    max_qty_in_stoke_tooltip: "Maximum quantity in stock threshold",
    avl_qty_tooltip:
      "Total stock on hand, minus any quantity reserved for work orders",
    order_qty_tooltip:
      "Purchasing order quantity that is ordered and is awaiting receipt",
    reserved_qty_tooltip:
      "Stock reserved or scheduled for upcoming work orders",
    no_po_found: "There are no Purchase Orders added",
    changes: "Changes",
    cancel_statement:
      "Are you sure you want to cancel this part? You will loose the information you have populated",
    create_po: "Create Purchase Order",
    restock: "Restock",
    restock_success: "Part restocked successfully",
  },
  sheet: {
    sheet_name: "Part Name*",
    description: "Description",
    category: "Category",
    unit_cost: "Unit Cost",
    unit_type: "Measuring Unit*",
    min_qty: "Min Quantity",
    max_qty: "Max.Quantity",
    part_type: "Part Type*",
    stock: "Stock/ Non-Stock",
    location: "Inventory Location*",
    area: "Area *",
    quantity: "Quantity*",
    assets: "Assets",
    vendors: "Vendors",
    people: "People",
    teams: "Teams",
    serialNumber: "Serial Number*",
    qr_barcode: "QR Code/ Bar Code*",
  },

  sheet2: {
    sheet_name: "اسم القطعة*",
    description: "الوصف",
    category: "الفئة",
    unit_cost: "سعر القطعة ",
    unit_type: "وحدة القياس*",
    min_qty: "الحد الأدنى للكمية",
    max_qty: "الحد الأقصى للكمية",
    part_type: "نوع القطعة*",
    stock: "في المخزون / غير موجود في المخزون",
    location: "الموقع*",
    area: "المنطقة*",
    quantity: "الكمية*",
    assets: "الممتلكات",
    vendors: "الموردين",
    people: "الأفراد",
    teams: "الفرق",
    serialNumber: "الرقم التسلسلي*",
    qr_barcode: "رمز الاستجابة السريعة / رمز الباركود*",
  },
  upload_code: "Upload Code",
  delete_part_statement: "Are you sure you want to delete this part?",
  delete_success: "Part deleted successfully",
  delete_error: "Unable to delete part. Linked with other forms",
  added_success: "Part added successfully",
  updated_success: "Part updated successfully",
  related_part_error:
    "Part cannot be deleted as it is connected with other work orders and procedures",
  no_part_file: "No files belong to Part",
  no_part_available: "Looks like you don't have Parts yet.",
  press_to_add_part: "Press the " + " button to add your first Part.",
  no_parts_added: "Start adding",
  added_parts:"added",
  no_add_part:"there are no",
  how_to_add_parts: "How to add parts & inventory",
  minQty_valid: "The min. quantity must be less than the max. quantity.",
  maxQty_valid: "The max. quantity must be greater than the min. quantity.",
  no_barcode: "No part profiles contain this given QR/Bar Code",
  people_valid: "Must select either People or Teams",

  errors: {
    "Name is empty.": "Name is empty.",
    "Part name must be unique": "Part name must be unique",
    "No Category selected for this part.":
      "No Category selected for this part.",
    "No unitCost selected for this part.":
      "No unitCost selected for this part.",
    "No measuringUnit selected for this part.":
      "No measuringUnit selected for this part.",
    "No type selected for this part.": "No type selected for this part.",
    "Serial number is empty.": "Serial number is empty.",
    "Serial number contain 1 digit as a minimum and 13 digits as a maximum.":
      "Serial number contain 1 digit as a minimum and 13 digits as a maximum.",
    "Serial number is not unique.": "Serial number is not unique.",
    "Serial number contains only digits.":
      "Serial number contains only digits.",
    "Serial Numbers must contain alphanumeric data from 1 to 13 digits":
      "Serial Numbers must contain alphanumeric data from 1 to 13 digits",
    "No QR/Bar Code selected for this part.":
      "No QR/Bar Code selected for this part.",
    "No Location selected for this part.":
      "No Location selected for this part.",
    "No Area selected for this part.": "No Area selected for this part.",
    "No Quantity selected for this part.":
      "No Quantity selected for this part.",
    "The part name is not unique.": "The part name is not unique.",
    "No unit cost was entered.": "No unit cost was entered.",
    "No measuring unit was entered.": "No measuring unit was entered.",
    "No part type was entered.": "No part type was entered.",
    "No location was entered.": "No location was entered.",

    "No area was entered.": "No area was entered.",
    "No area entered does not exist in the location.":
      "No area entered does not exist in the location.",
    "No quantity was entered.": "No quantity was entered.",
    "No QR/Bar Code selected for this part.":
      "No QR/Bar Code selected for this part.",
    "No people or teams exist for this part.":
      "No people or teams exist for this part.",
    "The people entered do not exist in the system.":
      "The people entered do not exist in the system.",
    "The team entered does not exist in the system.":
      "The team entered does not exist in the system.",
    "The asset entered does not exist in the system.":
      "The asset entered does not exist in the system.",
    "The vendor entered does not exist in the system.":
      "The vendor entered does not exist in the system.",
    "Both the minimum and maximum quantities must be greater than 0. Additionally, the maximum quantity should be more than the minimum quantity.":
      "Both the minimum and maximum quantities must be greater than 0. Additionally, the maximum quantity should be more than the minimum quantity.",
  },
};
