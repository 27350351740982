import React, { useState, useEffect, createRef } from "react";
import {
  List,
  Header,
  Modal,
  TextArea,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Popup,
  Icon,
  Checkbox,
  Label,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import calendar_icon from "../../../Assets/Icons/calender-icon.svg";
import { format } from "date-fns";
import child_icon from "../../../Assets/Icons/childAsset.png";
import parent_icon from "../../../Assets/Icons/parentAsset.png";
import { selectTranslations } from "../../config/i18n/slice";
import AddCategoryModal from "../../components/modal/category/CategoryCreateModal";
import location_icon from "../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import { useSuccessModal } from "../../../hooks/Messages/useSuccessModal";
import { useCancelModal } from "../../../hooks/Messages/useCancelModal";

import {
  getAllProcedures,
  createNewAsset,
  IMAGES_URL,
  getAllCustomers,
  getAllVendros,
  uploadFile,
  getAllCategories,
  getAllParentLocations,
  getActiveUsersByRole,
  getAllTeams,
  handleKeyDown,
  getAllAreaByLocationIdActive,
  getAllPeopleByActiveLocation,
  getAllAssets,
  getAllChildAssets,
  getAllLocations,
  getAllPeopleByLocation,
  getAllAssetsOption,
} from "../../config/functions";

import { useDispatch, useSelector } from "react-redux";
import { generateUniqueInteger } from "../../../utilFunctions/serialNumberGenerator";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import {
  AddIcon,
  AddIconWithCircle,
  AddImage,
  BarcodeIcon,
  CalenderGreyIcon,
  CloseIcon,
  CsvImg,
  DeleteIcon,
  DocImg,
  DocxImg,
  FilesIcon,
  ImgPhotos,
  Mp4File,
  PdfBlueIcon,
  PercentageIcon,
  PngPhotos,
  PriceIcon,
  QRcodeIconInactive,
  Repeat,
  Success,
  TextSnippet,
  TxtFile,
  XlsxFile,
} from "../../../Assets/Icons/svg";

import { useDropzone } from "react-dropzone";
import Asterisk from "../../components/common/Asterisk";
import { scrollToError } from "../../../utilFunctions/scrollToError";
import DatePicker from "react-multi-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import ToolTip from "react-power-tooltip";
import {
  fetchAssets,
  fetchAssetsInitData,
} from "../../../redux/reducers/assetReducer";
import QRComponentToPrint from "./preview/qrCodeComponent";
import { Resizable } from "re-resizable";
import useNewCancelModal from "../../../hooks/Messages/useNewCncelModal";

/*
 * The FileUpload component handles file uploads with conditional rendering based on the file type.
 */
const FileUpload = ({
  onFileChange,
  name = "",
  onCertificateFileChange,
  attachments = {},
  setAttachments = () => {},
}) => {
  const fileTypes = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "image/webp",
      "image/heic",
    ],
    documents: [
      "text/plain",
      "application/pdf",
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    video: ["video/mp4", "video/quicktime", "video/webm", "video/hevc"],
  };

  const fileTypesCompare = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "image/webp",
      "image/heic",
    ],
    txt: ["text/plain"],
    csv: ["text/csv"],
    docx: [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    xlsx: [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    documents: ["application/msword"],
    pdf: ["application/pdf"],
    video: [
      "video/mp4",
      "video/quicktime",
      "application/octet-stream",
      "video/webm",
      "video/hevc",
    ],
  };

  const { getRootProps, getInputProps } = useDropzone({
    // accept:
    //   name === "image"
    //     ? Object.fromEntries(fileTypes.images.map((type) => [type, []]))
    //     : Object.fromEntries(
    //         [
    //           ...fileTypes.images,
    //           ...fileTypes.documents,
    //           ...fileTypes.video,
    //         ].map((type) => [type, []])
    //       ),
    onDrop: (acceptedFiles) => {
      const allowedFileTypes =
        name === "image"
          ? [...fileTypes.images]
          : [...fileTypes.images, ...fileTypes.documents, ...fileTypes.video];

      // Validate file types
      const invalidFiles = acceptedFiles.filter(
        (file) => !allowedFileTypes.includes(file.type)
      );

      // Show toast if there are invalid files
      if (invalidFiles?.length > 0) {
        toast.error(t.common.invalid_msg);
        return;
      }

      let totalFile = attachments?.warrantyFile?.length + acceptedFiles?.length;
      let totalAttachment = organisationImage?.numberOfAttachments
        ? organisationImage?.numberOfAttachments
        : 10;
      let totalSize = organisationImage?.attachmentsSize
        ? organisationImage?.attachmentsSize
        : 200;
      if (name !== "image" && totalFile > totalAttachment) {
        toast.error(
          `${t.common.number_error_1} ${totalAttachment} ${t.common.number_error_2}`
        );
        return;
      }
      const validFiles = acceptedFiles.filter((file) => {
        const isImage = fileTypes.images.includes(file.type);
        const isDocument = fileTypes.documents.includes(file.type);

        const isVideo = fileTypes.video.includes(file.type);

        const validSize =
          (isVideo || isImage || isDocument) &&
          file.size < totalSize * 1024 * 1024;

        if (!validSize) {
          toast.error(
            `${t.common.file_upload_valid} ${totalSize} ${t.common.file_upload_second}`
          );
          return false;
        }
        return true;
      });

      name === "image"
        ? onFileChange(validFiles, name)
        : onCertificateFileChange(validFiles, name);
    },
  });
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const organisationImage = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation;
    } else {
      return null;
    }
  });

  const renderFile = (file, index) => (
    <div
      className={`image-dropzone blue-border p-1 file d-flex justify-content-between text-start ${
        index == 0 ? "" : "mt-5"
      }`}
    >
      <div>
        <label
          style={{ fontSize: "14px" }}
          className="d-flex align-items-center m-1"
        >
          <div
            className={`${
              selectedLanguage == "en" ? "ml-4 mr-4" : "ml-4 mr-4"
            }`}
          >
            {fileTypesCompare?.images?.includes(file?.type) ? (
              <PngPhotos />
            ) : fileTypesCompare?.txt?.includes(file?.type) ? (
              <TxtFile />
            ) : fileTypesCompare?.csv?.includes(file?.type) ? (
              <CsvImg />
            ) : fileTypesCompare?.documents?.includes(file?.type) ? (
              <DocImg />
            ) : fileTypesCompare?.pdf?.includes(file?.type) ? (
              <PdfBlueIcon />
            ) :fileTypesCompare?.video?.includes(file?.type) ? (
              <Mp4File />
            ) : fileTypesCompare?.docx?.includes(file?.type) ? (
              <DocxImg />
            ) : fileTypesCompare?.xlsx?.includes(file?.type) ? (
              <XlsxFile />
            ) : null}
          </div>

          {file?.name}
        </label>
      </div>

      <div
        className="mr-5 ml-5 mt-1 c-pointer"
        onClick={(e) => {
          e.stopPropagation();
          const newCovidCopy = attachments.warrantyFile.filter(
            (covidFile, i) => i !== index
          );
          setAttachments({
            ...attachments,
            warrantyFile: newCovidCopy,
          });
        }}
      >
        <CloseIcon width={"18px"} height={"18px"} />
      </div>
    </div>
    // <div>
    //   <label>
    //     {file?.name}
    //     <span
    //       className="ml-3 mr-3 c-pointer"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         const newCovidCopy = attachments.warrantyFile.filter(
    //           (covidFile, i) => i !== index
    //         );
    //         setAttachments({
    //           ...attachments,
    //           warrantyFile: newCovidCopy,
    //         });
    //       }}
    //     >
    //       <DeleteIcon />
    //     </span>
    //   </label>
    // </div>
  );

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === "image" ? (
        attachments?.image?.name ? (
          <div
            className={`image-dropzone blue-border p-1 file d-flex justify-content-between text-start
            `}
          >
            <div>
              <label
                style={{ fontSize: "14px" }}
                className="d-flex align-items-center m-1"
              >
                <div
                  className={`${
                    selectedLanguage == "en" ? "ml-4 mr-4" : "ml-4 mr-4"
                  }`}
                >
                  {fileTypes?.images?.includes(attachments?.image.type) ? (
                    <PngPhotos />
                  ) : (
                    <Mp4File />
                  )}
                </div>

                {attachments?.image?.name}
              </label>
            </div>

            <div
              className="mr-5 ml-5 mt-1 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setAttachments({
                  ...attachments,
                  image: null,
                });
              }}
            >
              <CloseIcon width={"18px"} height={"18px"} />
            </div>
          </div>
        ) : (
          <div className="image-dropzone  text-center mb-5">
            <div>
              <p className="p1">
                <div className="d-flex align-items-start justify-content-center ">
                  <div>
                    {" "}
                    <ImgPhotos />
                  </div>
                  <div
                    className={`mr-2 ml-2 ${
                      selectedLanguage == "en" ? "text-left" : "text-right"
                    }`}
                  >
                    {" "}
                    <div className="" style={{ color: "#0C66E4" }}>
                      {" "}
                      {t.common.upload_img}
                    </div>{" "}
                    <p
                      className="mb-0"
                      style={{ color: "#788CA5", fontSize: "12px" }}
                    >
                      {" "}
                      {t.common.valid_files_img}
                    </p>{" "}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )
      ) : attachments?.warrantyFile?.length > 0 ? (
        <>
          {attachments.warrantyFile.map((file, index) =>
            renderFile(file, index)
          )}

<div className="warrenty-file-dropzone mt-5 d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <span className="mt-1">
                <FilesIcon />
              </span>
              <div className="pl-4 pr-4 d-flex align-items-center">
                <p className="p1 m-0">{t.common.files}</p>
                <span style={{ color: "#788CA5" }} className="ml-3">
                  {t.common.files_attachments}
                </span>
              </div>
            </div>
            <div>
              <AddIconWithCircle />
            </div>
          </div>
        </>
      ) : (
        <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
        <div className="d-flex">
          <span className="mt-1">
            <FilesIcon />
          </span>
          <div className="pl-4 pr-4 d-flex align-items-center">
            <p className="p1 m-0">{t.common.files}</p>
            <span style={{ color: "#788CA5" }} className="ml-3">
              {t.common.files_attachments}
            </span>
          </div>
        </div>
        <div>
          <AddIconWithCircle />
        </div>
      </div>
      )}
    </div>
  );
};

/*
 * The `Add` component that handles the form submission for adding a new
 * asset, including input fields for asset details, validations, dropdown options, file uploads, and modals for success and cancellation.
 */
function Add({
  toggled,
  untoggle,
  setRefresh,
  refresh,
  setFilterOptions,
  setSelectedDates,
  setSearchTitle,
  filterOptions,
  role,
  allIds,
}) {
  const t = useSelector(selectTranslations);
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const { userPermissions } = usePermissionsSimplified();
  const { setSuccessModal, SuccessModal } = useSuccessModal();
  const dispatch = useDispatch();
  const { setCancelModal, CancelModal } = useCancelModal();
  const [avtiveBtn, setActiveBtn] = useState(2);
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );

  const { initData } = useSelector((state) => ({
    initData: state.asset.assetInitData,
  }));

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const [initChange, setInitChange] = useState(false);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [successModal, toggleSuccessModal] = useState(false);
  const [openCategoryAddModal, setOpenCategoryAddModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  const [attachments, setAttachments] = useState({
    image: null,
    warrantyFile: [],
  });
  const [responseData, setResponseData] = useState({});
  const [assetData, setAssetData] = useState([]);

  const [dropdownOptions, setDropdownOptions] = useState({
    vendors: [],
    customers: [],
    locations: [],
    procedures: [],
    categories: [],
    subLocations: [],
    people: [],
    teams: [],
    area: [],
    child_asset: [],
  });

  const [data, setData] = useState({
    name: "",
    description: "",
    isChild: false,
    parentAsset: "",
    model: "",
    category: "",
    // area: '',
    qrcode: "",
    barcode: true,
    serialNumber: "",
    vendor: "",
    customer: "",
    location: "",
    purchasePrice: 0,
    manufacturer: "",
    descriptionRate: "",
    estimatedUsefulLife: 0,
    placedDate: "",
    warrantyExpirationDate: "",
    additionalInformation: "",
    procedure: "",
    isSubLocation: false,
    isArea: false,
    subLocations: "",
    area: "",
    people: [],
    teams: [],
  });

  const onCertificateFileChange = (files, name) => {
    setInitChange(true);
    let newFiles = [];
    Array.from(files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });
    setAttachments({
      ...attachments,
      [name]: [...attachments[name], ...newFiles],
    });
  };

  const [validation, setValidation] = useState({
    name: {
      error: true,
      pattern: (name) => name != "",
      skipped: false,
      message: t.common.required_field,
    },
    location: {
      error: true,
      pattern: (name) => name != "",
      skipped: false,
      message: t.common.required_field,
    },
    parent_asset: {
      error: true,
      pattern: (name) => name != "",
      skipped: false,
      message: t.common.required_field,
    },

    category: {
      error: true,
      pattern: (category) => category != "",
      skipped: false,
      message: t.common.required_field,
    },
    serialNumber: {
      error: true,
      pattern: (serialNumber) => serialNumber != "",
      skipped: false,
      message: t.common.required_field,
    },
  });

  const [validForm, setValidForm] = useState(false);

  const resetForm = () => {
    setData({
      name: "",
      isChild: false,
      parentAsset: "",
      description: "",
      model: "",
      category: "",
      area: "",
      qrcode: "",
      barcode: true,
      serialNumber: "",
      vendor: "",
      customer: "",
      location: "",
      purchasePrice: 0,
      manufacturer: "",
      descriptionRate: "",
      estimatedUsefulLife: 0,
      placedDate: "",
      warrantyExpirationDate: "",
      additionalInformation: "",
      procedure: "",
      isSubLocation: false,
      isArea: false,
      subLocations: "",
      people: [],
      teams: [],
      // area: [{ area: '' }],
    });
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    setActiveBtn(2);
    setAttachments({
      image: null,
      warrantyFile: null,
    });

    setValidation({
      name: {
        error: false,
        pattern: (name) => name != "",
        skipped: false,
        message: t.common.required_field,
      },
      parent_asset: {
        error: true,
        pattern: (name) => name != "",
        skipped: false,
        message: t.common.required_field,
      },

      location: {
        error: true,
        pattern: (name) => name != "",
        skipped: false,
        message: t.common.required_field,
      },
      // customer: {
      //   error: false,
      //   pattern: (customer) => customer != '',
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      category: {
        error: false,
        pattern: (category) => category != "",
        skipped: false,
        message: t.common.required_field,
      },
      serialNumber: {
        error: false,
        pattern: (serialNumber) => serialNumber != "",
        skipped: false,
        message: t.common.required_field,
      },
    });
    setValidForm(false);
    setInitChange(false);
  };

  /*
   * The function fetches active users by role and organizes their data into dropdown options with profile images and team information.
   */
  const fetchPeopleOptions = async () => {
    if (!data?.people) {
      setDropdownOptions((prevOptions) => ({
        ...prevOptions,
        people: [],
      }));
    }
    const fetchData = await getAllPeopleByLocation(
      !(role === "super_admin")
        ? data?.location
          ? data?.location
          : allIds
        : data?.location
        ? data?.location
        : [],
      organisationId
    );
    if (fetchData) {
      let the_options = fetchData.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setDropdownOptions((prevOptions) => ({
        ...prevOptions,
        people: the_options,
      }));

      return the_options;
    }
  };

  /*
   * The function fetchTeamsOptions fetches team data and generates dropdown options with team names and avatars.
   */
  const fetchTeamsOptions = async () => {
    if (!data?.teams) {
      setDropdownOptions((prevOptions) => ({
        ...prevOptions,
        teams: [],
      }));
    }
    const fetchData = await getAllTeams(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      !(role === "super_admin")
        ? data?.location
          ? [data?.location]
          : allIds
        : data?.location
        ? [data?.location]
        : []
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setDropdownOptions((prevOptions) => ({
        ...prevOptions,
        teams: the_options,
      }));

      return the_options;
    }
  };

  /*
   * The function `onValuesChange` handles changes in form field values, updating state and performing validation based on the field type.
   */
  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    const dropdown_fields = [
      "vendor",
      "parentAsset",
      "customer",
      "procedure",
      "area",
      "category",
      "location",
      "subLocations",
      "people",
      "teams",
    ];
    const boolean_fields = ["isSubLocation", "isArea", "isChild"];

    let currentFieldValue = "";

    if (!dropdown_fields.includes(name)) {
      currentFieldValue = event.target.value;
      setData({
        ...data,
        [name]: event.target.value,
      });
    }

    if (boolean_fields.includes(name)) {
      currentFieldValue = value.checked;
      setData({
        ...data,
        [name]: value.checked,
      });
    }

    if (dropdown_fields.includes(name)) {
      currentFieldValue = value.value;
      if (name == "location") {
        setData({
          ...data,
          [name]: value.value,
          people: [],
          teams: [],
          customer: "",
        });
      } else if (name == "parentAsset") {
        let locationData = dropdownOptions?.child_asset?.filter(
          (customer) => currentFieldValue == customer.value
        );
        console.log("locationData: ", locationData);
        if (locationData?.length > 0) {
          setData({
            ...data,
            [name]: currentFieldValue,
            location: locationData[0]?.locations,
          });
        } else {
          setData({
            ...data,
            [name]: "",
          });
        }
      } else {
        setData({
          ...data,
          [name]: value.value,
        });
      }

      //   if(name == 'parentAsset'){
      //     let checkAsset=   assetData?.response?.length > 0 && assetData.response.find((asset) =>
      //       asset.id == currentFieldValue
      //   );

      //        if(checkAsset?.attributes?.childAssets?.data?.length){
      //           return toast.error("Unable to Change Asset Hierarchy")
      //        }else{
      //         setData({
      //           ...data,
      //           [name]: currentFieldValue,
      //         });
      //        }
      // }else{

      // }
      // setData({
      //   ...data,
      //   [name]: value.value,
      // });
    }

    let fieldValidation = validation[name];
    if (fieldValidation) {
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
        },
      };
      // console.log('current', currentValidation);
      setValidation(currentValidation);
    }
    // if (name == 'serialNumber') {
    //   if (currentFieldValue.length > 0 && currentFieldValue.length !== 10) {
    //     setValidation({
    //       ...validation,
    //       serialNumber: {
    //         ...validation.serialNumber,
    //         error: true,
    //         skipped: true,
    //         message: t.assets.serial_valid,
    //       },
    //     });
    //   }
    // }
  };

  /*
   * The `onFileChange` function sets a new file attachment with random ID and details based on the input files and name.
   */
  const onFileChange = (files, name) => {
    // console.log('show', e.target.files[0]);
    setInitChange(true);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setAttachments({
      ...attachments,
      [name]: {
        file: files[0],
        id: randomnumber,
        name: files[0].name,
        type: files[0].type,
        cancelPopup: false,
        isChanged: true,
      },
    });
  };

  /*
   * The `fetchCustomers` function fetches customer data, processes it, and sets the dropdown options with customer information.
   */
  const fetchCustomers = async () => {
    if (!data?.customer) {
      setDropdownOptions((prevOptions) => ({
        ...prevOptions,
        customers: [],
      }));
    }
    const fetchData = await getAllCustomers(
      organisationId,
      "",
      1,
      99999,
      !(role === "super_admin")
        ? data?.location
          ? [data?.location]
          : allIds
        : data?.location
        ? [data?.location]
        : []
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        customers: the_options,
      });
    }
  };

  /*
   * The `fetchLocations` function fetches parent locations data and maps it to create dropdown options.
   */
  const fetchLocations = async () => {
    const fetchData = await getAllParentLocations(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      ["name:asc"]
    );
    let the_options = [];
    let parentOptions = [];
    let subOptions = [];
    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        if (!(role === "super_admin")) {
          // Check if the element's ID is included in allIds
          if (allIds?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
              className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (allIds?.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
              (subEl) => allIds?.includes(subEl.id)
            );

            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });

              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
            }
          }
        } else {
          // Add parent option
          parentOptions.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: location_icon },
            className: "main-location-dropdown-option",
          });

          // Collect sub-options
          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });

      // Sort sub-options alphabetically
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      // Merge parent options with sorted sub-options
      parentOptions.forEach((parentOption) => {
        the_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            the_options.push(subOption);
          }
        });
      });

      // Update the options state
      setDropdownOptions((prev) => ({
        ...prev,
        locations: the_options,
      }));

      return the_options;
    }
  };
  /*
   * The function `fetchSubLocations` retrieves sub-locations data based on a given location ID and updates the dropdown options accordingly.
   */
  const fetchSubLocations = async () => {
    let options = [];
    if (data.location) {
      const fetchData = await /* The above code is a comment block in JavaScript. It appears to be
      documenting a function called `getAllParentLocations`. The function's
      purpose or implementation is not provided in the comment block. */
      getAllParentLocations(organisationId, "", 1, 99999);
      if (fetchData.response) {
        let locationData = fetchData.response.find(
          (one) => one.id == data.location
        );
        if (locationData && locationData?.attributes?.subLocations?.data) {
          let the_options = locationData?.attributes?.subLocations?.data.map(
            (el) => {
              options.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
              });
            }
          );
        }
        setDropdownOptions({
          ...dropdownOptions,
          subLocations: options,
        });
      }
    }
  };

  const fetchAllAssets = async () => {
    if (data?.isChild) {
      const fetchData = await getAllAssetsOption(
        organisationId,
        "all_asset",
        1,
        99999,
        !(role === "super_admin") ? [] : [],
        [],
        "",
        "",
        [],
        [],
        "",
        !(role === "super_admin") ? [] : [],
        true
      );

      let the_options = [];
      let parentOptions = [];
      let childOptions = [];
      setAssetData(fetchData);

      if (fetchData?.response?.length > 0) {
        fetchData.response.forEach((el) => {
          if (!(role === "super_admin")) {
            const isParentDisposed = el.attributes.status === "dispose";

            // Collect child assets and check their status
            let activeChildCount = 0;
            let shouldShowParent = false;

            if (el?.attributes?.childAssets?.data?.length > 0) {
              el.attributes.childAssets.data.forEach((childEl) => {
                const isChildDisposed = childEl.attributes.status === "dispose";
                const childLocationId = childEl.attributes.location?.data?.id;

                // Check if child is active (not disposed) and location is in allIds
                const childIsActive =
                  !isChildDisposed && allIds?.includes(childLocationId);

                if (childIsActive) {
                  activeChildCount++;

                  // Add active child asset option
                  childOptions.push({
                    key: childEl.id,
                    value: childEl.id,
                    locations: childLocationId,
                    text: childEl.attributes.name,
                    image: { src: child_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id,
                    disabled: false, // Active child asset is enabled
                  });
                }

                // Check if we should show the parent based on child activity
                if (childIsActive) {
                  shouldShowParent = true;
                }
              });
            }

            // Determine if the parent should be shown based on conditions
            const parentLocationId = el?.attributes?.location?.data?.id;
            const parentIsActive =
              !isParentDisposed && allIds?.includes(parentLocationId);

            // Conditions for showing and disabling the parent
            if (parentIsActive || shouldShowParent) {
              parentOptions.push({
                key: el.id,
                value: el.id,
                locations: parentLocationId,
                text: el.attributes.name,
                image: { src: parent_icon },
                className: "main-location-dropdown-option",
                disabled:
                  isParentDisposed || (shouldShowParent && !parentIsActive), // Disable if disposed or child is active but parent is not in allIds
              });
            }
          } else {
            const isParentDisposed = el.attributes.status === "dispose";

            // Collect child assets and check their status
            let activeChildCount = 0;
            if (el?.attributes?.childAssets?.data?.length > 0) {
              el.attributes.childAssets.data.forEach((childEl) => {
                const isChildDisposed = childEl.attributes.status === "dispose";
                if (!isChildDisposed) {
                  activeChildCount++;
                  // Add active child asset option
                  childOptions.push({
                    key: childEl.id,
                    locations: childEl.attributes.location?.data?.id,
                    value: childEl.id,
                    text: childEl.attributes.name,
                    image: { src: child_icon }, // Use appropriate icon
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                    disabled: false, // Active child asset is enabled
                  });
                }
              });
            }

            // Only add the parent option if:
            // - It's not disposed, OR
            // - It's disposed but has active child assets
            if (!isParentDisposed || activeChildCount > 0) {
              parentOptions.push({
                key: el.id,
                value: el.id,
                locations: el.attributes.location?.data?.id,
                text: el.attributes.name,
                image: { src: parent_icon }, // Use appropriate icon
                className: "main-location-dropdown-option",
                disabled: isParentDisposed, // Parent option is disabled if disposed
              });
            }
          }
          // Check if the parent asset is disposed
        });

        // Sort child options alphabetically
        childOptions.sort((a, b) => a.text.localeCompare(b.text));

        // Merge parent options with sorted child options
        parentOptions.forEach((parentOption) => {
          the_options.push(parentOption);
          // Add sorted child options belonging to this parent
          childOptions.forEach((childOption) => {
            if (childOption.parentId === parentOption.key) {
              the_options.push(childOption);
            }
          });
        });

        // Update the options state
        setDropdownOptions((prev) => ({
          ...prev,
          child_asset: the_options,
        }));

        return the_options;
      }
    }
  };

  const fetchArea = async () => {
    let the_options_area = [];
    if (data.location) {
      const response = await getAllAreaByLocationIdActive(
        organisationId,
        [data?.location]
        //  data?.subLocations
      );
      if (response.length > 0) {
        response.forEach((el) => {
          the_options_area.push({
            key: `${el.id}`,
            value: el.id,
            text: `${el.attributes.name}`,
          });
        });
      }
    }

    setDropdownOptions((prev) => {
      return {
        ...prev,
        area: the_options_area,
      };
    });
    // const fetchData = await getAllParentLocations(organisationId);
    // if (fetchData) {
    // console.log(options);

    // }
  };

  /*
   * The function fetches procedure options from an API and maps the response data to a specific format for dropdown selection.
   */
  const fetchProceduresOptions = async () => {
    const fetchData = await getAllProcedures(false, organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.title,
        };
      });

      setDropdownOptions({
        ...dropdownOptions,
        procedures: the_options,
      });

      return the_options;
    }
  };

  /*
   * The function fetchCategoryOptions fetches all categories for a specific organisation and maps them to key-value pairs for dropdown options.
   */
  const fetchCategoryOptions = async () => {
    const fetchData = await getAllCategories(organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });

      setDropdownOptions({
        ...dropdownOptions,
        categories: the_options,
      });

      return the_options;
    }
  };

  /*
   * The fetchVendorsData function fetches vendor data and sets dropdown options with vendor information.
   */

  const fetchVendorsData = async () => {
    const fetchData = await getAllVendros(organisationId, "", 1, 9999);
    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        vendors: the_options,
      });
    }
  };

  /*
   * The `cancelHandler` function resets the form, untoggles a modal, and sets certain state variables to
   * false.
   */
  const cancelHandler = () => {
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    resetForm();
    untoggle();
    setNewCancelModal(false);
    setInitChange(false);
  };

  const downloadThisQR = () => {
    const svg = document.getElementById("QRCodeDownloadable");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  /*
   * The validationHandler function updates validation properties based on data input and checks for any errors in the validation.
   */
  const validationHandler = () => {
    let currentValidation = {
      ...validation,
      name: {
        ...validation.name,
        error: validation.name.pattern(data.name) ? false : true,
        skipped: true,
      },
      parent_asset: {
        ...validation.parent_asset,
        error: validation.parent_asset.pattern(
          data?.isChild && !data?.parentAsset
        )
          ? true
          : false,
        skipped: true,
      },
      category: {
        ...validation.category,
        error: validation.category.pattern(data.category) ? false : true,
        skipped: true,
      },
      location: {
        ...validation.location,
        error: validation.location.pattern(data.location) ? false : true,
        skipped: true,
      },
      serialNumber: {
        ...validation.serialNumber,
        error: validation.serialNumber.pattern(data.serialNumber)
          ? false
          : true,
        skipped: true,
      },
    };

    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some((el) => el[1].error);
    return errorsInData;
  };

  const uploadFilessHandler = async (files = []) => {
    // console.log(files);
    let theFiles = files.filter((el) => el.isChanged);
    theFiles = theFiles.map((el) => el.file);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  /* The above code is a snippet of JavaScript code using the useEffect hook from React. It is checking
the validation object for any elements that have both an error and skipped property set to true. If
such an element is found, the cond variable will be set to true.
*/
  useEffect(() => {
    if (toggled) {
      fetchLocations();
    }
  }, [toggled]);

  useEffect(() => {
    setData({
      ...data,
      subLocations: "",
      area: "",
      people: [],
    });
    setDropdownOptions({
      ...dropdownOptions,
      subLocations: [],
      area: [],
      people: [],
    });
  }, [data.location]);

  useEffect(() => {
    setData({
      ...data,
      area: "",
    });
    setDropdownOptions({
      ...dropdownOptions,

      area: [],
    });
  }, [data.subLocations]);

  /*
   * The function `createNewAssetHandler` handles the creation of a new asset by validating data, uploading image and warranty files, and calling another function to complete the asset creation process.
   */
  const createNewAssetHandler = async () => {
    let errorsInData = validationHandler();

    if (!errorsInData) {
      setButtonStatus(1);
      const assetImageReponse =
        attachments.image && attachments.image.isChanged
          ? await uploadFile([attachments.image.file])
          : null;
      const warrantyFileResponse =
        attachments.warrantyFile && attachments.warrantyFile?.length
          ? await uploadFilessHandler(attachments.warrantyFile)
          : null;

      createNewAsset2Handler(
        assetImageReponse?.status == 200 ? assetImageReponse.data[0].id : null,
        warrantyFileResponse?.status == 200
          ? warrantyFileResponse.data.map((el) => el.id)
          : null
      );
    } else {
      scrollToError();
    }
  };

  /*
   * The function `createNewAsset2Handler` handles the creation of a new asset with various data inputs and displays success or error messages accordingly.
   */
  const createNewAsset2Handler = async (image, warranty) => {
    const response = await createNewAsset(
      String(data.name),
      String(data.description),
      String(data.model),
      data.category ? data.category : null,
      data?.area ? data.area : null,
      String(data.serialNumber),
      avtiveBtn == 1 ? true : false,
      data.vendor ? Number(data.vendor) : null,
      data?.customer ? Number(data.customer) : null,
      data.placedDate ? format(new Date(data.placedDate), "yyyy-MM-dd") : null,
      data.warrantyExpirationDate
        ? format(new Date(data.warrantyExpirationDate), "yyyy-MM-dd")
        : null,
      data.additionalInformation ? String(data.additionalInformation) : null,
      image,
      warranty,
      avtiveBtn == 2 ? true : false,
      organisationId,
      data.procedure ? data.procedure : null,
      currentUserId,
      data.location || null,
      data.subLocations ? data.subLocations : null,
      data.purchasePrice ? Number(data.purchasePrice) : 0,
      data.manufacturer ? data.manufacturer : null,
      data.descriptionRate ? `${String(data.descriptionRate)}%` : null,
      data.estimatedUsefulLife ? Number(data.estimatedUsefulLife) : 0,
      data.people,
      data.teams,
      data?.isChild,
      data?.isChild ? Number(data?.parentAsset) : null
    );

    if (response.status == 200) {
      setButtonStatus(0);
      untoggle();
      resetForm();
      setResponseData({
        id: response.data.id,
        name: response.data.name,
        qrcode: response.data.qrcode,
      });

      setFilterOptions({
        status: [],
        locations: [],
        createdByUser: [],
        customers: [],
        vendors: [],
        categories: [],
        serialNumber: "",
      });

      setSearchTitle("");
      setSelectedDates([]);

      if (initData?.length == 0) {
        const fetchData = await dispatch(
          fetchAssetsInitData({ organisationId, role, allIds })
        );
      }

      toast.success(t.assets.asset + " " + t.common.added_success);
    } else if (response.status == 400) {
      if (
        response.error &&
        response.error.details &&
        response.error.details.errors?.length
      ) {
        response.error.details.errors.map((one) => {
          if (one?.path[0] && one?.path[0] === "serialNumber") {
            toast.error(t.assets.serial_number_exist);
          } else {
            toast.error(`${one?.path[0]}- ${one.message}`);
          }
        });
      } else if (response.error && response.error.message) {
        toast.error(response.error.message);
        // untoggle();
      } else {
        toast.error(t.common.something_wrong);
        untoggle();
      }
    } else {
      toast.error(t.common.something_wrong);
      untoggle();
    }
    setButtonStatus(0);
  };

  /// Executed after category is created to select the newly created category
  const categoryCreateSuccess = (res) => {
    fetchCategoryOptions();
    if (res?.data?.data?.attributes?.name) {
      setData({
        ...data,
        category: res?.data?.data?.id,
      });
    }
  };

  /*
   * The `addNewArea` function adds a new empty area object to the `data` object.
   */
  const addNewArea = () => {
    let areaData = [...data.area];
    areaData.push({ area: "" });
    setData({
      ...data,
      area: areaData,
    });
  };

  /*
   * The `handleAreaChange` function updates the `area` property of an item in the `data` object at a specific index with a new value.
   */
  const handleAreaChange = (value, index) => {
    let areaData = [...data.area];
    areaData[index].area = value;
    setData({
      ...data,
      area: areaData,
    });
  };

  /*
   * The `removeArea` function removes an element from the `area` array in the `data` object at the specified index.
   */
  const removeArea = (index) => {
    let areaData = [...data.area];
    areaData.splice(index, 1);
    setData({
      ...data,
      area: areaData,
    });
  };

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled ? "aside-modal active procedure" : "aside-modal procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">{t.assets.form.add_asset}</div>

              <div>
                <span
                  className="c-pointer"
                  style={{ position: "relative" }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange
                      ? setNewCancelModal(initChange && true)
                      : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == "en"
                        ? "bottom center"
                        : "bottom center"
                    }
                    show={showCancleTooltip}
                    fontFamily={
                      selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                    }
                    textBoxWidth="36px"
                    arrowAlign="center"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              height: "90vh",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
            className="body"
          >
            <Form.Field>
              <label className="label-item">
                {t.assets.form.name}
                <Asterisk color="#172B4D" />
              </label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.assets.form.enter_asset_name}
                value={data.name}
                onChange={onValuesChange("name")}
                error={validation.name.error && validation.name.skipped}
                onBlur={(e) => {
                  if (validation.name.pattern(data.name)) {
                    setValidation({
                      ...validation,
                      name: {
                        ...validation.name,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      name: {
                        ...validation.name,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.name.error && validation.name.skipped ? (
                <div className="label-error">{validation.name.message}</div>
              ) : (
                ""
              )}
            </Form.Field>

            <FileUpload
              onFileChange={onFileChange}
              name="image"
              attachments={attachments}
              setAttachments={setAttachments}
            />

            <Form className="mb-5">
              <Form.Field>
                <label className="label-item mt-5">
                  {t.assets.form.description}
                </label>
                <TextArea
                  autoComplete="new-password"
                  value={data.description}
                  onChange={onValuesChange("description")}
                  rows={2}
                  fluid
                  placeholder={t.assets.form.describe_asset}
                />
              </Form.Field>
            </Form>

            <div className="mt-3">
              <Checkbox
                // disabled={data?.isChild}
                checked={data?.isChild}
                label={t.assets.child_asset}
                onChange={onValuesChange("isChild")}
              />{" "}
            </div>
            {data?.isChild ? (
              <Form.Field>
                <label className="label-item mt-3">
                  {t.assets.parent_asset} <Asterisk color="#172B4D" />
                </label>
                <Dropdown
                  placeholder={t.assets.parent_asset_place}
                  fluid
                  selection
                  clearable
                  noResultsMessage={t.common.no_results_found}
                  search
                  onOpen={fetchAllAssets}
                  options={dropdownOptions.child_asset}
                  value={data.parentAsset}
                  onChange={onValuesChange("parentAsset")}
                  error={
                    validation.parent_asset.error &&
                    validation.parent_asset.skipped
                  }
                  onBlur={(e) => {
                    if (
                      validation.parent_asset.pattern(
                        data?.isChild && !data?.parentAsset
                      )
                    ) {
                      setValidation({
                        ...validation,
                        parent_asset: {
                          ...validation.parent_asset,
                          error: true,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        parent_asset: {
                          ...validation.parent_asset,
                          error: false,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.parent_asset.error &&
                validation.parent_asset.skipped ? (
                  <div className="label-error">
                    {validation.parent_asset.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field>
            ) : null}

            <Form.Field autoComplete="off">
              <label
                className={data?.isChild ? "label-item" : "label-item mt-2"}
              >
                {t.assets.form.serial_number}
                <Asterisk color="#172B4D" />
              </label>

              <div className="flexbox">
                <Input
                  style={{ flex: 1 }}
                  autoComplete="new-password"
                  fluid
                  type="text" // Changed to text to enforce maxLength
                  maxLength={13}
                  placeholder={t.assets.form.enter_serial_number}
                  value={data.serialNumber}
                  // onChange={onValuesChange('serialNumber')}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits and limit to 13 characters
                    if (/^[a-zA-Z0-9.]{0,13}$/.test(value)) {
                      onValuesChange("serialNumber")(e);
                    }
                  }}
                  error={
                    validation.serialNumber.error &&
                    validation.serialNumber.skipped
                  }
                  onBlur={(e) => {
                    if (validation.serialNumber.pattern(data.serialNumber)) {
                      setValidation({
                        ...validation,
                        serialNumber: {
                          ...validation.serialNumber,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        serialNumber: {
                          ...validation.serialNumber,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                  // onBlur={(e) => {
                  //   const { value } = e.target;
                  //   if (value && value.length > 0 ) {
                  //     setValidation({
                  //       ...validation,
                  //       serialNumber: {
                  //         ...validation.serialNumber,
                  //         error: true,
                  //         skipped: true,
                  //         message: t.assets.serial_valid,
                  //       },
                  //     });
                  //   } else if (value.length == 10) {
                  //     setValidation({
                  //       ...validation,
                  //       serialNumber: {
                  //         ...validation.serialNumber,
                  //         error: false,
                  //         skipped: true,
                  //       },
                  //     });
                  //   } else {
                  //     setValidation({
                  //       ...validation,
                  //       serialNumber: {
                  //         ...validation.serialNumber,
                  //         error: true,
                  //         skipped: true,
                  //         message: t.common.required_field,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                <span
                  className="ml-2 mr-2 p-1 d-flex align-items-center justify-content-center c-pointer"
                  style={{ backgroundColor: "#E9F2FF" }}
                  onClick={() => {
                    let generatedSerialNumber = generateUniqueInteger();
                    setData({
                      ...data,
                      serialNumber: generatedSerialNumber,
                    });
                    if (
                      validation.serialNumber.pattern(generatedSerialNumber)
                    ) {
                      setValidation({
                        ...validation,
                        serialNumber: {
                          ...validation.serialNumber,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        serialNumber: {
                          ...validation.serialNumber,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                >
                  <Repeat />
                </span>
              </div>
              {validation.serialNumber.error &&
              validation.serialNumber.skipped ? (
                <div className="label-error">
                  {validation.serialNumber.message}
                </div>
              ) : (
                ""
              )}
            </Form.Field>

            <label className="label-item mb-2">
              {t.parts.form.add_QR}
              <Asterisk color="#172B4D" />
            </label>

            <div className="flexbox barcode-btns">
              <button
                active={avtiveBtn == 1}
                onClick={() => setActiveBtn(1)}
                className={`qr-button ${avtiveBtn == 1 ? "active" : ""}`}
              >
                <span>
                  <BarcodeIcon />
                </span>{" "}
                {t.assets.filter.barcode}
              </button>

              <button
                className={`qr-button ${avtiveBtn == 2 ? "active" : ""}`}
                active={avtiveBtn == 2}
                onClick={() => setActiveBtn(2)}
              >
                <span className="mr-1">
                  <QRcodeIconInactive />
                </span>
                {t.assets.filter.qrcode}
              </button>
            </div>

            <Form.Field>
              <label className="label-item mt-6">{t.assets.form.model}</label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.assets.form.enter_asset_model}
                value={data.model}
                onChange={onValuesChange("model")}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.assets.form.category}
                <Asterisk color="#172B4D" />
              </label>
              <div className="flexbox">
                <Dropdown
                  style={{ flex: 1 }}
                  noResultsMessage={t.common.no_results_found}
                  selection
                  search
                  clearable
                  fluid
                  options={dropdownOptions.categories}
                  placeholder={t.assets.form.select_category}
                  value={data.category}
                  onChange={onValuesChange("category")}
                  onOpen={fetchCategoryOptions}
                  error={
                    validation.category.error && validation.category.skipped
                  }
                  onBlur={(e) => {
                    if (validation.category.pattern(data.category)) {
                      setValidation({
                        ...validation,
                        category: {
                          ...validation.category,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        category: {
                          ...validation.category,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />

                {userPermissions?.category?.create &&
                userPermissions?.category?.view ? (
                  <span
                    className="ml-2 mr-2 p-1 d-flex align-items-center justify-content-center c-pointer"
                    onClick={() => {
                      setOpenCategoryAddModal(true);
                    }}
                    style={{ backgroundColor: "#E9F2FF" }}
                  >
                    <AddIcon />
                  </span>
                ) : null}
              </div>
              {validation.category.error && validation.category.skipped ? (
                <div className="label-error">{validation.category.message}</div>
              ) : (
                ""
              )}
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.assets.form.procedure}</label>
              <Dropdown
                placeholder={t.assets.form.select_procedure}
                noResultsMessage={t.common.no_results_found}
                fluid
                selection
                search
                multiple
                options={dropdownOptions.procedures}
                value={data.procedure}
                onChange={onValuesChange("procedure")}
                onOpen={fetchProceduresOptions}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.assets.form.location}
                <Asterisk color="#172B4D" />
              </label>
              <Dropdown
                placeholder={t.assets.form.select_location}
                className="loc-dropdown"
                noResultsMessage={t.common.no_results_found}
                fluid
                selection
                clearable
                search
                options={dropdownOptions.locations}
                value={data.location}
                onChange={onValuesChange("location")}
                onOpen={fetchLocations}
                error={validation.location.error && validation.location.skipped}
                onBlur={(e) => {
                  if (validation.location.pattern(data.location)) {
                    setValidation({
                      ...validation,
                      location: {
                        ...validation.location,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      location: {
                        ...validation.location,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.location.error && validation.location.skipped ? (
                <div className="label-error">{validation.location.message}</div>
              ) : (
                ""
              )}
            </Form.Field>

            {/* <Grid columns="equal">
              <Grid.Column>
                <>
                  <label className="label-item">
                    {t.assets.form.sub_locations}
                  </label>
                  <Dropdown
                    placeholder={t.assets.form.select_sub_locations}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    selection
                    disabled={!data?.location}
                    search
                    options={dropdownOptions.subLocations}
                    value={data.subLocations}
                    onChange={onValuesChange('subLocations')}
                    onOpen={fetchSubLocations}
                  />
                </>
              </Grid.Column>
            </Grid> */}

            <Grid columns="equal">
              <Grid.Column>
                <>
                  <label className="label-item">{t.assets.form.area}</label>
                  <Dropdown
                    placeholder={t.assets.form.add_area}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    selection
                    clearable
                    disabled={!data?.location}
                    search
                    options={dropdownOptions.area}
                    value={data.area}
                    onChange={onValuesChange("area")}
                    onOpen={fetchArea}
                  />
                </>
              </Grid.Column>
            </Grid>

            {/* <div className="mt-4 mb-4">
             <Checkbox
               checked={data.isSubLocation}
               label={t.assets.form.is_there_sub_location}
               onChange={(e, value) => {
                 if (value.checked) {
                   setData({
                     ...data,
                     isSubLocation: true,
                   });
                 } else {
                   setData({
                     ...data,
                     isSubLocation: false,
                   });
                 }
               }}
             />
           </div>


            
      
           {data.isSubLocation ? (
             <div className="mt-4 mb-4">
               <Checkbox
                 checked={data.isArea}
                 label={t.assets.form.is_there_area}
                 onChange={onValuesChange('isArea')}
               />
             </div>
           ) : null}


           {data.isSubLocation && data.isArea ? (
             <Grid columns="equal">
               <Grid.Column>
                 <label className="label-item">{t.assets.form.area}</label>
                 {data.area.map((one, index) => {
                   return (
                     <>
                       <div className="flexbox mt-3">
                         <Input
                           style={{ flex: 1 }}
                           className=" area-input-div"
                           placeholder={t.assets.form.add_area}
                           type="text"
                           fluid
                           selection
                           value={one.area}
                           onChange={(e) =>
                             handleAreaChange(e.target.value, index)
                           }
                         />


                         {index < data.area.length - 1 ? (
                           <span
                             className="ml-2 mr-2 p-1 d-flex align-items-center justify-content-center c-pointer"
                             onClick={() => {
                               removeArea(index);
                             }}
                             style={{ backgroundColor: '#E9F2FF' }}
                           >
                             <DeleteIcon fill={'#0C66E4'} />
                           </span>
                         ) : (
                           <span
                             className="ml-2 mr-2 p-1 d-flex align-items-center justify-content-center c-pointer"
                             onClick={() => {
                               addNewArea(index);
                             }}
                             style={{ backgroundColor: '#E9F2FF' }}
                           >
                             <AddIcon />
                           </span>
                         )}
                       </div>
                     </>
                   );
                 })}
               </Grid.Column>
             </Grid>
           ) : (
             false
           )} */}

            <Grid columns="equal">
              <Grid.Column>
                <Form.Field>
                  <label className="label-item">{t.assets.form.customer}</label>
                  <Dropdown
                    placeholder={t.assets.form.select_customer}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    clearable
                    search
                    selection
                    options={dropdownOptions.customers}
                    value={data.customer}
                    // disabled={
                    //   !data.location &&
                    //   !(role === "super_admin" || role === "admin")
                    // }
                    onChange={onValuesChange("customer")}
                    onOpen={() => fetchCustomers()}
                  />
                </Form.Field>
                <Form.Field>
                  <label className="label-item">{t.assets.form.vendor}</label>
                  <Dropdown
                    placeholder={t.assets.form.select_vendor}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    search
                    clearable
                    selection
                    options={dropdownOptions.vendors}
                    value={data.vendor}
                    onChange={onValuesChange("vendor")}
                    onOpen={() => fetchVendorsData()}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.workOrders.form.teams}
                  </label>
                  <Dropdown
                    placeholder={t.workOrders.form.teams_placeholder}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    selection
                    search
                    multiple
                    // ////allowAdditions
                    // onAddItem={handleAddTeam}
                    options={dropdownOptions.teams}
                    // disabled={
                    //   !data.location &&
                    //   !(role === "super_admin" || role === "admin")
                    // }
                    value={data.teams}
                    onChange={onValuesChange("teams")}
                    onOpen={fetchTeamsOptions}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">{t.people.title}</label>
                  <Dropdown
                    placeholder={t.workOrders.form.people_placeholder}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    selection
                    search
                    multiple
                    options={dropdownOptions.people}
                    value={data.people}
                    // disabled={!data.location}
                    // disabled={
                    //   !data.location &&
                    //   !(role === "super_admin" || role === "admin")
                    // }
                    onChange={onValuesChange("people")}
                    onOpen={fetchPeopleOptions}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.assets.form.purchase_price}
                  </label>

                  <Input
                    autoComplete="new-password"
                    type="number"
                    onKeyDown={(evt) => handleKeyDown(evt)}
                    min="0"
                    fluid
                    placeholder={t.assets.form.add_purchase_price}
                    value={data.purchasePrice}
                    onChange={onValuesChange("purchasePrice")}
                  >
                    <input />
                    <span
                      className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                        selectedLanguage == "en" ? "ml-5" : "mr-5"
                      }`}
                      style={{ backgroundColor: "#E9F2FF", color: "#0C66E4" }}
                    >
                      {organisationCurrency}
                    </span>
                  </Input>
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.assets.form.manufacturer}
                  </label>

                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.assets.form.add_manufacturer}
                    value={data.manufacturer}
                    onChange={onValuesChange("manufacturer")}
                  ></Input>
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.assets.form.depreciation_rate}
                  </label>
                  <div className="estimation-time-div">
                    <Input
                      autoComplete="new-password"
                      type="number"
                      fluid
                      onKeyDown={(evt) => handleKeyDown(evt)}
                      min="0"
                      placeholder={t.assets.form.add_depreciation_rate}
                      value={data.descriptionRate}
                      onChange={onValuesChange("descriptionRate")}
                    >
                      <input />
                      <span
                        className="mr-3"
                        style={
                          selectedLanguage == "en"
                            ? { right: "0px" }
                            : { left: "0px" }
                        }
                      >
                        <PercentageIcon />
                      </span>
                    </Input>
                  </div>
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.assets.form.estimated_life}
                  </label>

                  <Input
                    type="number"
                    fluid
                    onKeyDown={(evt) => handleKeyDown(evt)}
                    min="0"
                    placeholder={t.assets.form.add_estimated_life}
                    value={data.estimatedUsefulLife}
                    onChange={onValuesChange("estimatedUsefulLife")}
                  >
                    <input />
                    <span
                      className={`mr-2 d-flex align-items-center justify-content-center ${
                        selectedLanguage == "en" ? "ml-5" : "mr-5"
                      }`}
                      style={{
                        padding: "8px",
                        backgroundColor: "#E9F2FF",
                        color: "#0C66E4",
                      }}
                    >
                      {"Yr"}
                    </span>
                  </Input>
                </Form.Field>
                <Form.Field>
                  <label className="label-item">
                    {t.assets.form.placed_in_service_date}
                  </label>
                  <div className="change-date-time-inll position-relative">
                    <DatePicker
                      style={{ width: "100%" }}
                      value={data.placedDate ? new Date(data.placedDate) : null}
                      onChange={(value) => {
                        if (value && value.valueOf()) {
                          setData({
                            ...data,
                            placedDate: format(value.valueOf(), "yyyy-MM-dd"),
                          });
                        } else {
                          setData({
                            ...data,
                            placedDate: null,
                          });
                        }
                      }}
                      showMonthDropdown
                      showYearDropdown
                      placeholder={t.assets.form.add_placed_in_service_date}
                      className={"active"}
                      weekDays={[
                        t.workOrders.details_view.sun,
                        t.workOrders.details_view.mon,
                        t.workOrders.details_view.tue,
                        t.workOrders.details_view.wed,
                        t.workOrders.details_view.thu,
                        t.workOrders.details_view.fri,
                        t.workOrders.details_view.sat,
                      ]}
                      months={[
                        t.common.january,
                        t.common.february,
                        t.common.march,
                        t.common.april,
                        t.common.may,
                        t.common.june,
                        t.common.july,
                        t.common.august,
                        t.common.september,
                        t.common.october,
                        t.common.november,
                        t.common.december,
                      ]}
                      format="DD/MM/YYYY"
                    ></DatePicker>

                    <img
                      className="position-absolute"
                      style={
                        selectedLanguage == "en"
                          ? { top: "8px", right: "7px" }
                          : { top: "8px", left: "7px" }
                      }
                      src={calendar_icon}
                    />
                  </div>
                </Form.Field>
                <Form.Field>
                  <label className="label-item">
                    {t.assets.form.warranty_expiration_date}
                  </label>
                  <div className="change-date-time-inll position-relative">
                    <DatePicker
                      style={{ width: "100%" }}
                      value={
                        data.warrantyExpirationDate
                          ? new Date(data.warrantyExpirationDate)
                          : null
                      }
                      onChange={(value) => {
                        if (value && value.valueOf()) {
                          setData({
                            ...data,
                            warrantyExpirationDate: format(
                              value.valueOf(),
                              "yyyy-MM-dd"
                            ),
                          });
                        } else {
                          setData({
                            ...data,
                            warrantyExpirationDate: null,
                          });
                        }
                      }}
                      showMonthDropdown
                      showYearDropdown
                      placeholder={t.assets.form.add_warranty_expiration_date}
                      className={"active"}
                      weekDays={[
                        t.workOrders.details_view.sun,
                        t.workOrders.details_view.mon,
                        t.workOrders.details_view.tue,
                        t.workOrders.details_view.wed,
                        t.workOrders.details_view.thu,
                        t.workOrders.details_view.fri,
                        t.workOrders.details_view.sat,
                      ]}
                      months={[
                        t.common.january,
                        t.common.february,
                        t.common.march,
                        t.common.april,
                        t.common.may,
                        t.common.june,
                        t.common.july,
                        t.common.august,
                        t.common.september,
                        t.common.october,
                        t.common.november,
                        t.common.december,
                      ]}
                      format="DD/MM/YYYY"
                    ></DatePicker>

                    <img
                      className="position-absolute"
                      style={
                        selectedLanguage == "en"
                          ? { top: "8px", right: "7px" }
                          : { top: "8px", left: "7px" }
                      }
                      src={calendar_icon}
                    />
                  </div>
                </Form.Field>
              </Grid.Column>
            </Grid>

            <Form.Field autoComplete="off">
              <label className="label-item">
                {t.assets.form.additional_information}
              </label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.assets.form.add_additional_information}
                value={data.additionalInformation}
                onChange={onValuesChange("additionalInformation")}
              />
            </Form.Field>

            <Form.Field>
              <FileUpload
                onFileChange={onFileChange}
                name="warrantyFile"
                attachments={attachments}
                onCertificateFileChange={onCertificateFileChange}
                setAttachments={setAttachments}
              />
            </Form.Field>

            <div className="form-button-container">
              <button
                onClick={() =>
                  initChange
                    ? setNewCancelModal(initChange && true)
                    : cancelHandler()
                }
                className="form-action-button"
              >
                {t.common.cancel}
              </button>

              <button
                onClick={createNewAssetHandler}
                className="form-action-button blue"
                disabled={buttonStatus == 1}
              >
                {t.common.submit}
              </button>
            </div>
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

      {/* success modal */}
      <SuccessModal
        closeCallback={async () => {
          setFilterOptions({
            status: [],

            locations: [],
            createdByUser: [],
            customers: [],
            vendors: [],
            categories: [],
            serialNumber: "",
          });

          setSearchTitle("");
          setSelectedDates([]);

          if (initData?.length == 0) {
            const fetchData = await dispatch(
              fetchAssetsInitData({ organisationId, role, allIds })
            );
          }
        }}
      >
        <span>
          {t.assets.asset} <b>{responseData.name}</b> {t.common.added_success}
        </span>
      </SuccessModal>

      {/* cancel modal */}
      {/*
     <CancelModal
       yesCallback={() => {
         cancelHandler();
       }}
       deleteErrorMessage={true}
     >
       <span>{t.common.cancel_statement}</span>
     </CancelModal> */}
      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: "24px" }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>

      {/* Add Category Modal */}
      <AddCategoryModal
        isOpen={openCategoryAddModal}
        setModalOpenState={setOpenCategoryAddModal}
        handleSuccess={(res) => categoryCreateSuccess(res)}
      />
    </>
  );
}

export default Add;
