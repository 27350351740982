import React, { useEffect, useState, createRef } from 'react';
import {
  Checkbox,
  Table,
  Button,
  Header,
  Pagination,
  Icon,
  Placeholder,
  Label,
  Image,
  Grid,
  Menu,
} from 'semantic-ui-react';
import { selectTranslations } from '../../../config/i18n/slice';
import { useHistory } from 'react-router-dom';

import Moment from 'react-moment';

import { NavLink } from 'react-router-dom';

import { useLocation } from 'react-router';

import md5 from 'md5';

import moment from 'moment';

import {
  getAllWorkOrdersByLocationID,
  getUserProfileByID,
  humanize,
  IMAGES_URL,
} from '../../../config/functions';
import { useSelector } from 'react-redux';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import Animation from '../../../components/common/CustomLoader';

const WorkOrders = ({ data }) => {
  const t = useSelector(selectTranslations);

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [fetching, setFetchLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [initData, setInitData] = useState([]);
  const [expandedRow, setExpandedRow] = useState();
  const [targetedOrder, setTargetedOrder] = useState();
  const [togglePreview, setTogglePreviw] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const order = useLocation();
  const history = useHistory();
  const { userPermissions,checkForLinkPermission, checkForPermission } = usePermissionsSimplified();

  useEffect(() => {
    //console.log('data.id', data.id);
    const fetchData = async () => {
      const fetchData = await getAllWorkOrdersByLocationID(
        data.id,
        organisationId
      );
      //console.log('fetchData PRDER ', fetchData);
      if (fetchData) {
        setOrders(fetchData);
        setInitData(fetchData);
        setFetchLoading(false);
      }
    };
    setFetchLoading(false); 
    fetchData();
  }, [refresh]);

  const statusOptions = [
    {
      value: 'open',
      color: 'blue',
      label: t.workOrders.work_order_status.open,
    },
    {
      value: 'cancelled',
      color: 'darkgrey',
      label: t.workOrders.work_order_status.cancel,
    },
    {
      value: 'inProgress',
      color: 'yellow',
      label: t.workOrders.work_order_status.inProgress,
    },
    {
      value: 'onHold',
      color: 'red',
      label: t.workOrders.work_order_status.onHold,
    },
    {
      value: 'inReview',
      color: 'black',
      label: t.workOrders.work_order_status.inReview,
    },
    {
      value: 'completed',
      color: 'green',
      label: t.workOrders.work_order_status.completed,
    },
  ];
  const categoryOptions = [
    {
      value: 'damage',
      label: t.workOrders.form.category_options[0],
    },
    {
      value: 'corrective action Downtime',
      label: t.workOrders.form.category_options[1],
    },
    {
      value: 'planned Downtime',
      label: t.workOrders.form.category_options[2],
    },
    {
      value: 'unplanned Downtime',
      label: t.workOrders.form.category_options[3],
    },
    {
      value: 'inspection',
      label: t.workOrders.form.category_options[4],
    },
    {
      value: 'electrical',
      label: t.workOrders.form.category_options[5],
    },
    {
      value: 'mechanical',
      label: t.workOrders.work_order_category.Mechanical,
    },
    {
      value: 'HVAC',
      label: t.workOrders.work_order_category.HVAC,
    },
    {
      value: 'preventive maintenance',
      label: t.workOrders.form.category_options[8],
    },
    {
      value: 'project',
      label: t.workOrders.form.category_options[9],
    },
    {
      value: 'safety',
      label: t.workOrders.work_order_category.Safety,
    },
    {
      value: 'sop',
      label: t.workOrders.form.category_options[11],
    },
  ];

  const priorityOptions = [
    {
      value: 'none',
      label: t.workOrders.priority_status.none,
      color: null,
    },
    {
      value: 'low',
      label: t.workOrders.priority_status.low,

      color: 'yellow',
    },
    {
      value: 'medium',
      label: t.workOrders.priority_status.medium,

      color: 'orange',
    },
    {
      value: 'high',
      label: t.workOrders.priority_status.high,

      color: 'red',
    },
  ];

  const handleSetDataByFilter = (newData) => {
    setOrders(newData);
  };

  const isPastDue = (date) => {
    var date = moment(date).format('YYYY-MM-DD');
    var now = moment().format('YYYY-MM-DD');

    return now > date;
  };

  return (
    <div>
      {fetching ? (
         <div className='d-flex justify-content-center align-items-center' style={{minHeight:"500px"}}>
         <Animation/>
         {/* <Placeholder fluid>
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
         </Placeholder> */}
       </div>
        // <div>
        //  <Placeholder fluid>
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //   </Placeholder>
        // </div>
      ) : (
        <div className="preview-details-wrapper">
          <div className="data-header mt-5">
            {orders.map((el) => {
              const workOrderPriorityColor = priorityOptions.find(
                (pro) => pro.value == el.attributes.priority
              );

              const workOrderStatusColor = statusOptions.find(
                (status) => status.value == el.attributes.status
              );

              const categoryStatus = categoryOptions.find(
                (one) => one.value == el.attributes.category
              );

              let assigneePeople = el.attributes.people;
              if (assigneePeople?.length > 0) {
                assigneePeople = assigneePeople
                  .slice(0, 3)
                  .map((people, index) => {
                    let avatarURL = `http://gravatar.com/avatar/${md5(
                      people.firstName + ' ' + people.lastName
                    )}?d=identicon`;
                    return (
                      <Image
                        avatar
                        title={people.firstName + ' ' + people.lastName}
                        src={avatarURL}
                        style={
                          index != 0
                            ? {
                                marginLeft: '-6px',
                                width: '16px',
                                height: '16px',
                              }
                            : { width: '16px', height: '16px' }
                        }
                      />
                    );
                  });
              }

              let assigneeTeams = el.attributes.teams;
              if (assigneeTeams?.length > 0) {
                assigneeTeams = assigneeTeams.slice(0, 3).map((team, index) => {
                  let avatarURL = `http://gravatar.com/avatar/${md5(
                    team.name
                  )}?d=identicon`;
                  return (
                    <Image
                      avatar
                      title={team.name}
                      src={avatarURL}
                      style={
                        index != 0
                          ? {
                              marginLeft: '-6px',
                              width: '16px',
                              height: '16px',
                            }
                          : { width: '16px', height: '16px' }
                      }
                    />
                  );
                });
              }

              let createdByUser = el.attributes.createdByUser ? (
                <div>
                  <Image
                    avatar
                    title={
                      el.attributes.createdByUser.firstName +
                      ' ' +
                      el.attributes.createdByUser.lastName
                    }
                    src={`http://gravatar.com/avatar/${md5(
                      el.attributes.createdByUser.firstName +
                        ' ' +
                        el.attributes.createdByUser.lastName
                    )}?d=identicon`}
                    style={{ width: '16px', height: '16px' }}
                  />
                  <span style={{ marginLeft: '4px' }}>
                    {el.attributes.createdByUser.firstName}
                  </span>
                </div>
              ) : (
                t.common.na
              );

              return (
                <>
                  <div className="data-header map-preview mt-5">
                    <div className="header-title d-flex justify-content-between align-items-center">
                      <div className="header-pill-container d-flex align-items-baseline">
                        <p className="detail-title" style={{ width: '200px' }}>
                        {checkForLinkPermission('work-order') ? 
                          <div
                            className="location-link mt-1"
                            basic
                            style={{ marginLeft: '4px' }}
                            onClick={()=>
                              history.push(
                                `/work-orders?id=${el?.attributes?.countId}`
                              )}
                          >
                            {el.attributes.title}
                          </div> :  <div
                            className="mt-1"
                            basic
                            style={{ marginLeft: '4px' }}
                          >
                            {el.attributes.title}
                          </div>}
                        </p>

                        <span
                          className={`pill gap-0 ${el.attributes.priority}`}
                        >
                         {workOrderPriorityColor?.label +
                            ' ' +
                            t.locations.work_order_fields.priority}
                        </span>
                      </div>
                      <div className='header-id'>
                        <label   className={`${
                                  selectedLanguage == 'en' ? '' : 'ml-2'
                                }`}>{t.locations.table.ID}</label>
                        {':'}
                        <span>{el?.attributes?.countId}</span>
                      </div>
                    </div>
                    <div className="">
                      <p className="detail-updated-title">
                        {t.assets.table.updatedAt}
                        {':'}{' '}
                        <span>
                          {
                            <Moment
                              format="DD/MM/YYYY LT"
                              date={el?.attributes.updatedAt}
                            />
                          }
                        </span>
                      </p>
                      {/* {userPermissions?.asset?.statusToggle ? (
              <div>
                <div className="d-flex align-items-center">
                  <div className="ml-3 mr-3">
                    {data?.attributes?.status
                      ? t.assets.active
                      : t.assets.inActive}
                  </div>
                  <Toggle
                    checked={toggleStatus}
                    defaultChecked={data?.attributes?.status}
                    onChange={(e) => handleAssetStatus(data?.id, e)}
                  />
                </div>
              </div>
            ) : null} */}
                    </div>

                    <div className="d-flex align-items-center mt-2">
                      <label className="label-item mt-5">
                        {''}
                        <label className="workorder-intro-label location-label2">
                          {t.workOrders.filter.status}
                          {':'}
                        </label>
                      </label>
                      <p className="workorder-intro-value mt-2 d-flex gap-3">
                        <div className="item-value mt-2">
                          <Label
                            circular
                            empty
                            color={workOrderStatusColor?.color}
                            style={selectedLanguage == 'en' ? { marginRight: '5px' } : {marginLeft: '5px'}}
                          
                          />
                        </div>

                        <span className="mt-2">
                          {el?.attributes.status
                            ? workOrderStatusColor?.label
                            : t.common.na}
                        </span>
                      </p>
                    </div>

                    <div className="d-flex align-items-center mt-4">
                      <label className="workorder-intro-label location-label2">
                        {t.workOrders.table.category}
                        {':'}
                      </label>
                      <p className="workorder-intro-value">
                        {categoryStatus?.label || t.common.na}
                      </p>
                    </div>

                    <div className="d-flex align-items-center mt-5">
                      <label className="workorder-intro-label location-label2">
                        {t.workOrders.filter.dueDate}
                        {':'}
                      </label>
                      <p className="workorder-intro-value">
                        {el?.attributes?.dueDate ? (
                          <Moment
                            format="DD/MM/YYYY LT"
                            date={
                              el.attributes.dueDate +
                              'T' +
                              el.attributes.dueTime
                            }
                          />
                        ) : (
                          <span>{t.common.na}</span>
                        )}
                      </p>
                    </div>

                    <div className="d-flex align-items-start mt-5">
                      <label className="workorder-intro-label location-label2">
                        {t.workOrders.table.procedure}
                        {':'}
                      </label>
                      <p className="workorder-intro-value">
                     { checkForLinkPermission('procedure') ?
                        <div
                          className={`${
                            el.attributes?.procedure?.data?.attributes?.title
                              ? 'work-order-link'
                              : ''
                          }`}
                          onClick={() => history.push(`/procedures?id=${el.attributes?.procedure?.data?.attributes?.countId}`)}
                          basic
                          style={{ marginLeft: '4px' }}
                        >
                          {el.attributes?.procedure?.data?.attributes?.title ||
                            t.common.na}
                        </div> : 
                          <div
                          className={`${
                            el.attributes?.procedure?.data?.attributes?.title
                              ? ''
                              : ''
                          }`}
                          basic
                          style={{ marginLeft: '4px' }}
                        >
                          {el.attributes?.procedure?.data?.attributes?.title ||
                            t.common.na}
                        </div> }
                      </p>
                    </div>
                  </div>

                  {/* 

                        <Table.Row
                          key={el.id}
                          id={el.id}
                          className="accordion-row"
                          negative={isPastDue(
                            el.attributes.dueDate + 'T' + el.attributes.dueTime
                          )}
                        >
                          <Table.Cell>{el.id}</Table.Cell>
                          <Table.Cell>{el.attributes.title}</Table.Cell>
                          <Table.Cell>
                            <Label
                              circular
                              empty
                              color={workOrderStatusColor?.color}
                              style={{ marginRight: '5px' }}
                            />
                            {el.attributes.priority
                              ? workOrderStatusColor?.label
                              : 'None'}
                          </Table.Cell>
                          <Table.Cell>
                            <Label
                              circular
                              empty
                              color={workOrderPriorityColor?.color}
                              style={{ marginRight: '5px' }}
                            />
                            {el.attributes.status
                              ? workOrderPriorityColor?.label
                              : 'None'}
                          </Table.Cell>

                          <Table.Cell>{categoryStatus?.label}</Table.Cell>
                          <Table.Cell>
                            {
                              <Moment
                                format="DD/MM/YYYY LT"
                                date={
                                  el.attributes.dueDate +
                                  'T' +
                                  el.attributes.dueTime
                                }
                              />
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {el.attributes?.procedure?.data?.attributes?.title}
                          </Table.Cell>
                        </Table.Row> */}
                </>
              );
            })}
            {/* <div className=" d-flex justify-content-between align-items-center">
            <div className="header-pill-container d-flex align-items-baseline">
              <p className="detail-title" style={{ width: '200px' }}>
                {data?.attributes?.title}
              </p>
           {   console.log(data)}
              <span className={`pill gap-0 ${data.attributes.priority}`}>
                {humanize(data.attributes.priority)}
              </span>
            </div>
            <div>
              <label>{t.assets.table.ID}</label>
              {':'}
              <span>{data?.id}</span>
            </div>
          </div> */}
            {/* <div className="">
            <p className="procedure-intro-value">
              {data?.attributes?.description || t.common.na}
            </p>
            <p className="detail-updated-title">
              {t.assets.table.updatedAt}
              {':'}{' '}
              <span>
                {
                  <Moment
                    format="DD/MM/YYYY LT"
                    date={data?.attributes.updatedAt}
                  />
                }
              </span>
            </p> */}
            {/* {userPermissions?.asset?.statusToggle ? (
                <div>
                  <div className="d-flex align-items-center">
                    <div className="ml-3 mr-3">
                      {data?.attributes?.status
                        ? t.assets.active
                        : t.assets.inActive}
                    </div>
                    <Toggle
                      checked={toggleStatus}
                      defaultChecked={data?.attributes?.status}
                      onChange={(e) => handleAssetStatus(data?.id, e)}
                    />
                  </div>
                </div>
              ) : null} */}
          </div>

          {orders.length == 0 ? (
            <div style={{ textAlign: 'center' }}>
              <Header as="h3">{t.common.na}</Header>
            </div>
          ) : null}
          {/* </div> */}
        </div>
        // <div>
        //   {initData.length > 0 ? (
        //     <>
        //       <Table selectable>
        //         <Table.Header>
        //           <Table.Row>
        //             {/* <Table.HeaderCell>
        //               <Checkbox />
        //             </Table.HeaderCell> */}
        //             <Table.HeaderCell title="Work Order #">
        //               {t.locations.work_order_fields.ID}
        //             </Table.HeaderCell>
        //             <Table.HeaderCell>
        //               {t.locations.work_order_fields.title}
        //             </Table.HeaderCell>
        //             <Table.HeaderCell>
        //               {t.locations.work_order_fields.status}
        //             </Table.HeaderCell>
        //             <Table.HeaderCell>
        //               {t.locations.work_order_fields.priority}
        //             </Table.HeaderCell>
        //             <Table.HeaderCell>
        //               {t.locations.work_order_fields.category}
        //             </Table.HeaderCell>
        //             <Table.HeaderCell>
        //               {t.locations.work_order_fields.due_date}
        //             </Table.HeaderCell>
        //             <Table.HeaderCell>
        //               {t.locations.work_order_fields.procedure}
        //             </Table.HeaderCell>
        //           </Table.Row>
        //         </Table.Header>

        //         <Table.Body>
        //           {orders.map((el) => {
        //             const workOrderPriorityColor = priorityOptions.find(
        //               (pro) => pro.value == el.attributes.priority
        //             );

        //             const workOrderStatusColor = statusOptions.find(
        //               (status) => status.value == el.attributes.status
        //             );

        //             const categoryStatus = categoryOptions.find(
        //               (one) => one.value == el.attributes.category
        //             );

        //             let assigneePeople = el.attributes.people;
        //             if (assigneePeople?.length > 0) {
        //               assigneePeople = assigneePeople
        //                 .slice(0, 3)
        //                 .map((people, index) => {
        //                   let avatarURL = `http://gravatar.com/avatar/${md5(
        //                     people.firstName + ' ' + people.lastName
        //                   )}?d=identicon`;
        //                   return (
        //                     <Image
        //                       avatar
        //                       title={people.firstName + ' ' + people.lastName}
        //                       src={avatarURL}
        //                       style={
        //                         index != 0
        //                           ? {
        //                               marginLeft: '-6px',
        //                               width: '16px',
        //                               height: '16px',
        //                             }
        //                           : { width: '16px', height: '16px' }
        //                       }
        //                     />
        //                   );
        //                 });
        //             }

        //             let assigneeTeams = el.attributes.teams;
        //             if (assigneeTeams?.length > 0) {
        //               assigneeTeams = assigneeTeams
        //                 .slice(0, 3)
        //                 .map((team, index) => {
        //                   let avatarURL = `http://gravatar.com/avatar/${md5(
        //                     team.name
        //                   )}?d=identicon`;
        //                   return (
        //                     <Image
        //                       avatar
        //                       title={team.name}
        //                       src={avatarURL}
        //                       style={
        //                         index != 0
        //                           ? {
        //                               marginLeft: '-6px',
        //                               width: '16px',
        //                               height: '16px',
        //                             }
        //                           : { width: '16px', height: '16px' }
        //                       }
        //                     />
        //                   );
        //                 });
        //             }

        //             let createdByUser = el.attributes.createdByUser ? (
        //               <div>
        //                 <Image
        //                   avatar
        //                   title={
        //                     el.attributes.createdByUser.firstName +
        //                     ' ' +
        //                     el.attributes.createdByUser.lastName
        //                   }
        //                   src={`http://gravatar.com/avatar/${md5(
        //                     el.attributes.createdByUser.firstName +
        //                       ' ' +
        //                       el.attributes.createdByUser.lastName
        //                   )}?d=identicon`}
        //                   style={{ width: '16px', height: '16px' }}
        //                 />
        //                 <span style={{ marginLeft: '4px' }}>
        //                   {el.attributes.createdByUser.firstName}
        //                 </span>
        //               </div>
        //             ) : (
        //               t.common.na
        //             );

        //             return (
        //               <>
        //                 <Table.Row
        //                   key={el.id}
        //                   id={el.id}
        //                   className="accordion-row"
        //                   negative={isPastDue(
        //                     el.attributes.dueDate + 'T' + el.attributes.dueTime
        //                   )}
        //                 >
        //                   <Table.Cell>{el.id}</Table.Cell>
        //                   <Table.Cell>{el.attributes.title}</Table.Cell>
        //                   <Table.Cell>
        //                     <Label
        //                       circular
        //                       empty
        //                       color={workOrderStatusColor?.color}
        //                       style={{ marginRight: '5px' }}
        //                     />
        //                     {el.attributes.priority
        //                       ? workOrderStatusColor?.label
        //                       : 'None'}
        //                   </Table.Cell>
        //                   <Table.Cell>
        //                     <Label
        //                       circular
        //                       empty
        //                       color={workOrderPriorityColor?.color}
        //                       style={{ marginRight: '5px' }}
        //                     />
        //                     {el.attributes.status
        //                       ? workOrderPriorityColor?.label
        //                       : 'None'}
        //                   </Table.Cell>

        //                   <Table.Cell>{categoryStatus?.label}</Table.Cell>
        //                   <Table.Cell>
        //                     {
        //                       <Moment
        //                         format="DD/MM/YYYY LT"
        //                         date={
        //                           el.attributes.dueDate +
        //                           'T' +
        //                           el.attributes.dueTime
        //                         }
        //                       />
        //                     }
        //                   </Table.Cell>
        //                   <Table.Cell>
        //                     {el.attributes?.procedure?.data?.attributes?.title}
        //                   </Table.Cell>
        //                 </Table.Row>
        //               </>
        //             );
        //           })}
        //         </Table.Body>
        //       </Table>
        //     </>
        //   ) : (
        //     <div style={{ textAlign: 'center' }}>
        //       <Header as="h3">{t.common.na}</Header>
        //     </div>
        //   )}
        // </div>
      )}
    </div>
  );
};

export default WorkOrders;
