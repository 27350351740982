import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../../config/i18n/slice";
import {
  Checkbox,
  Dimmer,
  Dropdown,
  Form,
  Input,
  Loader,
  Modal,
} from "semantic-ui-react";
import location_icon from "../../../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../../../Assets/Icons/pin_drop.png";
import {
  ArrowLeft,
  ArrowRight,
  CloseIcon,
  CustomerPlusIcon,
  SearchIcon,
} from "../../../../../Assets/Icons/svg";
import { useHistory } from "react-router-dom";
import {
  currencyDecimalPlaces,
  fetchInvLocationsOptions,
  getAllWOPartsData,
  handleKeyDown,
  updatePartWorkOrder,
} from "../../../../config/functions";
import { toast } from "react-toastify";
import useDisplayFormattedText from "../../../../../hooks/useDisplayFormattedText";
import DataTable from "react-data-table-component";
import Pagination from "../../../../components/common/CommonPagination";
import _ from "lodash";
import { usePermissionsSimplified } from "../../../../../hooks/usePermissionsSimplified";
const Part = ({ data, untoggle, setData }) => {
  const t = useSelector(selectTranslations);
  const {
    userPermissions,
    checkForLinkPermission,
  } = usePermissionsSimplified();
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const roleData = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });
  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });

  const allIds = [];

  currentUserLocations?.length &&
    currentUserLocations.forEach((location) => {
      if (!allIds.some((inv) => inv == location?.id)) {
        allIds.push(location?.id);
      }

      // location.subLocations.forEach((subLocation) => {
      //   if (!allIds.some((inv) => inv == subLocation?.id)) {
      //     allIds.push(subLocation?.id);
      //   }
      // });
    });

  const [options, setOptions] = useState({
    inv_location: [],
  });

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [partsData, setPartsData] = useState([]);
  const [selectedFinalPartData, setSelectedFinalPartData] = useState([]);
  const [selectedTablePartData, setSelectedTablePartData] = useState([]);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [partSearchTitle, setPartSearchTitle] = useState("");
  const [allPartsData, setAllPartsData] = useState([]);
  const [prevPartsData, setPrevPartsData] = useState([]);
  const [initPartsData, setInitPartsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [addPartModal, setAddPartModal] = useState(false);
  const [selectedInvLoc, setSelectedInvLoc] = useState("");
  const conditionalRowStyles = [
    {
      when: (row) => row?.disable == true,
      // Condition to check
      style: {
        backgroundColor: "#e4e4e4", // Apply red color to the row
      },
    },
  ];
  const mergeAndTransformData = (parts = [], sets = []) => {
    return [
      ...parts.map((item) => ({
        countId: item?.part?.data?.attributes?.countId,
        part: item?.part?.data?.id,
        unitType: item?.part?.data?.attributes?.measuringUnit,
        type: "Part",
        id: `${item?.part?.data?.attributes?.countId}-${item?.location?.data?.id}`,
        temp_id: item?.id,
        name: item?.part?.data?.attributes?.name,
        location_name: item?.location?.data?.attributes?.name,
        location: item?.location?.data?.id,
        quantity: item?.quantity !== null ? item?.quantity : 0,
        unitCost: item?.unitCost !== null ? Number(item?.unitCost) : 0,
        totalCost: item?.totalCost !== null ? Number(item?.totalCost) : 0,
      })),
      ...sets.map((item) => ({
        type: "Set",
        part: item?.set?.data?.id,
        unitType: null,
        id: `${item?.set?.data?.attributes?.countId}-${item?.location?.data?.id}`,
        temp_id: item?.id,
        countId: item?.set?.data?.attributes?.countId,
        name: item?.set?.data?.attributes?.name,
        location_name: item?.location?.data?.attributes?.name,
        location: item?.location?.data?.id,
        quantity: item?.quantity !== null ? item?.quantity : 0,
        unitCost: item?.unitCost !== null ? Number(item?.unitCost) : 0,
        totalCost: item?.totalCost !== null ? Number(item?.totalCost) : 0,
      })),
    ];
  };

  const getInvLocations = async () => {
    const fetchData = await fetchInvLocationsOptions(organisationId);

    let parentOptions = [];

    if (fetchData?.length > 0) {
      fetchData.forEach((el) => {
        parentOptions.push({
          key: el.id,
          value: el.id,
          text: el.name,
          disabled: el?.disable,
          image: { src: location_icon },
          className: "main-location-dropdown-option",
        });

        if (el?.subLocations?.length > 0) {
          el.subLocations.forEach((subEl) => {
            parentOptions.push({
              key: subEl.id,
              value: subEl.id,
              text: subEl.name,
              image: { src: sub_location_icon },
              className: "sub-location-dropdown-option",
              parentId: el.id, // Keep track of the parent
            });
          });
        }
      });
      setOptions({
        ...options,
        inv_location: parentOptions,
      });

      return parentOptions;
    }
  };
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#E9F2FF",
        paddingTop: "8px", // override the cell padding for head cells
      },
    },
    cells: {
      style: {
        paddingTop: "20px",
        paddingBottom: "8px",
      },
    },
  };

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const { DisplayFormattedText } = useDisplayFormattedText();
  let allQtyOptions = [
    {
      key: 0,
      value: "pieces",
      text: t.parts.qty_unit.piece,
    },
    {
      key: 1,
      value: "items",
      text: t.parts.qty_unit.items,
    },
    {
      key: 2,
      value: "units",
      text: t.parts.qty_unit.unit,
    },
    {
      key: 3,
      value: "liters (L)",
      text: selectedLanguage == "en" ? "l" : "لتر",
    },
    {
      key: 4,
      value: "gallons (gal)",
      text: selectedLanguage == "en" ? "gal" : "جالون",
    },
    {
      key: 5,
      value: "cubic meters (m³)",
      text: selectedLanguage == "en" ? "m³" : "م³",
    },
    {
      key: 6,
      value: "cubic feet (ft³)",
      text: selectedLanguage == "en" ? "ft³" : "ق³",
    },
    {
      key: 7,
      value: "barrels",
      text: t.parts.vol_unit.barrels,
    },
    {
      key: 8,
      value: "kilograms (kg)",
      text: selectedLanguage == "en" ? "kg" : "كجم",
    },
    {
      key: 9,
      value: "pounds (lb)",
      text: selectedLanguage == "en" ? "lb" : "رطل",
    },

    {
      key: 10,
      value: "tons",
      text: t.parts.mass_unit.tons,
    },
    {
      key: 11,
      value: "metric tonnes",
      text: t.parts.mass_unit.m_tons,
    },
  ];
  let _newData = initPartsData;
  useEffect(() => {
    // Clone the data to apply filters without mutating the original data
    let filteredData = [..._newData];
    setCurrentPage(1);
    // Apply partSearchTitle filter
    if (partSearchTitle.length > 0) {
      const re = new RegExp(_.escapeRegExp(partSearchTitle), "i");
      const isMatch = (result) => re.test(result?.name);
      filteredData = _.filter(filteredData, isMatch);
    }

    // Apply selectedInvLoc filter
    if (selectedInvLoc) {
      filteredData = filteredData.filter(
        (part) => part?.location === selectedInvLoc
      );
    }
    // Check if all parts are selected and set state
    if (filteredData.length > 0) {
      const allSelectedDisable = filteredData.every(
        (item) => item.disable === true
      );

      if (!allSelectedDisable) {
        const allSelected = filteredData
          .filter((item) => item.disable === false) // Only include non-disabled items
          .every((item) => item.selectedPart === true);
        setPartsData(filteredData);

        setAreAllSelected(allSelected);
      } else {
        setPartsData(filteredData);
        setAreAllSelected(false);
      }
      // const allSelected = filteredData.every((item) => item?.selectedPart === true);
    } else {
      setPartsData([]);
    }
  }, [partSearchTitle, selectedInvLoc]);

  const handleRowSelected = (row, checked, selectedPartsData = []) => {
    let allSelectedRows = [...selectedPartsData];
    let selectedRows = [...prevPartsData];

    if (checked) {
      allSelectedRows.push({ ...row, selectedPart: true, selectedPart: true });
      selectedRows.push({ ...row, selectedPart: true });
    } else {
      allSelectedRows = allSelectedRows.filter((el) => el?.id !== row?.id);
      selectedRows = selectedRows.filter((el) => el?.id !== row?.id);
    }

    const updatedData = [...partsData].map((item) => ({
      ...item,
      selectedPart: allSelectedRows.some((el) => el.id === item.id),
    }));

    const updatedInitData = [...initPartsData].map((item) => ({
      ...item,
      selectedPart: allSelectedRows.some((el) => el.id === item.id),
    }));

    // Check if all rows are selected to update "Select All" checkbox
    const allRowsSelected = updatedData.every((item) => item.selectedPart);

    setPartsData(updatedData);
    setInitPartsData(updatedInitData);
    setSelectedTablePartData(allSelectedRows);
    setAreAllSelected(allRowsSelected);
  };

  const handleQtyChange = (
    // values,
    row,
    checked,
    // setFieldValue,
    selectedPartsData = [],
    index = null
    // setFieldTouched
  ) => {
    let value = parseFloat(checked).toFixed(4);
    let allSelectedRows = [...partsData];
    let allSelectedRowsData = Array.isArray(selectedPartsData)
      ? [...selectedPartsData]
      : [];
    let allSelectedInitRows = [...initPartsData];
    const rowIndex = allSelectedRows.findIndex((el) => el?.id === row?.id);
    const rowInitIndex = allSelectedInitRows.findIndex(
      (el) => el?.id === row?.id
    );

    const updatedRow = { ...allSelectedRows[rowIndex] };
    const updatedInitRow = { ...allSelectedInitRows[rowInitIndex] };
    if (checked) {
      updatedRow.quantity = Number(value);
      updatedInitRow.quantity = Number(value);
      updatedRow.totalCost = handleDecimalPoints(
        Number(value) * Number(updatedRow.unitCost)
      );
      updatedInitRow.totalCost = handleDecimalPoints(
        Number(value) * Number(updatedInitRow.unitCost)
      );
      if (updatedRow.availableQty == Number(value)) {
        updatedRow.fillMax = true;
      } else {
        updatedRow.fillMax = false;
      }
      if (updatedInitRow.availableQty == Number(value)) {
        updatedInitRow.fillMax = true;
      } else {
        updatedInitRow.fillMax = false;
      }
    } else {
      updatedRow.quantity = null;
      updatedInitRow.quantity = null;
      updatedRow.fillMax = false;
      updatedInitRow.fillMax = false;
      updatedRow.totalCost = Number(updatedRow.unitCost);
      updatedInitRow.totalCost = Number(updatedInitRow.unitCost);
    }

    allSelectedRows[rowIndex] = updatedRow;
    allSelectedInitRows[rowInitIndex] = updatedInitRow;

    if (allSelectedRowsData?.length > 0) {
      const rowIndexSelected = allSelectedRowsData.findIndex(
        (el) => el?.id === row?.id
      );
      const updatedSelectedRow = { ...allSelectedRowsData[rowIndexSelected] };

      if (checked) {
        updatedSelectedRow.quantity = Number(value);
        updatedSelectedRow.totalCost = Number(
          handleDecimalPoints(
            Number(value) * Number(updatedSelectedRow.unitCost)
          )
        );

        if (Number(updatedSelectedRow.availableQty) == Number(value)) {
          updatedSelectedRow.fillMax = true;
        } else {
          updatedSelectedRow.fillMax = false;
        }
        // if (updatedSelectedRow.availableQty == Number(checked)) {
        //   updatedSelectedRow.fillMax = true;
        // }
      } else {
        updatedSelectedRow.quantity = null;
        updatedSelectedRow.fillMax = false;
        updatedSelectedRow.totalCost = 0;
        // Update the selected row in allSelectedRows

        // Update state with the modified data
      }

      allSelectedRowsData[rowIndexSelected] = updatedSelectedRow;

      let subTotalCost = 0;
      allSelectedRowsData.forEach((row) => {
        subTotalCost += Number(row.totalCost);
      });

      //   if (index !== null) {
      //     setFieldTouched(`orderItems.items[${index}].quantity`, false);
      //   }

      setSelectedTablePartData(allSelectedRowsData);

      handleDecimalPoints(subTotalCost);
      // setFieldValue('orderItems.items', allSelectedRowsData, false);
      //   setFieldValue("orderItems.subTotal", Number(subTotalCost), false);

      //   setFieldValue(
      //     "orderItems.totalCost",
      //     handleDecimalPoints(Number(finalCost)),
      //     false
      //   );
    }
    setPartsData(allSelectedRows);
    setInitPartsData(allSelectedInitRows);
  };
  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const roundedValue = Math.round(value * factor) / factor;
    const formattedValue = roundedValue.toFixed(decimalPlaces);

    // Remove trailing zeros after decimal point
    if (formattedValue.indexOf(".") !== -1) {
      return formattedValue.replace(/\.?0+$/, "");
    }

    return Number(formattedValue);
  };
  const HandleUnitType = (data) => {
    let unitType;
    if (data) {
      unitType = allQtyOptions.find((el) => el.value == data);
    }
    return data ? unitType?.text : null;
  };
  const addParts = async (selectedPartsData = [], fetchdata = false) => {
    setAreAllSelected(false);
    // setAddPartModal(true);
    if (partsData?.length <= 0 || fetchdata) {
      setLoading(true);
      const fetchData = await getAllWOPartsData(organisationId);

      if (fetchData) {
        if (fetchData?.length == 0) {
          setLoading(false);
          // setNoPartModal(true);
        } else {
          let allPartData = fetchData?.map((part) => {
            return {
              id: `${part.countId}-${part?.inventoryLocation?.id}`,
              part: part?.id,
              type: part.type,
              countId: part?.countId,
              name: part?.name,
              totalCost:
                part?.inventoryLocation?.unitCost !== null
                  ? Number(
                      handleDecimalPoints(part?.inventoryLocation?.unitCost)
                    )
                  : 0,
              quantity: 1,
              unitCost:
                part?.inventoryLocation?.unitCost !== null
                  ? Number(
                      handleDecimalPoints(part?.inventoryLocation?.unitCost)
                    )
                  : 0,
              unitType: part?.measuringUnit,
              selectedPart: false,
              location: part?.inventoryLocation?.id,
              location_name: part?.inventoryLocation?.name,
              asset: part?.relatedAsset,
              status: part?.inventoryLocation?.status,
              disable:
                part?.inventoryLocation?.status == "outOfStock" ? true : false,
              availableQty:
                part?.inventoryLocation?.availableQuantity !== null
                  ? part?.inventoryLocation?.availableQuantity
                  : 0,
            };
          });
          const filteredParts = fetchData
            ?.map((part) => {
              // let selectedPart = selectedPartsData.find(
              //   (el) => el?.id == part?.id
              // );

              const isSelected = selectedPartsData.some(
                (selectedPart) => selectedPart.id === part.id
              );

              if (isSelected) {
                return null; // Skip this part
              } else {
                return {
                  id: `${part.countId}-${part?.inventoryLocation?.id}`,
                  type: part.type,
                  part: part?.id,
                  countId: part?.countId,
                  name: part?.name,
                  // totalCost: part?.unitCost
                  //   ? handleDecimalPoints(part?.unitCost)
                  //   : 0,
                  totalCost:
                    part?.inventoryLocation?.unitCost !== null
                      ? Number(
                          handleDecimalPoints(part?.inventoryLocation?.unitCost)
                        )
                      : 0,
                  quantity: 1,
                  unitCost:
                    part?.inventoryLocation?.unitCost !== null
                      ? Number(
                          handleDecimalPoints(part?.inventoryLocation?.unitCost)
                        )
                      : 0,
                  // unitCost: part?.unitCost
                  //   ? handleDecimalPoints(part?.unitCost)
                  //   : 0,
                  unitType: part?.measuringUnit,
                  selectedPart: false,
                  location: part?.inventoryLocation?.id,
                  location_name: part?.inventoryLocation?.name,
                  asset: part?.relatedAsset,
                  status: part?.inventoryLocation?.status,
                  disable:
                    part?.inventoryLocation?.status == "outOfStock"
                      ? true
                      : false,
                  availableQty: part?.inventoryLocation?.availableQuantity
                    ? part?.inventoryLocation?.availableQuantity
                    : 0,
                };
              }
            })
            .filter((part) => part !== null);

          setPartsData(filteredParts);
          setInitPartsData(filteredParts);
          setAllPartsData(allPartData);
          setLoading(false);
          if (!fetchdata) {
            setAddPartModal(true);
          }
        }
      } else {
        toast.error(t.common.something_wrong);
        setLoading(false);
        if (!fetchdata) {
          setAddPartModal(true);
        }
      }
      setLoading(false);
    } else {
      setLoading(true);
      setAddPartModal(true);
      const filteredParts = allPartsData
        ?.map((part) => {
          const isSelected = selectedPartsData.some(
            (selectedPart) => selectedPart.id === part.id
          );
          if (isSelected) {
            return null; // Skip this part
          } else {
            return {
              id: part?.id,
              type: part?.type,
              countId: part?.countId,
              part: part?.part,
              name: part?.name,
              totalCost: part.totalCost,
              quantity: part?.quantity ? part?.quantity : null,
              unitCost: part?.unitCost,
              unitType: part?.unitType,
              selectedPart: false,
              location: part?.location,
              location_name: part?.location_name,
              asset: part?.asset,
              status: part?.status,
              disable: part?.disable,
              availableQty: part?.availableQty ? part.availableQty : 0,

              // selectedPart: false,
              // id: part.id,
              // countId: part.countId,
              // name: part.name,
              // totalCost: part.totalCost,
              // quantity: part?.quantity ? part?.quantity : null,
              // unitCost: part.unitCost,
              // unitType: part.unitType,
              // availableQty: part.availableQty,
              // orderedQuantity: part.orderedQuantity,
              // fillMax: part?.fillMax,
            };
          }
        })
        .filter((part) => part !== null);
      setPartsData(filteredParts);
      setInitPartsData(filteredParts);
      setLoading(false);
    }
  };

  const handleClosePartModal = () => {
    let newAddedData = [...prevPartsData];
    setSelectedInvLoc("");
    setAddPartModal(false);
    setPartSearchTitle("");
    setCurrentPage(1);
    setSelectedTablePartData(newAddedData);
    setSelectedFinalPartData(newAddedData);
  };

  const handleSelectAll = (checked) => {
    let updatedData = [...partsData];
    let selectedRows = [...prevPartsData];
    let allSelectedRowsData = [...selectedTablePartData]; // Clone the existing selected data

    if (checked) {
      // If checked, mark all rows as selected, but skip if disabled
      updatedData = updatedData.map((item) =>
        item.disable
          ? item // Skip if disabled
          : { ...item, selectedPart: true }
      );

      // Add the selected parts to allSelectedRowsData, avoiding duplicates
      updatedData.forEach((item) => {
        if (!item.disable) {
          const alreadySelected = allSelectedRowsData.some(
            (selectedItem) => selectedItem.id === item.id
          );

          if (!alreadySelected) {
            allSelectedRowsData.push({ ...item, selectedPart: true });
            selectedRows.push({ ...item, selectedPart: true });
          }
        }
      });
    } else {
      // If unchecked, mark all rows as deselected, but skip if disabled
      updatedData = updatedData.map((item) =>
        item.disable
          ? item // Skip if disabled
          : { ...item, selectedPart: false }
      );

      // Remove the deselected parts from allSelectedRowsData
      allSelectedRowsData = allSelectedRowsData.filter(
        (selectedItem) =>
          !updatedData.some(
            (updatedItem) =>
              updatedItem.id === selectedItem.id && !updatedItem.disable
          )
      );

      selectedRows = allSelectedRowsData.filter(
        (selectedItem) =>
          !updatedData.some(
            (updatedItem) =>
              updatedItem.id === selectedItem.id && !updatedItem.disable
          )
      );
    }

    const updatedInitData = [...initPartsData].map((item) => ({
      ...item,
      selectedPart:
        !item.disable && allSelectedRowsData.some((el) => el.id === item.id),
    }));

    // Update state
    setPartsData(updatedData);
    setInitPartsData(updatedInitData);
    setSelectedTablePartData(allSelectedRowsData); // Update selected rows// Update previous state
    setAreAllSelected(checked); // Update the "Select All" state
  };

  const transformDataSet = (dataArray) => {
    return dataArray?.map((data) => ({
      set: data?.part, // Set the `set` field to `part` value
      location: data?.location ? data?.location : null, // Use `asset` if available, otherwise default to 58
      unitCost: data?.unitCost !== null ? Number(data?.unitCost) : null, // Use unitCost if positive, else 100
      quantity: data?.quantity !== null ? data?.quantity : 1, // Use quantity if positive, else 5
      totalCost: data?.totalCost !== null ? Number(data?.totalCost) : null,
      ...(data?.temp_id ? { id: data.temp_id } : {}), // Calculate or default
    }));
  };

  const checkPermission = (labor) => {
    if (roleData == "super_admin") {
      return true;
    } else if (allIds.includes(labor)) {
      return true;
    } else {
      return false;
    }
  };

  const transformDataPart = (dataArray) => {
    return dataArray?.map((data) => ({
      part: data?.part, // Set the `set` field to `part` value
      location: data?.location ? data?.location : null, // Use `asset` if available, otherwise default to 58
      unitCost: data?.unitCost !== null ? Number(data?.unitCost) : null,
      // Use unitCost if positive, else 100
      quantity: data?.quantity !== null ? data?.quantity : 1, // Use quantity if positive, else 5
      totalCost: data?.totalCost !== null ? Number(data?.totalCost) : null,
      ...(data?.temp_id ? { id: data.temp_id } : {}),
      // Calculate or default
    }));
  };

  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };

  const handleAddPartData = async (setFieldValue) => {
    const parts = selectedTablePartData.filter((item) => item.type === "Part");
    const sets = selectedTablePartData.filter((item) => item.type === "Set");

    let setsData = transformDataSet(sets);
    let partData = transformDataPart(parts);

    const response = await updatePartWorkOrder(
      partData,
      setsData,
      data?.id,
      organisationId
    );
    if (response && response.status == 200) {
      setData([]);
      untoggle();
      setPartSearchTitle("");
      // setSelectedTablePartData([]);
      // setSelectedFinalPartData([]);
      setSelectedInvLoc("");
      setAllPartsData([]);
      setPrevPartsData([]);
      setInitPartsData([]);
      setPartsData([]);
      setCurrentPage(1);

      history.push(`/work-orders?id=${data?.attributes?.countId}`);
    } else if (response.status == 400) {
      if (response.error && response.error.message) {
        toast.error(response.error.message);
      } else {
        toast.error(t.common.something_wrong);
      }
      setSelectedTablePartData(prevPartsData);
      setSelectedFinalPartData(prevPartsData);
    } else {
      toast.error(t.common.something_wrong);
    }
    setSelectedInvLoc("");
    setAddPartModal(false);
    setCurrentPage(1);
    // setSelectedFinalPartData(selectedTablePartData);
    // setFieldValue('orderItems.items', selectedTablePartData);
  };
  useEffect(() => {
    getInvLocations();
    const selectedFinalData = mergeAndTransformData(
      data?.attributes?.part,
      data?.attributes?.set
    );
    addParts(selectedFinalData, true);
    setSelectedTablePartData(selectedFinalData);
    setSelectedFinalPartData(selectedFinalData);
    setPrevPartsData(selectedFinalData);
  }, []);
  return (
    <>
      {loading ? (
        <Loader active inline="centered">
          {t.common.loading}
        </Loader>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div
                className={`labor-grey-btn ${
                  selectedLanguage == "ar" ? "ml-5" : "mr-5"
                }`}
              >
                {t.workOrders.form.sub_total}

                <span
                  className={`${selectedLanguage == "en" ? "ml-3" : "mr-3"}`}
                >
                  {data?.attributes?.subTotal} {` ${organisationCurrency}s`}
                </span>
              </div>
            </div>
            <div>
              {!["completed", "cancelled", "inReview","onHold"].includes(
                data?.attributes?.status
              ) &&
              ["user", "admin", "super_admin", "limited_admin"].includes(
                roleData
              ) ? (
                <button
                  className={`buttun-type-link mt-1`}
                  basic
                  type="button"
                  // disabled={!values?.locations}
                  style={{ background: "transparent" }}
                  onClick={() => addParts(selectedTablePartData)}
                >
                  <span className="customer-add-btn">
                    {" "}
                    <CustomerPlusIcon width="20px" height="20px" />{" "}
                  </span>

                  <p className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                    {t.workOrders.form.add_part_set}
                  </p>
                </button>
              ) : null}
            </div>
          </div>

          {selectedFinalPartData?.length > 0 ? (
            <>
              <div className="scroll-data" style={{ overflow: "auto" }}>
                <div className={"mt-6"}>
                  <div className={`kanbar-header`}>
                    <div class="grid-container-element set wo-form wo-form-data">
                      <div
                        style={{
                          minWidth: "86px",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.workOrders.table.ID}
                      </div>
                      <div
                        style={{
                          minWidth: "140px",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.workOrders.details_view.name}
                      </div>
                      <div
                        style={{
                          minWidth: "140px",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.parts.form.inv_loc}
                      </div>
                      <div
                        style={{
                          minWidth: "100px",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.parts.table.quantity}
                      </div>{" "}
                      <div
                        style={{
                          minWidth: "130px",
                          textAlign: "center",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.parts.form.unit_cost}
                      </div>
                      <div
                        style={{
                          minWidth: "130px",
                          textAlign: "center",
                          backgroundColor: "#e9f2ff",
                        }}
                        class="grid-child-element set"
                      >
                        {t.parts.form.total_cost}
                      </div>
                    </div>
                  </div>
                </div>

                {selectedFinalPartData.map((part, index) => (
                  <div
                    className="grid-container-element set wo-form form"
                    key={index}
                  >
                    <div
                      className="grid-child-element set form"
                      style={{
                        padding: "14px 9px",
                        minWidth: "86px",
                      }}
                    >
                      {part?.countId ? part.countId : null}
                    </div>

                    <div
                      className="grid-child-element set form"
                      style={{
                        padding: "14px 9px",
                        minWidth: "140px",
                      }}
                    >
                      {userPermissions?.part?.view ? (
                        <b
                          style={{ color: "#0c66e4", wordBreak: "break-all" }}
                          className="c-pointer"
                          onClick={() =>
                            history.push(`/parts?id=${part?.countId}`)
                          }
                        >
                          {" "}
                          {DisplayFormattedText(part?.name, 13)}
                        </b>
                      ) : (
                        <b> {DisplayFormattedText(part?.name, 13)}</b>
                      )}
                    </div>
                    <div
                      className="grid-child-element set form"
                      style={{
                        padding: "14px 9px",
                        minWidth: "140px",
                      }}
                    >
                      {DisplayFormattedText(part?.location_name, 13)}
                    </div>
                    <div
                      className="grid-child-element set form"
                      style={{
                        padding: "14px 9px",
                        minWidth: "100px",
                      }}
                    >
                      {part?.quantity
                        ? DisplayFormattedText(String(part?.quantity), 13)
                        : 0}{" "}
                      {part?.unitType ? HandleUnitType(part?.unitType) : ""}
                    </div>
                    <div
                      className={
                        selectedLanguage == "en"
                          ? "grid-child-element set form mt-2"
                          : "grid-child-element set form mt-2"
                      }
                      style={{
                        minWidth: "130px",
                      }}
                    >
                      <div
                        style={{
                          width: "75px",
                          display: "inline-block",
                          textAlign: "end",
                        }}
                        className="number-cost-set"
                      >
                        {DisplayFormattedText(String(part?.unitCost), 7)}
                      </div>
                      <span
                        className={` align-items-center justify-content-center ${
                          selectedLanguage == "en" ? "ml-2" : "mr-2"
                        }`}
                        style={{
                          backgroundColor: "#E9F2FF",
                          color: "#0C66E4",
                          padding: "9px 3px 9px 2px",
                          fontSize: "14px",
                        }}
                      >
                        {organisationCurrency}
                      </span>
                    </div>

                    <div
                      className={
                        selectedLanguage == "en"
                          ? "grid-child-element set form mt-2"
                          : "grid-child-element set form mt-2"
                      }
                      style={{
                        minWidth: "130px",
                      }}
                    >
                      <div
                        style={{
                          width: "75px",
                          display: "inline-block",
                          textAlign: "end",
                        }}
                        className="number-cost-set"
                      >
                        {DisplayFormattedText(String(part?.totalCost), 7)}
                      </div>
                      <span
                        className={` align-items-center justify-content-center ${
                          selectedLanguage == "en" ? "ml-2" : "mr-2"
                        }`}
                        style={{
                          backgroundColor: "#E9F2FF",
                          color: "#0C66E4",
                          padding: "9px 3px 9px 2px",
                          fontSize: "14px",
                          minWidth: "33px",
                          textAlign: "end",
                        }}
                      >
                        {organisationCurrency}
                      </span>
                    </div>

                    {/* <div
                       onClick={() => {
                         removeFromSelectedParts(index, selectedFinalPartData);
                       }}
                       className="grid-child-element set form mt-2"
                     >
                       <DeleteIcon />
                     </div> */}
                  </div>
                ))}
              </div>
              {/* <div
               className="mt-4"
               style={{
                 display: "flex",
                 gap: "20px",
                 justifyContent: "end",
                 alignItems: "end",
               }}
             >
               <span>
                 <b>{t.workOrders.form.sub_total}</b>
               </span>
               <span>
                 <b>{data?.attributes?.subTotal}</b>
               </span>
               <span>
                 <b>{`${organisationCurrency}s`}</b>
               </span>
             </div> */}
            </>
          ) : (
            <div className="text-center mt-5">
              <p className="required-text">{t.common.na}</p>
            </div>
          )}

          <Modal
            size="large"
            open={addPartModal}
            className="assets-modal part-list-modal"
            style={{ height: "770px" }}
            onClose={() => {
              handleClosePartModal();
            }}
          >
            <div className="header-container">
              <span className="assets-header">
                {t.workOrders.form.add_part_set}
              </span>
              <div>
                <span
                  className="c-pointer text-right"
                  onClick={() => {
                    handleClosePartModal();
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            </div>

            <Modal.Content className="mt-2">
              <div style={{ gap: "10px" }} className="d-flex">
                <Form.Field style={{ minHeight: "43px" }}>
                  <Dropdown
                    placeholder={t.parts.form.inv_loc}
                    style={{ minWidth: "300px", padding: "6px 2px 1px 12px" }}
                    className={
                      selectedLanguage == "en"
                        ? "loc-dropdown"
                        : "loc-dropdown wos"
                    }
                    selection
                    noResultsMessage={t.common.no_results_found}
                    search
                    clearable
                    // onAddItem={handleAddLocation}
                    options={options?.inv_location}
                    // onOpen={fetchLocationsOptions}
                    value={selectedInvLoc}
                    onChange={(e, value) => {
                      setSelectedInvLoc(value?.value);
                    }}
                  />
                </Form.Field>
                <div
                  style={{ width: "-webkit-fill-available" }}
                  className="set-input"
                >
                  <span className="search-svg-set">
                    <SearchIcon />
                  </span>
                  <Input
                    style={{ width: "-webkit-fill-available" }}
                    placeholder={t.workOrders.form.search_parts}
                    value={partSearchTitle}
                    onChange={(e) => setPartSearchTitle(e.target.value)}
                  />
                </div>
              </div>
              {loading ? (
                <Dimmer active inverted>
                  <Loader inverted content={t.common.loading} />
                </Dimmer>
              ) : (
                <>
                  <div style={{}} className="  mt-5  bulk-upload-table">
                    <DataTable
                      className="wo-table-div"
                      conditionalRowStyles={conditionalRowStyles}
                      columns={[
                        {
                          name: (
                            <div>
                              <Checkbox
                                checked={areAllSelected}
                                disabled={partsData?.every(
                                  (item) => item?.disable == true
                                )}
                                onChange={(e, value) =>
                                  handleSelectAll(value.checked)
                                }
                                // onClick={() => handleRowAllSelected()}
                                // onChange={(e, value) => {
                                //   handleRowSelected(
                                //     row,
                                //     value.checked,

                                //     selectedTablePartData
                                //   );
                                // }}
                              />
                            </div>
                          ),
                          width: "50px",
                          cell: (row) => (
                            <div className="d-flex align-items-center">
                              <div>
                                <Checkbox
                                  checked={row?.selectedPart}
                                  disabled={row?.disable}
                                  className="set-checkbox-gery"
                                  // defaultChecked={row?.selectedPart}
                                  onChange={(e, value) => {
                                    if (!row?.disable) {
                                      handleRowSelected(
                                        row,
                                        value.checked,
                                        selectedTablePartData
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          ),
                        },
                        {
                          minWidth: "100px",
                          name: t.workOrders.table.ID,
                          //   name: t.categories.table.name,
                          sortField: "name",
                          sortable: true,
                          cell: (row) => (
                            <div className="d-flex align-items-center">
                              <div>
                                <span className="id-text-style">
                                  {row?.countId ? row?.countId : ""}
                                </span>
                              </div>
                            </div>
                          ),
                        },
                        {
                          minWidth: "240px",
                          name: t.workOrders.details_view.name,

                          cell: (row) => (
                            <div className="d-flex align-items-center">
                              <div>
                                <span className="id-text-style">
                                  {row?.name
                                    ? DisplayFormattedText(row?.name, 23)
                                    : ""}
                                </span>
                              </div>
                            </div>
                          ),
                        },
                        {
                          minWidth: "200px",
                          name: t.parts.form.inv_loc,

                          cell: (row) => (
                            <div className="d-flex align-items-center">
                              <div>
                                <span className="id-text-style">
                                  {row?.location_name
                                    ? DisplayFormattedText(
                                        row?.location_name,
                                        20
                                      )
                                    : ""}
                                </span>
                              </div>
                            </div>
                          ),
                        },
                        {
                          minWidth: "200px",
                          name: t.assets.title,
                          cell: (row) => {
                            const locationData = row?.asset;
                            if (locationData?.length > 0) {
                              const firstLocation = locationData[0]?.name;
                              const remainingCount = locationData.length - 1;
                              const remainingLocations = locationData
                                .slice(1)
                                .map((location) => location?.name)
                                .join(", ");
                              const displayText =
                                remainingCount > 0
                                  ? `${firstLocation} ... +${remainingCount}`
                                  : firstLocation;
                              return (
                                <span
                                  title={locationData
                                    .map((location) => location?.name)
                                    .join(", ")}
                                >
                                  {displayText}
                                </span>
                              );
                              // const displayText = locationData
                              //   .map((location) => {
                              //     const nameParts = location?.attributes?.name;

                              //     return nameParts;
                              //   })
                              //   .join(', '); // Adjust the join character as needed
                              // return DisplayFormattedText(displayText, 20);
                            } else {
                              return "";
                            }
                          },

                          // cell: (row) => (
                          //   <div className="d-flex align-items-center">
                          //     <div>
                          //       <span className="id-text-style">
                          //         {/* {row?.asset ? row?.asset : ""} */}
                          //       </span>
                          //     </div>
                          //   </div>
                          // ),
                        },

                        {
                          minWidth: "112px",
                          name: t.po.form.qty_to_order,

                          cell: (row) => (
                            <div className="d-flex align-items-center">
                              <Input
                                style={{ width: "80px" }}
                                disabled={!row?.selectedPart}
                                // defaultValue="1"
                                autoComplete="new-password"
                                type="number"
                                onKeyDown={(evt) => handleKeyDown(evt)}
                                onWheel={(e) => e.target.blur()}
                                value={row?.quantity}
                                // onChange={(e) => {

                                //   if (
                                //    Number(e.target.value)
                                //   ) {
                                //     console.log('e.target.value: ', e.target.value);
                                //     setFieldValue(
                                //       `orderItems.items[${index}].quantity`,
                                //       Number(e.target.value)
                                //     );
                                //     setFieldValue(
                                //       `orderItems.items[${index}].totalCost`,
                                //       Number(e.target.value * part.unitCost),
                                //       false
                                //     );
                                //   } else {
                                //     setFieldValue(`orderItems.items[${index}].quantity`, '');
                                //     setFieldValue(
                                //       `orderItems.items[${index}].totalCost`,
                                //       part.unitCost
                                //     );
                                //   }
                                // }}

                                onChange={(e, value) => {
                                  handleQtyChange(
                                    //   values,
                                    row,
                                    e.target.value,
                                    //   setFieldValue,
                                    selectedTablePartData
                                  );
                                }}
                                // error={
                                //   errors?.orderItems?.items?.length > 0 &&
                                //   errors?.orderItems?.items[index]?.quantity &&
                                //   touched?.orderItems?.items[index]?.quantity
                                // }
                                // onFocus={(e) => {
                                //   setFieldTouched(`orderItems.items[${index}].quantity`, true);
                                // }}
                              >
                                <input />
                                {/* {errors?.orderItems?.items?.length > 0 &&
                   errors?.orderItems?.items[index]?.quantity &&
                   touched?.orderItems?.items[index]?.quantity ? (
                     <span className="label-error">
                       {errors?.orderItems?.items[index]?.quantity}
                     </span>
                   ) : null} */}
                              </Input>

                              <span
                                className={`align-items-center justify-content-center ${
                                  selectedLanguage === "en" ? "ml-2" : "mr-2"
                                }`}
                                style={{
                                  backgroundColor: "#E9F2FF",
                                  color: "#0C66E4",
                                  minWidth:
                                    selectedLanguage == "en" ? "40px" : "56px",
                                  padding: "9px 3px 9px 2px",
                                  fontSize: "14px",
                                  display: "inline-block",
                                  textAlign: "center",
                                }}
                              >
                                {row?.unitType
                                  ? HandleUnitType(row?.unitType)
                                  : null}
                              </span>
                            </div>
                          ),
                        },
                        {
                          minWidth: "150px",
                          name: t.parts.form.avl_qty,
                          right: true,
                          cell: (row) => (
                            <div className="d-flex align-items-center">
                              <div>
                                <span className="id-text-style">
                                  {row?.type == "Set" ? (
                                    row?.status == "outOfStock" ? (
                                      <div
                                        style={{
                                          color: "#788CA5",
                                          width: "103px",
                                          textAlign: "end",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {t.parts.status.out_of_stock}
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          color: "black",
                                          width: "103px",
                                          textAlign: "end",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {t.parts.status.inStock}
                                      </div>
                                    )
                                  ) : row?.status == "outOfStock" ? (
                                    <div
                                      style={{
                                        color: "#788CA5",
                                        width: "103px",
                                        textAlign: "end",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {t.parts.status.out_of_stock}
                                    </div>
                                  ) : row?.availableQty ? (
                                    <>
                                      <div className="id-text-style d-flex align-items-center">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span
                                            style={{
                                              textAlign: "end",
                                              minWidth: "65px", // Set a fixed width based on the longest expected value
                                              // Align text to the right within the fixed space
                                              display: "inline-block",
                                            }}
                                          >
                                            {row?.availableQty
                                              ? row?.availableQty
                                              : 0}
                                          </span>
                                          <span
                                            className={`align-items-center justify-content-center ${
                                              selectedLanguage === "en"
                                                ? "ml-2"
                                                : "mr-2"
                                            }`}
                                            style={{
                                              backgroundColor: "#E9F2FF",
                                              color: "#0C66E4",
                                              minWidth:
                                                selectedLanguage == "en"
                                                  ? "33px"
                                                  : "48px",
                                              padding: "9px 3px 9px 2px",
                                              fontSize: "14px",
                                              display: "inline-block",
                                              textAlign: "center",
                                            }}
                                          >
                                            {row?.unitType
                                              ? HandleUnitType(row?.unitType)
                                              : null}
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    0
                                  )}
                                </span>
                              </div>
                            </div>
                          ),
                        },
                        {
                          minWidth: "150px",
                          name: t.parts.form.unit_cost,
                          right: true,
                          style: {
                            justifyContent: "end", // Align header text to the right
                          },
                          cell: (row) => (
                            <div className="id-text-style d-flex align-items-center">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    textAlign: "end",
                                    minWidth: "65px", // Set a fixed width based on the longest expected value
                                    // Align text to the right within the fixed space
                                    display: "inline-block",
                                  }}
                                >
                                  {row?.unitCost ? row?.unitCost : 0}
                                </span>
                                <span
                                  className={`align-items-center justify-content-center ${
                                    selectedLanguage === "en" ? "ml-2" : "mr-2"
                                  }`}
                                  style={{
                                    backgroundColor: "#E9F2FF",
                                    color: "#0C66E4",
                                    minWidth: "33px",
                                    padding: "9px 3px 9px 2px",
                                    fontSize: "14px",
                                    display: "inline-block",
                                  }}
                                >
                                  {organisationCurrency}
                                </span>
                              </div>
                            </div>
                          ),
                        },
                      ]}
                      data={getPaginatedData(partsData)}
                      // data={partsData}
                      fixedHeader
                      customStyles={customStyles}
                      onRowClicked={(row) => {
                        if (!row.disable) {
                          handleRowSelected(
                            row,
                            row?.selectedPart ? false : true,
                            selectedTablePartData
                          );
                        }
                      }}
                      noDataComponent={<span>{t.sets.no_data_wo}</span>}
                    />
                  </div>

                  {partsData && partsData?.length > 0 && (
                    <div className="d-flex justify-content-end">
                      <Pagination
                        ofString={t.common.of}
                        total={partsData.length}
                        prevItem={
                          selectedLanguage === "en" ? (
                            <ArrowLeft />
                          ) : (
                            <ArrowRight />
                          )
                        }
                        nextItem={
                          selectedLanguage === "en" ? (
                            <ArrowRight />
                          ) : (
                            <ArrowLeft />
                          )
                        }
                        page={currentPage}
                        limit={pageSize}
                        handleNext={() => {
                          setCurrentPage(currentPage + 1);
                        }}
                        handlePrevious={() => {
                          setCurrentPage(currentPage - 1);
                        }}
                        parts={true}
                      />
                    </div>
                  )}
                </>
              )}
            </Modal.Content>
            {!loading && (
              <div
                className={`modal-button-container ${
                  selectedLanguage == "en" ? "mr-8" : "ml-10"
                }`}
              >
                <button
                  className="modal-form-action-button black"
                  onClick={() => {
                    handleClosePartModal();
                  }}
                >
                  {t.assets.form.cancel}
                </button>

                <button
                  onClick={() => handleAddPartData()}
                  className="modal-form-action-button light-blue"
                >
                  {t.common.add}
                </button>
              </div>
            )}
          </Modal>
        </>
      )}
    </>
  );
};

export default Part;
