import React, { useState, useEffect, createRef } from "react";
import {
  List,
  Header,
  Modal,
  Checkbox,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Popup,
  Icon,
  TextArea,
  Label,
  DropdownItem,
  DropdownMenu,
  DropdownHeader,
  SegmentGroup,
  Segment,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import parent_icon from "../../../Assets/Icons/parentAsset.png";
import child_icon from "../../../Assets/Icons/childAsset.png";
import gchild_icon from "../../../Assets/Icons/grandChild.png";
import { useStateWithCallbackLazy } from "use-state-with-callback";

import AddCategoryModal from "../../components/modal/category/CategoryCreateModal";

import {
  updateInventory,
  getAllProcedures,
  getAllAssets,
  getAllVendros,
  uploadFile,
  IMAGES_URL,
  urlToFile,
  getAllCategories,
  handleKeyDown,
  getAllAreaByLocationId,
  getActiveUsersByRole,
  getAllTeams,
  getAllAreaLocations,
  currencyDecimalPlaces,
  getAllParentLocations,
  getAllAssetsOption,
} from "../../config/functions";

import QRCode from "react-qr-code";
import { v4 as uuidv4 } from "uuid";

import { Country, State, City } from "country-state-city";
import { useSelector } from "react-redux";
import { generateUniqueInteger } from "../../../utilFunctions/serialNumberGenerator";
import { useHistory } from "react-router-dom";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import { selectTranslations } from "../../config/i18n/slice";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import { scrollToError } from "../../../utilFunctions/scrollToError";
import {
  AddIcon,
  AddIconWithCircle,
  AddImage,
  AddedFileIcon,
  BarcodeIcon,
  CloseIcon,
  CsvImg,
  CustomerPlusIcon,
  DeleteIcon,
  DocImg,
  DocxImg,
  FilesIcon,
  ImgPhotos,
  InfoIcon,
  InfoIconBlue,
  InventoryIcon,
  Mp4File,
  PdfBlueIcon,
  PngPhotos,
  QRcodeIconInactive,
  Repeat,
  TextSnippet,
  TxtFile,
  XlsxFile,
} from "../../../Assets/Icons/svg";
import useCancelModal from "../../../hooks/Messages/useCancelModal";
import ToolTip from "react-power-tooltip";
import { useDropzone } from "react-dropzone";
import Asterisk from "../../components/common/Asterisk";
import QRComponentToPrint from "../Assets/preview/qrCodeComponent";
import useNewCancelModal from "../../../hooks/Messages/useNewCncelModal";
import { Resizable } from "re-resizable";
import location_icon from "../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
const numberRegex = /^[0-9]\d*$/;
const positiveNumRegex = /^[1-9]\d*$/;
//File Upload Function
const FileUpload = ({
  onFileChange,
  onCertificateFileChange,
  name = "",
  attachments = {},
  setAttachments = () => {},
}) => {
  const fileTypes = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "image/webp",
      "image/heic",
    ],
    documents: [
      "text/plain",
      "application/pdf",
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    video: ["video/mp4", "video/quicktime", "video/webm", "video/hevc"],
  };

  const fileTypesCompare = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "image/webp",
      "image/heic",
    ],
    txt: ["text/plain"],
    csv: ["text/csv"],
    docx: [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    xlsx: [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    documents: ["application/msword"],
    pdf: ["application/pdf"],
    video: [
      "video/mp4",
      "video/quicktime",
      "application/octet-stream",
      "video/webm",
      "video/hevc",
    ],
  };
  const { getRootProps, getInputProps } = useDropzone({
    // accept:
    // name === "image"
    //   ? Object.fromEntries(
    //       fileTypes.images.map((type) => [type, []])
    //     )
    //   : Object.fromEntries(
    //       [
    //         ...fileTypes.images,
    //         ...fileTypes.documents,
    //         ...fileTypes.video,
    //       ].map((type) => [type, []])
    //     ),
    onDrop: (acceptedFiles) => {
      const allowedFileTypes =
        name === "image"
          ? [...fileTypes.images]
          : [...fileTypes.images, ...fileTypes.documents, ...fileTypes.video];

      // Validate file types
      const invalidFiles = acceptedFiles.filter(
        (file) => !allowedFileTypes.includes(file.type)
      );

      // Show toast if there are invalid files
      if (invalidFiles?.length > 0) {
        toast.error(t.common.invalid_msg);
        return;
      }

      let totalFile = attachments?.warrantyFile?.length + acceptedFiles?.length;
      let totalAttachment = organisationImage?.numberOfAttachments
        ? organisationImage?.numberOfAttachments
        : 10;
      let totalSize = organisationImage?.attachmentsSize
        ? organisationImage?.attachmentsSize
        : 200;
      if (name !== "image" && totalFile > totalAttachment) {
        toast.error(
          `${t.common.number_error_1} ${totalAttachment} ${t.common.number_error_2}`
        );
        return;
      }
      const validFiles = acceptedFiles.filter((file) => {
        const isImage = fileTypes.images.includes(file.type);
        const isDocument = fileTypes.documents.includes(file.type);
        const isVideo = fileTypes.video.includes(file.type);

        const validSize =
          (isVideo || isImage || isDocument) &&
          file.size < totalSize * 1024 * 1024;

        if (!validSize) {
          toast.error(
            `${t.common.file_upload_valid} ${totalSize} ${t.common.file_upload_second}`
          );
          return false;
        }
        return true;
      });

      name === "image"
        ? onFileChange(validFiles, name)
        : onCertificateFileChange(validFiles, name);
    },
  });
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const organisationImage = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation;
    } else {
      return null;
    }
  });

  const renderFile = (file, index) => (
    <div
      className={`image-dropzone blue-border p-1 file d-flex justify-content-between text-start ${
        index == 0 ? "" : "mt-5"
      }`}
    >
      <div>
        <label
          style={{ fontSize: "14px" }}
          className="d-flex align-items-center m-1"
        >
          <div
            className={`${
              selectedLanguage == "en" ? "ml-4 mr-4" : "ml-4 mr-4"
            }`}
          >
            {fileTypesCompare?.images?.includes(file?.type) ? (
              <PngPhotos />
            ) : fileTypesCompare?.txt?.includes(file?.type) ? (
              <TxtFile />
            ) : fileTypesCompare?.pdf?.includes(file?.type) ? (
              <PdfBlueIcon />
            ) : fileTypesCompare?.csv?.includes(file?.type) ? (
              <CsvImg />
            ) : fileTypesCompare?.documents?.includes(file?.type) ? (
              <DocImg />
            ) : fileTypesCompare?.video?.includes(file?.type) ? (
              <Mp4File />
            ) : fileTypesCompare?.docx?.includes(file?.type) ? (
              <DocxImg />
            ) : fileTypesCompare?.xlsx?.includes(file?.type) ? (
              <XlsxFile />
            ) : null}
          </div>

          {file?.name}
        </label>
      </div>

      <div
        className="mr-5 ml-5 mt-1 c-pointer"
        onClick={(e) => {
          e.stopPropagation();
          const newCovidCopy = attachments.warrantyFile.filter(
            (covidFile, i) => i !== index
          );
          setAttachments({
            ...attachments,
            warrantyFile: newCovidCopy,
          });
        }}
      >
        <CloseIcon width={"18px"} height={"18px"} />
      </div>
    </div>
    // <div>
    //   <label>
    //     {file?.name}
    //     <span
    //       className="ml-3 mr-3 c-pointer"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         const newCovidCopy = attachments.warrantyFile.filter(
    //           (covidFile, i) => i !== index
    //         );
    //         setAttachments({
    //           ...attachments,
    //           warrantyFile: newCovidCopy,
    //         });
    //       }}
    //     >
    //       <DeleteIcon />
    //     </span>
    //   </label>
    // </div>
  );

  const renderImageFile = (file, index) => (
    <div
      className={`image-dropzone file d-flex justify-content-between text-center ${
        index == 0 ? "" : "mt-5"
      }`}
    >
      <div>
        <label style={{ fontSize: "16px" }} className="mb-0">
          <span
            className={`${
              selectedLanguage == "en" ? "ml-5 mr-8" : "ml-8 mr-5"
            }`}
          >
            <AddedFileIcon />
          </span>

          {file?.name}
        </label>
      </div>

      <div
        className="mr-5 ml-5 mt-1 c-pointer"
        onClick={(e) => {
          e.stopPropagation();
          const newCovidCopy = attachments.image.filter(
            (covidFile, i) => i !== index
          );
          setAttachments({
            ...attachments,
            image: newCovidCopy,
          });
        }}
      >
        <CloseIcon />
      </div>
    </div>
    // <div>
    //   <label>
    //     {file?.name}
    //     <span
    //       className="ml-3 mr-3 c-pointer"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         const newCovidCopy = attachments.warrantyFile.filter(
    //           (covidFile, i) => i !== index
    //         );
    //         setAttachments({
    //           ...attachments,
    //           warrantyFile: newCovidCopy,
    //         });
    //       }}
    //     >
    //       <DeleteIcon />
    //     </span>
    //   </label>
    // </div>
  );
  // console.log(attachments);
  //TO DO : Customize and Style this Drag and Drop to Upload box as you want🧑‍💻😊
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === "image" ? (
        attachments?.image?.name ? (
          <div
            className={`image-dropzone blue-border p-1 file d-flex justify-content-between text-start
            `}
          >
            <div>
              <label
                style={{ fontSize: "14px" }}
                className="d-flex align-items-center m-1"
              >
                <div
                  className={`${
                    selectedLanguage == "en" ? "ml-4 mr-4" : "ml-4 mr-4"
                  }`}
                >
                  {fileTypes?.images?.includes(attachments?.image.type) ? (
                    <PngPhotos />
                  ) : (
                    <Mp4File />
                  )}
                </div>

                {attachments?.image?.name}
              </label>
            </div>

            <div
              className="mr-5 ml-5 mt-1 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setAttachments({
                  ...attachments,
                  image: null,
                });
              }}
            >
              <CloseIcon width={"18px"} height={"18px"} />
            </div>
          </div>
        ) : (
          <div className="image-dropzone  text-center mb-5">
            <div>
              <p className="p1">
                <div className="d-flex align-items-start justify-content-center ">
                  <div>
                    {" "}
                    <ImgPhotos />
                  </div>
                  <div
                    className={`mr-2 ml-2 ${
                      selectedLanguage == "en" ? "text-left" : "text-right"
                    }`}
                  >
                    {" "}
                    <div className="" style={{ color: "#0C66E4" }}>
                      {" "}
                      {t.common.upload_img}
                    </div>{" "}
                    <p
                      className="mb-0"
                      style={{ color: "#788CA5", fontSize: "12px" }}
                    >
                      {" "}
                      {t.common.valid_files_img}
                    </p>{" "}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )
      ) : attachments?.warrantyFile?.length > 0 ? (
        <>
          {attachments.warrantyFile.map((file, index) =>
            renderFile(file, index)
          )}

<div className="warrenty-file-dropzone mt-5 d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <span className="mt-1">
                <FilesIcon />
              </span>
              <div className="pl-4 pr-4 d-flex align-items-center">
                <p className="p1 m-0">{t.common.files}</p>
                <span style={{ color: "#788CA5" }} className="ml-3">
                  {t.common.files_attachments}
                </span>
              </div>
            </div>
            <div>
              <AddIconWithCircle />
            </div>
          </div>
        </>
      ) : (
        <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
        <div className="d-flex">
          <span className="mt-1">
            <FilesIcon />
          </span>
          <div className="pl-4 pr-4 d-flex align-items-center">
            <p className="p1 m-0">{t.common.files}</p>
            <span style={{ color: "#788CA5" }} className="ml-3">
              {t.common.files_attachments}
            </span>
          </div>
        </div>
        <div>
          <AddIconWithCircle />
        </div>
      </div>
      )}
    </div>
  );
};
//Edit part compoment
function Edit({
  toggled,
  untoggle,
  refresh,
  setRefresh,
  targetedData,
  setFilterOptions,
  role = "",
  allIds = [],
}) {
  const history = useHistory();
  const t = useSelector(selectTranslations);
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const { userPermissions } = usePermissionsSimplified();

  const [attachments, setAttachments] = useState({
    image: null,
    warrantyFile: [],
  });

  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const [initChange, setInitChange] = useState(false);

  const [showQtyTooltip, setQtyTooltip] = useState({});
  const [showMinQtyTooltip, setMinQtyTooltip] = useState({});
  const [showPeopleTooltip, setPeopleTooltip] = useState(false);
  const [showTeamsTooltip, setTeamsTooltip] = useState(false);
  const [showMaxQtyTooltip, setMaxQtyTooltip] = useState({});

  const [categoryName, setCategoryName] = useState("");

  const [cancelModal, toggleCancelModal] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [successModal, toggleSuccessModal] = useState(false);

  const [fetchingAssets, setFetchingAssets] = useState(true);
  const [fetchingLocation, setFetchingLocation] = useState(true);
  const [fetchingVendors, setFetchingVendros] = useState(true);
  const [fetchingCategory, setFetchingCategory] = useState(true);
  const [fetchingPoeple, setFetchingPeople] = useState(true);
  const [fetchingTeams, setFetchingTeams] = useState(true);
  const [openCategoryAddModal, setOpenCategoryAddModal] = useState(false);
  const qrRef = createRef();
  const [image, setImage] = useState(null);
  const [files, setFiles] = useState([]);
  const [data, setData] = useState({
    quantityPerStore: [
      {
        location: "",
        unitCost: null,
        minimumQuantity: null,
        maximumQuantity: null,
        quantity: null,
      },
    ],
    name: "",
    // minimumQuantity: null,
    // maximumQuantity: null,
    description: "",
    category: "",
    // unitCost: null,
    costPer: "",
    partType: "",
    isNonStock: false,
    qrcode: "",
    barcode: "",
    serialNumber: "",
    vendor: [],
    asset: [],
    people: [],
    teams: "",
  });
  const [responseData, setResponseData] = useState({});
  const [showCancleTooltip, setCancleTooltip] = useState(false);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const [options, setOptions] = useState({
    vendors: [],
    assets: [],
    procedures: [],
    categories: [],
    locations: [],
    location: [
      {
        option: [],
      },
    ],
    // area: [
    //   {
    //     option: [],
    //   },
    // ],
    allQtyOptions: [
      {
        key: 0,
        value: "pieces",
        text: t.parts.qty_unit.pic,
      },
      {
        key: 1,
        value: "items",
        text: t.parts.qty_unit.item,
      },
      {
        key: 2,
        value: "units",
        text: t.parts.qty_unit.units,
      },
      {
        key: 3,
        value: "liters (L)",
        text: t.parts.vol_unit.liter,
      },
      {
        key: 4,
        value: "gallons (gal)",
        text: t.parts.vol_unit.gallons,
      },
      {
        key: 5,
        value: "cubic meters (m³)",
        text: t.parts.vol_unit.cubic_mtr,
      },
      {
        key: 6,
        value: "cubic feet (ft³)",
        text: t.parts.vol_unit.cubic_feet,
      },
      {
        key: 7,
        value: "barrels",
        text: t.parts.vol_unit.barrel,
      },
      {
        key: 8,
        value: "kilograms (kg)",
        text: t.parts.mass_unit.kilo,
      },
      {
        key: 9,
        value: "pounds (lb)",
        text: t.parts.mass_unit.pounds,
      },
      {
        key: 10,
        value: "tons",
        text: t.parts.mass_unit.ton,
      },
      {
        key: 11,
        value: "metric tonnes",
        text: t.parts.mass_unit.mat_tons,
      },
    ],
    costPerQtyUnit: [
      {
        key: 0,
        value: "pieces",
        text: t.parts.qty_unit.pic,
      },
      {
        key: 1,
        value: "items",
        text: t.parts.qty_unit.item,
      },
      {
        key: 2,
        value: "units",
        text: t.parts.qty_unit.units,
      },
    ],
    costPerVolUnit: [
      {
        key: 0,
        value: "liters (L)",
        text: t.parts.vol_unit.liter,
      },
      {
        key: 1,
        value: "gallons (gal)",
        text: t.parts.vol_unit.gallons,
      },
      {
        key: 2,
        value: "cubic meters (m³)",
        text: t.parts.vol_unit.cubic_mtr,
      },
      {
        key: 3,
        value: "cubic feet (ft³)",
        text: t.parts.vol_unit.cubic_feet,
      },
      {
        key: 4,
        value: "barrels",
        text: t.parts.vol_unit.barrel,
      },
    ],
    costPerMassUnit: [
      {
        key: 0,
        value: "kilograms (kg)",
        text: t.parts.mass_unit.kilo,
      },
      {
        key: 1,
        value: "pounds (lb)",
        text: t.parts.mass_unit.pounds,
      },
      {
        key: 10,
        value: "tons",
        text: t.parts.mass_unit.ton,
      },
      {
        key: 11,
        value: "metric tonnes",
        text: t.parts.mass_unit.mat_tons,
      },
    ],

    partType: [
      {
        key: 1,
        text: t.parts.type.critical,
        value: "critical",
      },
      {
        key: 2,
        text: t.parts.type.non_critical,
        value: "non-critical",
      },
    ],
  });

  const [customFields, setCustomFields] = useState([]);

  /*
   * The fetchArea function retrieves and updates area options based on a selected location.
   */
  const fetchArea = async (id, index) => {
    let currentSelectedLocation = options.locations.find(
      (location) => location.value == `${id}`
    );

    let the_options_area = [];

    if (currentSelectedLocation) {
      currentSelectedLocation.area.forEach((el) => {
        if (!data?.quantityPerStore.some((i) => i.area == el.id)) {
          the_options_area.push({
            key: `${el.id}`,
            value: Number(`${el.id}`),
            text: `${el.attributes.name}`,
          });
        }
      });
    }

    // if (currentSelectedLocation) {
    //   currentSelectedLocation.area.forEach((el) => {
    //     if (el.attributes.isStore && data?.quantityPerStore.map((i)=> {i.area == el.id; console.log(i.area ,"ssss")} ))
    //       the_options_area.push({
    //         key: `${el.id}`,
    //         value: Number(`${el.id}`),
    //         text: `${el.attributes.name}`,
    //       });
    //   });
    // }

    let areaOptions = [...options.area];

    areaOptions[index].option = the_options_area;

    // setOptions((prev) => {
    //   return {
    //     ...prev,

    //     area: areaOptions,
    //   };
    // });

    // setOptions({
    //   ...options,
    //   area: areaOptions,
    // });
  };

  const fetchLocationOption = async (id, index) => {
    let locationOptions = [...options.locations];

    const locations = data?.quantityPerStore
      .map((store) => store.location)
      .filter((location) => location !== "" && location != id);
    console.log("locations: ", locations);

    let updated_loc = locationOptions?.map((location) => {
      if (locations.includes(location?.value)) {
        // Disable the matching location
        return { ...location, disabled: true };
      }
      return location;
    });

    let areaOptions = [...options.location];

    areaOptions[index].option = updated_loc;

    setOptions((prev) => {
      return {
        ...prev,
        location: areaOptions,
      };
    });

    // let currentSelectedLocation = locationOptions?.find(
    //   (location) => location.value == `${id}`
    // );
    // console.log(currentSelectedLocation, "dsds");

    // let the_options_area = [];
    // if (currentSelectedLocation) {
    //   currentSelectedLocation.area.forEach((el) => {
    //     if (!data?.quantityPerStore.some((i) => i.area == el.id)) {
    //       the_options_area.push({
    //         key: `${el.id}`,
    //         value: `${el.id}`,
    //         text: `${el.attributes.name}`,
    //       });
    //     }
    //   });
    // }

    // let areaOptions = [...options.area];

    // areaOptions[index].option = the_options_area;

    // setOptions((prev) => ({
    //   ...prev,
    //   locations: the_options,
    // }));

    // return the_options;
  };

  /*
   * validation state
   */
  const [validation, setValidation] = useState({
    name: {
      error: true,
      pattern: (name) => name && name.trim() != "",
      skipped: false,
      message: t.common.required_field,
    },
    // unitCost: {
    //   error: true,
    //   pattern: (cost) => {
    //     if (!cost || cost === null) {
    //       return {
    //         isValid: false,
    //         message: t.common.cost_must_be_greater_than_zero,
    //       };
    //     } else if (!/^(\d{1,8})(\.\d{1,4})?$/.test(cost)) {
    //       return {
    //         isValid: false,
    //         message: t.common.valid_digits,
    //       };
    //     } else if (cost <= 0) {
    //       return {
    //         isValid: false,
    //         message: t.common.cost_must_be_greater_than_zero,
    //       };
    //     }
    //     return { isValid: true };
    //   },
    //   skipped: false,
    //   message: "",
    // },
    costPer: {
      error: true,
      pattern: (store) => store != "",
      skipped: false,
      message: t.common.required_field,
    },
    partType: {
      error: true,
      pattern: (store) => store != "",
      skipped: false,
      message: t.common.required_field,
    },
    people: {
      error: true,
      pattern: (users) => users?.length > 0,
      skipped: false,
      message: t.common.required_field,
    },
    teams: {
      error: true,
      pattern: (customer) => customer != "",
      skipped: false,
      message: t.common.required_field,
    },

    quantityPerStore: [
      {
        quantity: {
          error: true,
          pattern: (quantity) => {
            if (quantity === null || quantity === undefined) {
              return false;
            } else if (!/^(\d{1,8})(\.\d{1,4})?$/.test(quantity)) {
              return false;
            } else if (quantity < 0) {
              return false;
            }
            return true;
          },
          skipped: false,
        },

        unitCost: {
          error: true,
          pattern: (unitCost) => {
            if (
              unitCost === null ||
              unitCost === "" ||
              unitCost === undefined
            ) {
              return true;
            } else if (!/^(\d{1,8})(\.\d{1,4})?$/.test(unitCost)) {
              return false;
            } else if (unitCost < 0) {
              return true;
            }
            return true;
          },
          skipped: false,
        },

        minimumQuantity: {
          error: false, // Initially set to false
          pattern: (minQty, maxQty) => minQty < maxQty || maxQty === null, // Check if minQty is less than or equal to maxQty or if maxQty is not provided
          skipped: false, // Initially set to false
          message: t.parts.minQty_valid, // Error message for invalid minimum quantity
        },
        maximumQuantity: {
          error: false, // Initially set to false
          pattern: (maxQty, minQty) => maxQty > minQty || minQty === null, // Check if maxQty is greater than or equal to minQty or if minQty is not provided
          skipped: false, // Initially set to false
          message: t.parts.maxQty_valid, // Error message for invalid maximum quantity
        },
        // unitCost: {
        //   error: true,
        //   pattern: (quantity) => quantity >= 1 && numberRegex.test(quantity),
        //   skipped: false,
        //   message: t.common.required_field,
        // },
        // minimumQuantity: {
        //   error: true,
        //   pattern: (quantity) => quantity >= 1 && numberRegex.test(quantity),
        //   skipped: false,
        //   message: t.common.required_field,
        // },
        location: {
          error: true,
          pattern: (store) => store != "",
          skipped: false,
          message: t.common.required_field,
        },
        // area: {
        //   error: true,
        //   pattern: (store) => store != "",
        //   skipped: false,
        //   message: t.common.required_field,
        // },
      },
    ],
    serialNumber: {
      error: true,
      pattern: (serialNumber) => serialNumber != "",
      skipped: false,
      message: t.common.required_field,
    },

    // minimumQuantity: {
    //   error: false, // Initially set to false
    //   pattern: (minQty, maxQty) => minQty < maxQty || maxQty === null, // Check if minQty is less than or equal to maxQty or if maxQty is not provided
    //   skipped: false, // Initially set to false
    //   message: t.parts.minQty_valid, // Error message for invalid minimum quantity
    // },
    // maximumQuantity: {
    //   error: false, // Initially set to false
    //   pattern: (maxQty, minQty) => maxQty > minQty || minQty === null, // Check if maxQty is greater than or equal to minQty or if minQty is not provided
    //   skipped: false, // Initially set to false
    //   message: t.parts.maxQty_valid, // Error message for invalid maximum quantity
    // },
  });

  const [activeBtn, setActiveBtn] = useState(2);
  const [validForm, setValidForm] = useState(false);

  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const truncatedValue = Math.trunc(value * factor) / factor;
    const formattedValue = truncatedValue.toFixed(decimalPlaces);

    // Remove trailing zeros after the decimal point
    if (formattedValue.indexOf(".") !== -1) {
      return formattedValue.replace(/\.?0+$/, "");
    }

    return Number(formattedValue);
  };

  const fetchAssets = async () => {
    if (!data?.asset) {
      setOptions({
        ...options,

        assets: [],
      });
    }
    const locations = data.quantityPerStore
      .map((store) => store.location)
      .filter((location) => location !== "");

    const fetchData = await getAllAssetsOption(
      organisationId,
      "all_asset",
      1,
      9999,
      data?.locations
        ? [data?.locations]
        : !(role === "super_admin")
        ? data?.locations
          ? [data?.locations]
          : []
        : [],
      [],
      "",
      "",
      [],
      ["name:asc"]
    );

    let assetOptions = [];
    let parentToChildMap = new Map();
    let childToGrandChildMap = new Map();
    let hierarchicalOptions = [];

    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        const isDisposed = el?.attributes?.status === "dispose";

        // Access rule: Non-admin users can't access certain assets
        const isActive =
          !isDisposed &&
          (!(role === "super_admin")
            ? data?.locations
              ? data?.locations == el?.attributes?.location?.data?.id
              : allIds?.includes(el?.attributes?.location?.data?.id)
            : true);

        // Filter out disposed or inactive assets with no descendants
        if (
          isDisposed ||
          (!isActive && !el?.attributes?.storedAssets?.length)
        ) {
          return;
        }

        // Create parent asset option
        const parentAsset = {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { src: parent_icon }, // Parent asset icon
          className: "main-location-dropdown-option",
          disabled: !isActive, // Disable only for non-admin roles
        };

        let hasAccessibleChildren = false;
        let hasAccessibleGrandchildren = false;

        parentToChildMap.set(el.id, []);
        assetOptions.push(parentAsset);

        // Collect child assets
        if (el?.attributes?.storedAssets?.length > 0) {
          el.attributes.storedAssets.forEach((childEl) => {
            if (childEl?.childAsset?.data) {
              const childAsset = childEl.childAsset.data;
              const childIsDisposed =
                childAsset?.attributes?.status === "dispose";

              const childIsActive =
                !childIsDisposed &&
                (!(role === "super_admin")
                  ? data?.locations
                    ? data?.locations ==
                      childAsset?.attributes?.location?.data?.id
                    : allIds?.includes(
                        childAsset?.attributes?.location?.data?.id
                      )
                  : true);

              // Filter out disposed or inactive child assets with no grandchildren
              if (
                childIsDisposed ||
                (!childIsActive && !childEl?.grandChildAsset?.data?.length)
              ) {
                return;
              }

              // Track whether there are any accessible children
              if (childIsActive) hasAccessibleChildren = true;

              const childOption = {
                key: childAsset.id,
                value: childAsset.id,
                text: `${childAsset.attributes.name}`,
                image: { src: child_icon }, // Child asset icon
                className: "sub-location-dropdown-option",
                parentId: el.id,
                disabled: !childIsActive, // Disable child based on role
              };

              parentToChildMap.get(el.id).push(childOption);
              childToGrandChildMap.set(childAsset.id, []);

              // Collect grandchild assets
              if (childEl?.grandChildAsset?.data?.length > 0) {
                childEl.grandChildAsset.data.forEach((grandChildEl) => {
                  const grandChildIsDisposed =
                    grandChildEl?.attributes?.status === "dispose";

                  const grandChildIsActive =
                    !grandChildIsDisposed &&
                    (!(role === "super_admin")
                      ? data?.locations
                        ? data?.locations ==
                          grandChildEl?.attributes?.location?.data?.id
                        : allIds?.includes(
                            grandChildEl?.attributes?.location?.data?.id
                          )
                      : true);

                  // Filter out disposed or inactive grandchild assets
                  if (grandChildIsDisposed || !grandChildIsActive) {
                    return;
                  }

                  // Track whether there are any accessible grandchildren
                  if (grandChildIsActive) hasAccessibleGrandchildren = true;

                  const grandChildOption = {
                    key: grandChildEl.id,
                    value: grandChildEl.id,
                    text: `${grandChildEl.attributes.name}`,
                    image: { src: gchild_icon }, // Grandchild asset icon
                    className: "grandchild-asset-dropdown-option",
                    parentId: el.id,
                    childParentId: childAsset.id,
                    disabled: !grandChildIsActive, // Disable based on role
                  };

                  childToGrandChildMap
                    .get(childAsset.id)
                    .push(grandChildOption);
                });
              }
            }
          });
        }

        // Ensure the parent asset is displayed if it has no accessible children or grandchildren
        if (!hasAccessibleChildren && !hasAccessibleGrandchildren && isActive) {
          // Clear the child options and only show the parent
          parentToChildMap.set(el.id, []);
        }
      });

      // Sort child and grandchild options alphabetically within each parent and child context
      parentToChildMap.forEach((children) => {
        children.sort((a, b) => a.text.localeCompare(b.text));
      });

      childToGrandChildMap.forEach((grandChildren) => {
        grandChildren.sort((a, b) => a.text.localeCompare(b.text));
      });

      // Combine all the options into a hierarchical structure
      assetOptions.forEach((parentOption) => {
        hierarchicalOptions.push(parentOption);

        const children = parentToChildMap.get(parentOption.key) || [];
        children.forEach((childOption) => {
          hierarchicalOptions.push(childOption);

          const grandChildren = childToGrandChildMap.get(childOption.key) || [];
          grandChildren.forEach((grandChildOption) => {
            hierarchicalOptions.push(grandChildOption);
          });
        });
      });
      setOptions((prev) => {
        return {
          ...prev,
          assets: hierarchicalOptions,
        };
      });

      return hierarchicalOptions;
    }
  };

  const fetchVendorsData = async () => {
    if (!data?.vendor) {
      setOptions({
        ...options,

        vendors: [],
      });
    }

    const locations = data.quantityPerStore
      .map((store) => store.location)
      .filter((location) => location !== "");

    const fetchData = await getAllVendros(
      organisationId,
      "",
      1,
      9999,
      !(role === "super_admin") ? allIds : []
    );
    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions({
        ...options,
        vendors: the_options,
      });
    }
  };

  const fetchTeams = async () => {
    if (!data?.teams) {
      setOptions({
        ...options,

        teams: [],
      });
    }

    const locations = data.quantityPerStore
      .map((store) => store.location)
      .filter((location) => location !== "");

    const fetchData = await getAllTeams(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      !(role === "super_admin") ? allIds : []
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        teams: the_options,
      }));

      return the_options;
    }
  };

  const fetchPeople = async () => {
    if (!data?.people) {
      setOptions({
        ...options,

        people: [],
      });
    }

    const locations = data.quantityPerStore
      .map((store) => store.location)
      .filter((location) => location !== "");

    const fetchData = await getActiveUsersByRole(
      ["user", "limited_admin", "admin"],
      organisationId,
      !(role === "super_admin") ? allIds : []
    );
    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ? el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          // description: `${teams_string_array.join(' , ')}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: the_options,
      }));

      return the_options;
    }
  };
  /*
   * The `resetForm` function resets form data, validation rules, options, and sets certain states to their initial values.
   */
  const resetForm = () => {
    setData({
      quantityPerStore: [
        {
          location: "",
          // area: "",
          quantity: "",
          unitCost: "",
          minimumQuantity: null,
          maximumQuantity: null,
        },
      ],
      name: "",
      description: "",
      // minimumQuantity: null,
      // maximumQuantity: null,
      category: "",
      unitCost: null,
      costPer: "",
      partType: "",
      isNonStock: false,
      barcode: "",
      qrcode: "",
      serialNumber: "",
      vendor: [],
      asset: [],
      people: [],
      teams: "",
    });

    setFetchingLocation(true);

    setAttachments({
      image: null,
      warrantyFile: [],
    });

    setModalWidth(Math.max(window.innerWidth / 2, 514));

    setImage(null);
    setFiles([]);

    setValidation({
      quantityPerStore: [
        {
          quantity: {
            error: true,
            pattern: (quantity) => {
              if (
                quantity === null ||
                quantity === "" ||
                quantity === undefined
              ) {
                return false;
              } else if (!/^\d+(\.\d{1,4})?$/.test(quantity)) {
                return false;
              } else if (quantity < 0) {
                return false;
              }
              return true;
            },
            skipped: false,
          },
          unitCost: {
            error: true,
            pattern: (quantity) => {
              if (
                quantity === null ||
                quantity === "" ||
                quantity === undefined
              ) {
                return true;
              } else if (!/^\d+(\.\d{1,4})?$/.test(quantity)) {
                return false;
              } else if (quantity < 0) {
                return true;
              }
              return true;
            },
            skipped: false,
          },

          minimumQuantity: {
            error: false, // Initially set to false
            pattern: (minQty, maxQty) => minQty <= maxQty || maxQty === null, // Check if minQty is less than or equal to maxQty or if maxQty is not provided
            skipped: false, // Initially set to false
            message: t.parts.minQty_valid, // Error message for invalid minimum quantity
          },
          maximumQuantity: {
            error: false, // Initially set to false
            pattern: (maxQty, minQty) => maxQty >= minQty || minQty === null, // Check if maxQty is greater than or equal to minQty or if minQty is not provided
            skipped: false, // Initially set to false
            message: t.parts.maxQty_valid, // Error message for invalid maximum quantity
          },

          // unitCost: {
          //   error: true,
          //   pattern: (quantity) => quantity >= 1 && numberRegex.test(quantity),
          //   skipped: false,
          //   message: t.common.required_field,
          // },

          location: {
            error: true,
            pattern: (store) => store != "",
            skipped: false,
            message: t.common.required_field,
          },
          // area: {
          //   error: true,
          //   pattern: (store) => store != "",
          //   skipped: false,
          //   message: t.common.required_field,
          // },
        },
      ],
      name: {
        error: false,
        pattern: (name) => name && name.trim() != "",
        skipped: false,
        message: t.common.required_field,
      },
      // minimumQuantity: {
      //   error: false, // Initially set to false
      //   pattern: (minQty, maxQty) => minQty <= maxQty || maxQty === null, // Check if minQty is less than or equal to maxQty or if maxQty is not provided
      //   skipped: false, // Initially set to false
      //   message: t.parts.minQty_valid, // Error message for invalid minimum quantity
      // },
      // maximumQuantity: {
      //   error: false, // Initially set to false
      //   pattern: (maxQty, minQty) => maxQty >= minQty || minQty === null, // Check if maxQty is greater than or equal to minQty or if minQty is not provided
      //   skipped: false, // Initially set to false
      //   message: t.parts.maxQty_valid, // Error message for invalid maximum quantity
      // },
      people: {
        error: true,
        pattern: (users) => users?.length > 0,
        skipped: false,
        message: t.common.required_field,
      },
      teams: {
        error: true,
        pattern: (customer) => customer != "",
        skipped: false,
        message: t.common.required_field,
      },

      partType: {
        error: true,
        pattern: (store) => store != "",
        skipped: false,
        message: t.common.required_field,
      },
      // unitCost: {
      //   error: true,
      //   pattern: (cost) => {
      //     if (!cost || cost === null) {
      //       return {
      //         isValid: false,
      //         message: t.common.cost_must_be_greater_than_zero,
      //       };
      //     } else if (!/^(\d{1,8})(\.\d{1,4})?$/.test(cost)) {
      //       return {
      //         isValid: false,
      //         message: t.common.valid_digits,
      //       };
      //     } else if (cost <= 0) {
      //       return {
      //         isValid: false,
      //         message: t.common.cost_must_be_greater_than_zero,
      //       };
      //     }
      //     return { isValid: true };
      //   },
      //   skipped: false,
      //   message: "",
      // },
      costPer: {
        error: true,
        pattern: (store) => store != "",
        skipped: false,
        message: t.common.required_field,
      },
      // quantity: {
      //   error: false,
      //   pattern: (quantity) => quantity >= 1,
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      // cost: {
      //   error: false,
      //   pattern: (quantity) => quantity >= 1,
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      serialNumber: {
        error: false,
        pattern: (serialNumber) => serialNumber != "",
        skipped: false,
        message: t.common.required_field,
      },
      // minimumQuantity: {
      //   error: false,
      //   pattern: (quantity) =>
      //     quantity === '' || (!isNaN(quantity) && Number(quantity) >= 0),
      //   skipped: false,
      //   message: 'Please enter valid integer value',
      // },
    });

    setOptions({
      vendors: [],
      assets: [],
      procedures: [],
      categories: [],
      locations: [],
      location: [
        {
          option: [],
        },
      ],
      allQtyOptions: [
        {
          key: 0,
          value: "pieces",
          text: t.parts.qty_unit.pic,
        },
        {
          key: 1,
          value: "items",
          text: t.parts.qty_unit.item,
        },
        {
          key: 2,
          value: "units",
          text: t.parts.qty_unit.units,
        },
        {
          key: 3,
          value: "liters (L)",
          text: t.parts.vol_unit.liter,
        },
        {
          key: 4,
          value: "gallons (gal)",
          text: t.parts.vol_unit.gallons,
        },
        {
          key: 5,
          value: "cubic meters (m³)",
          text: t.parts.vol_unit.cubic_mtr,
        },
        {
          key: 6,
          value: "cubic feet (ft³)",
          text: t.parts.vol_unit.cubic_feet,
        },
        {
          key: 7,
          value: "barrels",
          text: t.parts.vol_unit.barrel,
        },
        {
          key: 8,
          value: "kilograms (kg)",
          text: t.parts.mass_unit.kilo,
        },
        {
          key: 9,
          value: "pounds (lb)",
          text: t.parts.mass_unit.pounds,
        },
        {
          key: 10,
          value: "tons",
          text: t.parts.mass_unit.ton,
        },
        {
          key: 11,
          value: "metric tonnes",
          text: t.parts.mass_unit.mat_tons,
        },
      ],
      costPerQtyUnit: [
        {
          key: 0,
          value: "pieces",
          text: t.parts.qty_unit.pic,
        },
        {
          key: 1,
          value: "items",
          text: t.parts.qty_unit.item,
        },
        {
          key: 2,
          value: "units",
          text: t.parts.qty_unit.units,
        },
      ],
      costPerVolUnit: [
        {
          key: 0,
          value: "liters (L)",
          text: t.parts.vol_unit.liter,
        },
        {
          key: 1,
          value: "gallons (gal)",
          text: t.parts.vol_unit.gallons,
        },
        {
          key: 2,
          value: "cubic meters (m³)",
          text: t.parts.vol_unit.cubic_mtr,
        },
        {
          key: 3,
          value: "cubic feet (ft³)",
          text: t.parts.vol_unit.cubic_feet,
        },
        {
          key: 4,
          value: "barrels",
          text: t.parts.vol_unit.barrel,
        },
      ],
      costPerMassUnit: [
        {
          key: 0,
          value: "kilograms (kg)",
          text: t.parts.mass_unit.kilo,
        },
        {
          key: 1,
          value: "pounds (lb)",
          text: t.parts.mass_unit.pounds,
        },
        {
          key: 10,
          value: "tons",
          text: t.parts.mass_unit.ton,
        },
        {
          key: 11,
          value: "metric tonnes",
          text: t.parts.mass_unit.mat_tons,
        },
      ],

      partType: [
        {
          key: 1,
          text: t.parts.type.critical,
          value: "critical",
        },
        {
          key: 2,
          text: t.parts.type.non_critical,
          value: "non-critical",
        },
      ],
    });
    setActiveBtn(2);

    setInitChange(false);
    setValidForm(false);
  };
  /*
   * The function `removeThisData` removes a inventory
   */
  const removeThisData = (id) => (e) => {
    e.stopPropagation();
    const index = id;
    let newfields = [...data.quantityPerStore];
    let newfieldsOption = [...data.quantityPerStore];
    newfields.splice(index, 1);

    let newfieldsValidation = [...validation.quantityPerStore];
    newfieldsValidation.splice(index, 1);

    let newfieldsOptions = [...options.locations];
    let curretFieldOptions = newfieldsOption[index].location;
    let updated_loc = newfieldsOptions?.map((location) => {
      if (location?.value === curretFieldOptions) {
        // Disable the matching location
        return { ...location, disabled: false };
      }
      return location;
    });

    let newfieldsLocOptions = [...options.location];
    // newfieldsOptions.splice(index, 1);
    newfieldsLocOptions.splice(index, 1);

    setData({
      ...data,
      quantityPerStore: newfields,
      // vendor: [],
      asset: [],
      people: [],
      teams: "",
    });

    setOptions((prev) => {
      return {
        ...prev,
        location: newfieldsLocOptions,
      };
    });

    setValidation({
      ...validation,
      quantityPerStore: newfieldsValidation,
    });
  };

  /* The above code is a React useEffect hook that runs when the `targetedData` or `toggled` state changes. It checks if `toggled` is true and if `targetedData` is not null. If both conditions are
met, it performs the following tasks: */
  useEffect(() => {
    if (toggled) {
      if (targetedData) {
        fetchOptions();
        const transformedInventory = targetedData?.attributes?.inventory?.map(
          (item) => {
            return {
              location: item.location?.data?.id || "", // Extract location ID
              // area: item.area?.data?.id || "", // Extract area ID
              quantity: item?.quantity !== null ? item?.quantity : null,
              minimumQuantity:
                item?.minimumQuantity !== null ? item?.minimumQuantity : null,
              maximumQuantity:
                item?.maximumQuantity !== null ? item?.maximumQuantity : null,
              unitCost: item?.unitCost !== null ? item?.unitCost : null,
              ispre: true,
              id: item?.id,

              // Add other properties as needed
            };
          }
        );
        setData({
          ...data,
          name: targetedData?.attributes?.name || "",
          description: targetedData?.attributes?.description || "",
          category: targetedData?.attributes?.category?.data?.id || "",
          // unitCost:
          //   String(handleDecimalPoints(targetedData?.attributes?.unitCost)) ||
          //   null,
          // minimumQuantity: targetedData?.attributes?.minimumQuantity || null,
          // maximumQuantity: targetedData?.attributes?.maximumQuantity || null,
          costPer: targetedData?.attributes?.measuringUnit,
          partType: targetedData?.attributes?.type,
          isNonStock: targetedData?.attributes?.isNonStock,
          serialNumber: targetedData?.attributes?.serialNumber,
          vendor:
            targetedData.attributes?.vendors?.data?.length > 0
              ? targetedData?.attributes?.vendors?.data.map(
                  (el_team) => el_team.id
                )
              : [],
          asset:
            targetedData.attributes?.assets?.data?.length > 0
              ? targetedData.attributes.assets.data.map((el_team) => el_team.id)
              : [],
          people: targetedData.attributes?.people?.data
            ? targetedData.attributes.people.data.map((el_team) => el_team.id)
            : [],
          teams: targetedData.attributes?.teams?.data?.id
            ? targetedData.attributes?.teams?.data?.id
            : "",
          quantityPerStore:
            targetedData?.attributes?.inventory?.length > 0
              ? transformedInventory
              : [
                  {
                    location: "",
                    quantity: null,
                    unitCost: null,
                    minimumQuantity: null,
                    maximumQuantity: null,
                  },
                ],
        });

        setAttachments({
          ...attachments,
          image: targetedData?.attributes?.logo?.data
            ? {
                id: targetedData?.attributes.logo?.data?.id,
                file: urlToFile(
                  targetedData?.attributes.logo?.data?.attributes.url,
                  targetedData?.attributes.logo?.data?.attributes.name
                ),
                name: targetedData?.attributes.logo?.data?.attributes.name,
                type: targetedData?.attributes.logo?.data?.attributes.mime,
                cancelPopup: false,
                isChanged: false,
              }
            : null,
          warrantyFile:
            targetedData?.attributes?.files &&
            targetedData?.attributes?.files?.data?.length
              ? targetedData?.attributes.files?.data?.map((one) => {
                  return {
                    id: one?.id,
                    file: urlToFile(
                      one?.attributes?.url,
                      one?.attributes?.name
                    ),
                    name: one?.attributes?.name,
                    type: one?.attributes?.mime,
                    cancelPopup: false,
                    isChanged: false,
                  };
                })
              : [],
        });
        const areaDataValidation = generateValidationRules(
          targetedData?.attributes?.inventory?.length
        );

        setValidation({
          ...validation,
          quantityPerStore: areaDataValidation,
        });

        setActiveBtn(targetedData?.attributes?.barcode === true ? 1 : 2);
      }
      // }
    }
  }, [targetedData, toggled]);

  /*
   * The `onCertificateFileChange` function sets a random number as an ID for a file and updates the multiple attachments state with file information.
   */
  const onCertificateFileChange = (files, name) => {
    setInitChange(true);
    let newFiles = [];
    Array.from(files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });

    setAttachments({
      ...attachments,
      [name]: [...attachments[name], ...newFiles],
    });
  };
  /*
   * The `onFileChange` function sets a random number as an ID for a file and updates the single attachments state with file information.
   */
  const onFileChange = (files, name) => {
    // console.log('show', e.target.files[0]);
    setInitChange(true);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setAttachments({
      ...attachments,
      [name]: {
        file: files[0],
        id: randomnumber,
        name: files[0].name,
        type: files[0].type,
        cancelPopup: false,
        isChanged: true,
      },
    });
  };

  /*
   * The function `generateValidationRules` creates an array of validation rules for inventory data based on the specified inventory length.
   */
  const generateValidationRules = (inventoryLength) => {
    let areaDataValidation = Array.from(
      { length: inventoryLength },
      (_, index) => ({
        quantity: {
          error: true,
          pattern: (quantity) => {
            if (quantity === null || quantity === undefined) {
              return false;
            } else if (!/^(\d{1,8})(\.\d{1,4})?$/.test(quantity)) {
              return false;
            } else if (quantity < 0) {
              return false;
            }
            return true;
          },
          skipped: false,
        },
        unitCost: {
          error: true,
          pattern: (unitCost) => {
            if (
              unitCost === null ||
              unitCost === "" ||
              unitCost === undefined
            ) {
              return true;
            } else if (!/^(\d{1,8})(\.\d{1,4})?$/.test(unitCost)) {
              return false;
            } else if (unitCost < 0) {
              return true;
            }
            return true;
          },
          skipped: false,
        },

        minimumQuantity: {
          error: false, // Initially set to false
          pattern: (minQty, maxQty) => minQty < maxQty || maxQty === null, // Check if minQty is less than or equal to maxQty or if maxQty is not provided
          skipped: false, // Initially set to false
          message: t.parts.minQty_valid, // Error message for invalid minimum quantity
        },
        maximumQuantity: {
          error: false, // Initially set to false
          pattern: (maxQty, minQty) => maxQty > minQty || minQty === null, // Check if maxQty is greater than or equal to minQty or if minQty is not provided
          skipped: false, // Initially set to false
          message: t.parts.maxQty_valid, // Error message for invalid maximum quantity
        },
        // quantity: {
        //   error: true,
        //   pattern: (quantity) => quantity >= 0 && quantity !== null,
        //   skipped: false,
        //   message: t.common.required_field,
        // },
        location: {
          error: true,
          pattern: (store) => store !== "",
          skipped: false,
          message: t.common.required_field,
        },
        // area: {
        //   error: true,
        //   pattern: (store) => store !== "",
        //   skipped: false,
        //   message: t.common.required_field,
        // },
        // Add other validation rules as needed
      })
    );
    return areaDataValidation;
  };

  /*
   * The function `fetchOptions` fetches various data such as assets, vendors, categories, people, teams and locations, and prepares options for each category based on the fetched data.
   */
  const fetchOptions = async () => {
    const [
      fetchData,
      vendors,
      categories,
      people,
      teams,
      fetchDataLocation,
    ] = await Promise.all([
      getAllAssetsOption(
        organisationId,
        "all_asset",
        1,
        9999,
        !(role === "super_admin") ? allIds : [],
        [],
        "",
        "",
        [],
        ["name:asc"]
      ),
      getAllVendros(
        organisationId,
        "",
        1,
        9999,
        !(role === "super_admin") ? allIds : []
      ),
      getAllCategories(organisationId),
      getActiveUsersByRole(
        ["user", "limited_admin", "admin"],
        organisationId,
        !(role === "super_admin") ? allIds : []
      ),
      getAllTeams(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        !(role === "super_admin") ? allIds : []
      ),
      getAllParentLocations(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        ["name:asc"]
      ),
    ]);

    let assetOptions = [];
    let parentToChildMap = new Map();
    let childToGrandChildMap = new Map();
    let hierarchicalOptions = [];

    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        const isDisposed = el?.attributes?.status === "dispose";

        // Access rule: Non-admin users can't access certain assets
        const isActive =
          !isDisposed &&
          (!(role === "super_admin")
            ? data?.locations
              ? data?.locations == el?.attributes?.location?.data?.id
              : allIds?.includes(el?.attributes?.location?.data?.id)
            : true);

        // Filter out disposed or inactive assets with no descendants
        if (
          isDisposed ||
          (!isActive && !el?.attributes?.storedAssets?.length)
        ) {
          return;
        }

        // Create parent asset option
        const parentAsset = {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { src: parent_icon }, // Parent asset icon
          className: "main-location-dropdown-option",
          disabled: !isActive, // Disable only for non-admin roles
        };

        let hasAccessibleChildren = false;
        let hasAccessibleGrandchildren = false;

        parentToChildMap.set(el.id, []);
        assetOptions.push(parentAsset);

        // Collect child assets
        if (el?.attributes?.storedAssets?.length > 0) {
          el.attributes.storedAssets.forEach((childEl) => {
            if (childEl?.childAsset?.data) {
              const childAsset = childEl.childAsset.data;
              const childIsDisposed =
                childAsset?.attributes?.status === "dispose";

              const childIsActive =
                !childIsDisposed &&
                (!(role === "super_admin")
                  ? data?.locations
                    ? data?.locations ==
                      childAsset?.attributes?.location?.data?.id
                    : allIds?.includes(
                        childAsset?.attributes?.location?.data?.id
                      )
                  : true);

              // Filter out disposed or inactive child assets with no grandchildren
              if (
                childIsDisposed ||
                (!childIsActive && !childEl?.grandChildAsset?.data?.length)
              ) {
                return;
              }

              // Track whether there are any accessible children
              if (childIsActive) hasAccessibleChildren = true;

              const childOption = {
                key: childAsset.id,
                value: childAsset.id,
                text: `${childAsset.attributes.name}`,
                image: { src: child_icon }, // Child asset icon
                className: "sub-location-dropdown-option",
                parentId: el.id,
                disabled: !childIsActive, // Disable child based on role
              };

              parentToChildMap.get(el.id).push(childOption);
              childToGrandChildMap.set(childAsset.id, []);

              // Collect grandchild assets
              if (childEl?.grandChildAsset?.data?.length > 0) {
                childEl.grandChildAsset.data.forEach((grandChildEl) => {
                  const grandChildIsDisposed =
                    grandChildEl?.attributes?.status === "dispose";

                  const grandChildIsActive =
                    !grandChildIsDisposed &&
                    (!(role === "super_admin")
                      ? data?.locations
                        ? data?.locations ==
                          grandChildEl?.attributes?.location?.data?.id
                        : allIds?.includes(
                            grandChildEl?.attributes?.location?.data?.id
                          )
                      : true);

                  // Filter out disposed or inactive grandchild assets
                  if (grandChildIsDisposed || !grandChildIsActive) {
                    return;
                  }

                  // Track whether there are any accessible grandchildren
                  if (grandChildIsActive) hasAccessibleGrandchildren = true;

                  const grandChildOption = {
                    key: grandChildEl.id,
                    value: grandChildEl.id,
                    text: `${grandChildEl.attributes.name}`,
                    image: { src: gchild_icon }, // Grandchild asset icon
                    className: "grandchild-asset-dropdown-option",
                    parentId: el.id,
                    childParentId: childAsset.id,
                    disabled: !grandChildIsActive, // Disable based on role
                  };

                  childToGrandChildMap
                    .get(childAsset.id)
                    .push(grandChildOption);
                });
              }
            }
          });
        }

        // Ensure the parent asset is displayed if it has no accessible children or grandchildren
        if (!hasAccessibleChildren && !hasAccessibleGrandchildren && isActive) {
          // Clear the child options and only show the parent
          parentToChildMap.set(el.id, []);
        }
      });

      // Sort child and grandchild options alphabetically within each parent and child context
      parentToChildMap.forEach((children) => {
        children.sort((a, b) => a.text.localeCompare(b.text));
      });

      childToGrandChildMap.forEach((grandChildren) => {
        grandChildren.sort((a, b) => a.text.localeCompare(b.text));
      });

      // Combine all the options into a hierarchical structure
      assetOptions.forEach((parentOption) => {
        hierarchicalOptions.push(parentOption);

        const children = parentToChildMap.get(parentOption.key) || [];
        children.forEach((childOption) => {
          hierarchicalOptions.push(childOption);

          const grandChildren = childToGrandChildMap.get(childOption.key) || [];
          grandChildren.forEach((grandChildOption) => {
            hierarchicalOptions.push(grandChildOption);
          });
        });
      });
      // setOptions((prev) => {
      //       return {
      //         ...prev,
      //         assets: hierarchicalOptions,
      //       };
      //     });

      //     return hierarchicalOptions;
    }

    let vendors_options = [];
    if (vendors?.response) {
      vendors_options = vendors.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });

      setFetchingVendros(false);
    }
    let category_options = [];
    if (categories.response) {
      category_options = categories.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });
      setFetchingCategory(false);
    }

    let people_options = [];
    if (people?.data) {
      people_options = people.data.map((el) => {
        const the_image = el.profileImage?.url
          ? el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          // description: `${teams_string_array.join(' , ')}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setFetchingPeople(false);
    }

    let teams_options = [];
    if (teams?.response) {
      teams_options = teams.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setFetchingTeams(false);
    }

    let the_options = [];
    let parentOptions = [];
    let subOptions = [];
    if (fetchDataLocation?.response?.length > 0) {
      fetchDataLocation.response.forEach((el) => {
        if (!(role === "super_admin")) {
          // Check if the element's ID is included in allIds
          if (allIds?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
              className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (allIds.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
              (subEl) => allIds?.includes(subEl.id)
            );

            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });

              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
            }
          }
        } else {
          parentOptions.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: location_icon },
            className: "main-location-dropdown-option",
          });

          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      parentOptions.forEach((parentOption) => {
        the_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            the_options.push(subOption);
          }
        });
      });
    }

    // the_options = the_options.map((option) => {
    //   if (locations.includes(option.value)) {
    //     return {
    //       ...option,
    //       disabled: true, // Disable the option if it matches a location
    //     };
    //   }
    //   return option; // Keep the option unchanged if not in locations
    // });

    let areaOptions = [];

    if (targetedData?.attributes?.inventory?.length > 0) {
      targetedData.attributes.inventory.forEach(async (inventory, index) => {
        const locationsData = targetedData?.attributes?.inventory
          ?.map((store) => store.location?.data?.id)
          .filter((location) => location !== "");

        let updated_loc = the_options?.map((location) => {
          if (locationsData.includes(location?.value)) {
            // Disable the matching location
            return { ...location, disabled: true };
          }
          return location;
        });

        areaOptions.push({ option: updated_loc });
      });
    } else {
      areaOptions = [
        {
          option: [],
        },
      ];
    }

    setOptions({
      ...options,
      assets: fetchData?.response?.length > 0 ? hierarchicalOptions : [],
      vendors: vendors.response?.length > 0 ? vendors_options : [],
      teams: teams.response?.length > 0 ? teams_options : [],
      categories: categories.response?.length > 0 ? category_options : [],
      people: people.data?.length > 0 ? people_options : [],
      locations: fetchDataLocation?.response?.length > 0 ? the_options : [],
      location: areaOptions,
      // area: areaOptions,
    });

    setFetchingLocation(false);

    // targetedData?.attributes?.inventory.map((inventory)=>{

    // })
  };
  /*
   * The `addNewStore` function refer to add new inventory.
   */
  const addNewStore = () => {
    let areaData = [...data.quantityPerStore];
    areaData.push({
      location: "",
      unitCost: null,
      // area: "",
      minimumQuantity: null,
      maximumQuantity: null,
      quantity: null,
    });

    let areaDataValidation = [...validation.quantityPerStore];

    areaDataValidation.push({
      quantity: {
        error: true,
        pattern: (quantity) => {
          if (quantity === null || quantity === undefined) {
            return false;
          } else if (!/^(\d{1,8})(\.\d{1,4})?$/.test(quantity)) {
            return false;
          } else if (quantity < 0) {
            return false;
          }
          return true;
        },
        skipped: false,
      },
      unitCost: {
        error: true,
        pattern: (quantity) => {
          if (quantity === null || quantity === "" || quantity === undefined) {
            return true;
          } else if (!/^(\d{1,8})(\.\d{1,4})?$/.test(quantity)) {
            return false;
          } else if (quantity < 0) {
            return true;
          }
          return true;
        },
        skipped: false,
      },

      minimumQuantity: {
        error: false, // Initially set to false
        pattern: (minQty, maxQty) => minQty <= maxQty || maxQty === null, // Check if minQty is less than or equal to maxQty or if maxQty is not provided
        skipped: false, // Initially set to false
        message: t.parts.minQty_valid, // Error message for invalid minimum quantity
      },
      maximumQuantity: {
        error: false, // Initially set to false
        pattern: (maxQty, minQty) => maxQty >= minQty || minQty === null, // Check if maxQty is greater than or equal to minQty or if minQty is not provided
        skipped: false, // Initially set to false
        message: t.parts.maxQty_valid, // Error message for invalid maximum quantity
      },
      // unitCost: {
      //   error: true,
      //   pattern: (quantity) => quantity >= 1 && numberRegex.test(quantity),
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      // costPer: {
      //   error: true,
      //   pattern: (store) => store != '',
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      // minimumQuantity: {
      //   error: true,
      //   pattern: (quantity) => quantity >= 1 && numberRegex.test(quantity),
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      location: {
        error: true,
        pattern: (store) => store != "",
        skipped: false,
        message: t.common.required_field,
      },
      // area: {
      //   error: true,
      //   pattern: (store) => store != "",
      //   skipped: false,
      //   message: t.common.required_field,
      // },
    });

    let areaDataOptions = [...options.location];

    areaDataOptions.push({
      option: [],
    });
    setData({
      ...data,
      quantityPerStore: areaData,
    });

    setValidation({
      ...validation,
      quantityPerStore: areaDataValidation,
    });
    setOptions({
      ...options,
      location: areaDataOptions,
    });
  };
  /*
   * The function `onValuesChange` handles changes in form field values, updating state and performing validations based on the field name.
   */
  const onValuesChange = (name) => (event, value) => {
    let currentFieldValue = event.target.value;
    setInitChange(true);
    const dropdown_fields = [
      "vendor",
      "people",
      "teams",
      "asset",
      "costPer",
      "procedure",
      "category",
      "location",
      // "area",
      "partType",
    ];

    const boolean_fields = ["isNonStock"];
    if (!dropdown_fields.includes(name) && !boolean_fields.includes(name)) {
      currentFieldValue = event.target.value;
      // if (name == "unitCost") {
      //   if (event.target.value) {
      //     let values = handleDecimalPoints(event.target.value);
      //     setData({
      //       ...data,
      //       [name]: Number(values),
      //     });
      //   } else {
      //     setData({
      //       ...data,
      //       [name]: null,
      //     });
      //   }
      // } else {
      setData({
        ...data,
        [name]: event.target.value,
      });
      // }
    }

    if (dropdown_fields.includes(name)) {
      // if(name == "location"){

      //   let loc_options = [...options.locations]
      //   const updatedLocations = loc_options.filter(
      //     (location) => location?.value !== value?.value
      //   );
      //   setData({
      //     ...data,
      //     [name]: value.value,
      //   });

      //   setOptions((prev)=>{
      //     return {
      //       ...prev,
      //       locations: updatedLocations,
      //     }
      //   })

      // }else{
      setData({
        ...data,
        [name]: value.value,
      });
    }

    // }

    if (boolean_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.checked,
      });
    }

    let fieldValidation = validation[name];
    if (fieldValidation) {
      // console.log(
      //   fieldValidation.pattern(currentFieldValue) ? false : true,
      //   currentFieldValue
      // );
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
          skipped: true,
        },
      };
      // console.log('current', currentValidation);
      setValidation(currentValidation);
    }

    // if (name == "unitCost") {
    //   if (currentFieldValue == 0) {

    //     setValidation({
    //       ...validation,
    //       unitCost: {
    //         ...validation.unitCost,
    //         error: true,
    //         skipped: true,
    //         message: t.common.cost_must_be_greater_than_zero,
    //       },
    //     });
    //   } else {
    //     setValidation({
    //       ...validation,
    //       unitCost: {
    //         ...validation.unitCost,
    //         error: false,
    //         skipped: false,
    //         message: t.common.cost_must_be_greater_than_zero,
    //       },
    //     });
    //   }
    // }
  };
  /*
   * The function fetchCategoryOptions fetches category options from an API and sets the options with category information.
   */
  const fetchCategoryOptions = async () => {
    const fetchData = await getAllCategories(organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });

      setOptions({
        ...options,
        categories: the_options,
      });

      return the_options;
    }
  };
  /*
   * Handle measuring Unit Dropdown
   */
  const onValuesChange2 = (name, value) => {
    setData({
      ...data,
      costPer: value,
    });
  };
  /*
   * The function `uploadFilessHandler` filters and maps files that have been changed before uploading them, returning the upload response or false if no files are changed.
   */
  const uploadFilessHandler = async (files = []) => {
    // console.log(files);
    let theFiles = files.filter((el) => el.isChanged);
    theFiles = theFiles.map((el) => el.file);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };
  /*
   * The `cancelHandler` function resets a form, adjusts modal width, hides a cancel modal, untoggles something, and sets an initial change to false.
   */
  const cancelHandler = () => {
    resetForm();
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    untoggle();
    setNewCancelModal(false);

    setInitChange(false);
  };

  const downloadThisQR = () => {
    const svg = document.getElementById("QRCodeDownloadable");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  /*
   * The `validationHandler` function in JavaScript updates validation properties based on input data and
   * checks for errors before returning a boolean indicating if there are any errors.
   */
  const validationHandler = () => {
    let currentValidation = {
      ...validation,
      name: {
        ...validation.name,
        error: validation.name.pattern(data.name) ? false : true,
        skipped: true,
      },
      teams: {
        ...validation.teams,
        error: validation.teams.pattern(data.teams) ? false : true,
        skipped: true,
      },
      people: {
        ...validation.people,
        error: validation.people.pattern(data?.people) ? false : true,
        skipped: true,
      },
      // unitCost: {
      //   ...validation.unitCost,
      //   error: validation.unitCost.pattern(data.unitCost).isValid
      //     ? false
      //     : true,
      //   skipped: true,
      //   message: validation.unitCost.pattern(data.unitCost).message,
      // },
      partType: {
        ...validation.partType,
        error: validation.partType.pattern(data.partType) ? false : true,
        skipped: true,
      },
      costPer: {
        ...validation.costPer,
        error: validation.costPer.pattern(data.costPer) ? false : true,
        skipped: true,
      },
      // minimumQuantity: {
      //   ...validation.minimumQuantity,
      //   error: validation.minimumQuantity.pattern(
      //     data.minimumQuantity,
      //     data.maximumQuantity
      //   )
      //     ? false
      //     : true,
      //   skipped: true,
      // },
      // maximumQuantity: {
      //   ...validation.maximumQuantity,
      //   error: validation.maximumQuantity.pattern(
      //     data.maximumQuantity,
      //     data.minimumQuantity
      //   )
      //     ? false
      //     : true,
      //   skipped: true,
      // },

      serialNumber: {
        ...validation.serialNumber,
        error: validation.serialNumber.pattern(data.serialNumber)
          ? false
          : true,
        skipped: true,
      },
    };

    currentValidation.quantityPerStore = validation.quantityPerStore.map(
      (item, index) => {
        let newItem = {};
        for (const key in item) {
          if (Object.hasOwnProperty.call(item, key)) {
            if (key == "minimumQuantity") {
              newItem[key] = {
                ...item[key],
                error: item[key].pattern(
                  data.quantityPerStore[index][key],
                  data?.quantityPerStore[index]?.maximumQuantity
                )
                  ? false
                  : true,
                skipped: true,
              };
            } else if (key == "maximumQuantity") {
              newItem[key] = {
                ...item[key],
                error: item[key].pattern(
                  data.quantityPerStore[index][key],
                  data?.quantityPerStore[index]?.minimumQuantity
                )
                  ? false
                  : true,
                skipped: true,
              };
            } else {
              newItem[key] = {
                ...item[key],
                error: item[key].pattern(data.quantityPerStore[index][key])
                  ? false
                  : true,
                skipped: true,
              };

              if (key == "quantity" || key == "unitCost") {
                newItem[key].message = item[key].pattern(
                  data.quantityPerStore[index][key]
                )
                  ? ""
                  : data.quantityPerStore[index][key] === null ||
                    data.quantityPerStore[index][key] === ""
                  ? t.common.required_field
                  : t.common.valid_digits;
              }
            }
          }
        }
        return newItem;
      }
    );

    if (data.people.length > 0 || data.teams) {
      currentValidation.people.error = false;
      currentValidation.teams.error = false;
    } else {
      currentValidation.people.error = true;
      currentValidation.teams.error = true;
    }

    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some(([key, value]) => {
      if (Array.isArray(value)) {
        return value.some((obj) => {
          return Object.values(obj).some((subItem) => subItem.error);
        });
      } else {
        return value.error;
      }
    });

    return errorsInData;
  };

  /*
   * The function `updateInventoryHandler` handles updating inventory data with validation checks and
   * file uploads.
   */
  const updateInventoryHandler = async () => {
    const errorInData = validationHandler();
    // console.log(files);
    // return;
    if (!errorInData) {
      setButtonStatus(1);

      const assetImageReponse =
        attachments.image && attachments.image.isChanged
          ? await uploadFile([attachments.image.file])
          : null;
      const warrantyFileResponse =
        attachments.warrantyFile && attachments?.warrantyFile?.length
          ? await uploadFilessHandler(attachments.warrantyFile)
          : null;

      let theAttachments =
        warrantyFileResponse?.status == 200
          ? warrantyFileResponse.data.map((el) => el.id)
          : [];

      let unchangedAttachments = attachments.warrantyFile.filter(
        (el) => !el.isChanged
      );
      unchangedAttachments =
        unchangedAttachments.length > 0
          ? unchangedAttachments.map((el) => el.id)
          : [];
      // console.log(unchangedAttachments)
      const toBeSendedCertificateFiles = theAttachments.concat(
        unchangedAttachments
      );

      updateInventory2Handler(
        assetImageReponse?.status == 200
          ? assetImageReponse.data[0].id
          : attachments?.image?.id
          ? attachments?.image.id
          : null,
        toBeSendedCertificateFiles
      );
    } else {
      if (data.people.length == 0 && !data.teams) {
        // Show toast message here
        toast.error(t.parts.people_valid);
      } else {
        scrollToError();
      }
    }
  };

  /*
   * The function `updateInventory2Handler` is an asynchronous function that updates inventory data based
   * on the provided parameters and handles the response accordingly.
   */
  const updateInventory2Handler = async (image, files) => {
    const response = await updateInventory(
      Number(targetedData.id),
      String(data.name),
      String(data.description),
      data?.category ? Number(data.category) : null,
      // data?.unitCost ? Number(data.unitCost) : null,
      data?.costPer ? String(data.costPer) : null,
      data?.partType ? String(data.partType) : null,
      data?.isNonStock,
      activeBtn == 2 ? true : false,
      activeBtn == 1 ? true : false,
      data?.serialNumber ? String(data.serialNumber) : null,
      data?.vendor ? data.vendor : null,
      data?.asset ? data.asset : null,
      data?.people ? data.people : null,
      data?.teams ? data.teams : null,
      image,
      files,
      organisationId,
      currentUserId,
      data?.quantityPerStore ? data?.quantityPerStore : null
      // data?.minimumQuantity ? data?.minimumQuantity : null,
      // data?.maximumQuantity ? data?.maximumQuantity : null
    );

    if (response && response.status == 200) {
      resetForm();
      untoggle();
      setResponseData({
        id: response.data.id,
        name: response.data.name,
        qrcode: response.data.qrcode,
      });

      setFilterOptions({
        vendors: [],
        serialNumber: "",
        categories: [],
        teams: [],
        status: [""],
        partType: [""],
        assets: [],
        locations: [],
        // area: [],
        serialNumber: "",
      });

      toast.success(t.parts.updated_success);
    } else if (response.status == 400) {
      if (
        response.error &&
        response.error.details &&
        response.error.details.errors?.length
      ) {
        response.error.details.errors.map((one) => {
          if (one?.path[0] && one?.path[0] === "serialNumber") {
            toast.error(t.assets.serial_number_exist);
          } else {
            toast.error(`${one?.path[0]}- ${one.message}`);
          }
        });
      } else if (response.error && response.error.message) {
        toast.error(response.error.message);
        // untoggle();
      } else {
        toast.error(t.common.something_wrong);
        untoggle();
      }
    } else {
      toast.error(t.common.something_wrong);
      untoggle();
    }
    setButtonStatus(0);
  };

  /// Executed after category is created to select the newly crested category
  const categoryCreateSuccess = (res) => {
    fetchCategoryOptions();
    if (res?.data?.data?.attributes?.name) {
      setData({
        ...data,
        category: res?.data?.data?.id,
      });
    }
  };

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled ? "aside-modal procedure active" : "aside-modal procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between align-center">
              <div className="name">
                {t.parts.form.edit_part} #{targetedData?.attributes?.countId}
              </div>
              <div>
                <span
                  className="c-pointer"
                  style={{ position: "relative" }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange
                      ? setNewCancelModal(initChange && true)
                      : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == "en"
                        ? "bottom center"
                        : "bottom center"
                    }
                    arrowAlign="center"
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          {fetchingLocation ? (
            <div style={{ minHeight: "100vh" }}>
              <Dimmer active inverted>
                <Loader inverted content={t.common.loading} />
              </Dimmer>
            </div>
          ) : (
            <div
              className="body"
              style={{
                height: "90vh",
                overflowX: "hidden",
                overflowY: "scroll",
              }}
            >
              <Form.Field>
                <label className="label-item">
                  {t.parts.form.name} <Asterisk color="#172B4D" />
                </label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder={t.parts.form.add_name}
                  value={data.name}
                  onChange={onValuesChange("name")}
                  error={validation.name.error && validation.name.skipped}
                  onBlur={(e) => {
                    if (validation.name.pattern(data.name)) {
                      setValidation({
                        ...validation,
                        name: {
                          ...validation.name,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        name: {
                          ...validation.name,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.name.error && validation.name.skipped ? (
                  <div className="label-error">{validation.name.message}</div>
                ) : (
                  ""
                )}
              </Form.Field>

              <FileUpload
                onFileChange={onFileChange}
                name="image"
                attachments={attachments}
                setAttachments={setAttachments}
              />

              <Form style={{ marginBottom: "20px" }}>
                <Form.Field>
                  <label
                    className={`label-item ${
                      attachments?.image?.name ? "mt-5" : "mt-7"
                    }`}
                  >
                    {" "}
                    {t.parts.form.description}
                  </label>
                  <TextArea
                    autoComplete="new-password"
                    value={data.description}
                    onChange={onValuesChange("description")}
                    rows={4}
                    fluid
                    placeholder={t.customers.form.add_description}
                  />
                </Form.Field>
              </Form>

              <Form.Field>
                <label className="label-item">{t.parts.form.category}</label>
                <div className="d-flex">
                  <Dropdown
                    selection
                    noResultsMessage={t.common.no_results_found}
                    clearable
                    search
                    fluid
                    options={options.categories}
                    placeholder={t.parts.form.select_category}
                    value={data.category}
                    onChange={onValuesChange("category")}
                    onOpen={fetchCategoryOptions}
                  />
                  {userPermissions?.category?.create &&
                  userPermissions?.category?.view ? (
                    <span
                      className="ml-5 mr-2 p-1 d-flex align-items-center justify-content-center c-pointer"
                      onClick={() => {
                        setOpenCategoryAddModal(true);
                      }}
                      style={{ backgroundColor: "#E9F2FF" }}
                    >
                      <span className="">
                        {" "}
                        <AddIcon />
                      </span>
                    </span>
                  ) : null}
                </div>
              </Form.Field>

              {/* <Form.Field>
                <label className="label-item">
                  {t.parts.form.unit_cost}
                  <Asterisk color="#172B4D" />
                </label>
                <Input
                  autoComplete="new-password"
                  type="number"
                  min="0"
                  fluid
                  placeholder={t.parts.form.enter_unit_cost}
                  value={data.unitCost}
                  onChange={onValuesChange("unitCost")}
                  onKeyDown={(evt) => handleKeyDown(evt)}
                  error={
                    validation.unitCost.error && validation.unitCost.skipped
                  }
                  onBlur={(e) => {
                    const unitCostValidation = validation.unitCost.pattern(
                      data.unitCost
                    );
                    if (unitCostValidation?.isValid) {
                      setValidation({
                        ...validation,
                        unitCost: {
                          ...validation.unitCost,
                          error: false,
                          skipped: true,
                          message: "",
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        unitCost: {
                          ...validation.unitCost,
                          error: true,
                          skipped: true,
                          message: unitCostValidation.message,
                        },
                      });
                    }
                  }}
                >
                  <input />
                  <span
                    className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                      selectedLanguage == "en" ? "ml-5" : "mr-5"
                    }`}
                    style={{ backgroundColor: "#E9F2FF", color: "#0C66E4" }}
                  >
                    {organisationCurrency}
                  </span>
                </Input>
                {validation.unitCost.error && validation.unitCost.skipped ? (
                  <div className="label-error">
                    {validation.unitCost.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field> */}

              <Form.Field className="wraper-drop-masure">
                <label className="label-item">
                  {t.parts.form.unit_type}
                  <Asterisk color="#172B4D" />
                </label>
                <Dropdown
                  placeholder={t.parts.form.select_cost_type}
                  noResultsMessage={t.common.no_results_found}
                  fluid
                  search
                  clearable
                  selection
                  value={data?.costPer}
                  onChange={(e, { value }) => {
                    if (!value) {
                      // Reset selected option on clear
                      onValuesChange2("costPer", "");

                      setValidation({
                        ...validation,
                        costPer: {
                          ...validation.costPer,
                          error: true,
                          skipped: true,
                        },
                      });
                    } else {
                      onValuesChange2("costPer", value);
                    }
                  }}
                  closeOnChange={true}
                  options={options.allQtyOptions}
                  error={validation.costPer.error && validation.costPer.skipped}
                  onBlur={(e) => {
                    if (validation.costPer.pattern(data.costPer)) {
                      setValidation({
                        ...validation,
                        costPer: {
                          ...validation.costPer,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        costPer: {
                          ...validation.costPer,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                  // onChange={onValuesChange("costPer")}
                >
                  <DropdownMenu>
                    <DropdownHeader content="Quantity-Based Units" />
                    {options.costPerQtyUnit.map((option) => (
                      <DropdownItem
                        onClick={() => {
                          onValuesChange2("costPer", option.value);
                        }}
                        key={option.value}
                        {...option}
                      />
                    ))}
                    <DropdownHeader content="Volume-Based Units" />
                    {options.costPerVolUnit.map((option) => (
                      <DropdownItem
                        onClick={() => {
                          onValuesChange2("costPer", option.value);
                        }}
                        key={option.value}
                        {...option}
                      />
                    ))}
                    <DropdownHeader content="Mass and Weight-Based Units" />
                    {options.costPerMassUnit.map((option) => (
                      <DropdownItem
                        onClick={() => {
                          onValuesChange2("costPer", option.value);
                        }}
                        key={option.value}
                        {...option}
                      />
                    ))}
                  </DropdownMenu>

                  {/* <DropdownMenu>
            <DropdownHeader content='Volume-Based Units' />
            {options.costPer.map((option) => (
              <DropdownItem key={option.value} {...option} />
            ))}
          </DropdownMenu> */}
                </Dropdown>
                {validation.costPer.error && validation.costPer.skipped ? (
                  <div className="label-error">
                    {validation.costPer.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field>

              {/* <Form.Field>
                <label className="label-item parts-minimum-qty">
                  {t.parts.form.minimum_qty}{" "}
                  <span
                    className="c-pointer"
                    style={{ position: "relative" }}
                    onMouseEnter={() => setMinQtyTooltip(true)}
                    onMouseLeave={() => setMinQtyTooltip(false)}
                  >
                    {showMinQtyTooltip ? (
                      <>
                        <InfoIconBlue />
                        <ToolTip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position="right top"
                          show={showMinQtyTooltip}
                          textBoxWidth="105px"
                          arrowAlign="center"
                          fontSize="9px"
                          fontWeight="400"
                          fontFamily={
                            selectedLanguage == "ar"
                              ? "Noto Kufi Arabic"
                              : "Roboto"
                          }
                          padding="4px 14px 4px 7px"
                          lineHeight="16px"
                          borderRadius="6px"
                          static
                          moveDown="0px"
                        >
                          <span className="work-order-preview-tooltip parts">
                            {t.parts.form.min_qty_tooltip}
                          </span>
                        </ToolTip>
                      </>
                    ) : (
                      <InfoIcon />
                    )}
                  </span>
                </label>
                <Input
                  type="number"
                  fluid
                  error={
                    validation.minimumQuantity.error &&
                    validation.minimumQuantity.skipped
                  }
                  onKeyDown={(evt) => handleKeyDown(evt)}
                  autoComplete="new-password"
                  placeholder={t.parts.form.enter_minimum_qty}
                  value={data?.minimumQuantity ? data?.minimumQuantity : ""}
                  onChange={(e) => {
                    let valueData = Number(e.target.value);

                    if (valueData) {
                      // Update the minimum quantity in the form data
                      setData({
                        ...data,
                        minimumQuantity: valueData,
                      });

                      // Check if maximum quantity is provided
                      if (
                        data.maximumQuantity !== null &&
                        valueData >= data.maximumQuantity
                      ) {
                        // If maximum quantity is provided and minimum quantity is greater than maximum quantity
                        // Set an error in the validation state for minimum quantity
                        setValidation((prevValidation) => ({
                          ...prevValidation,
                          minimumQuantity: {
                            ...prevValidation.minimumQuantity,
                            error: true, // Set error to true
                            skipped: true, // Set skipped to true
                          },
                          maximumQuantity: {
                            ...prevValidation.maximumQuantity,
                            error: false, // Set error to true
                            skipped: false, // Set skipped to true
                          },
                        }));
                      } else {
                        // Reset error in the validation state for minimum quantity
                        setValidation((prevValidation) => ({
                          ...prevValidation,
                          minimumQuantity: {
                            ...prevValidation.minimumQuantity,
                            error: false, // Reset error to false
                            skipped: false, // Reset skipped to false
                          },
                          maximumQuantity: {
                            ...prevValidation.maximumQuantity,
                            error: false, // Reset error to false
                            skipped: false, // Reset skipped to false
                          },
                        }));
                      }
                    } else {
                      // If the input value is invalid, set an error in the validation state for minimum quantity
                      setValidation((prevValidation) => ({
                        ...prevValidation,
                        minimumQuantity: {
                          ...prevValidation.minimumQuantity,
                          error: false, // Set error to true
                          skipped: false, // Set skipped to true
                        },
                        maximumQuantity: {
                          ...prevValidation.maximumQuantity,
                          error: false, // Set error to true
                          skipped: false, // Set skipped to true
                        },
                      }));

                      // Reset minimum quantity in the form data
                      setData({
                        ...data,
                        minimumQuantity: null,
                      });
                    }
                  }}
                  // error={
                  //   validation.quantityPerStore[index].minimumQuantity
                  //     .error &&
                  //   validation.quantityPerStore[index].minimumQuantity
                  //     .skipped
                  // }
                  // onBlur={(e) => {
                  //   const value = e.target.value;
                  //   if (
                  //     validation.quantityPerStore[
                  //       index
                  //     ].minimumQuantity.pattern(value)
                  //   ) {
                  //     setValidation((prevValidation) => ({
                  //       ...prevValidation,
                  //       quantityPerStore: prevValidation.quantityPerStore.map(
                  //         (item, i) => {
                  //           if (i === index) {
                  //             return {
                  //               ...item,
                  //               minimumQuantity: {
                  //                 ...item.minimumQuantity,
                  //                 error: false,
                  //                 skipped: true,
                  //               },
                  //             };
                  //           }
                  //           return item;
                  //         }
                  //       ),
                  //     }));
                  //   } else {
                  //     setValidation((prevValidation) => ({
                  //       ...prevValidation,
                  //       quantityPerStore: prevValidation.quantityPerStore.map(
                  //         (item, i) => {
                  //           if (i === index) {
                  //             return {
                  //               ...item,
                  //               minimumQuantity: {
                  //                 ...item.minimumQuantity,
                  //                 error: true,
                  //                 skipped: true,
                  //               },
                  //             };
                  //           }
                  //           return item;
                  //         }
                  //       ),
                  //     }));
                  //   }
                  // }}
                ></Input>
                {validation.minimumQuantity.error &&
                validation.minimumQuantity.skipped ? (
                  <div className="label-error">
                    {validation.minimumQuantity.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field>
              <Form.Field>
                <label className="label-item">
                  {t.parts.form.miximum_qty}{" "}
                  <span
                    className="c-pointer"
                    style={{ position: "relative" }}
                    onMouseEnter={() => setMaxQtyTooltip(true)}
                    onMouseLeave={() => setMaxQtyTooltip(false)}
                  >
                    {showMaxQtyTooltip ? (
                      <>
                        <InfoIconBlue />
                        <ToolTip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position="right top"
                          show={showMaxQtyTooltip}
                          textBoxWidth="105px"
                          arrowAlign="center"
                          fontSize="9px"
                          fontWeight="400"
                          fontFamily={
                            selectedLanguage == "ar"
                              ? "Noto Kufi Arabic"
                              : "Roboto"
                          }
                          padding="4px 14px 4px 7px"
                          lineHeight="16px"
                          borderRadius="6px"
                          static
                          moveDown="0px"
                        >
                          <span className="work-order-preview-tooltip parts">
                            {t.parts.form.max_qty_tooltip}
                          </span>
                        </ToolTip>
                      </>
                    ) : (
                      <InfoIcon />
                    )}
                  </span>
                </label>
                <div className="">
                  <Input
                    type="number"
                    min={1}
                    onKeyDown={(evt) => handleKeyDown(evt)}
                    autoComplete="new-password"
                    fluid
                    error={
                      validation.maximumQuantity.error &&
                      validation.maximumQuantity.skipped
                    }
                    placeholder={t.parts.form.enter_miximum_qty}
                    value={data?.maximumQuantity ? data?.maximumQuantity : ""}
                    onChange={(e) => {
                      let valueData = Number(e.target.value);

                      if (valueData) {
                        // Update the minimum quantity in the form data
                        setData({
                          ...data,
                          maximumQuantity: valueData,
                        });

                        // Check if maximum quantity is provided
                        if (
                          data.minimumQuantity !== null &&
                          valueData <= data.minimumQuantity
                        ) {
                          // If maximum quantity is provided and minimum quantity is greater than maximum quantity
                          // Set an error in the validation state for minimum quantity
                          setValidation((prevValidation) => ({
                            ...prevValidation,
                            maximumQuantity: {
                              ...prevValidation.maximumQuantity,
                              error: true, // Set error to true
                              skipped: true, // Set skipped to true
                            },
                            minimumQuantity: {
                              ...prevValidation.minimumQuantity,
                              error: false, // Set error to true
                              skipped: false, // Set skipped to true
                            },
                          }));
                        } else {
                          // Reset error in the validation state for minimum quantity
                          setValidation((prevValidation) => ({
                            ...prevValidation,
                            minimumQuantity: {
                              ...prevValidation.minimumQuantity,
                              error: false, // Reset error to false
                              skipped: false, // Reset skipped to false
                            },
                            maximumQuantity: {
                              ...prevValidation.maximumQuantity,
                              error: false, // Reset error to false
                              skipped: false, // Reset skipped to false
                            },
                          }));
                        }
                      } else {
                        // If the input value is invalid, set an error in the validation state for minimum quantity
                        setValidation((prevValidation) => ({
                          ...prevValidation,
                          minimumQuantity: {
                            ...prevValidation.minimumQuantity,
                            error: false, // Reset error to false
                            skipped: false, // Reset skipped to false
                          },
                          maximumQuantity: {
                            ...prevValidation.maximumQuantity,
                            error: false, // Set error to true
                            skipped: false, // Set skipped to true
                          },
                        }));

                        // Reset minimum quantity in the form data
                        setData({
                          ...data,
                          maximumQuantity: null,
                        });
                      }
                    }}
                  >
                    <input />
                  </Input>
                </div>
                {validation.maximumQuantity.error &&
                validation.maximumQuantity.skipped ? (
                  <div className="label-error">
                    {validation.maximumQuantity.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field> */}

              <Form.Field>
                <label className="label-item">
                  {t.parts.filter.partType}
                  <Asterisk color="#172B4D" />
                  {/* <Icon name="asterisk" color="red" size="small" /> */}
                </label>{" "}
                {/* {validation.vendor.error && validation.vendor.skipped ? (
                  <div className="label-error">{validation.vendor.message}</div>
                ) : (
                  ''
                )} */}
                <Dropdown
                  placeholder={t.parts.form.select_part}
                  noResultsMessage={t.common.no_results_found}
                  fluid
                  search
                  clearable
                  selection
                  options={options.partType}
                  value={data.partType}
                  onChange={onValuesChange("partType")}
                  error={
                    validation.partType.error && validation.partType.skipped
                  }
                  onBlur={(e) => {
                    if (validation.partType.pattern(data.partType)) {
                      setValidation({
                        ...validation,
                        partType: {
                          ...validation.partType,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        partType: {
                          ...validation.partType,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                  // onOpen={() => fetchAssets()}
                  // error={validation.asset.error && validation.asset.skipped}
                  // onBlur={(e) => {
                  //   if (validation.asset.pattern(data.asset)) {
                  //     setValidation({
                  //       ...validation,
                  //       asset: {
                  //         ...validation.asset,
                  //         error: false,
                  //         skipped: true,
                  //       },
                  //     });
                  //   } else {
                  //     setValidation({
                  //       ...validation,
                  //       asset: {
                  //         ...validation.asset,
                  //         error: true,
                  //         skipped: true,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {validation.partType.error && validation.partType.skipped ? (
                  <div className="label-error">
                    {validation.partType.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field>
              <Checkbox
                value={data.isNonStock}
                checked={data.isNonStock}
                label={t.parts.form.non_stock}
                onChange={onValuesChange("isNonStock")}
              />

              {/* <Divider />

            <div className="billing-info mt-6  d-flex">
              <InventoryIcon />
              <p
                style={{ fontSize: '16px', lineHeight: '24px' }}
                className={`required-text ${
                  selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
                }`}
              >
                {t.parts.form.store_qty}
              </p>
            </div> */}

              {data.quantityPerStore.map((one, index) => {
                return (
                  <>
                    <div
                      className={`store-details ${
                        !(role === "super_admin")
                          ? one?.ispre
                            ? allIds?.includes(one?.location)
                              ? ""
                              : "d-none"
                            : ""
                          : ""
                      }`}
                    >
                      <Segment className="p-6 mt-6">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label className={`label-item `}>
                              {t.parts.form.inv_loc}
                              <Asterisk color="#172B4D" />
                            </label>
                          </div>

                          {data?.quantityPerStore?.length ==
                          1 ? null : !one?.ispre ? (
                            <div
                              onClick={removeThisData(index)}
                              className="mb-2"
                            >
                              <DeleteIcon />
                            </div>
                          ) : null}
                        </div>
                        <Dropdown
                          placeholder={t.assets.form.select_location}
                          noResultsMessage={t.common.no_results_found}
                          fluid
                          clearable
                          selection
                          disabled={one?.ispre}
                          className="loc-dropdown"
                          search
                          options={options?.location[index]?.option}
                          value={one?.location}
                          // disabled={one?.ispre}
                          onOpen={() =>
                            fetchLocationOption(one?.location, index)
                          }
                          onChange={(e, value) => {
                            if (value) {
                              let areaData = [...data.quantityPerStore];
                              areaData[index].location = value.value;
                              // areaData[index].area = "";
                              // let loc_options = [...options.locations];
                              // // const updatedLocations = loc_options.filter(
                              // //   (location) => location?.value !== value?.value
                              // // );

                              // let updated_loc = loc_options?.map((location) => {
                              //   if (location?.value === value?.value) {
                              //     // Disable the matching location
                              //     return { ...location, disabled: true };
                              //   }
                              //   return location;
                              // });
                              setData({
                                ...data,
                                quantityPerStore: areaData,
                                // vendor: [],
                              });
                              // setOptions((prev) => {
                              //   return {
                              //     ...prev,
                              //     locations: updated_loc,
                              //   };
                              // });
                              // fetchArea(value.value, index);
                              setValidation((prevValidation) => ({
                                ...prevValidation,
                                quantityPerStore: prevValidation.quantityPerStore.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        location: {
                                          ...item.location,
                                          error: false,
                                          skipped: true,
                                        },
                                      };
                                    }
                                    return item;
                                  }
                                ),
                              }));
                            } else if (!value) {
                              let areaData = [...data.quantityPerStore];
                              areaData[index].location = "";

                              setData({
                                ...data,
                                quantityPerStore: areaData,
                                // vendor: [],
                              });

                              setValidation((prevValidation) => ({
                                ...prevValidation,
                                quantityPerStore: prevValidation.quantityPerStore.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        location: {
                                          ...item.location,
                                          error: true,
                                          skipped: true,
                                        },
                                      };
                                    }
                                    return item;
                                  }
                                ),
                              }));
                            }
                          }}
                          error={
                            validation?.quantityPerStore[index]?.location
                              ?.error &&
                            validation?.quantityPerStore[index]?.location
                              ?.skipped
                          }
                          onBlur={(e) => {
                            if (
                              validation.quantityPerStore[
                                index
                              ].location.pattern(one.location)
                            ) {
                              setValidation((prevValidation) => ({
                                ...prevValidation,
                                quantityPerStore: prevValidation.quantityPerStore.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        location: {
                                          ...item.location,
                                          error: false,
                                          skipped: true,
                                        },
                                      };
                                    }
                                    return item;
                                  }
                                ),
                              }));
                            } else {
                              setValidation((prevValidation) => ({
                                ...prevValidation,
                                quantityPerStore: prevValidation.quantityPerStore.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        location: {
                                          ...item.location,
                                          error: true,
                                          skipped: true,
                                        },
                                      };
                                    }
                                    return item;
                                  }
                                ),
                              }));
                            }
                          }}
                        />
                        {validation.quantityPerStore[index].location.error &&
                        validation.quantityPerStore[index].location.skipped ? (
                          <div className="label-error">
                            {
                              validation.quantityPerStore[index].location
                                .message
                            }
                          </div>
                        ) : (
                          ""
                        )}
                        {/* <label className={`label-item mt-5`}>
                          {t.parts.filter.area}
                          <Asterisk color="#172B4D" />
                        </label>
                        <Dropdown
                          placeholder={t.parts.form.add_area}
                          noResultsMessage={t.common.no_results_found}
                          fluid
                          selection
                          disabled={one?.ispre}
                          search
                          options={options?.area[index]?.option}
                          value={one?.area}
                          onChange={(e, value) => {
                            if (value) {
                              let areaData = [...data.quantityPerStore];
                              areaData[index].area = value.value;
                              setData({
                                ...data,
                                quantityPerStore: areaData,
                              });
                              setValidation((prevValidation) => ({
                                ...prevValidation,
                                quantityPerStore: prevValidation.quantityPerStore.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        area: {
                                          ...item.area,
                                          error: false,
                                          skipped: true,
                                        },
                                      };
                                    }
                                    return item;
                                  }
                                ),
                              }));
                            } else if (!value) {
                              let areaData = [...data.quantityPerStore];
                              areaData[index].area = "";

                              setData({
                                ...data,
                                quantityPerStore: areaData,
                              });
                              setValidation((prevValidation) => ({
                                ...prevValidation,
                                quantityPerStore: prevValidation.quantityPerStore.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        area: {
                                          ...item.area,
                                          error: true,
                                          skipped: true,
                                        },
                                      };
                                    }
                                    return item;
                                  }
                                ),
                              }));
                            }
                          }}
                          error={
                            validation.quantityPerStore[index].area.error &&
                            validation.quantityPerStore[index].area.skipped
                          }
                          onBlur={(e) => {
                            if (
                              validation.quantityPerStore[index].area.pattern(
                                one.area
                              )
                            ) {
                              setValidation((prevValidation) => ({
                                ...prevValidation,
                                quantityPerStore: prevValidation.quantityPerStore.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        area: {
                                          ...item.area,
                                          error: false,
                                          skipped: true,
                                        },
                                      };
                                    }
                                    return item;
                                  }
                                ),
                              }));
                            } else {
                              setValidation((prevValidation) => ({
                                ...prevValidation,
                                quantityPerStore: prevValidation.quantityPerStore.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        area: {
                                          ...item.area,
                                          error: true,
                                          skipped: true,
                                        },
                                      };
                                    }
                                    return item;
                                  }
                                ),
                              }));
                            }
                          }}
                        />
                        {validation.quantityPerStore[index].area.error &&
                        validation.quantityPerStore[index].area.skipped ? (
                          <div className="label-error">
                            {validation.quantityPerStore[index].area.message}
                          </div>
                        ) : (
                          ""
                        )} */}
                        <Form.Field style={{ minHeight: "58px" }}>
                          <label className="label-item mt-5">
                            {t.parts.table.quantity}
                            <Asterisk color="#172B4D" />{" "}
                            <span
                              className="c-pointer"
                              style={{ position: "relative" }}
                              onMouseEnter={() =>
                                setQtyTooltip({
                                  ...showQtyTooltip,
                                  [index]: true,
                                })
                              }
                              onMouseLeave={() =>
                                setQtyTooltip({
                                  ...showQtyTooltip,
                                  [index]: false,
                                })
                              }
                            >
                              {showQtyTooltip[index] ? (
                                <>
                                  <InfoIconBlue />
                                  <ToolTip
                                    color="#fff"
                                    backgroundColor="#172B4D"
                                    position={
                                      selectedLanguage == "ar"
                                        ? "left top"
                                        : "right top"
                                    }
                                    show={showQtyTooltip[index]}
                                    textBoxWidth="130px"
                                    arrowAlign="center"
                                    fontSize="9px"
                                    fontWeight="400"
                                    fontFamily={
                                      selectedLanguage == "ar"
                                        ? "Noto Kufi Arabic"
                                        : "Roboto"
                                    }
                                    padding="4px 14px 4px 7px"
                                    lineHeight="16px"
                                    borderRadius="6px"
                                    static
                                    moveDown="0px"
                                  >
                                    <span className="work-order-preview-tooltip parts">
                                      {t.parts.form.qty_tooltip}
                                    </span>
                                  </ToolTip>
                                </>
                              ) : (
                                <InfoIcon />
                              )}
                            </span>
                          </label>
                          <Input
                            type="number"
                            fluid
                            disabled={one?.ispre}
                            onKeyDown={(evt) => handleKeyDown(evt)}
                            autoComplete="new-password"
                            onWheel={(e) => e.target.blur()}
                            placeholder={t.parts.form.enter_qty}
                            value={one.quantity}
                            onChange={(e) => {
                              const value = Number(e.target.value);

                              let areaData = [...data.quantityPerStore];
                              if (value) {
                                areaData[index].quantity = Number(
                                  value.toFixed(4)
                                );

                                setData({
                                  ...data,
                                  quantityPerStore: areaData,
                                });
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          quantity: {
                                            ...item.quantity,
                                            error: false,
                                            skipped: true,
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));
                              } else if (!value) {
                                if (
                                  e.target.value == 0 &&
                                  e.target.value !== ""
                                ) {
                                  areaData[index].quantity = 0;
                                } else {
                                  areaData[index].quantity = null;
                                }
                                setData({
                                  ...data,
                                  quantityPerStore: areaData,
                                });

                                if (value === null) {
                                  setValidation((prevValidation) => ({
                                    ...prevValidation,
                                    quantityPerStore: prevValidation.quantityPerStore.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            quantity: {
                                              ...item.quantity,
                                              error: true,
                                              skipped: true,
                                              message: t.common.required_field,
                                            },
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  }));
                                } else {
                                  setValidation((prevValidation) => ({
                                    ...prevValidation,
                                    quantityPerStore: prevValidation.quantityPerStore.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            quantity: {
                                              ...item.quantity,
                                              error: false,
                                              skipped: false,
                                            },
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  }));
                                }
                              }
                            }}
                            error={
                              validation.quantityPerStore[index].quantity
                                .error &&
                              validation.quantityPerStore[index].quantity
                                .skipped
                            }
                            onBlur={(e) => {
                              const value = e.target.value;

                              if (
                                validation.quantityPerStore[
                                  index
                                ].quantity.pattern(value)
                              ) {
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          quantity: {
                                            ...item.quantity,
                                            error: false,
                                            skipped: true,
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));
                              } else {
                                let errorMessage;
                                if (!value) {
                                  errorMessage = t.common.required_field;
                                } else {
                                  errorMessage = t.common.valid_digits;
                                }
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          quantity: {
                                            ...item.quantity,
                                            error: true,
                                            skipped: true,
                                            message: errorMessage,
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));
                              }
                            }}
                          />
                          {validation.quantityPerStore[index].quantity.error &&
                          validation.quantityPerStore[index].quantity
                            .skipped ? (
                            <div className="label-error">
                              {
                                validation.quantityPerStore[index].quantity
                                  .message
                              }
                            </div>
                          ) : (
                            ""
                          )}
                        </Form.Field>{" "}
                        <Form.Field style={{ minHeight: "58px" }}>
                          <label className="label-item parts-minimum-qty mt-5">
                            {t.parts.form.minimum_qty}{" "}
                            <span
                              className="c-pointer"
                              style={{ position: "relative" }}
                              onMouseEnter={() =>
                                setMinQtyTooltip({
                                  ...showQtyTooltip,
                                  [index]: true,
                                })
                              }
                              onMouseLeave={() =>
                                setMinQtyTooltip({
                                  ...showQtyTooltip,
                                  [index]: false,
                                })
                              }
                              // onMouseEnter={() => setMinQtyTooltip(true)}
                              // onMouseLeave={() => setMinQtyTooltip(false)}
                            >
                              {showMinQtyTooltip[index] ? (
                                <>
                                  <InfoIconBlue />
                                  <ToolTip
                                    color="#fff"
                                    backgroundColor="#172B4D"
                                    position="right top"
                                    show={showMinQtyTooltip[index]}
                                    textBoxWidth="105px"
                                    arrowAlign="center"
                                    fontSize="9px"
                                    fontWeight="400"
                                    fontFamily={
                                      selectedLanguage == "ar"
                                        ? "Noto Kufi Arabic"
                                        : "Roboto"
                                    }
                                    padding="4px 14px 4px 7px"
                                    lineHeight="16px"
                                    borderRadius="6px"
                                    static
                                    moveDown="0px"
                                  >
                                    <span className="work-order-preview-tooltip parts">
                                      {t.parts.form.min_qty_tooltip}
                                    </span>
                                  </ToolTip>
                                </>
                              ) : (
                                <InfoIcon />
                              )}
                            </span>
                          </label>
                          <Input
                            type="number"
                            fluid
                            onKeyDown={(evt) => handleKeyDown(evt)}
                            error={
                              validation.quantityPerStore[index].minimumQuantity
                                .error &&
                              validation.quantityPerStore[index].minimumQuantity
                                .skipped
                            }
                            // error={
                            //   validation.minimumQuantity.error &&
                            //   validation.minimumQuantity.skipped
                            // }
                            autoComplete="new-password"
                            placeholder={t.parts.form.enter_minimum_qty}
                            value={
                              one?.minimumQuantity ? one?.minimumQuantity : ""
                            }
                            onChange={(e) => {
                              let valueData = Number(e.target.value);
                              const isMaxQtyValid =
                                one?.maximumQuantity !== null &&
                                !/^(\d{1,8})(\.\d{1,4})?$/.test(
                                  one?.maximumQuantity
                                );
                              if (valueData) {
                                let areaData = [...data.quantityPerStore];

                                areaData[index].minimumQuantity = valueData;
                                setData({
                                  ...data,
                                  quantityPerStore: areaData,
                                });
                                if (
                                  !/^(\d{1,8})(\.\d{1,4})?$/.test(valueData)
                                ) {
                                  if (isMaxQtyValid) {
                                    setValidation((prevValidation) => ({
                                      ...prevValidation,
                                      quantityPerStore: prevValidation.quantityPerStore.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              minimumQuantity: {
                                                ...item.minimumQuantity,
                                                error: true,
                                                skipped: true,
                                                message: t.common.valid_digits,
                                              },
                                              maximumQuantity: {
                                                ...item.maximumQuantity,
                                                error: true, // Reset error to false
                                                skipped: true, // Reset skipped to false
                                                message: t.common.valid_digits,
                                              },
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                    }));
                                    // setValidation((prevValidation) => ({
                                    //   ...prevValidation,
                                    //   minimumQuantity: {
                                    //     ...prevValidation.minimumQuantity,
                                    //     error: true,
                                    //     skipped: true,
                                    //     message: t.common.valid_digits,
                                    //   },
                                    //   maximumQuantity: {
                                    //     ...prevValidation.maximumQuantity,
                                    //     error: true, // Reset error to false
                                    //     skipped: true, // Reset skipped to false
                                    //     message: t.common.valid_digits,
                                    //   },
                                    // }));
                                  } else {
                                    setValidation((prevValidation) => ({
                                      ...prevValidation,
                                      quantityPerStore: prevValidation.quantityPerStore.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              minimumQuantity: {
                                                ...item.minimumQuantity,
                                                error: true, // Reset error to false
                                                skipped: true,
                                                message: t.common.valid_digits, // Reset skipped to false
                                              },
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                    }));
                                    // setValidation((prevValidation) => ({
                                    //   ...prevValidation,
                                    //   minimumQuantity: {
                                    //     ...prevValidation.minimumQuantity,
                                    //     error: true, // Reset error to false
                                    //     skipped: true,
                                    //     message: t.common.valid_digits, // Reset skipped to false
                                    //   },

                                    // }));
                                  }
                                }
                                // Check if maximum quantity is provided
                                else if (
                                  one.maximumQuantity !== null &&
                                  valueData >= one.maximumQuantity
                                ) {
                                  setValidation((prevValidation) => ({
                                    ...prevValidation,
                                    quantityPerStore: prevValidation.quantityPerStore.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            minimumQuantity: {
                                              ...item.minimumQuantity,
                                              error: true, // Set error to true
                                              skipped: true, // Set skipped to true
                                              message: t.parts.minQty_valid,
                                            },
                                            maximumQuantity: {
                                              ...item.maximumQuantity,
                                              error: false, // Set error to true
                                              skipped: false, // Set skipped to true
                                            },
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  }));

                                  // setValidation((prevValidation) => ({
                                  //   ...prevValidation,
                                  //   minimumQuantity: {
                                  //     ...prevValidation.minimumQuantity,
                                  //     error: true, // Set error to true
                                  //     skipped: true, // Set skipped to true
                                  //     message: t.parts.minQty_valid,
                                  //   },
                                  //   maximumQuantity: {
                                  //     ...prevValidation.maximumQuantity,
                                  //     error: false, // Set error to true
                                  //     skipped: false, // Set skipped to true
                                  //   },
                                  // }));
                                } else {
                                  if (isMaxQtyValid) {
                                    setValidation((prevValidation) => ({
                                      ...prevValidation,
                                      quantityPerStore: prevValidation.quantityPerStore.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              maximumQuantity: {
                                                ...item.maximumQuantity,
                                                error: true, // Reset error to false
                                                skipped: true, // Reset skipped to false
                                                message: t.common.valid_digits,
                                              },
                                              minimumQuantity: {
                                                ...item.minimumQuantity,
                                                error: false, // Reset error to false
                                                skipped: false, // Reset skipped to false
                                              },
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                    }));
                                    // setValidation((prevValidation) => ({
                                    //   ...prevValidation,
                                    //   maximumQuantity: {
                                    //     ...prevValidation.maximumQuantity,
                                    //     error: true, // Reset error to false
                                    //     skipped: true, // Reset skipped to false
                                    //     message: t.common.valid_digits,
                                    //   },
                                    //   minimumQuantity: {
                                    //     ...prevValidation.minimumQuantity,
                                    //     error: false, // Reset error to false
                                    //     skipped: false, // Reset skipped to false
                                    //   },
                                    // }));
                                  } else {
                                    setValidation((prevValidation) => ({
                                      ...prevValidation,
                                      quantityPerStore: prevValidation.quantityPerStore.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              minimumQuantity: {
                                                ...item.minimumQuantity,
                                                error: false, // Reset error to false
                                                skipped: false, // Reset skipped to false
                                              },
                                              maximumQuantity: {
                                                ...item.maximumQuantity,
                                                error: false, // Reset error to false
                                                skipped: false, // Reset skipped to false
                                              },
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                    }));
                                    // setValidation((prevValidation) => ({
                                    //   ...prevValidation,
                                    //   minimumQuantity: {
                                    //     ...prevValidation.minimumQuantity,
                                    //     error: false, // Reset error to false
                                    //     skipped: false, // Reset skipped to false
                                    //   },
                                    //   maximumQuantity: {
                                    //     ...prevValidation.maximumQuantity,
                                    //     error: false, // Reset error to false
                                    //     skipped: false, // Reset skipped to false
                                    //   },
                                    // }));
                                  }
                                }
                              } else {
                                if (isMaxQtyValid) {
                                  setValidation((prevValidation) => ({
                                    ...prevValidation,
                                    quantityPerStore: prevValidation.quantityPerStore.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            maximumQuantity: {
                                              ...item.maximumQuantity,
                                              error: true, // Reset error to false
                                              skipped: true, // Reset skipped to false
                                              message: t.common.valid_digits,
                                            },
                                            minimumQuantity: {
                                              ...item.minimumQuantity,
                                              error: false, // Reset error to false
                                              skipped: false, // Reset skipped to false
                                            },
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  }));
                                  // setValidation((prevValidation) => ({
                                  //   ...prevValidation,
                                  //   maximumQuantity: {
                                  //     ...prevValidation.maximumQuantity,
                                  //     error: true, // Reset error to false
                                  //     skipped: true, // Reset skipped to false
                                  //     message: t.common.valid_digits,
                                  //   },
                                  //   minimumQuantity: {
                                  //     ...prevValidation.minimumQuantity,
                                  //     error: false, // Reset error to false
                                  //     skipped: false, // Reset skipped to false
                                  //   },
                                  // }));
                                } else {
                                  setValidation((prevValidation) => ({
                                    ...prevValidation,
                                    quantityPerStore: prevValidation.quantityPerStore.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            minimumQuantity: {
                                              ...item.minimumQuantity,
                                              error: false, // Reset error to false
                                              skipped: false, // Reset skipped to false
                                            },
                                            maximumQuantity: {
                                              ...item.maximumQuantity,
                                              error: false, // Reset error to false
                                              skipped: false, // Reset skipped to false
                                            },
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  }));

                                  // setValidation((prevValidation) => ({
                                  //   ...prevValidation,
                                  //   minimumQuantity: {
                                  //     ...prevValidation.minimumQuantity,
                                  //     error: false, // Reset error to false
                                  //     skipped: false, // Reset skipped to false
                                  //   },
                                  //   maximumQuantity: {
                                  //     ...prevValidation.maximumQuantity,
                                  //     error: false, // Reset error to false
                                  //     skipped: false, // Reset skipped to false
                                  //   },
                                  // }));
                                }
                                let areaDataFinal = [...data.quantityPerStore];
                                areaDataFinal[index].minimumQuantity = null;
                                setData({
                                  ...data,
                                  quantityPerStore: areaDataFinal,
                                });
                              }
                            }}
                          ></Input>
                          {validation.quantityPerStore[index].minimumQuantity
                            .error &&
                          validation.quantityPerStore[index].minimumQuantity
                            .skipped ? (
                            <div className="label-error">
                              {
                                validation.quantityPerStore[index]
                                  .minimumQuantity.message
                              }
                            </div>
                          ) : (
                            ""
                          )}
                        </Form.Field>
                        <Form.Field style={{ minHeight: "58px" }}>
                          <label className="label-item mt-5">
                            {t.parts.form.miximum_qty}{" "}
                            <span
                              className="c-pointer"
                              style={{ position: "relative" }}
                              onMouseEnter={() =>
                                setMaxQtyTooltip({
                                  ...showQtyTooltip,
                                  [index]: true,
                                })
                              }
                              onMouseLeave={() =>
                                setMaxQtyTooltip({
                                  ...showQtyTooltip,
                                  [index]: false,
                                })
                              }
                              // onMouseEnter={() => setMaxQtyTooltip(true)}
                              // onMouseLeave={() => setMaxQtyTooltip(false)}
                            >
                              {showMaxQtyTooltip[index] ? (
                                <>
                                  <InfoIconBlue />
                                  <ToolTip
                                    color="#fff"
                                    backgroundColor="#172B4D"
                                    position="right top"
                                    show={showMaxQtyTooltip[index]}
                                    textBoxWidth="105px"
                                    arrowAlign="center"
                                    fontSize="9px"
                                    fontWeight="400"
                                    fontFamily={
                                      selectedLanguage == "ar"
                                        ? "Noto Kufi Arabic"
                                        : "Roboto"
                                    }
                                    padding="4px 14px 4px 7px"
                                    lineHeight="16px"
                                    borderRadius="6px"
                                    static
                                    moveDown="0px"
                                  >
                                    <span className="work-order-preview-tooltip parts">
                                      {t.parts.form.max_qty_tooltip}
                                    </span>
                                  </ToolTip>
                                </>
                              ) : (
                                <InfoIcon />
                              )}
                            </span>
                          </label>
                          <div className="">
                            <Input
                              type="number"
                              min={1}
                              onKeyDown={(evt) => handleKeyDown(evt)}
                              autoComplete="new-password"
                              fluid
                              error={
                                validation.quantityPerStore[index]
                                  .maximumQuantity.error &&
                                validation.quantityPerStore[index]
                                  .maximumQuantity.skipped
                              }
                              // error={
                              //   validation.maximumQuantity.error &&
                              //   validation.maximumQuantity.skipped
                              // }
                              placeholder={t.parts.form.enter_miximum_qty}
                              value={
                                one?.maximumQuantity ? one?.maximumQuantity : ""
                              }
                              onChange={(e) => {
                                let valueData = Number(e.target.value);
                                const isMaxQtyValid =
                                  one?.minimumQuantity !== null &&
                                  !/^(\d{1,8})(\.\d{1,4})?$/.test(
                                    one?.minimumQuantity
                                  );
                                if (valueData) {
                                  let areaData = [...data.quantityPerStore];

                                  areaData[index].maximumQuantity = valueData;
                                  // Update the minimum quantity in the form data
                                  setData({
                                    ...data,
                                    quantityPerStore: areaData,
                                  });

                                  if (
                                    !/^(\d{1,8})(\.\d{1,4})?$/.test(valueData)
                                  ) {
                                    if (isMaxQtyValid) {
                                      setValidation((prevValidation) => ({
                                        ...prevValidation,
                                        quantityPerStore: prevValidation.quantityPerStore.map(
                                          (item, i) => {
                                            if (i === index) {
                                              return {
                                                ...item,
                                                minimumQuantity: {
                                                  ...item.minimumQuantity,
                                                  error: true, // Reset error to false
                                                  skipped: true,
                                                  message:
                                                    t.common.valid_digits, // Reset skipped to false
                                                },
                                                maximumQuantity: {
                                                  ...item.maximumQuantity,
                                                  error: true, // Reset error to false
                                                  skipped: true, // Reset skipped to false
                                                  message:
                                                    t.common.valid_digits,
                                                },
                                              };
                                            }
                                            return item;
                                          }
                                        ),
                                      }));
                                      // setValidation((prevValidation) => ({
                                      //   ...prevValidation,
                                      //   minimumQuantity: {
                                      //     ...prevValidation.minimumQuantity,
                                      //     error: true, // Reset error to false
                                      //     skipped: true,
                                      //     message: t.common.valid_digits, // Reset skipped to false
                                      //   },
                                      //   maximumQuantity: {
                                      //     ...prevValidation.maximumQuantity,
                                      //     error: true, // Reset error to false
                                      //     skipped: true, // Reset skipped to false
                                      //     message: t.common.valid_digits,
                                      //   },
                                      // }));
                                    } else {
                                      setValidation((prevValidation) => ({
                                        ...prevValidation,
                                        quantityPerStore: prevValidation.quantityPerStore.map(
                                          (item, i) => {
                                            if (i === index) {
                                              return {
                                                ...item,
                                                maximumQuantity: {
                                                  ...item.maximumQuantity,
                                                  error: true, // Reset error to false
                                                  skipped: true,
                                                  message:
                                                    t.common.valid_digits, // Reset skipped to false
                                                },
                                              };
                                            }
                                            return item;
                                          }
                                        ),
                                      }));
                                      // setValidation((prevValidation) => ({
                                      //   ...prevValidation,
                                      //   maximumQuantity: {
                                      //     ...prevValidation.maximumQuantity,
                                      //     error: true, // Reset error to false
                                      //     skipped: true,
                                      //     message: t.common.valid_digits, // Reset skipped to false
                                      //   },

                                      // }));
                                    }
                                  } else if (
                                    one?.minimumQuantity !== null &&
                                    valueData <= one?.minimumQuantity
                                  ) {
                                    setValidation((prevValidation) => ({
                                      ...prevValidation,
                                      quantityPerStore: prevValidation.quantityPerStore.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              maximumQuantity: {
                                                ...item.maximumQuantity,
                                                error: true, // Set error to true
                                                skipped: true,
                                                message: t.parts.maxQty_valid, // Set skipped to true
                                              },
                                              minimumQuantity: {
                                                ...item.minimumQuantity,
                                                error: false, // Set error to true
                                                skipped: false, // Set skipped to true
                                              },
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                    }));

                                    // setValidation((prevValidation) => ({
                                    //   ...prevValidation,
                                    //   maximumQuantity: {
                                    //     ...prevValidation.maximumQuantity,
                                    //     error: true, // Set error to true
                                    //     skipped: true,
                                    //     message: t.parts.maxQty_valid, // Set skipped to true
                                    //   },
                                    //   minimumQuantity: {
                                    //     ...prevValidation.minimumQuantity,
                                    //     error: false, // Set error to true
                                    //     skipped: false, // Set skipped to true
                                    //   },
                                    // }));
                                  } else {
                                    if (isMaxQtyValid) {
                                      setValidation((prevValidation) => ({
                                        ...prevValidation,
                                        quantityPerStore: prevValidation.quantityPerStore.map(
                                          (item, i) => {
                                            if (i === index) {
                                              return {
                                                ...item,
                                                minimumQuantity: {
                                                  ...item.minimumQuantity,
                                                  error: true, // Reset error to false
                                                  skipped: true, // Reset skipped to false
                                                  message:
                                                    t.common.valid_digits,
                                                },
                                                maximumQuantity: {
                                                  ...item.maximumQuantity,
                                                  error: false, // Reset error to false
                                                  skipped: false, // Reset skipped to false
                                                },
                                              };
                                            }
                                            return item;
                                          }
                                        ),
                                      }));
                                      // setValidation((prevValidation) => ({
                                      //   ...prevValidation,
                                      //   minimumQuantity: {
                                      //     ...prevValidation.minimumQuantity,
                                      //     error: true, // Reset error to false
                                      //     skipped: true, // Reset skipped to false
                                      //     message: t.common.valid_digits,
                                      //   },
                                      //   maximumQuantity: {
                                      //     ...prevValidation.maximumQuantity,
                                      //     error: false, // Reset error to false
                                      //     skipped: false, // Reset skipped to false
                                      //   },
                                      // }));
                                    } else {
                                      setValidation((prevValidation) => ({
                                        ...prevValidation,
                                        quantityPerStore: prevValidation.quantityPerStore.map(
                                          (item, i) => {
                                            if (i === index) {
                                              return {
                                                ...item,
                                                minimumQuantity: {
                                                  ...item.minimumQuantity,
                                                  error: false, // Reset error to false
                                                  skipped: false, // Reset skipped to false
                                                },
                                                maximumQuantity: {
                                                  ...item.maximumQuantity,
                                                  error: false, // Reset error to false
                                                  skipped: false, // Reset skipped to false
                                                },
                                              };
                                            }
                                            return item;
                                          }
                                        ),
                                      }));
                                      // setValidation((prevValidation) => ({
                                      //   ...prevValidation,
                                      //   minimumQuantity: {
                                      //     ...prevValidation.minimumQuantity,
                                      //     error: false, // Reset error to false
                                      //     skipped: false, // Reset skipped to false
                                      //   },
                                      //   maximumQuantity: {
                                      //     ...prevValidation.maximumQuantity,
                                      //     error: false, // Reset error to false
                                      //     skipped: false, // Reset skipped to false
                                      //   },
                                      // }));
                                    }
                                  }
                                } else {
                                  if (isMaxQtyValid) {
                                    setValidation((prevValidation) => ({
                                      ...prevValidation,
                                      quantityPerStore: prevValidation.quantityPerStore.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              minimumQuantity: {
                                                ...item.minimumQuantity,
                                                error: true, // Reset error to false
                                                skipped: true, // Reset skipped to false
                                                message: t.common.valid_digits,
                                              },
                                              maximumQuantity: {
                                                ...item.maximumQuantity,
                                                error: false, // Reset error to false
                                                skipped: false, // Reset skipped to false
                                              },
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                    }));

                                    // setValidation((prevValidation) => ({
                                    //   ...prevValidation,
                                    //   minimumQuantity: {
                                    //     ...prevValidation.minimumQuantity,
                                    //     error: true, // Reset error to false
                                    //     skipped: true, // Reset skipped to false
                                    //     message: t.common.valid_digits,
                                    //   },
                                    //   maximumQuantity: {
                                    //     ...prevValidation.maximumQuantity,
                                    //     error: false, // Reset error to false
                                    //     skipped: false, // Reset skipped to false
                                    //   },
                                    // }));
                                  } else {
                                    setValidation((prevValidation) => ({
                                      ...prevValidation,
                                      quantityPerStore: prevValidation.quantityPerStore.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              minimumQuantity: {
                                                ...item.minimumQuantity,
                                                error: false, // Reset error to false
                                                skipped: false, // Reset skipped to false
                                              },
                                              maximumQuantity: {
                                                ...item.maximumQuantity,
                                                error: false, // Reset error to false
                                                skipped: false, // Reset skipped to false
                                              },
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                    }));

                                    // setValidation((prevValidation) => ({
                                    //   ...prevValidation,
                                    //   minimumQuantity: {
                                    //     ...prevValidation.minimumQuantity,
                                    //     error: false, // Reset error to false
                                    //     skipped: false, // Reset skipped to false
                                    //   },
                                    //   maximumQuantity: {
                                    //     ...prevValidation.maximumQuantity,
                                    //     error: false, // Reset error to false
                                    //     skipped: false, // Reset skipped to false
                                    //   },
                                    // }));
                                  }
                                  let areaDataFinal = [
                                    ...data.quantityPerStore,
                                  ];
                                  areaDataFinal[index].maximumQuantity = null;

                                  setData({
                                    ...data,
                                    quantityPerStore: areaDataFinal,
                                  });
                                }
                              }}
                            >
                              <input />
                            </Input>
                          </div>
                          {validation.quantityPerStore[index].maximumQuantity
                            .error &&
                          validation.quantityPerStore[index].maximumQuantity
                            .skipped ? (
                            <div className="label-error">
                              {
                                validation.quantityPerStore[index]
                                  .maximumQuantity.message
                              }
                            </div>
                          ) : (
                            ""
                          )}
                          {/* {validation.maximumQuantity.error &&
              validation.maximumQuantity.skipped ? (
                <div className="label-error">
                  {validation.maximumQuantity.message}
                </div>
              ) : (
                ""
              )} */}
                        </Form.Field>
                        <Form.Field style={{ minHeight: "58px" }}>
                          <label className="label-item mt-5">
                            {t.parts.form.unit_cost}
                            {/* <Asterisk color="#172B4D" /> */}
                          </label>
                          <Input
                            autoComplete="new-password"
                            type="number"
                            min="0"
                            // disabled={one?.ispre}
                            fluid
                            placeholder={t.parts.form.enter_unit_cost}
                            value={one.unitCost}
                            // onChange={onValuesChange("unitCost")}
                            onChange={(e) => {
                              const value = Number(e.target.value);
                              // const value = Number(e.target.value).toFixed(4);
                              let areaData = [...data.quantityPerStore];
                              if (value) {
                                areaData[index].unitCost = Number(
                                  value.toFixed(4)
                                );

                                // if (areaData[index].unitCost) {
                                //   areaData[index].totalCost =
                                //     Number(value) *
                                //     Number(areaData[index].unitCost);
                                // }
                                setData({
                                  ...data,
                                  quantityPerStore: areaData,
                                });
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          unitCost: {
                                            ...item.unitCost,
                                            error: false,
                                            skipped: true,
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));
                              } else if (!value) {
                                if (
                                  e.target.value == 0 &&
                                  e.target.value !== ""
                                ) {
                                  areaData[index].unitCost = 0;
                                } else {
                                  areaData[index].unitCost = null;
                                }
                                setData({
                                  ...data,
                                  quantityPerStore: areaData,
                                });

                                if (value === null) {
                                  setValidation((prevValidation) => ({
                                    ...prevValidation,
                                    quantityPerStore: prevValidation.quantityPerStore.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            unitCost: {
                                              ...item.unitCost,
                                              error: false,
                                              skipped: true,
                                              message: t.common.required_field,
                                            },
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  }));
                                } else {
                                  setValidation((prevValidation) => ({
                                    ...prevValidation,
                                    quantityPerStore: prevValidation.quantityPerStore.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            unitCost: {
                                              ...item.unitCost,
                                              error: false,
                                              skipped: false,
                                            },
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  }));
                                }
                              }
                            }}
                            onKeyDown={(evt) => handleKeyDown(evt)}
                            error={
                              validation.quantityPerStore[index].unitCost
                                .error &&
                              validation.quantityPerStore[index].unitCost
                                .skipped
                            }
                            // error={validation.unitCost.error && validation.unitCost.skipped}
                            onBlur={(e) => {
                              const value = e.target.value;

                              if (
                                validation.quantityPerStore[
                                  index
                                ].unitCost.pattern(value)
                              ) {
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          unitCost: {
                                            ...item.unitCost,
                                            error: false,
                                            skipped: true,
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));
                              } else {
                                let errorMessage;
                                if (!value) {
                                  errorMessage = t.common.required_field;
                                } else {
                                  errorMessage = t.common.valid_digits;
                                }
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          unitCost: {
                                            ...item.unitCost,
                                            error: true,
                                            skipped: true,
                                            message: errorMessage,
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));
                              }
                            }}
                            // onBlur={(e) => {
                            //   const unitCostValidation = validation.unitCost.pattern(
                            //     data.unitCost
                            //   );
                            //   if (unitCostValidation?.isValid) {
                            //     setValidation({
                            //       ...validation,
                            //       unitCost: {
                            //         ...validation.unitCost,
                            //         error: false,
                            //         skipped: true,
                            //         message: "",
                            //       },
                            //     });
                            //   } else {
                            //     setValidation({
                            //       ...validation,
                            //       unitCost: {
                            //         ...validation.unitCost,
                            //         error: true,
                            //         skipped: true,
                            //         message: unitCostValidation.message,
                            //       },
                            //     });
                            //   }
                            // }}
                          >
                            <input />
                            <span
                              className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                                selectedLanguage == "en" ? "ml-5" : "mr-5"
                              }`}
                              style={{
                                backgroundColor: "#E9F2FF",
                                color: "#0C66E4",
                              }}
                            >
                              {organisationCurrency}
                            </span>
                          </Input>
                          {validation.quantityPerStore[index].unitCost.error &&
                          validation.quantityPerStore[index].unitCost
                            .skipped ? (
                            <div className="label-error">
                              {
                                validation.quantityPerStore[index].unitCost
                                  .message
                              }
                            </div>
                          ) : (
                            ""
                          )}
                        </Form.Field>
                      </Segment>
                    </div>
                  </>
                );
              })}

              <div
                className={`buttun-type-link mt-5${
                  data.quantityPerStore.length == 1 ? "" : " "
                }`}
                basic
                onClick={addNewStore}
              >
                <span className="customer-add-btn">
                  {" "}
                  <CustomerPlusIcon />{" "}
                </span>

                <p className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                  {t.parts.form.add_inv_loc}
                </p>
              </div>

              <Form.Field>
                <label className="label-item mt-6">
                  {t.workRequests.form.assets}
                  {/* <Icon name="asterisk" color="red" size="small" /> */}
                </label>{" "}
                {/* {validation.vendor.error && validation.vendor.skipped ? (
                  <div className="label-error">{validation.vendor.message}</div>
                ) : (
                  ''
                )} */}
                <Dropdown
                  placeholder={t.workRequests.form.add_assets}
                  noResultsMessage={t.common.no_results_found}
                  fluid
                  search
                  selection
                  // disabled={
                  //   !data.quantityPerStore?.some(
                  //     (store) => store.location !== ""
                  //   ) && !(role === "super_admin" || role === "admin")
                  // }
                  className="people-dropdown"
                  multiple
                  options={options.assets}
                  value={data.asset}
                  onChange={(e, value) => {
                    // Find the currently selected options
                    const selectedOptions = options.assets.filter((option) =>
                      value?.value.includes(option.value)
                    );

                    let newSelectedValues = [...value?.value];

                    selectedOptions.forEach((option) => {
                      // When selecting a parent asset
                      if (
                        !option?.disabled &&
                        option.className === "main-location-dropdown-option"
                      ) {
                        // Find child assets of this parent
                        const childOptions = options.assets.filter(
                          (subOption) =>
                            subOption.parentId === option.key &&
                            !subOption.disabled
                        );

                        // Add child assets to the selected values if they are not disabled
                        newSelectedValues = [
                          ...newSelectedValues,
                          ...childOptions.map((child) => child.value),
                        ];

                        // For each child, find its grandchildren (if the child is selected)
                        childOptions.forEach((child) => {
                          const grandChildren = options.assets.filter(
                            (grandChild) =>
                              grandChild.childParentId === child.key &&
                              !grandChild.disabled
                          );

                          // Add grandchildren to the selected values if they are not disabled
                          newSelectedValues = [
                            ...newSelectedValues,
                            ...grandChildren.map(
                              (grandChild) => grandChild.value
                            ),
                          ];
                        });
                      }

                      // When selecting a child asset
                      if (
                        !option?.disabled &&
                        option.className === "sub-location-dropdown-option"
                      ) {
                        // Find grandchild assets of this child
                        const grandChildren = options.assets.filter(
                          (grandChild) =>
                            grandChild.childParentId === option.key &&
                            !grandChild.disabled
                        );

                        // Add grandchildren to the selected values if they are not disabled
                        newSelectedValues = [
                          ...newSelectedValues,
                          ...grandChildren.map(
                            (grandChild) => grandChild.value
                          ),
                        ];
                      }
                    });

                    // Update state with new selected values
                    setData({
                      ...data,
                      asset: [...new Set(newSelectedValues)], // Ensure unique values only
                    });
                  }}
                  onOpen={() => fetchAssets()}
                  // error={validation.asset.error && validation.asset.skipped}
                  // onBlur={(e) => {
                  //   if (validation.asset.pattern(data.asset)) {
                  //     setValidation({
                  //       ...validation,
                  //       asset: {
                  //         ...validation.asset,
                  //         error: false,
                  //         skipped: true,
                  //       },
                  //     });
                  //   } else {
                  //     setValidation({
                  //       ...validation,
                  //       asset: {
                  //         ...validation.asset,
                  //         error: true,
                  //         skipped: true,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {/* {validation.asset.error && validation.asset.skipped ? (
                  <div className="label-error">{validation.asset.message}</div>
                ) : (
                  ''
                )} */}
              </Form.Field>
              <Form.Field>
                <label className="label-item">
                  {t.parts.form.vendors}
                  {/* <Icon name="asterisk" color="red" size="small" /> */}
                </label>
                <Dropdown
                  placeholder={t.parts.form.select_vendor}
                  noResultsMessage={t.common.no_results_found}
                  fluid
                  search
                  multiple
                  selection
                  options={options.vendors}
                  // disabled={
                  //   !data.quantityPerStore?.some(
                  //     (store) => store.location !== ""
                  //   ) && !(role === "super_admin" || role === "admin")
                  // }
                  value={data.vendor}
                  onChange={onValuesChange("vendor")}
                  onOpen={() => fetchVendorsData()}
                  // error={validation.vendor.error && validation.vendor.skipped}
                  // onBlur={(e) => {
                  //   if (validation.vendor.pattern(data.vendor)) {
                  //     setValidation({
                  //       ...validation,
                  //       vendor: {
                  //         ...validation.vendor,
                  //         error: false,
                  //         skipped: true,
                  //       },
                  //     });
                  //   } else {
                  //     setValidation({
                  //       ...validation,
                  //       vendor: {
                  //         ...validation.vendor,
                  //         error: true,
                  //         skipped: true,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {/* {validation.vendor.error && validation.vendor.skipped ? (
                  <div className="label-error">{validation.vendor.message}</div>
                ) : (
                  ''
                )} */}
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  {t.workRequests.form.people}{" "}
                  <span
                    className="c-pointer"
                    style={{ position: "relative" }}
                    onMouseOver={() => setPeopleTooltip(true)}
                    onMouseLeave={() => setPeopleTooltip(false)}
                  >
                    {showPeopleTooltip ? (
                      <>
                        <InfoIconBlue />
                        <ToolTip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position={
                            selectedLanguage == "ar" ? "left top" : "right top"
                          }
                          show={showPeopleTooltip}
                          textBoxWidth="97px"
                          arrowAlign="center"
                          fontSize="9px"
                          fontWeight="400"
                          fontFamily={
                            selectedLanguage == "ar"
                              ? "Noto Kufi Arabic"
                              : "Roboto"
                          }
                          padding="4px 14px 4px 7px"
                          lineHeight="16px"
                          borderRadius="6px"
                          static
                          moveDown="0px"
                        >
                          <span className="work-order-preview-tooltip parts">
                            {t.parts.form.people_tooltip}
                          </span>
                        </ToolTip>
                      </>
                    ) : (
                      <InfoIcon />
                    )}
                  </span>
                </label>
                <Dropdown
                  placeholder={t.workRequests.form.add_people}
                  // disabled={!data?.locations.length}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  search
                  multiple
                  options={options.people}
                  value={data.people}
                  onChange={onValuesChange("people")}
                  // disabled={
                  //   !data.quantityPerStore?.some(
                  //     (store) => store.location !== ""
                  //   ) && !(role === "super_admin" || role === "admin")
                  // }
                  // error={validation.people.error && validation.people.skipped}
                  onBlur={(e) => {
                    if (validation.people.pattern(data.people)) {
                      setValidation({
                        ...validation,
                        people: {
                          ...validation.people,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      if (validation.teams.pattern(data.teams)) {
                        setValidation({
                          ...validation,
                          people: {
                            ...validation.people,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          people: {
                            ...validation.people,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }
                  }}
                  onOpen={fetchPeople}
                />
                {/* {validation.people.error && validation.people.skipped ? (
                <div className="label-error" style={{ marginTop: '-15px' }}>
                  {validation.people.message}
                </div>
              ) : (
                ''
              )} */}
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  {t.workRequests.form.teams}{" "}
                  <span
                    className="c-pointer"
                    style={{ position: "relative" }}
                    onMouseOver={() => setTeamsTooltip(true)}
                    onMouseLeave={() => setTeamsTooltip(false)}
                  >
                    {showTeamsTooltip ? (
                      <>
                        <InfoIconBlue />
                        <ToolTip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position={
                            selectedLanguage == "ar" ? "left top" : "right top"
                          }
                          show={showTeamsTooltip}
                          textBoxWidth="91px"
                          arrowAlign="center"
                          fontSize="9px"
                          fontWeight="400"
                          fontFamily={
                            selectedLanguage == "ar"
                              ? "Noto Kufi Arabic"
                              : "Roboto"
                          }
                          padding="4px 14px 4px 7px"
                          lineHeight="16px"
                          borderRadius="6px"
                          static
                          moveDown="0px"
                        >
                          <span className="work-order-preview-tooltip parts">
                            {t.parts.form.teams_tooltip}
                          </span>
                        </ToolTip>
                      </>
                    ) : (
                      <InfoIcon />
                    )}
                  </span>
                </label>
                <Dropdown
                  placeholder={t.workRequests.form.add_teams}
                  fluid
                  // disabled={!data?.locations.length}
                  noResultsMessage={t.common.no_results_found}
                  selection
                  search
                  // disabled={
                  //   !data.quantityPerStore?.some(
                  //     (store) => store.location !== ""
                  //   ) && !(role === "super_admin" || role === "admin")
                  // }
                  options={options.teams}
                  clearable
                  value={data.teams}
                  onChange={onValuesChange("teams")}
                  // error={validation.teams.error && validation.teams.skipped}
                  onBlur={(e) => {
                    if (validation.teams.pattern(data.teams)) {
                      setValidation({
                        ...validation,
                        teams: {
                          ...validation.teams,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      if (validation.people.pattern(data.people)) {
                        setValidation({
                          ...validation,
                          teams: {
                            ...validation.teams,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          teams: {
                            ...validation.teams,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }
                  }}
                  onOpen={fetchTeams}
                />
                {/* {validation.teams.error && validation.teams.skipped ? (
                <div className="label-error">{validation.teams.message}</div>
              ) : (
                ''
              )} */}
              </Form.Field>

              <Form.Field autoComplete="off">
                <label className="label-item">
                  {t.parts.form.serial_number}
                  <Asterisk color="#172B4D" />
                </label>

                <div className="flexbox">
                  <Input
                    style={{ flex: 1 }}
                    autoComplete="new-password"
                    fluid
                    type="text"
                    maxLength={13}
                    placeholder={t.assets.form.enter_serial_number}
                    value={data.serialNumber}
                    onKeyDown={(evt) => handleKeyDown(evt)}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only digits and limit to 13 characters
                      if (/^[a-zA-Z0-9.]{0,13}$/.test(value)) {
                        onValuesChange("serialNumber")(e);
                      }
                    }}
                    error={
                      validation.serialNumber.error &&
                      validation.serialNumber.skipped
                    }
                    onBlur={(e) => {
                      if (validation.serialNumber.pattern(data.serialNumber)) {
                        setValidation({
                          ...validation,
                          serialNumber: {
                            ...validation.serialNumber,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          serialNumber: {
                            ...validation.serialNumber,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />

                  <span
                    className="ml-5 mr-2 p-1 d-flex align-items-center justify-content-center c-pointer"
                    style={{ backgroundColor: "#E9F2FF" }}
                    onClick={() => {
                      let generatedSerialNumber = generateUniqueInteger();
                      setData({
                        ...data,
                        serialNumber: generatedSerialNumber,
                        // Math.floor(Math.random() * 9000000000) + 1000000000,
                      });
                      if (
                        validation.serialNumber.pattern(generatedSerialNumber)
                      ) {
                        setValidation({
                          ...validation,
                          serialNumber: {
                            ...validation.serialNumber,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          serialNumber: {
                            ...validation.serialNumber,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  >
                    <Repeat />
                  </span>
                </div>
                {validation.serialNumber.error &&
                validation.serialNumber.skipped ? (
                  <div className="label-error">
                    {validation.serialNumber.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field>

              <label className="label-item">
                {t.parts.form.add_QR}
                <Asterisk color="#172B4D" />
              </label>

              <div className="flexbox barcode-btns">
                <button
                  active={activeBtn == 1}
                  onClick={() => setActiveBtn(1)}
                  className={`qr-button ${activeBtn == 1 ? "active" : ""}`}
                >
                  <span>
                    <BarcodeIcon />
                  </span>{" "}
                  {t.parts.form.barcode}
                </button>

                <button
                  className={`qr-button ${activeBtn == 2 ? "active" : ""}`}
                  active={activeBtn == 2}
                  onClick={() => setActiveBtn(2)}
                >
                  <span className="mr-1">
                    <QRcodeIconInactive />
                  </span>
                  {t.parts.form.qrcode}
                </button>
              </div>

              {activeBtn == 2 ? (
                <div className="mt-6">
                  <QRComponentToPrint titleData={"ID"} ref={qrRef} />
                </div>
              ) : null}

              {activeBtn == 1 ? (
                <div className="mt-3">
                  <QRComponentToPrint
                    titleData={"ID"}
                    isQRcode={false}
                    ref={qrRef}
                  />
                </div>
              ) : null}

              <Form.Field className="mt-8">
                <FileUpload
                  onCertificateFileChange={onCertificateFileChange}
                  name="warrantyFile"
                  attachments={attachments}
                  setAttachments={setAttachments}
                />
              </Form.Field>

              {/* <Grid.Column>
              <Form.Field>
                <label className="label-item">{t.parts.form.area}</label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder={t.parts.form.enter_part_area}
                  value={data.area}
                  onChange={onValuesChange('area')}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  {t.parts.form.min_quantity}
                </label>
                <Input
                  type="text"
                  min={0}
                  fluid
                  placeholder={t.parts.form.enter_min_quantity}
                  value={data.minimumQuantity}
                  onChange={
                    (e) => {
                    
                      if (e.target.value && numberRegex.test(e.target.value)) {
                        setData({
                          ...data,
                          minimumQuantity: e.target.value,
                        });
                      } else if (!e.target.value) {
                        setData({
                          ...data,
                          minimumQuantity: '',
                        });
                      }
                    }

        
                  }
                  error={
                    validation.minimumQuantity.error &&
                    validation.minimumQuantity.skipped
                  }
                  onBlur={(e) => {
                    if (
                      validation.minimumQuantity.pattern(data.minimumQuantity)
                    ) {
                      setValidation({
                        ...validation,
                        minimumQuantity: {
                          ...validation.minimumQuantity,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        minimumQuantity: {
                          ...validation.minimumQuantity,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.minimumQuantity.error &&
                validation.minimumQuantity.skipped ? (
                  <div className="label-error">
                    {validation.minimumQuantity.message}
                  </div>
                ) : (
                  ''
                )}
              </Form.Field>

              <Checkbox
                value={data.isNonStock}
                label={t.parts.form.non_stock}
                onChange={onValuesChange('isNonStock')}
              />
 
            </Grid.Column> */}

              {/* <Grid columns="equal">
            <Grid.Column>
              <Form.Field autoComplete="off">
                <label className="label-item">{t.parts.form.qr_code}</label>

                <div className="flexbox">
                  <Button.Group icon style={{ marginLeft: '5px' }}>
                    <Button
                      icon
                      secondary
                      title="QR-Gen"
                      onClick={() => {
                        setData({
                          ...data,
                          qrcode: data.qrcode ? null : uuidv4(),
                        });
                      }}
                    >
                      <Icon name="qrcode" />
                    </Button>
                  </Button.Group>
                </div>

                {data.qrcode ? (
                  <div style={{ marginTop: '5px' }} className="mb-3">
                    <QRCode
                      id="QRCode"
                      title="Part QR Code"
                      value={data.qrcode}
                      size={100}
                      disabled
                    />
                  </div>
                ) : (
                  ''
                )}
              </Form.Field>
              <Form.Field>
                <label className="label-item">{t.parts.form.cost}</label>
                <Input
                  type="number"
                  autoComplete="new-password"
                  fluid
                  labelPosition="right"
                  placeholder={t.parts.form.enter_part_cost}
                  value={data.cost}
                  onChange={onValuesChange('cost')}
                >
                  <input />
                  <Label style={{ height: '100%' }}>
                    {organisationCurrency}
                  </Label>
                </Input>
              </Form.Field>

              <Form.Field>
                <label className="label-item">{t.parts.form.asset}</label>
                <Dropdown
                  placeholder={t.parts.form.select_asset}
                  noResultsMessage={t.common.no_results_found}
                  fluid
                  search
                  clearable
                  selection
                  options={options.assets}
                  value={data.asset}
                  onChange={onValuesChange('asset')}
                  loading={fetchingAssets}
                  disabled={fetchingAssets}
                
                />
               
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field autoComplete="off">
                <label className="label-item">
                  {' '}
                  {t.parts.form.serial_number}
                </label>

                <div className="flexbox">
                  <Input
                    style={{ flex: 1 }}
                    autoComplete="new-password"
                    fluid
                    placeholder={t.parts.form.enter_serial_number}
                    value={data.serialNumber}
                    onChange={onValuesChange('serialNumber')}
                    error={
                      validation.serialNumber.error &&
                      validation.serialNumber.skipped
                    }
                    onBlur={(e) => {
                      if (validation.serialNumber.pattern(data.serialNumber)) {
                        setValidation({
                          ...validation,
                          serialNumber: {
                            ...validation.serialNumber,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          serialNumber: {
                            ...validation.serialNumber,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />

                  <Button
                    icon
                    primary
                    title="Auto-Gen"
                    onClick={() => {
                      let serialNumberData = generateUniqueInteger();
                      setData({
                        ...data,
                        serialNumber: serialNumberData,
                        
                      });
                      if (validation.serialNumber.pattern(serialNumberData)) {
                        setValidation({
                          ...validation,
                          serialNumber: {
                            ...validation.serialNumber,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          serialNumber: {
                            ...validation.serialNumber,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  >
                    <Icon name="shuffle" />
                  </Button>
                </div>
                {validation.serialNumber.error &&
                validation.serialNumber.skipped ? (
                  <div className="label-error">
                    {validation.serialNumber.message}
                  </div>
                ) : (
                  ''
                )}
              </Form.Field>
              <Form.Field>
                <label className="label-item">{t.parts.form.cost_type}</label>
                <Dropdown
                  placeholder={t.parts.form.select_cost_type}
                  noResultsMessage={t.common.no_results_found}
                  fluid
                  search
                  selection
                  options={options.costPer}
                  value={data.costPer}
                  onChange={onValuesChange('costPer')}
                />
              </Form.Field>
              <Form.Field>
                <label className="label-item"> {t.parts.form.vendor}</label>
                <Dropdown
                  placeholder={t.parts.form.select_vendor}
                  noResultsMessage={t.common.no_results_found}
                  fluid
                  search
                  selection
                  options={options.vendors}
                  value={data.vendor}
                  onChange={onValuesChange('vendor')}
                  loading={fetchingVendors}
                  disabled={fetchingVendors}
                 
                />
               
              </Form.Field>
            </Grid.Column>
          </Grid>
          <Grid columns="equal">
            <Grid.Column>
              <Form.Field>
                <label className="label-item">{t.parts.form.procedure}</label>
                <Dropdown
                  placeholder={t.parts.form.select_procedure}
                  noResultsMessage={t.common.no_results_found}
                  fluid
                  selection
                  search
                  options={options.procedures}
                  value={data.procedure}
                  onChange={onValuesChange('procedure')}
                  onOpen={fetchProceduresOptions}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid> */}

              {/* <Form.Field>
            <label className="label-item">
              {t.parts.form.additional_part_details}
            </label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder={t.parts.form.additional_part_details}
              value={data.additionalInformation}
              onChange={onValuesChange('additionalInformation')}
            />
          </Form.Field> */}

              {/* <Form.Field>
            <label className="label-item">{t.parts.form.image}</label>
            {image ? (
              <List divided relaxed>
                <Popup
                  key={image?.id}
                  content={t.common.remove_file}
                  on="click"
                  pinned
                  open={image?.cancelPopup}
                  trigger={
                    <List.Item
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        let currentFile = { ...image };
                        currentFile.cancelPopup = !currentFile.cancelPopup;
                        setImage(currentFile);
                      }}
                    >
                      <List.Icon
                        name={
                          image?.type.match('image.*')
                            ? 'image'
                            : image?.type.match('pdf')
                            ? 'file pdf'
                            : 'file'
                        }
                        size="large"
                        color="black"
                        verticalAlign="middle"
                      />
                      <List.Content>
                        <List.Header>{image?.name}</List.Header>
                      </List.Content>
                    </List.Item>
                  }
                >
                  <Header as="h4">{t.common.remove_file}</Header>
                  <Button
                    color="black"
                    onClick={(e) => {
                      e.stopPropagation();
                      let currentFile = { ...image };
                      currentFile.cancelPopup = false;
                      setImage(currentFile);
                    }}
                  >
                    {t.common.keep}
                  </Button>
                  <Button
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      setImage(null);
                    }}
                  >
                    {t.common.yes_remove}
                  </Button>
                </Popup>
              </List>
            ) : (
              <div>
                <input
                  ref={inventoryImageRef}
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => onImageChange(e, 'image')}
                />
                <div
                  className="buttun-type-link"
                  onClick={() => inventoryImageRef.current.click()}
                >
                  <Icon name="add" />
                  {t.common.add}
                </div>
                <p
                  style={{
                    fontSize: '12px',
                    marginTop: '5px',
                    color: '#7e7e7e',
                  }}
                >
                  {t.common.allowed_type} jpg, jpeg, png
                </p>
              </div>
            )}
          </Form.Field> */}

              {/* <Form.Field>
            <label className="label-item">{t.parts.form.files}</label>
            {files.length > 0 ? (
              <List divided relaxed>
                {files.map((el, i) => (
                  <Popup
                    key={el.id}
                    content={t.common.remove_file}
                    on="click"
                    pinned
                    open={el.cancelPopup}
                    trigger={
                      <List.Item
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          let newFiles = [...files];
                          let currentFile = { ...files[i] };
                          currentFile.cancelPopup = !currentFile.cancelPopup;
                          newFiles[i] = currentFile;
                          setFiles(newFiles);
                        }}
                      >
                        <List.Icon
                          name={
                            el.type.match('image.*')
                              ? 'image'
                              : el.type.match('pdf')
                              ? 'file pdf'
                              : 'file'
                          }
                          size="large"
                          color="black"
                          verticalAlign="middle"
                        />
                        <List.Content>
                          <List.Header>{el.name}</List.Header>
                        </List.Content>
                      </List.Item>
                    }
                  >
                    <Header as="h4">{t.common.remove_file}</Header>
                    <Button
                      color="black"
                      onClick={(e) => {
                        e.stopPropagation();
                        let newFiles = [...files];
                        let currentFile = { ...files[i] };
                        currentFile.cancelPopup = false;
                        newFiles[i] = currentFile;
                        setFiles(newFiles);
                      }}
                    >
                      {t.common.keep}
                    </Button>
                    <Button
                      color="red"
                      onClick={(e) => {
                        e.stopPropagation();
                        let newFiles = [...files];
                        newFiles.splice(i, 1);
                        setFiles(newFiles);
                      }}
                    >
                      {t.common.yes_remove}
                    </Button>
                  </Popup>
                ))}
              </List>
            ) : (
              ''
            )}
            <div>
              <input
                ref={inventoryFilesRef}
                type="file"
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                text/plain, application/pdf, image/*"
                hidden
                multiple
                onChange={(e) => onFilesChange(e)}
              />
              <div
                className="buttun-type-link"
                onClick={() => inventoryFilesRef.current.click()}
              >
                <Icon name="add" />
                {t.parts.form.add_files}
              </div>
              <p
                style={{
                  fontSize: '12px',
                  marginTop: '5px',
                  color: '#7e7e7e',
                }}
              >
                {t.common.allowed_type} jpg, jpeg, png, pdf, doc, docx, xlsx,
                csv
              </p>
            </div>
          </Form.Field> */}

              {/* <Divider />
          {generatedCustomDataFields()}
          <div className="buttun-type-link" basic onClick={addCustomData}>
            <Icon name="add" />
            {t.parts.form.custom_data}
          </div>
          <Divider /> */}

              <div className="form-button-container mt-10">
                <button
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                  // onClick={createNewInventoryHandler}
                  className="form-action-button"
                >
                  {" "}
                  {t.assets.form.cancel}
                </button>
                <button
                  content={t.parts.form.cancel}
                  className="form-action-button blue"
                  basic
                  onClick={updateInventoryHandler}
                >
                  {" "}
                  {t.common.edit}
                </button>
              </div>
            </div>
          )}
        </Resizable>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

      {/* cancel modal */}

      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        {" "}
        <p style={{ lineHeight: "24px" }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>
      {/* Add new category modal */}
      <AddCategoryModal
        isOpen={openCategoryAddModal}
        setModalOpenState={setOpenCategoryAddModal}
        handleSuccess={(res) => categoryCreateSuccess(res)}
      />
    </>
  );
}

export default Edit;
