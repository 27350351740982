import React, { useEffect, useState, createRef } from 'react';
import {
  Image,
  Table,
  Button,
  Header,
  Pagination,
  Icon,
  Placeholder,
} from 'semantic-ui-react';

import Moment from 'react-moment';

import md5 from 'md5';

import { NavLink } from 'react-router-dom';

import { useLocation } from 'react-router';
import { toAbsoluteUrl, checkIsActive } from '../../../_metronic/_helpers';

import { getAllProcedures, humanize } from '../../config/functions';

import Add from './add';
import EditInspection from './edit';
import Preview from './preview/index';

import Filters from './filters';
import { useSelector } from 'react-redux';

export const GlobalProcedures = () => {
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [fetching, setFetchLoading] = useState(true);

  const [data, setData] = useState([]);
  const [initData, setInitData] = useState([]);

  const [targetedProcedure, setTargetedProcedure] = useState();
  const [refresh, setRefresh] = useState(false);
  const [togglePreview, setTogglePreviw] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };

  const rowStyle = { cursor: 'pointer' };

  useEffect(() => {
    setFetchLoading(true);
    const fetchData = async () => {
      const fetchData = await getAllProcedures(true, organisationId);
      // console.log(fetchData);
      if (fetchData.response) {
        setData(fetchData);
        setInitData(fetchData);
        setFetchLoading(false);
      }
    };

    fetchData();
  }, [refresh]);

  const handelToggleEdit = (targetedProcedure) => {
    setTargetedProcedure(targetedProcedure);
    setTogglePreviw(false);

    setToggleEdit(true);
  };

  const handleSetDataByFilter = (newData) => {
    setData(newData);
  };

  return (
    <div>
      <Header as="h2">Global Procedures</Header>
      <div className="header-actions">
        <Button.Group basic>
          <NavLink to="/procedures">
            <Button
              color={getMenuItemActive('/procedures', false) ? 'teal' : ''}
            >
              My Procedures
            </Button>
          </NavLink>
          <NavLink to="/global-procedures">
            <Button
              color={
                getMenuItemActive('/global-procedures', false) ? 'teal' : ''
              }
              title="Pre Defined Inspection Templates"
            >
              Global Library
            </Button>
          </NavLink>
        </Button.Group>
      </div>
      {fetching ? (
        <div>
          <Placeholder fluid>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </div>
      ) : (
        <div>
          {initData.length > 0 ? (
            <>
              <Filters
                data={initData}
                setDataByFilter={handleSetDataByFilter}
                hasStatus={false}
              />
              <Table selectable>
                <Table.Header>
                  <Table.Row>
                    {/* <Table.HeaderCell>
                      <Checkbox />
                    </Table.HeaderCell> */}
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Fields</Table.HeaderCell>
                    <Table.HeaderCell>Created By</Table.HeaderCell>
                    <Table.HeaderCell>Created At</Table.HeaderCell>
                    <Table.HeaderCell>Updated At</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {data.map((el) => {
                    let createdByUser = el.attributes.createdByUser ? (
                      <div>
                        <Image
                          avatar
                          title={
                            el.attributes.createdByUser.firstName +
                            ' ' +
                            el.attributes.createdByUser.lastName
                          }
                          src={`http://gravatar.com/avatar/${md5(
                            el.attributes.createdByUser.firstName +
                              ' ' +
                              el.attributes.createdByUser.lastName
                          )}?d=identicon`}
                          style={{ width: '16px', height: '16px' }}
                        />
                        <span style={{ marginLeft: '4px' }}>
                          {el.attributes.createdByUser.firstName}
                        </span>
                      </div>
                    ) : (
                      'N/A'
                    );

                    return (
                      <Table.Row
                        style={rowStyle}
                        key={el.id}
                        id={el.id}
                        onClick={() => {
                          setTargetedProcedure(el);
                          setTogglePreviw(true);
                        }}
                      >
                        {/* <Table.Cell collapsing>
                          <Checkbox />
                        </Table.Cell> */}
                        <Table.Cell>{el.id}</Table.Cell>
                        <Table.Cell>{el.attributes.title}</Table.Cell>
                        <Table.Cell title={el.attributes.description}>
                          <span>
                            {el.attributes.description.substring(0, 50)}
                          </span>
                          <span>
                            {el.attributes.description.length > 49 ? '...' : ''}
                          </span>
                        </Table.Cell>
                        <Table.Cell>
                          {el.attributes.fields.data.length + ' fields'}
                        </Table.Cell>
                        <Table.Cell>{createdByUser}</Table.Cell>
                        <Table.Cell>
                          {
                            <Moment
                              format="DD/MM/YYYY LT"
                              date={el.attributes.createdAt}
                            />
                          }
                        </Table.Cell>
                        <Table.Cell>
                          {
                            <Moment
                              format="DD/MM/YYYY LT"
                              date={el.attributes.updatedAt}
                            />
                          }
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>

              <Pagination
                defaultActivePage={1}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                totalPages={10}
              />
            </>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Header as="h2">
                Looks like there are no Global Procedures yet.
              </Header>
            </div>
          )}
        </div>
      )}

      <Add
        toggled={toggleAsideModal}
        untoggle={() => {
          setToggleAsideModal(false);
        }}
        refresh={setRefresh}
        cloneList={data}
      />
      <EditInspection
        procedure={targetedProcedure}
        toggled={toggleEdit}
        untoggle={() => {
          setTargetedProcedure();
          setToggleEdit(false);
        }}
        cloneList={data}
      />

      <Preview
        procedure={targetedProcedure}
        toggled={togglePreview}
        handelToggleEdit={handelToggleEdit}
        untoggle={() => {
          setTargetedProcedure();
          setTogglePreviw(false);
        }}
      />
    </div>
  );
};
