import React, { useEffect, useState, createRef } from "react";
import {
  Segment,
  Card,
  Button,
  Header,
  Icon,
  Placeholder,
  Image,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { useDebounce } from "use-debounce";
import * as auth from "../../modules/Auth/_redux/authRedux";
import Tooltip from "react-power-tooltip";

import md5 from "md5";

import { NavLink } from "react-router-dom";

import { useLocation } from "react-router";
import { toAbsoluteUrl, checkIsActive } from "../../../_metronic/_helpers";

import {
  IMAGES_URL,
  getAllTeams,
  getUserData,
  humanize,
} from "../../config/functions";

import Add from "./add";
import Preview from "./preview/index";
import Edit from "./edit";

import Filters from "./filters";
import { useHistory } from "react-router";
import { connect, useDispatch, useSelector } from "react-redux";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from "../../components/common/userProfilePlaceholder";
import { selectTranslations } from "../../config/i18n/slice";
import useDisplayFormattedText from "../../../hooks/useDisplayFormattedText";
import {
  ArrowLeft,
  ArrowRight,
  CloseIcon,
  CreateIcon,
  MemberIcon,
  PeopleIcon,
  PeopleIconBlue,
  ProcedurePlusIcon,
  SortIcon,
} from "../../../Assets/Icons/svg";
import DataTable from "react-data-table-component";
import Pagination from "../../components/common/CommonPagination";
// import ReactTooltip from 'react-tooltip';
import DateConvert from "../../../utilFunctions/DateFormat";
import useGetCurrentTimeAccZone from "../../../hooks/useGetCurrentTimeAccZone";
import moment from "moment";
import {
  fetchAllTeamsData,
  fetchTeamsInitData,
  fetchTeamsWithFilter,
  fetchTeamsWithSort,
} from "../../../redux/reducers/teamsReducer";
import { Clearfix } from "react-bootstrap";
import { cl } from "@fullcalendar/core/internal-common";
import EmptyFilter from "../People/emptyFilter";
import Animation from "../../components/common/CustomLoader";

const Teams = (props) => {
  const { userPermissions } = usePermissionsSimplified();
  const { currentDate, organisationTimezone } = useGetCurrentTimeAccZone();

  const { DisplayFormattedText } = useDisplayFormattedText();
  const [sortData, setSortData] = useState();
  const [showATooltip, setShowATooltip] = useState({});
  const curentUrl = useLocation();
  const queryParams = new URLSearchParams(curentUrl.search);
  const teamId = queryParams.get("id");
  const history = useHistory();

  const dispatch = useDispatch();

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const [filterOptions, setFilterOptions] = useState({
   
    locations: [],
    customers: [],
    teams: [],
    superior: [],

    priority: [],
    status: [],
  });

  // const role = useSelector((state) => {
  //   if (state?.auth?.user?.role?.id) {
  //     let roleType = state.auth.user.role.type;
  //     let roleId = state.auth.user.role.id;
  //     return { roleId, roleType };
  //   } else {
  //     return null;
  //   }
  // });
  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });

  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const {
    data,
    allTeamsData,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.teams.teamsData,
    allTeamsData: state.teams.allTeamsData,
    isLoading: state.teams.isLoading,
    error: state.teams.error,
    initData: state.teams.teamsInitData,
    page: state.teams.page,
    pageSize: state.teams.pageSize,
    total: state.teams.total,
    initLoading: state.teams.initLoading,
  }));

  const [loadUserData, setLoadUserData] = useState(false);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const customStyles = {
    // rows: {
    //   style: {
    //     height: '72px',
    //     // override the row height
    //   },
    // },
    headCells: {
      style: {
        paddingTop: "11px", // override the cell padding for head cells
        // paddingBottom: '4px',
      },
    },
    cells: {
      style: {
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "16px",
        paddingTop: "19px",
        paddingBottom: "19px",
      },
    },
  };
  const [refresh, setRefresh] = useState(false);
  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [fetching, setFetchLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTitle, setSearchTitle] = useState("");
  const [debounceValue] = useDebounce(searchTitle, 500);
  // const [pageSize, setPageSize] = useState(25);
  // const [data, setData] = useState([]);
  // const [initData, setInitData] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [loader, setloader] = useState(false);

  const [targetedData, setTargetedData] = useState();
  const [togglePreview, setTogglePreviw] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };
  const t = useSelector(selectTranslations);

  const rowStyle = { cursor: "pointer" };

  const userRole = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });

  // const currentUserId = useSelector((state) => {
  //   if (state?.auth?.user?.id) {
  //     return state.auth.user.id;
  //   } else {
  //     return null;
  //   }
  // });

  const currentUserId = [];

  currentUserLocations?.length &&
    currentUserLocations.forEach((location) => {
      if (!currentUserId.some((inv) => inv == location?.id)) {
        currentUserId.push(location?.id);
      }

      location.subLocations.forEach((subLocation) => {
        if (!currentUserId.some((inv) => inv == subLocation?.id)) {
          currentUserId.push(subLocation?.id);
        }
      });
    });

  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoadUserData(true);
      if (!(role === "super_admin")) {
        const userData = await getUserData(user.id);

        dispatch(props.fulfillUser(userData));
      }
      const fetchData = await dispatch(
        fetchTeamsInitData({ organisationId, userRole, currentUserId })
      );
      setLoadUserData(false);
      if (fetchData) {
        if (role.roleType == "requester") {
        } else {
          // setData(fetchData);
          // setInitData(fetchData);
          setFetchLoading(false);
        }

        if (teamId) {
          let fetchAllData = await dispatch(
            fetchAllTeamsData({ organisationId, userRole, currentUserId })
          );
          let selectedProcedure = fetchAllData.payload.response.find(
            (each) => each.attributes?.countId == teamId
          );
          if (selectedProcedure) {
            setTargetedData(selectedProcedure);
            setTogglePreviw(true);
          }
        }
      }
    };

    fetchData();
  }, [teamId]);

  const handleSort = async (column, sortDirection) => {
    setloader(true);

    // Assuming the selector represents the field to sort
    const sort = [`${column.sortField}:${sortDirection}`];
    setSortData(sort);
    const sortedData = await dispatch(
      fetchTeamsWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        customer: filterOptions.customers,
        superior: filterOptions.superior,
        team: filterOptions.teams,
        location: filterOptions.locations,
        sort,
        userRole,
        currentUserId,
      })
    );

    setloader(false);

    // Update state or data variable with sortedData
    // setData(sortedData); // Assuming you have state or variable named 'data'
  };

  const handleRemoveSorting = async () => {
    setloader(true);

    // Assuming the select or represents the field to sort
    const sort = undefined;
    setSortData(sort);
    const sortedData = await dispatch(
      fetchTeamsWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        customer: filterOptions.customers,
        superior: filterOptions.superior,
        team: filterOptions.teams,
        location: filterOptions.locations,
        sort,
        userRole,
        currentUserId,
      })
    );

    setloader(false);
  };

  const columns = [
    {
      width: selectedLanguage === "en" ? "106px" : "123px",
      name: t.assets.table.ID,
      // selector: (row) => row?.id,
      sortable: true,
      sortField: "countNumId",
      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className="id-text-style"
        >
          {row?.attributes?.countId}
        </span>
      ),
    },
    {
      name: t.people.table.name,
      // selector: (row) => row.attributes.name,
      sortable: true,
      sortField: "name",
      minWidth: "283px",
      cell: (row) => {
        if (row?.attributes?.images?.data) {
          // return <span>{role?.text || humanize(row.role.type)}</span>;
          return (
            <div
              onClick={() => {
                setTargetedData(row);
                setTogglePreviw(true);
              }}
              className="d-flex align-items-center"
            >
              <div className="text-center">
                <Image
                  avatar
                  title={row?.attributes?.name}
                  src={`${IMAGES_URL}${row?.attributes?.images?.data.attributes.url}`}
                  style={
                    selectedLanguage === "en"
                      ? { width: "34px", height: "34px", right: "6px" }
                      : { width: "34px", height: "34px", right: "-6px" }
                  }

                  // style={
                  //   index != 0
                  //     ? {
                  //         marginLeft: '-6px',
                  //         width: '34px',
                  //         height: '34px',
                  //       }
                  //     : { width: '34px', height: '34px' }
                  // }
                />
              </div>
              <div
                className="username-container ml-1"
                // onClick={() => history.push(`/people?id=${row?.id}`)}
              >
                <p className="user-text">
                  {DisplayFormattedText(humanize(row?.attributes?.name), 8)}
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <div
              onClick={() => {
                setTargetedData(row);
                setTogglePreviw(true);
              }}
              className="d-flex align-items-center"
            >
              <div className="text-center">
                <UserProfilePlaceholder
                  name={row?.attributes?.name}
                  width={"34px"}
                  height={"34px"}
                  right={selectedLanguage === "en" ? "6px" : "-6px"}
                />
              </div>
              <div
                className="username-container ml-1"
                // onClick={() => history.push(`/people?id=${row?.id}`)}
              >
                <p className="user-text">
                  {DisplayFormattedText(humanize(row?.attributes?.name), 8)}
                </p>
              </div>
            </div>
          );
        }
      },
    },

    {
      name: t.people.table.supervisor,
      // selector: (row) =>
      //   row?.attributes.superior?.data?.attributes?.firstName
      //     ? row?.attributes?.superior?.data?.attributes?.firstName
      //     : '',
      sortable: true,
      minWidth: "150px",
      sortField: "superior.firstName",
      cell: (row) => {
        if (row.attributes.superior?.data) {
          return (
            <>
              {row.attributes.supervisor?.data?.attributes?.profileImage
                ?.url ? (
                <>
                  <div
                    onClick={() => {
                      setTargetedData(row);
                      setTogglePreviw(true);
                    }}
                    className="d-flex "
                  >
                    <Image
                      right={selectedLanguage == "en" ? "6px" : "-6px"}
                      width={"34px"}
                      height={"34px"}
                      title={
                        row?.attributes.superior?.data?.attributes?.firstName +
                        " " +
                        row?.attributes.superior?.data?.attributes?.lastName
                      }
                      src={
                        IMAGES_URL +
                        row.attributes.supervisor?.data?.attributes
                          ?.profileImage?.url
                      }
                      onClick={() =>
                        window.open(
                          IMAGES_URL +
                            row.attributes.supervisor?.data?.attributes
                              ?.profileImage?.url,
                          "_blank"
                        )
                      }
                    />
                  </div>
                </>
              ) : (
                <span
                  onClick={() => {
                    setTargetedData(row);
                    setTogglePreviw(true);
                  }}
                >
                  <UserProfilePlaceholder
                    name={
                      row?.attributes.superior?.data?.attributes?.firstName +
                      " " +
                      row?.attributes.superior?.data?.attributes?.lastName
                    }
                    width={"34px"}
                    height={"34px"}
                    right={selectedLanguage == "en" ? "6px" : "-6px"}
                  />
                </span>
              )}
            </>
          );
        } else {
          return t.common.na;
        }
      },
    },

    {
      name: t.teams.team_info_form.customer,
      // selector: (row) =>
      //   row?.attributes.customers?.data.length
      //     ? row?.attributes.customers.data.length
      //     : 0,
      // sortable: true,
      minWidth: "180px",
      sortField: "customers.name",
      cell: (row) => {
        const locationData = row?.attributes.customers?.data;
        if (locationData?.length > 0) {
          const firstLocation = locationData[0]?.attributes?.name.substring(
            0,
            15
          );
          const remainingCount = locationData.length - 1;
          const remainingLocations = locationData
            .slice(1)
            .map((location) => location.attributes.name)
            .join(", ");
          const displayText =
            remainingCount > 0
              ? `${firstLocation} ... +${remainingCount}`
              : firstLocation;
          return (
            <span
              onClick={() => {
                setTargetedData(row);
                setTogglePreviw(true);
              }}
              title={locationData
                .map((location) => location.attributes.name)
                .join(", ")}
            >
              {displayText}
            </span>
          );
          // const displayText = locationData
          //   .map((location) => {
          //     const nameParts = location?.attributes?.name;

          //     return nameParts;
          //   })
          //   .join(', '); // Adjust the join character as needed
          // return DisplayFormattedText(displayText, 20);
        } else {
          return t.common.na;
        }
      },
    },

    {
      width: "250px",
      name: t.teams.team_info_form.location,
      sortField: "locations.name",
      // selector: (row) => row?.attributes?.locations?.data?.length,
      // sortable: true,
      cell: (row) => {
        const locationData = row?.attributes?.locations?.data;
        if (locationData?.length > 0) {
          const firstLocation = locationData[0]?.attributes?.name;
          const remainingCount = locationData.length - 1;
          const remainingLocations = locationData.slice(1);
          const displayText =
            remainingCount > 0 ? <>{firstLocation} </> : firstLocation;
          return (
            <>
              <span
                onClick={() => {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }}
                className="id-text-style"
              >
                {displayText}
                <span
                  className="id-text-style ml-2 mr-2"
                  style={{
                    width: "fitContent",
                    color: "#0C66E4",
                    position: "relative",
                  }}
                  onMouseEnter={() =>
                    setShowATooltip({ ...showATooltip, [row.id]: true })
                  }
                  onMouseLeave={() =>
                    setShowATooltip({ ...showATooltip, [row.id]: false })
                  }
                >
                  {remainingCount == 0 ? "" : `+${remainingCount}`}

                  {showATooltip[row?.id] &&
                    (remainingCount > 0 ? (
                      <div className="custom-tooltip custom-tooltip-assets">
                        <div className="wrapper-assets-tooltip">
                          {remainingLocations.map((location) => (
                            <p className="mb-1">{location.attributes.name}</p>
                          ))}
                        </div>
                      </div>
                    ) : null)}
                </span>
              </span>
            </>
          );
        } else {
          return t.common.na;
        }
      },
    },

    {
      minWidth: "220px",
      name: t.teams.team_info_form.team_members,
      sortField: "users.firstName",
      // selector: (row) =>
      //   row?.attributes?.users?.data ? row.attributes.users?.data.length : 0,
      // sortable: true,
      cell: (row) => (
        <div
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
        >
          <MemberIcon />
          <span className="ml-2 mr-2">
            {(row.attributes?.users?.data
              ? row.attributes.users?.data.length
              : 0) +
              " " +
              t.teams.team_info_form.members}
          </span>
        </div>
      ),
    },
  ];

  const editModalHandler = () => {
    // console.log('targetedData from edit', targetedData);
    setTargetedData(targetedData);
    setTogglePreviw(false);
    setToggleEditModal(true);
  };

  const handleSetDataByFilter = (newData) => {
    // setData(newData);
  };

  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };

  const totalFilteredPages = Math.ceil(data.length / pageSize);

  return (
    <>
      <div className="new-listing-container table-header-dashboard stick-header pb-5">
        <div className="header-actions">
          <Header className="procedure-title mt-5" as="h2">
            {" "}
            <PeopleIcon />
            {t.aside.peopleTeams}
          </Header>
          <div></div>

          {userPermissions?.team?.create && initData.length > 0 ? (
            //   <button
            //   onClick={() => {
            //     setToggleAsideModal(true);
            //   }}
            //   className={`add-item-btn ${
            //     selectedLanguage == "en" ? "mr-7" : "ml-7"
            //   }`}
            // >
            //   <span>
            //     <CreateIcon />
            //     {t.teams.add_team}
            //   </span>
            // </button>
            <Button
              icon="add"
              primary
              className={`add-item-btn ${
                selectedLanguage == "en" ? "mr-7" : "ml-7"
              }`}
              onClick={() => {
                setToggleAsideModal(true);
              }}
            >
              {t.teams.add_team}
              {/* <ProcedurePlusIcon /> */}
            </Button>
          ) : null}
        </div>
        {initData?.length > 0 ? (
          <div className="new-listing-filter wo-filter sticky-filter mt-8">
            <Filters
              currentUserId={currentUserId}
              userRole={role}
              loader={loader}
              setloader={setloader}
              filterOptions={filterOptions}
              searchTitle={searchTitle}
              setSearchTitle={setSearchTitle}
              debounceValue={debounceValue}
              setFilterOptions={setFilterOptions}
              data={initData}
              fileData={data}
              setDataByFilter={handleSetDataByFilter}
              hasStatus={false}
              allTeamsData={allTeamsData}
              sortData={sortData}
            />
          </div>
        ) : null}
      </div>
      <div className="new-listing-container">
        {/* <Header as="h2">{t.teams.title}</Header>
      <div className="header-actions">
        <Button.Group basic>
          <NavLink to="/people">
            <Button color={getMenuItemActive('/people', false) ? 'teal' : ''}>
              {t.teams.filter.people}
            </Button>
          </NavLink>
          <NavLink to="/teams">
            <Button color={getMenuItemActive('/teams', false) ? 'teal' : ''}>
              {t.teams.title}
            </Button>
          </NavLink>
        </Button.Group>
        {userPermissions?.team?.create ? (
          <Button
            icon="add"
            content={t.teams.add}
            primary
            onClick={() => {
              setToggleAsideModal(true);
            }}
          />
        ) : null}
      </div> */}
        {initLoading || loadUserData ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "500px" }}
          >
            <Animation />
            {/* <Placeholder fluid>
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
         </Placeholder> */}
          </div>
        ) : (
          // <div>
          //   <Placeholder fluid>
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //     <Placeholder.Line />
          //   </Placeholder>
          // </div>
          <div className="new-listing-filter">
            {initData.length > 0 ? (
              <>
                {error ? (
                  <div className="mt-8 mb-8 ">
                    <Animation />
                  </div>
                ) : (
                  <DataTable
                    className="datatable-div"
                    onSort={handleSort}
                    columns={columns}
                    data={data}
                    fixedHeader
                    customStyles={customStyles}
                    noDataComponent={
                      initData.length > 0 ? (
                        <div className="no-data-container">
                          <div className="">
                            <p className="no-data-text">
                              {t.common.no_result_found}
                            </p>
                          </div>
                        </div>
                      ) : null
                    }
                    direction={selectedLanguage === "en" ? "ltr" : "rtl"}
                    onRowClicked={(row) => {
                      setTargetedData(row);
                      setTogglePreviw(true);
                    }}
                    sortIcon={
                      sortData ? (
                        <div>
                          <span>
                            <SortIcon />
                          </span>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveSorting();
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      ) : (
                        <span></span>
                      )
                    }
                    persistTableHead={true}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    progressPending={loader}
                    progressComponent={
                      <div className="mt-8 mb-8 ">{t.common.loading}</div>
                    }
                  />
                )}
                {/* {getPaginatedData(data).map((el) => {
                
                return (
                  <Card
                    fluid
                    link
                    className="card-selectable"
                    onClick={() => {
                      setTargetedData(el);
                      setTogglePreviw(true);
                    }}
                  >
                    <Card.Content>
                      <Card.Header>
                        <Image
                          avatar
                          title={el?.attributes.name}
                          src={LetteredAvatarImageUrl(el?.attributes.name)}
                        />
                        <span style={{ marginLeft: '5px' }}>
                          {el.attributes.name}
                        </span>
                      </Card.Header>
                      <Card.Description>
                        {el.attributes.description}
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <Icon name="user" />
                      {el.attributes.users?.data.length}{' '}
                      {t.teams.members_of_teams}
                    </Card.Content>
                  </Card>
                );
              })} */}

                <div className="d-flex justify-content-end">
                  <Pagination
                    ofString={t.common.of}
                    total={total}
                    prevItem={
                      selectedLanguage === "en" ? <ArrowLeft /> : <ArrowRight />
                    }
                    nextItem={
                      selectedLanguage === "en" ? <ArrowRight /> : <ArrowLeft />
                    }
                    page={page}
                    limit={pageSize}
                    handleNext={async () => {
                      setloader(true);
                      await dispatch(
                        fetchTeamsWithFilter({
                          organisationId,
                          searchName: debounceValue,
                          page: page + 1,
                          pageSize: pageSize,
                          customer: filterOptions.customers,
                          superior: filterOptions.superior,
                          team: filterOptions.teams,
                          location: filterOptions.locations,
                          sort: sortData,
                          userRole,
                          currentUserId,
                        })
                      );
                      setloader(false);
                    }}
                    handlePrevious={async () => {
                      setloader(true);
                      await dispatch(
                        fetchTeamsWithFilter({
                          organisationId,
                          searchName: debounceValue,
                          page: page - 1,
                          pageSize: pageSize,
                          customer: filterOptions.customers,
                          superior: filterOptions.superior,
                          team: filterOptions.teams,
                          location: filterOptions.locations,
                          sort: sortData,
                          userRole,
                          currentUserId,
                        })
                      );
                      setloader(false);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="">
                <EmptyFilter />
                {/* <Filters
                loader={loader}
                setloader={setloader}
                searchTitle={searchTitle}
                debounceValue={debounceValue}
                fileData={data}
                setSearchTitle={setSearchTitle}
                setFilterOptions={setFilterOptions}
                filterOptions={filterOptions}
                data={initData}
                setDataByFilter={handleSetDataByFilter}
                hasStatus={false}
                sortData={sortData}
              /> */}
                <div className="no-data-container">
                  <div className="no-work-orders pb-15">
                    <PeopleIconBlue />
                    <p className="no-data-text">
                      {t.common.there_no}{" "}
                      <span
                        className="link-text"
                        style={{ position: "relative", cursor: "pointer" }}
                        onMouseOver={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                      >
                        {/* <Tooltip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top left"
                        show={showTooltip}
                        textBoxWidth="54px"
                        arrowAlign="start"
                        fontSize="13px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == 'ar'
                            ? 'Noto Kufi Arabic'
                            : 'Roboto'
                        }
                        padding="0px 0px 0px 8px"
                        borderRadius="5px"
                        static
                        moveRight="65px"
                        moveUp="-3px"
                      >
                        <span className="work-order-tooltip">
                          {t.people.how_to_add_teams}
                        </span>
                      </Tooltip> */}

                        <Tooltip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position="top left"
                          show={showTooltip}
                          textBoxWidth={
                            selectedLanguage == "ar" ? "70px" : "58 px"
                          }
                          arrowAlign="start"
                          fontSize="13px"
                          fontWeight="400"
                          fontFamily={
                            selectedLanguage == "ar"
                              ? "Noto Kufi Arabic"
                              : "Roboto"
                          }
                          padding="0px 0px 0px 8px"
                          borderRadius="5px"
                          static
                          moveRight={selectedLanguage == "ar" ? "60px" : "65px"}
                          moveUp={selectedLanguage == "ar" ? "-1px" : "-3px"}
                        >
                          <span className="work-order-tooltip">
                            {t.people.how_to_add_teams}
                          </span>
                        </Tooltip>
                        {" " + t.people.filter.teams + " "}
                      </span>{" "}
                      {t.common.added}
                    </p>
                    {userPermissions?.team?.create ? (
                      <button
                        className="work-order-button-transparent"
                        onClick={() => {
                          setToggleAsideModal(true);
                        }}
                      >
                        {t.people.add_team}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
            {/* )  */}
            {/* : (
            <div style={{ textAlign: 'center' }}>
              <Header as="h2">{t.teams.no_teams_found}</Header>
              <Header as="h3">{t.teams.add_team_message}</Header>
            </div>
          )} */}
          </div>
        )}

        <Add
          allIds={currentUserId}
          userRole={role}
          toggled={toggleAsideModal}
          untoggle={() => {
            setToggleAsideModal(false);
            setModalWidth(Math.max(window.innerWidth / 2, 514));
          }}
          setRefresh={setRefresh}
          refresh={refresh}
          setSearchTitle={setSearchTitle}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          setSortData={setSortData}
        />

        <Edit
          allIds={currentUserId}
          userRole={role}
          toggled={toggleEditModal}
          targetedData={targetedData}
          untoggle={() => {
            setTargetedData();
            setToggleEditModal(false);
            setModalWidth(Math.max(window.innerWidth / 2, 514));
          }}
          setRefresh={setRefresh}
          refresh={refresh}
          setSortData={setSortData}
          setSearchTitle={setSearchTitle}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
        />

        <Preview
          targetedData={targetedData}
          setModalWidth={setModalWidth}
          modalWidth={modalWidth}
          toggled={togglePreview}
          edit={() => editModalHandler()}
          untoggle={() => {
            setTargetedData();
            setTogglePreviw(false);
            setModalWidth(Math.max(window.innerWidth / 2, 514));
            history.push("/teams");
          }}
          setRefresh={setRefresh}
          setSearchTitle={setSearchTitle}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          refresh={refresh}
        />
      </div>
    </>
  );
};

export default connect(null, auth.actions)(Teams);
