import React from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { Label, Header, List, TextArea, Image } from 'semantic-ui-react';
import { IMAGES_URL } from '../../../config/functions';
import { selectTranslations } from '../../../config/i18n/slice';
import UserProfilePlaceholder from '../../../components/common/userProfilePlaceholder';

const Comments = ({ data }) => {
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const statusOptions = [
    {
      value: 'open',
      color: 'blue',
      label: t.workOrders.work_order_status.open,
    },
    {
      value: 'cancelled',
      color: 'darkgrey',
      label: t.workOrders.work_order_status.cancel,
    },
    {
      value: 'inProgress',
      color: 'yellow',
      label: t.workOrders.work_order_status.inProgress,
    },
    {
      value: 'onHold',
      color: 'red',
      label: t.workOrders.work_order_status.onHold,
    },
    {
      value: 'inReview',
      color: 'black',
      label: t.workOrders.work_order_status.inReview,
    },
    
    {
      value: 'completed',
      color: 'green',
      label: t.workOrders.work_order_status.completed,
    },
    
  ];
  return (
    <div>
      {/* <div className="comment-container">
        <label className="comment-label">Write the comment</label>
        <div className="add-comment">
          <TextArea
            className="comment-textarea"
            rows={6}
            placeholder="write the comment here..."
          />
        </div>

        <div className="mt-2 mb-2">
          <button
          
            className="send-action-button blue"
        
          >
            Send
          </button>
        </div>

      
      </div> */}

      {data.attributes.reason && data.attributes.reason.length ? (
        data.attributes.reason.map((one) => {
          const workOrderStatus = statusOptions.find(
            (status) => status.value == one.status
          );
          return (
            <>
          
              <div className="comment">
                <div className="d-flex justify-content-between">
                  <span className="user-img">
                    {one?.image?.data?.attributes ? (
                      <Image
                        avatar
                        title={`${one?.user?.data?.attributes?.firstName ||
                          ''} ${one?.user?.data?.attributes?.lastName || ''}`}
                        src={`${IMAGES_URL}${one?.user?.data?.attributes?.profileImage?.data?.attributes?.url}`}
                        style={{
                          marginRight: '8px',
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    ) : (
                      <UserProfilePlaceholder
                        name={`${one?.user?.data?.attributes?.firstName ||
                          ''} ${one?.user?.data?.attributes?.lastName || ''}`}
                        style={{
                          marginRight: '8px',
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    )}
                    <label className="item-label font-weight-bold">
                      <span>{`${one?.user?.data?.attributes?.firstName ||
                        ''} ${one?.user?.data?.attributes?.lastName ||
                        ''}`}</span>
                      <Moment
                        format="DD/MM/YYYY LT"
                        className="time"
                        date={one.createdAt}
                      />
                    </label>
                  </span>
                  <div className="d-flex">
                    <Label
                      circular
                      empty
                      color={workOrderStatus?.color || 'black'}
                      style={
                        selectedLanguage == 'en'
                          ? { marginRight: '5px' }
                          : { marginLeft: '5px' }
                      }
                      size="big"
                    />
                    {workOrderStatus?.label || one.status}
                  </div>
                </div>
                <p className="comment-text">{one.comment}</p>
                <div>
                  {one?.image?.data && one?.image?.data.length
                    ? one?.image?.data.map((el) => {
                        return (
                          <List divided relaxed>
                            <List.Item
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                window.open(
                                  IMAGES_URL + el?.attributes.url,
                                  '_blank'
                                );
                              }}
                            >
                              <List.Icon
                                name={
                                  el?.attributes.mime.match('image.*')
                                    ? 'image'
                                    : el?.attributes.mime.match('pdf')
                                    ? 'file pdf'
                                    : 'file'
                                }
                                size="large"
                                color="black"
                                verticalAlign="middle"
                              />
                              <List.Content>
                                <p className="font-weight-bold ml-2 mr-2">
                                  {el?.attributes.name}
                                </p>
                              </List.Content>
                            </List.Item>
                          </List>
                        );
                      })
                    : null}
                </div>
              </div>
            </>
          );
        })
      ) : (
        <div className='text-center'>
          <p className='required-text'>{t.common.na}</p>
        </div>
      )}
    </div>
  );
};
export default Comments;
