import React, { useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { selectTranslations } from "../app/config/i18n/slice";
import { useSelector } from "react-redux";
function useGetJsonDataFromExcelFile(props) {
  const t = useSelector(selectTranslations);

  const getJsonDataFromExcelFile = async (
    file,
    // title = t.categories.upload_excel_category_file,
    fieldList = [],
    module = "category"
  ) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          const binaryString = event.target.result;
          const workbook = XLSX.read(binaryString, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          const headers =
            module == "asset" || module == "part" || module == "location"
              ? parsedData[3]
              : parsedData[0];

          let columnIndexes = {};
          for (let i = 0; i < headers.length; i++) {
            if (fieldList.includes(headers[i])) {
              columnIndexes[headers[i]] = i;
            }
          }
          if (module == "category" || module == "jobTitle") {
            const formattedData = parsedData.slice(1).map((row) => {
              let rowDataCategory;
              if (module == "category") {
                rowDataCategory = {
                  name: row[columnIndexes[t.categories.table.name]],
                };
              } else {
                rowDataCategory = {
                  title: row[columnIndexes[t.jobTitle.table.name]],
                };
              }

              return rowDataCategory;
            });

            resolve(formattedData);
          } else if (module == "asset") {
            const formattedData = parsedData.slice(5).map((row) => {
              const rowData = {
                name:
                  row[columnIndexes[t.assets.sheet.sheet_name]] ||
                  row[columnIndexes[t.assets.sheet2.sheet_name]]
                    ? String(
                        row[columnIndexes[t.assets.sheet.sheet_name]] ||
                          row[columnIndexes[t.assets.sheet2.sheet_name]]
                      )
                    : null,
                description:
                  row[columnIndexes[t.assets.sheet.sheet_description]] ||
                  row[columnIndexes[t.assets.sheet2.sheet_description]]
                    ? String(
                        row[columnIndexes[t.assets.sheet.sheet_description]] ||
                          row[columnIndexes[t.assets.sheet2.sheet_description]]
                      )
                    : null,

                parentAsset:
                  row[columnIndexes[t.assets.sheet.sheet_parent_asset]] ||
                  row[columnIndexes[t.assets.sheet2.sheet_parent_asset]]
                    ? String(
                        row[columnIndexes[t.assets.sheet.sheet_parent_asset]] ||
                          row[columnIndexes[t.assets.sheet2.sheet_parent_asset]]
                      )
                    : null,
                serialNumber:
                  row[columnIndexes[t.assets.sheet.sheet_serialNumber]] ||
                  row[columnIndexes[t.assets.sheet2.sheet_serialNumber]]
                    ? row[columnIndexes[t.assets.sheet.sheet_serialNumber]] ||
                      row[columnIndexes[t.assets.sheet2.sheet_serialNumber]]
                    : "N/A",
                "qrcode/barcode":
                  row[columnIndexes[t.assets.sheet.qr_barcode]] ||
                  row[columnIndexes[t.assets.sheet2.qr_barcode]]
                    ? String(
                        row[columnIndexes[t.assets.sheet.qr_barcode]] ||
                          row[columnIndexes[t.assets.sheet2.qr_barcode]]
                      )
                    : null,
                model:
                  row[columnIndexes[t.assets.sheet.modal]] ||
                  row[columnIndexes[t.assets.sheet2.modal]]
                    ? String(
                        row[columnIndexes[t.assets.sheet.modal]] ||
                          row[columnIndexes[t.assets.sheet2.modal]]
                      )
                    : null,
                categories:
                  row[columnIndexes[t.assets.sheet.category]] ||
                  row[columnIndexes[t.assets.sheet2.category]]
                    ? String(
                        row[columnIndexes[t.assets.sheet.category]] ||
                          row[columnIndexes[t.assets.sheet2.category]]
                      )
                    : null,
                location:
                  row[columnIndexes[t.assets.sheet.location]] ||
                  row[columnIndexes[t.assets.sheet2.location]]
                    ? String(
                        row[columnIndexes[t.assets.sheet.location]] ||
                          row[columnIndexes[t.assets.sheet2.location]]
                      )
                    : null,
                subLocation:
                  row[columnIndexes[t.assets.sheet.subLocation]] ||
                  row[columnIndexes[t.assets.sheet2.subLocation]]
                    ? String(
                        row[columnIndexes[t.assets.sheet.subLocation]] ||
                          row[columnIndexes[t.assets.sheet2.subLocation]]
                      )
                    : null,
                customer:
                  row[columnIndexes[t.assets.sheet.customer]] ||
                  row[columnIndexes[t.assets.sheet2.customer]]
                    ? String(
                        row[columnIndexes[t.assets.sheet.customer]] ||
                          row[columnIndexes[t.assets.sheet2.customer]]
                      )
                    : null,
                vendor:
                  row[columnIndexes[t.assets.sheet.vendor]] ||
                  row[columnIndexes[t.assets.sheet2.vendor]]
                    ? String(
                        row[columnIndexes[t.assets.sheet.vendor]] ||
                          row[columnIndexes[t.assets.sheet2.vendor]]
                      )
                    : null,
                teams:
                  row[columnIndexes[t.assets.sheet.teams]] ||
                  row[columnIndexes[t.assets.sheet2.teams]]
                    ? String(
                        row[columnIndexes[t.assets.sheet.teams]] ||
                          row[columnIndexes[t.assets.sheet2.teams]]
                      )
                    : null,
                people:
                  row[columnIndexes[t.assets.sheet.people]] ||
                  row[columnIndexes[t.assets.sheet2.people]]
                    ? String(
                        row[columnIndexes[t.assets.sheet.people]] ||
                          row[columnIndexes[t.assets.sheet2.people]]
                      )
                    : null,
                purchasePrice:
                  row[columnIndexes[t.assets.sheet.p_price]] ||
                  row[columnIndexes[t.assets.sheet2.p_price]]
                    ? row[columnIndexes[t.assets.sheet.p_price]] ||
                      row[columnIndexes[t.assets.sheet2.p_price]]
                    : null,
                manufacturer:
                  row[columnIndexes[t.assets.sheet.manufacturer]] ||
                  row[columnIndexes[t.assets.sheet2.manufacturer]]
                    ? String(
                        row[columnIndexes[t.assets.sheet.manufacturer]] ||
                          row[columnIndexes[t.assets.sheet2.manufacturer]]
                      )
                    : null,
                descriptionRate:
                  row[columnIndexes[t.assets.sheet.d_rate]] ||
                  row[columnIndexes[t.assets.sheet2.d_rate]]
                    ? row[columnIndexes[t.assets.sheet.d_rate]] ||
                      row[columnIndexes[t.assets.sheet2.d_rate]]
                    : null,
                estimatedUsefulLife:
                  row[columnIndexes[t.assets.sheet.e_life]] ||
                  row[columnIndexes[t.assets.sheet2.e_life]]
                    ? Number(
                        row[columnIndexes[t.assets.sheet.e_life]] ||
                          row[columnIndexes[t.assets.sheet2.e_life]]
                      )
                    : null,
                warrantyExpirationDate:
                  row[columnIndexes[t.assets.sheet.w_date]] ||
                  row[columnIndexes[t.assets.sheet2.w_date]]
                    ? row[columnIndexes[t.assets.sheet.w_date]] ||
                      row[columnIndexes[t.assets.sheet2.w_date]]
                    : null,
                placedDate:
                  row[columnIndexes[t.assets.sheet.placed_date]] ||
                  row[columnIndexes[t.assets.sheet2.placed_date]]
                    ? row[columnIndexes[t.assets.sheet.placed_date]] ||
                      row[columnIndexes[t.assets.sheet2.placed_date]]
                    : null,
                additionalInformation:
                  row[columnIndexes[t.assets.sheet.add_date]] ||
                  row[columnIndexes[t.assets.sheet2.add_date]]
                    ? String(
                        row[columnIndexes[t.assets.sheet.add_date]] ||
                          row[columnIndexes[t.assets.sheet2.add_date]]
                      )
                    : null,
              };

              return rowData;
            });

            resolve(formattedData);
          } else if (module == "part") {
            const formattedData = parsedData.slice(5).map((row) => {
              const rowData = {
                name:
                  row[columnIndexes[t.parts.sheet.sheet_name]] ||
                  row[columnIndexes[t.parts.sheet2.sheet_name]]
                    ? String(
                        row[columnIndexes[t.parts.sheet.sheet_name]] ||
                          row[columnIndexes[t.parts.sheet2.sheet_name]]
                      )
                    : null,
                description:
                  row[columnIndexes[t.parts.sheet.description]] ||
                  row[columnIndexes[t.parts.sheet2.description]]
                    ? String(
                        row[columnIndexes[t.parts.sheet.description]] ||
                          row[columnIndexes[t.parts.sheet2.description]]
                      )
                    : null,
                category:
                  row[columnIndexes[t.parts.sheet.category]] ||
                  row[columnIndexes[t.parts.sheet2.category]]
                    ? String(
                        row[columnIndexes[t.parts.sheet.category]] ||
                          row[columnIndexes[t.parts.sheet2.category]]
                      )
                    : null,
                unitCost:
                  row[columnIndexes[t.parts.sheet.unit_cost]] ||
                  row[columnIndexes[t.parts.sheet2.unit_cost]]
                    ? row[columnIndexes[t.parts.sheet.unit_cost]] ||
                      row[columnIndexes[t.parts.sheet2.unit_cost]]
                    : null,
                measuringUnit:
                  row[columnIndexes[t.parts.sheet.unit_type]] ||
                  row[columnIndexes[t.parts.sheet2.unit_type]]
                    ? String(
                        row[columnIndexes[t.parts.sheet.unit_type]] ||
                          row[columnIndexes[t.parts.sheet2.unit_type]]
                      )
                    : null,
                // minimumQuantity: row[columnIndexes[t.parts.sheet.min_qty]] || row[columnIndexes[t.parts.sheet2.min_qty]]
                //   ? row[columnIndexes[t.parts.sheet.min_qty]] || row[columnIndexes[t.parts.sheet2.min_qty]]
                //   : null,

                minimumQuantity:
                  row[columnIndexes[t.parts.sheet.min_qty]] !== undefined &&
                  row[columnIndexes[t.parts.sheet.min_qty]] !== null
                    ? row[columnIndexes[t.parts.sheet.min_qty]]
                    : row[columnIndexes[t.parts.sheet2.min_qty]] !==
                        undefined &&
                      row[columnIndexes[t.parts.sheet2.min_qty]] !== null
                    ? row[columnIndexes[t.parts.sheet2.min_qty]]
                    : null,
                // maximumQuantity: row[columnIndexes[t.parts.sheet.max_qty]] || row[columnIndexes[t.parts.sheet2.max_qty]]
                //   ? row[columnIndexes[t.parts.sheet.max_qty]] || row[columnIndexes[t.parts.sheet2.max_qty]]
                //   : null,

                maximumQuantity:
                  row[columnIndexes[t.parts.sheet.max_qty]] !== undefined &&
                  row[columnIndexes[t.parts.sheet.max_qty]] !== null
                    ? row[columnIndexes[t.parts.sheet.max_qty]]
                    : row[columnIndexes[t.parts.sheet2.max_qty]] !==
                        undefined &&
                      row[columnIndexes[t.parts.sheet2.max_qty]] !== null
                    ? row[columnIndexes[t.parts.sheet2.max_qty]]
                    : null,
                type:
                  row[columnIndexes[t.parts.sheet.part_type]] ||
                  row[columnIndexes[t.parts.sheet2.part_type]]
                    ? String(
                        row[columnIndexes[t.parts.sheet.part_type]] ||
                          row[columnIndexes[t.parts.sheet2.part_type]]
                      ).toLowerCase()
                    : null,
                isNonStock:
                  row[columnIndexes[t.parts.sheet.stock]] ||
                  row[columnIndexes[t.parts.sheet2.stock]]
                    ? String(
                        row[columnIndexes[t.parts.sheet.stock]] ||
                          row[columnIndexes[t.parts.sheet2.stock]]
                      ).toLowerCase()
                    : null,
                location:
                  row[columnIndexes[t.parts.sheet.location]] ||
                  row[columnIndexes[t.parts.sheet2.location]]
                    ? String(
                        row[columnIndexes[t.parts.sheet.location]] ||
                          row[columnIndexes[t.parts.sheet2.location]]
                      )
                    : null,
                area:
                  row[columnIndexes[t.parts.sheet.area]] ||
                  row[columnIndexes[t.parts.sheet2.area]]
                    ? String(
                        row[columnIndexes[t.parts.sheet.area]] ||
                          row[columnIndexes[t.parts.sheet2.area]]
                      )
                    : null,
                quantity:
                  row[columnIndexes[t.parts.sheet.quantity]] ||
                  row[columnIndexes[t.parts.sheet2.quantity]]
                    ? row[columnIndexes[t.parts.sheet.quantity]] ||
                      row[columnIndexes[t.parts.sheet2.quantity]]
                    : null,
                assets:
                  row[columnIndexes[t.parts.sheet.assets]] ||
                  row[columnIndexes[t.parts.sheet2.assets]]
                    ? String(
                        row[columnIndexes[t.parts.sheet.assets]] ||
                          row[columnIndexes[t.parts.sheet2.assets]]
                      )
                    : null,
                vendors:
                  row[columnIndexes[t.parts.sheet.vendors]] ||
                  row[columnIndexes[t.parts.sheet2.vendors]]
                    ? String(
                        row[columnIndexes[t.parts.sheet.vendors]] ||
                          row[columnIndexes[t.parts.sheet2.vendors]]
                      )
                    : null,
                people:
                  row[columnIndexes[t.parts.sheet.people]] ||
                  row[columnIndexes[t.parts.sheet2.people]]
                    ? String(
                        row[columnIndexes[t.parts.sheet.people]] ||
                          row[columnIndexes[t.parts.sheet2.people]]
                      )
                    : null,
                teams:
                  row[columnIndexes[t.parts.sheet.teams]] ||
                  row[columnIndexes[t.parts.sheet2.teams]]
                    ? String(
                        row[columnIndexes[t.parts.sheet.teams]] ||
                          row[columnIndexes[t.parts.sheet2.teams]]
                      )
                    : null,
                serialNumber:
                  row[columnIndexes[t.parts.sheet.serialNumber]] ||
                  row[columnIndexes[t.parts.sheet2.serialNumber]]
                    ? String(
                        row[columnIndexes[t.parts.sheet.serialNumber]] ||
                          row[columnIndexes[t.parts.sheet2.serialNumber]]
                      )
                    : null,
                "qrcode/barcode":
                  row[columnIndexes[t.parts.sheet.qr_barcode]] ||
                  row[columnIndexes[t.parts.sheet2.qr_barcode]]
                    ? String(
                        row[columnIndexes[t.parts.sheet.qr_barcode]] ||
                          row[columnIndexes[t.parts.sheet2.qr_barcode]]
                      )
                    : null,
              };

              return rowData;
            });

            resolve(formattedData);
          } else if(module == "location") {
            const formattedData = parsedData.slice(5).map((row) => {
              const rowData = {
                name:
                  row[columnIndexes[t.locations.sheet.name]] ||
                  row[columnIndexes[t.locations.sheet2.name]]
                    ? String(
                        row[columnIndexes[t.locations.sheet.name]] ||
                          row[columnIndexes[t.locations.sheet2.name]]
                      )
                    : null,
                    parentLocation:
                  row[columnIndexes[t.locations.sheet.parent_loc]] ||
                  row[columnIndexes[t.locations.sheet2.parent_loc]]
                    ? String(
                        row[columnIndexes[t.locations.sheet.parent_loc]] ||
                          row[columnIndexes[t.locations.sheet2.parent_loc]]
                      )
                    : null,

                    address:
                  row[columnIndexes[t.locations.sheet.address]] ||
                  row[columnIndexes[t.locations.sheet2.address]]
                    ? String(
                        row[columnIndexes[t.locations.sheet.address]] ||
                          row[columnIndexes[t.locations.sheet2.address]]
                      )
                    : null,
                area:
                  row[columnIndexes[t.locations.sheet.area]] ||
                  row[columnIndexes[t.locations.sheet2.area]]
                    ? String(
                        row[columnIndexes[t.locations.sheet.area]] ||
                          row[columnIndexes[t.locations.sheet2.area]]
                      )
                    : null,
                    propertyType:
                  row[columnIndexes[t.locations.sheet.property_type]] ||
                  row[columnIndexes[t.locations.sheet2.property_type]]
                    ? String(
                        row[columnIndexes[t.locations.sheet.property_type]] ||
                          row[columnIndexes[t.locations.sheet2.property_type]]
                      )
                    : null,
              };

              return rowData;
            });

            resolve(formattedData);
          }
        } catch (error) {
          resolve([]);
        }
      };

      reader.onerror = (error) => {
        resolve([]);
      };

      reader.readAsBinaryString(file);
    });
  };
  return { getJsonDataFromExcelFile };
}

export default useGetJsonDataFromExcelFile;
