export default {
  title: "Locations",
  title_small: "location",
  create_location: "Add Location",
  edit_location: "Edit Location",
  edit_sublocation: "Edit Sub Location",
  tabs: {
    first: "List View",
    second: "Map View",
  },
  add: "Location",
  filter: {
    search: "Search",
    customers: "Customers",
    countries: "Country",
    cities: "City",
    createdByUser: "Created By",
    createdAt: "Created At",
    search_by_country: "Filter By Countries",
    search_by_cities: "Filter By Cities",
    selected: "selected",
    select_people: "Select People",
  },
  store: "Store",

  propertyType: {
    officeBuildings: "Office Buildings",
    industrialFacilities: "Industrial Facilities",
    retailSpaces: "Retail Spaces",
    educationalInstitutions: "Educational Institutions",
    healthcareFacilities: "Healthcare Facilities",
    residentialBuildings: "Residential Buildings",
    hotelsAndHospitality: "Hotels and Hospitality",
    governmentBuildings: "Government Buildings",
    dataCenters: "Data Centers",
    sportsAndRecreationFacilities: "Sports and Recreation Facilities",
    culturalInstitutions: "Cultural Institutions",
    transportationHubs: "Transportation Hubs",
    laboratories: "Laboratories",
    agriculturalFacilities: "Agricultural Facilities",
    correctionalFacilities: "Correctional Facilities",
    utilitiesAndEnergyFacilities: "Utilities and Energy Facilities",
    commercialParkingStructures: "Commercial Parking Structures",
    mixedUseDevelopments: "Mixed-Use Developments",
    telecommunicationFacilities: "Telecommunication Facilities",
    financialInstitutions: "Financial Institutions",
    governmentHousing: "Government Housing",
    religiousBuildings: "Religious Buildings",
    shoppingCenters: "Shopping Centers",
    technologyAndInnovationHubs: "Technology and Innovation Hubs",
    entertainmentVenues: "Entertainment Venues",
  },
  created_at_options: {
    any_date: "Any Date",
    today: "Today",
    this_month: "This Month",
    this_year: "This Year",
    last_7_days: "Last 7 Days",
    last_month: "Last Month",
    last_year: "Last Year",
  },
  table: {
    name: "Name",
    ID: "ID",
    address: "Address",
    country: "Country",
    city: "City",
    customer: "Customers",
    createdByUser: "Created By",
    createdAt: "Created At",
    updatedAt: "Updated At",
    action: "Action",
  },
  upload: {
    location: "Bulk Upload Locations",
    location_sheet: "Upload Location Sheet",
    download: "Download Location Template",
    file: "Locations Template.xlsx",
    imported_location: "Locations Uploaded",
  },
  sheet: {
    name: "Location Name*",
    parent_loc: "Parent Location Name",
    address: "Address*",
    area: "Area",
    property_type: "Property Type",
  },

  sheet2: {
    name: "اسم الموقع *",
    parent_loc: "اسم الموقع الرئيسي",
    address: "العنوان *",
    area: "المنطقة",
    property_type: "نوع العقار",
  },
  no_location_statement: "Looks like you don't have Locations yet.",
  add_location_statement: "Press the " + " button to add your first Location.",
  form: {
    propertyType: "Property Type",
    select_propertyType: "Add property type",
    add_location: "Add Location",
    edit_location: "Edit Location",
    add_sub_location: "Add Sub Location",
    parent_location: "Parent Location",
    location_name: "Location Name",
    sub_location_name: "Sub Location Name",
    enter_location_name: "Add location name",
    enter_sub_location_name: "Add Sub Location Name",
    address: "Address",
    is_sub_location: "Is Sub Location?",
    country_add: "Add country",
    city_add: "Add city",
    country: "Country",
    address_placeholder: "Search address Google Maps",
    city: "City",
    people: "People",
    select_users: "Select Users",
    teams: "Teams",
    select_teams: "Select Teams",
    customer: "Customers",
    select_customer: "Add customer",
    vendors: "Vendors",
    select_vendors: "Add vendor",
    procedure: "Procedure",
    select_procedure: "Select Procedure",
    custom_data: "Custom Data",
    label: "Label",
    field_label: "Field Label",
    value: "Value",
    field_value: "Field Value",
    remove: "Remove",
    submit: "Submit",
    cancel: "Cancel",
    choose_from_existing_location: "Choose from existing location",
    details: "Details",
    map: "Map",
    assignees: "Assignees",
    sub_locations: "Sub Locations",
    sub_location: "Sub Location",

    work_orders: "Work Orders",
    qr_code: "QR Code",
    map_location: "Map Location",
    geo_markers: "Geofencing Markers",
    assign_data_statement:
      "You didn't assign data on the map yet, click add to now.",
    assign_geo_data_statement:
      "You didn't assign Geofencing coordinates on this data yet, click add to now.",
  },
  work_order_fields: {
    title: "Title",
    ID: "ID",
    status: "Status",
    priority: "Priority",
    category: "Category",
    due_date: "Due Date",
    procedure: "Procedure",
  },
  work_order_status: {
    open: "Open",
    inProgress: "In-Progress",
    onHold: "On-Hold",
    inReview: "In-Review",
    completed: "Completed",
  },
  priority_status: {
    none: "None",
    low: "Low",
    medium: "Medium",
    high: "High",
  },
  work_order_category: {
    Damage: "Damage",
    Electrical: "Electrical",
    Inspection: "Inspection",
    Mechanical: "Mechanical",
    Preventive: "Preventive",
    Project: "Project",
    Refrigeration: "Refrigeration",
    Safety: "Safety",
    Sop: "Standard Operating Procedure",
  },
  due_date_status: {
    any_date: "Any Date",
    today: "Today",
    tomorrow: "Tomorrow",
    next_7_day: "Next 7 Days",
    next_30_day: "Next 30 Days",
    this_month: "This Month",
    this_year: "This Year",
    yesterday: "Yesterday",
    last_week: "Last Week",
    last_month: "Last Month",
    last_year: "Last Year",
    past_due: "Past Due",
  },
  location: "Location",
  add_on_map: "Add On Map",
  update_on_map: "Update On Map",
  add_location_on_map_statement: "Would you like to add location on map?",
  add_location_on_map: "Add location on map",
  update_location_on_map: "Update location on map",
  drag_marker_statement:
    "Drag the marker or enter the latitude and longtitude below:",
  location_added_on_map_success: " Location added on map successfully",
  add_geo_coord_statement:
    "Would you like to add Geofencing Coordinates on map?",
  add_geo_coord: "Add geofencing coordinates",
  click_to_add_coord: "Click on map to add coords",
  geo_add_success: "Geofencing added on map successfully",
  update_geo_coord: "Update geofencing coordinates",
  latitude: "Latitude",
  latitude_add: "Add latitude",
  longitude: "Longitude",
  latitude_add: "Add longitude",
  location_updated_success: "Location updated successfully",
  sub_location_updated_success: "Sub-Location updated successfully",
  location_map_view: "Locations - Map View",
  no_location_added: "Start adding location",
  delete_location_statement: "Are you sure you want to delete this location?",
  delete_sublocation_statement:
    "Are you sure you want to delete this sub-location?",
  related_location_error: "Unable to delete location. Linked with other forms",
  delete_location_success: "Location deleted successfully",
  delete_sublocation_success: "Sub Location deleted successfully",
  add_sub: "Sub-location",
  how_to_add_locations: "how to add location",
  sub_location_tooltip: "Sub-location",
  location_map: "Please select location from the map.",
  loc_unique: "The location name must be unique",
  sub_unique: "The sub-location name must be unique",
};
