import React, { useState, useEffect, createRef } from 'react';
import { Icon, Card, Header, Table, Dimmer, Loader } from 'semantic-ui-react';
import { selectTranslations } from '../../../config/i18n/slice';
import { useSelector } from 'react-redux';
import {
  getTeamByID,
  humanize,
  bytesToSize,
  IMAGES_URL,
  getAllPOByPart,
} from '../../../config/functions';
import { useHistory } from 'react-router';
import { POIconBlue } from '../../../../Assets/Icons/svg';
import moment from 'moment';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
function Files({ targetedData }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return 'JOD';
    }
  });

  const {
    userPermissions,
    checkForLinkPermission,
  } = usePermissionsSimplified();

  const history = useHistory();
  /* This `useEffect` hook is responsible for fetching data related to purchase orders based on the
`targetedData` prop whenever it changes. Here's a breakdown of what it does: */
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const fetchData = await getAllPOByPart(organisationId, targetedData?.id);
      if (fetchData) {
        if (fetchData.length > 0) {
          // Filter orderItems based on targetedData.id
          const filteredData = fetchData.map((order) => ({
            ...order,
            attributes: {
              ...order.attributes,
              orderItems: {
                ...order.attributes.orderItems,
                items: order.attributes.orderItems.items.filter(
                  (item) => item.part.data.id === targetedData.id
                ),
              },
            },
          }));
          setData(filteredData);
        } else {
          setData([]);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [targetedData]);
  const t = useSelector(selectTranslations);

  return (
    <>
      {loading ? (
        <Dimmer active inverted>
          <Loader inverted>{t.common.loading}</Loader>
        </Dimmer>
      ) : data.length > 0 ? (
        <div className="po-section-wrapper">
          {data.map((items) => (
            <div key={items?.id} className="po-section-detail mt-5">
              <div className="po-section-header">
                {checkForLinkPermission('purchase-order') ? (
                  <p
                    className="c-pointer"
                    onClick={() =>
                      history.push(`/purchase-orders?id=${items?.attributes?.countId}`)
                    }
                  >
                    {items?.attributes?.details?.title}
                  </p>
                ) : (
                  <p className="c-pointer" style={{ color: 'black' }}>
                    {items?.attributes?.details?.title}
                  </p>
                )}
                <p>{items?.attributes?.details?.poNumber}</p>{' '}
                {/* Correct :ed property name */}
              </div>
              <div className="po-section-detail-in">
                <div className="row text-left">
                  <div className="col-md-6">
                    <p className="unit-text-po">
                      {t.parts.form.unit_cost}:{' '}
                      <span className="ml-3 mr-3">
                        {
                          items?.attributes?.orderItems?.items[0]?.part?.data
                            ?.attributes?.unitCost
                        }{' '}
                        {organisationCurrency}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p className="unit-text-po">
                      {t.parts.form.ordered_qty}:{' '}
                      <span className="ml-3 mr-3">
                        {items?.attributes?.orderItems?.items[0]?.qtyToOrder}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-12 text-right">
                    <div className="unit-text-po mt-4">
                      {moment(items?.attributes?.createdAt).format(
                        'DD/MM/YYYY hh:mm A'
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center">
          <POIconBlue />
          <p
            style={{ fontSize: '16px', color: '#44546F' }}
            className="required-text mt-3"
          >
            {t.parts.form.no_po_found}
          </p>
        </div>
      )}
    </>
  );
}

export default Files;
